'use strict';

var ajax =  require('../../ajax'),
    util = require('../../util');

var updateContainer = function (data, $ele) {
    var $availabilityMsg = $('#pdpMain .availability-web .availability-msg');
    //TSED-144
    var $addToCartBtn = $ele.parents('.quantity').parents('.inventory').siblings('.add-to-cart');
    if($ele && $($ele).closest('.product-set-item').length > 0){
        $availabilityMsg = $($ele).closest('.product-set-item').find('.availability-web .availability-msg');
    }
   // $('#pdpMain .add-to-cart').removeAttr('disabled', false);
    var message,qty,remain,backremain,preremain; // this should be lexically scoped, when `let` is supported (ES6)
    if (!data) {
        $availabilityMsg.html(Resources.ITEM_STATUS_NOTAVAILABLE);
        return;
    }
    $availabilityMsg.empty();
    // Look through levels ... if msg is not empty, then create span el
    if (data.levels.IN_STOCK > 0) {
        //TSED-144
        $addToCartBtn.removeClass('disabled');
        if($('#pdpMain .product-add-to-cart').find('.add-all-to-cart').length > 0) {
            $('#pdpMain .product-add-to-cart').find('.add-all-to-cart').removeClass('disabled');
        }
        if (data.levels.PREORDER === 0 && data.levels.BACKORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            // Just in stock
            message = Resources.IN_STOCK;
        } else {
            // In stock with conditions ...
            message = data.inStockMsg;
            qty = data.statusQuantity;
        }
        if(qty && qty!=undefined){
        	$availabilityMsg.append('<p class="in-stock-msg" itemprop="availability" content="http://schema.org/InStock">Order Quantity: ' + qty + '</p>');
        }
        $availabilityMsg.append('<p class="in-stock-msg" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
    }
    if (data.levels.PREORDER > 0) {
        if (data.levels.IN_STOCK === 0 && data.levels.BACKORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            message = Resources.PREORDER;
        } else {
            message = data.preOrderMsg;
            preremain = "remaining";
        }
        if(preremain && preremain.length > 0){
        	$availabilityMsg.append('<p class="backorder-msg backorder-remain" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
        }else{
        	 $availabilityMsg.append('<p class="backorder-msg" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
             $availabilityMsg.append('<p class="availabilityMessages">' + data.inStockDateMsg + '</p>');
             //TSED-144
             $addToCartBtn.removeClass('disabled');
        }
    }
    if (data.levels.BACKORDER > 0) {
        if (data.levels.IN_STOCK === 0 && data.levels.PREORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            message = Resources.BACKORDER;
        } else {
            message = data.backOrderMsg;
            backremain = "remaining";
        }
        if(backremain && backremain.length > 0){
        	$availabilityMsg.append('<p class="backorder-msg backorder-remain" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
        }else{
        	$availabilityMsg.append('<p class="backorder-msg" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
            $availabilityMsg.append('<p class="availabilityMessages">' + data.inStockDateMsg + '</p>');
            //TSED-144
            $addToCartBtn.removeClass('disabled');
        }
    }
    //Commented as per TSED-90:When a user adds units exceeding the available stock, only the exceeded quantity messaging displays.
    //The in-stock messaging does not display.
    /*if (data.inStockDate !== '') {
        $availabilityMsg.append('<p class="in-stock-date-msg" itemprop="availability" content="http://schema.org/InStock">' + String.format(Resources.IN_STOCK_DATE, data.inStockDate) + '</p>');
    }*/
    if (data.levels.NOT_AVAILABLE > 0) {
        if (data.levels.PREORDER === 0 && data.levels.BACKORDER === 0 && data.levels.IN_STOCK === 0) {
            message = Resources.NOT_AVAILABLE;
        } else {
            message = Resources.REMAIN_NOT_AVAILABLE;
            remain = "remaining";
        }
        if(remain && remain.length > 0){
            //TSED-144
            $addToCartBtn.addClass('disabled');
            if($('#pdpMain .product-add-to-cart').find('.add-all-to-cart').length > 0) {
                $('#pdpMain .product-add-to-cart').find('.add-all-to-cart').addClass('disabled');
            }
        	$availabilityMsg.append('<p class="not-available-msg remaining" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
        }else{
            //TSED-144
            $addToCartBtn.removeClass('disabled');
            if($('#pdpMain .product-add-to-cart').find('.add-all-to-cart').length > 0) {
                $('#pdpMain .product-add-to-cart').find('.add-all-to-cart').removeClass('disabled');
            }
        	$availabilityMsg.append('<p class="not-available-msg" itemprop="availability" content="http://schema.org/InStock">' + message + '</p>');
        }
       // $('#pdpMain .add-to-cart').attr('disabled', true);
    }
};

var getAvailability = function () {
	var qty = $(this).val();
	var pid = $('#pdpMain [name="pid"]');
	var $this = $(this);
		
	if($(this).closest('.product-set-item').length > 0){
		pid = $(this).closest('.product-set-item').find('[name="pid"]');
	}
	
	if(qty!= '' && pid!= ''){
		ajax.getJson({
	        url: util.appendParamsToUrl(Urls.getAvailability, {
	            pid: $(pid).val(),
	            Quantity: $(this).val()
	        }),
	        callback: updateContainer,
	        ele: $this
	    });
	}
};

module.exports = function () {
    $('#pdpMain').on('blur keyup change', '.pdpForm input[name="Quantity"]', getAvailability);
};
