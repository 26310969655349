'use strict';
require('./latinize')();
var _ = require('lodash'),
	imagesLoaded = require('imagesloaded');
var atmevents = require('./atmevents');
var gtmevents = require('./gtm');
var specialKeys = [8, 9, 17, 46, 36, 35, 37, 39];
var regex = {
    alphaonly: /^([A-Za-z ]+)$/,
    alphanumeric: /^([A-Za-z0-9 ]+)$/,
    numericCust: /[\~\`\!\@\#\$\%\^\&\*\(\)\_\+\.\=\[\]\{\}\\\|\;\:\'\"\,\<\>\?\/A-Za-z]/g,
    numeric: /[\~\`\!\@\#\$\%\^\&\*\(\)\-\_\+\.\=\[\]\{\}\\\|\;\:\'\"\,\<\>\?\/A-Za-z]/g,
    numericDecimal: /[\~\`\!\@\#\$\%\^\&\*\(\)\-\_\+\=\[\]\{\}\\\|\;\:\'\"\,\<\>\?\/A-Za-z]/g,
    allowLtdChars: /[\'\-\,\.\/A-Za-z]/g
};


var util = {
    /** 
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function(url, name, value) {
        // quit if the param already exists 
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function(url, params) {
        var _url = url;
        _.each(params, function(value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function(url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function(url) {
        var qs;
        if (!_.isString(url)) {
            return;
        }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function(el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function(path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function(url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function(urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function(url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function() {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function(qs) {
        if (!qs || qs.length === 0) {
            return {};
        }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillBillingAddressFields: function(address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            //$form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);

            $form.find('[name$="' + 'dwfrm_billing_billingAddress_addressFields_' + field.replace('Code', '') + '"]').val(address[field]);
            $form.find('[name$="' + 'dwfrm_billing_billingAddress_' + field.replace('Code', '') + '"]').val(address[field]);

            // update the state fields
            if (field === 'countryCode') {
                //$form.find('[name$="country"]').trigger('change');
                $form.find('select[name$="_billing_billingAddress_addressFields_country"]').val(address.countryCode.toUpperCase());
                $form.find('select[name$="_billing_billingAddress_addressFields_country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                //$form.find('[name$="state"]').val(address.stateCode);
                $form.find('select[name$="_billingAddress_addressFields_states_state"]').val(address.stateCode);
                $form.find('select[name$="_billingAddress_addressFields_states_state"]').trigger('change');
            }
            $('body').find('.billingphone').val(address.phone);

            if (address.phone.indexOf("|") > 0) {
                var indexOfDelimiter = address.phone.indexOf("|");
                var phone = address.phone.substring(indexOfDelimiter + 1, address.phone.length);
                var countryCode = address.phone.substring(0, indexOfDelimiter);
                $form.find('input[name*="_billing_billingAddress_phone"]').val(phone);
                $form.find('select[name$="_billing_billingAddress_countryCodes_phoneCountryCode"]').val(countryCode).change();
            }
        }
    },

    fillShippingAddressFields: function(address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            //$form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);

            $form.find('[name$="' + 'dwfrm_singleshipping_shippingAddress_addressFields_' + field.replace('Code', '') + '"]').val(address[field]);
            $form.find('[name$="' + 'dwfrm_singleshipping_shippingAddress_' + field.replace('Code', '') + '"]').val(address[field]);

            if (address.phone.indexOf("|") > 0) {
                var indexOfDelimiter = address.phone.indexOf("|");
                var phone = address.phone.substring(indexOfDelimiter + 1, address.phone.length);
                var countryCode = address.phone.substring(0, indexOfDelimiter);
                $form.find('select[name$="_singleshipping_shippingAddress_addressFields_countryCodes_phoneCountryCode"]').val(countryCode).change();
                $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val(phone);
            }

            // update the state fields
            if (field === 'countryCode') {
                //$form.find('[name$="country"]').trigger('change');
                $form.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val(address.countryCode.toUpperCase());
                $form.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                //$form.find('[name$="state"]').val(address.stateCode);
                $form.find('select[name$="_shippingAddress_addressFields_states_state"]').val(address.stateCode);
                $form.find('select[name$="_shippingAddress_addressFields_states_state"]').trigger('change');

            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function() {
        $('form').find('textarea[data-character-limit]').each(function() {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function(container, message) {
        $(container).on('click', '.delete', function() {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function(xLocation) {
        $('html, body').animate({
            scrollTop: xLocation
        }, 500);
    },

    isMobile: function() {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },
    selectbox: function() {
        this.buildselects();
        this.updateselect();

        $(window).load(function() {
            if ($('.selected-option').html() == 'Find a Class') {
                $('.selected-option').addClass("show-icon");
            } else {
                $('.selected-option').removeClass("show-icon");
            }
        });
        $(document).on('click', '.selected-option', function() {
            var $h = 0;
            var $this = $(this);
            if ($this.siblings('select').is(':disabled')) {
                return false;
            }

            $('.custom-select.current_item select').trigger('blur');
            $('.custom-select').not($this.closest('.custom-select')).removeClass('current_item');
            $this.siblings('.selection-list').css('top', $this.outerHeight() - 1);
            $this.closest('.custom-select').toggleClass('current_item');

            if (!$this.closest('.custom-select').hasClass('current_item')) {
                $this.siblings('select').trigger('blur');
                if (!util.isMobile()) {
                    $this.siblings('select').addClass('focus_none').trigger('focus');
                }
            }

            if ($this.siblings('.selection-list').find('li').length > 10) {
                $.each($this.siblings('.selection-list').find('li:visible').slice(0, 10), function() {
                    $h += $(this).outerHeight();
                    $this.siblings('.selection-list').height($h - 1);
                });
                var h = 0;
                $.each($this.closest('.custom-select').find('.selection-list li:visible').splice(0, $this.closest('.custom-select').find('.selection-list li.selected:visible').index() - 1), function() {
                    h += $(this).outerHeight();
                })
                $this.siblings('.selection-list').scrollTop(h);


            } else {
                $this.siblings('.selection-list').height("auto");
            }
            $(".class-search .class-divided .selection-list").height("126px");
            // for checkout new design
            if (!SitePreferences.OLD_CHECKOUT_ENABLED) {
                $(".country-code-row .selection-list, .country_code_label .selection-list").height("175px");
            }

            if ($(window).width() > 767) {
                if ($('.plp-content .plp-refine-main .selection-list').is(':visible')) {
                    $('.selected-option.selected').addClass('active-refine');
                } else {
                    $('.selected-option.selected').removeClass('active-refine');
                }
            }
        }).on('click', '.selection-list li', function() {
            var $item = $(this).closest('.custom-select');
            $item.find('.select-class').addClass('rm-hod-icon');
            if($('.custom-select').hasClass('location-radius') && $(window).width() < 1024) {
            	$item.find('.select-class').addClass('changed');
            }
            $item.find('li').removeClass('hover selected');
            $(this).addClass('selected');
            $item.find('select option').eq($(this).index()).prop('selected', true);
            $item.find('.selected-option').text($(this).text());
            $item.removeClass('current_item');
            $('.error-form.date-card').hide();
            $item.find('select').trigger('change');
            $item.find('select').trigger('blur');
            if (!util.isMobile()) {
                $item.find('select').addClass('focus_none').trigger('focus');
            }
        }).on('change input', 'select', function() {
            util.updateselect();
        }).on('focus', 'select', function(e) {
            if (!$(this).hasClass('focus_none') && !util.isMobile() && $(e.delegateTarget.activeElement).is(e.target)) {
                if ($(this).closest('form').find(':input:not([type="hidden"])').first().attr('id') == $(this).attr('id') && !$(this).hasClass('focused-first')) {
                    $(this).addClass('focused-first');
                    return;
                }
                $(this).siblings('.selected-option').trigger('click');
            }
        });

        $(document).on('click', function(e) {
            if (!$('.custom-select.current_item').is(e.target) && !$('.custom-select.current_item').find('*').is(e.target)) {
                util.closeselectbox();
                if ($(window).width() > 767) {
                    if ($('.plp-content .plp-refine-main .selection-list').is(':visible')) {
                        $('.selected-option.selected').addClass('active-refine');
                    } else {
                        $('.selected-option.selected').removeClass('active-refine');
                    }
                }
            }
        });

        $('body').off('keydown').on('keydown', function(e) {
            var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0,
                $active_select = $('.custom-select.current_item');

            if ($active_select.length > 0 && !util.isMobile()) {
                e.preventDefault();
                var $list = $active_select.find('li'),
                    $active;

                if (!$active_select.find('li.selected').length) {
                    $active_select
                        .find('li')
                        .eq($active_select.find('option:selected').index())
                        .addClass('selected');
                }

                if (key === 8) { // Delete key
                    window.$currentkeycode = window.$currentkeycode.slice(0, -1);
                } else {
                    window.$currentkeycode += $.trim(String.fromCharCode(key).toLowerCase());
                }
                $active = $active_select.find("li[label^='" + window.$currentkeycode + "']");

                if ($active.length == 0) {
                    window.$currentkeycode = window.$currentkeycode.substr(window.$currentkeycode.length - 1, 1);
                    $active = $active_select.find("li[label^='" + window.$currentkeycode + "']");
                }

                if (key == 40) {
                    $active = $active_select.find('li.selected');
                    if ($active.next().length > 0) {
                        $active_select.find('li').removeClass('selected');
                        $active.next().addClass('selected');
                    }
                } else if (key == 38) {
                    $active = $active_select.find('li.selected');
                    if ($active.prev().length > 0) {
                        $active_select.find('li').removeClass('selected');
                        $active.prev().addClass('selected');
                    }
                } else if (key === 13) {
                    if ($active.length == 0 && $active_select.find('li.selected').length > 0) {
                        $active_select.find('li.selected').trigger('click');
                    } else {
                        $active.eq(0).trigger('click');
                    }
                } else if (key === 9 || key === 27) {
                    util.closeselectbox();
                } else if ($active.length != 0) {
                    $list.removeClass('selected');
                    $active.eq(0).addClass('selected');
                }
                if (key === 8) { // Delete key
                    return false;
                }

                var h = 0;
                if ($active_select.find('.selection-list li.selected').length > 0) {
                    $.each($active_select.find('.selection-list li').splice(0, $active_select.find('.selection-list li.selected').index() - 1), function() {
                        h += $(this).outerHeight();
                    })
                }

                $active_select.find('.selection-list').scrollTop(h);
            }
        }).off('click').on('click', function(e) {
            $('select').removeClass('select-focus');
            $('.ui-dialog:visible .dialogInput').remove();
        });
    },
    closeselectbox: function() {
        $('.custom-select.current_item select').trigger('blur');
        $('.custom-select').removeClass('current_item');
        $('.custom-select li').removeClass('hover');
        $('select').removeClass('focus_none');
    },
    buildselects: function() {
        $('.custom-select').each(function() {
            var $this = $(this),
                $select = $this.find('select'),
                $selOpt = $select.find('option:selected'),
                selOptIndex = $selOpt.index(),
                $opts = $this.find('.selection-list li'),
                firstOpt = $this.find('option').eq(0).val();

            if (selOptIndex > -1) {
                $opts.each(function(i) {
                    var $t = $(this);
                    $t.attr('label', $.trim($t.text().toLocaleLowerCase()));
                    if (i === 0 && firstOpt === '') {
                        $t.addClass('hide');
                    }
                });

                util.updateselect($select);

                $this.find('li').removeClass('selected').eq(selOptIndex).addClass('selected');

            }
        });
    },
    updateselect: function() {
    	
        $('.custom-select').each(function() {
            var $this = $(this);
            if ($this.find('.selected-option').length == 0) {
                $this.append('<div class="selected-option"></div>');
            }
            if ($this.find('.selection-list').length == 0) {
                $this.append('<div class="selection-list"></div>');
                var $list = '';
                $this.find('option').each(function() {
                    //@FIX-PHSSECOM-647
                    var $displayText = $(this).text();
                    if ($displayText != undefined && $displayText != null && $displayText.replace(/ /g, '').split('-').length > 1 && $displayText.replace(/ /g, '').split('-')[1].split('|').length == 2) {
                        var $price = $displayText.replace(/ /g, '').split('-')[1].split('|');
                        var $displayName = $displayText.split('-');
                        $displayText = $displayName[0] + '- <span style="text-decoration: line-through">' + $price[0] + '</span> ' + $price[1];
                        $displayText = $displayText.replace(/,/g, '');
                    }
                    $list += '<li label="' + $(this).text().toLocaleLowerCase() + '">' + $displayText + '</li>'
                });
                $this.find('.selection-list').append($list);
            } else {
                $this.find('.selection-list li').each(function() {
                    $this.attr('label', $.trim($(this).text().toLocaleLowerCase()));
                });
            }
            //@FIX-PHSSECOM-647
            var $optionText = $(this).find('select option:selected').text();
            if ($optionText != undefined && $optionText != null && $optionText.replace(/ /g, '').split('-').length > 1 && $optionText.replace(/ /g, '').split('-')[1].split('|').length == 2) {
                var $optionPrice = $optionText.replace(/ /g, '').split('-')[1].split('|');
                var $optionDisplayName = $optionText.split('-');
                $optionText = $optionDisplayName[0] + '- <span style="text-decoration: line-through">' + $optionPrice[0] + '</span> ' + $optionPrice[1];
                $optionText = $optionText.replace(/,/g, '');
            }
            $this.find('.selected-option').html($optionText);
            $this.find('.selection-list li').removeClass('selected').eq($(this).find('select option:selected').index()).addClass('selected');

            if ($(this).find('select option:selected').val() != '') {
                $this.find('.selected-option').addClass('selected');
            } else {
                $this.find('.selected-option').removeClass('selected');
            }
        });
    },
    scrollMobile: function() {
    	//store the element
    	if($('#QuickViewDialog').length > 0) {
    		var $stickyButton = $('#QuickViewDialog .product-col.quickviewmobile .product-add-to-cart');
    		var $returnPolicy = $('#QuickViewDialog .product-col .product-actions .thirty-day-section-wrap');
    		if(!$('#QuickViewDialog .product-col.quickviewmobile').length) {
    			$stickyButton = $('#QuickViewDialog .product-col .product-add-to-cart button#add-all-to-cart');
    		}
    		
    		var element = document.querySelector('#QuickViewDialog .product-actions');
    		var position = element.getBoundingClientRect();
    		
        	if($(window).width() < 767) {
        		if(position.top < window.innerHeight && position.bottom >= 0 || position.top >= 0 && position.bottom <= window.innerHeight) {
        			$stickyButton.removeClass('stick-to-bottom');
        			$returnPolicy.removeClass('stick-to-bottom');
        		} else {
        			$stickyButton.addClass('stick-to-bottom');
        			$returnPolicy.addClass('stick-to-bottom');
        		}
            } 
        	if($(window).width() >= 768 && $(window).width() < 1024) {
        		imagesLoaded('.quickview-modal').on('done', function () {
        			if($('.quickview-modal').height() > $(window).height()) {
            			if(position.top < window.innerHeight && position.bottom >= 0 || position.top >= 0 && position.bottom <= window.innerHeight) {
                			$stickyButton.removeClass('stick-to-bottom');
                			$returnPolicy.removeClass('stick-to-bottom');
                		} else {
                			$stickyButton.addClass('stick-to-bottom');
                			$returnPolicy.addClass('stick-to-bottom');
                		}
            		}
        		});
        	}
        	$(window).scroll(function (event) {
    			window.addEventListener('scroll', function() {
    				var element = document.querySelector('#QuickViewDialog .product-actions');
    				var position = element.getBoundingClientRect();
    				var overlay = document.querySelector('#QuickViewDialog');
    				var overlayPosition = overlay.getBoundingClientRect();
    				if(overlayPosition.top < window.innerHeight && overlayPosition.bottom >= 0 || overlayPosition.top >= 0 && overlayPosition.bottom <= window.innerHeight) {
    					if(position.top < window.innerHeight && position.bottom >= 0 || position.top >= 0 && position.bottom <= window.innerHeight) {
    						$stickyButton.removeClass('stick-to-bottom');
    						$returnPolicy.removeClass('stick-to-bottom');
    					} else {
    						$stickyButton.addClass('stick-to-bottom');
    						$returnPolicy.addClass('stick-to-bottom');
    					}
    				} else {
    					$stickyButton.removeClass('stick-to-bottom');
    					$returnPolicy.removeClass('stick-to-bottom');
    				}
    			});
    		});
        	
        	$('.wrapdialog').scroll(function (event) {
        		$('.wrapdialog').on('scroll', function() {
    				var element = document.querySelector('#QuickViewDialog .product-actions');
    				var position = element.getBoundingClientRect();
    				var overlay = document.querySelector('#QuickViewDialog');
    				var overlayPosition = overlay.getBoundingClientRect();
    				var Productheight = $('.product-add-to-cart').height();
    				
    				
    				if(overlayPosition.top < (window.innerHeight+Productheight) && overlayPosition.bottom >= 0 || overlayPosition.top >= 0 && overlayPosition.bottom <= (window.innerHeight+Productheight)) {
    					if(position.top < (window.innerHeight+Productheight) && position.bottom >= 0 || position.top >= 0 && position.bottom <= (window.innerHeight+Productheight)) {
    						$stickyButton.removeClass('stick-to-bottom');
    						$returnPolicy.removeClass('stick-to-bottom');
    						
    					} else {
    						$stickyButton.addClass('stick-to-bottom');
    						$returnPolicy.addClass('stick-to-bottom');
    						
    					}
    					
    				} else {
    					$stickyButton.removeClass('stick-to-bottom');
    					$returnPolicy.removeClass('stick-to-bottom');
    				}
    				const element1 = document.querySelector('.quickview-modal');
			    	const topV = element1.style.top;
			    	if(Math.sign(parseInt(topV)) == -1) {
			    		$('.quickview-modal').css('top','0');
			    	}
    			});
    		});
    	}
    },
    alphanumeric: function() {
        if (!util.isMobile()) {
            $(document).on('keydown', '.alphanumeric', function(e) {
                var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
                if (specialKeys.indexOf(key) == -1) {
                    if (!((key == 32) || (key == 46) || (key >= 35 && key <= 57) || (key >= 65 && key <= 105))) {
                        return false;
                    }
                }
            });
        } else {
            $(document).on('keyup input', '.alphanumeric', function(e) {
                var value = $(this).val();
                if (!regex.alphanumeric.test(value)) {
                    $(this).val(value.replace(/[\~\`\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\\\|\;\:\'\"\,\.\<\>\?\/]/g, ""))
                }
            });
        }
    },
    alphaonly: function() {
        if (!util.isMobile()) {
            $(document).on('keydown', '.alphaonly', function(e) {
                var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
                if (specialKeys.indexOf(key) == -1) {
                    if (!((key == 8) || (key == 32) || (key == 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
                        return false;
                    }
                }
            }).on('paste', '.alphaonly,[name$="firstname"],[name$="lastname"]', function(e) {
                var strPasted;
                setTimeout(function() {
                    strPasted = $(e.currentTarget).val().replace(/[\~\`\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\\\|\;\:\"\,\.\<\>\?\/0-9]/g, "");
                    $(e.currentTarget).val(strPasted);

                }, 0);
            });
        } else {
            $(document).on('keyup input', '.alphaonly,[name$="firstname"],[name$="lastname"]', function(e) {
                var value = $(this).val();
                if (!regex.alphaonly.test(value)) {
                    $(this).val(value.replace(/[\~\`\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\\\|\;\:\"\,\.\<\>\?\/\u00D7\u00F70-9]/g, ""))
                }
            });
        }
    },
    allowNumeric: function() {
        if (util.isMobile()) {
            $(document).on('keypress keydown keyup paste', '.allownumeric', function(e) {
                if ($(this).hasClass('phone') || $(this).hasClass('postal')) {
                    $(this).val($(this).val().replace(regex.numericCust, ""));
                } else {
                    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                    if (!isIE11 == true) {
                        $(this).val($(this).val().replace(regex.numeric, ""));
                    }
                }
            }).on('keypress keydown keyup paste', '.allownumeric_decimal', function(e) {
                var strPasted;
                var decimalstr = "";
                strPasted = $(e.currentTarget).val().replace(regex.numericDecimal, "");
                //remove if decimals are more
                if (strPasted.split('.').length > 2) {
                    $.each(strPasted.split('.'), function(i, v) {
                        if (decimalstr.length == 0) {
                            decimalstr = v + '.';
                        } else {
                            decimalstr += v;
                        }
                    });

                    strPasted = decimalstr;
                }

                $(e.currentTarget).val(strPasted);
            });
        } else {
            $(document).on('keypress keyup', '.allownumeric', function(e) {
                var key = e.keyCode || e.which;
                // allow Ctrl+A
                if ((e.ctrlKey && key == 97 /* firefox */ ) || (e.ctrlKey && key == 65) /* opera */ || key == 97) {
                    return true;
                }
                // allow Ctrl+X (cut)
                if ((e.ctrlKey && key == 120 /* firefox */ ) || (e.ctrlKey && key == 88) /* opera */ || key == 120) {
                    return true;
                }
                // allow Ctrl+C (copy)
                if ((e.ctrlKey && key == 99 /* firefox */ ) || (e.ctrlKey && key == 67) /* opera */ || key == 99) {
                    return true;
                }
                // allow Ctrl+Z (undo)
                if ((e.ctrlKey && key == 122 /* firefox */ ) || (e.ctrlKey && key == 90) /* opera */ || key == 122) {
                    return true;
                }
                // allow tab
                if ((e.ctrlKey && key == 9 /* firefox */ ) || key == 9) {
                    return true;
                }
                // allow or deny Ctrl+V (paste), Shift+Ins
                if ((e.ctrlKey && key == 118 /* firefox */ ) || (e.ctrlKey && key == 86) /* opera */ || (e.shiftKey && key == 45) || key == 118) {
                    $(this).val($(this).val().replace(regex.numericCust, ""));
                    //$(this).trigger('keyup');
                    return true;
                }
                if (key != 13 && key != 8 && key != 0 && key != 9 && key != 37 && key != 39 && key != 46 && (key < 48 || key > 57)) {
                    $(this).val($(this).val().replace(regex.numericCust, ""));
                    return false;
                }

            }).on('keypress', '.allownumeric_decimal', function(e) {
                var key = e.keyCode || e.which;
                // allow Ctrl+A
                if ((e.ctrlKey && key == 97 /* firefox */ ) || (e.ctrlKey && key == 65) /* opera */ || key == 97) {
                    return true;
                }
                // allow Ctrl+X (cut)
                if ((e.ctrlKey && key == 120 /* firefox */ ) || (e.ctrlKey && key == 88) /* opera */ || key == 120) {
                    return true;
                }
                // allow Ctrl+C (copy)
                if ((e.ctrlKey && key == 99 /* firefox */ ) || (e.ctrlKey && key == 67) /* opera */ || key == 99) {
                    return true;
                }
                // allow Ctrl+Z (undo)
                if ((e.ctrlKey && key == 122 /* firefox */ ) || (e.ctrlKey && key == 90) /* opera */ || key == 122) {
                    return true;
                }
                // allow tab
                if ((e.ctrlKey && key == 9 /* firefox */ ) || key == 9) {
                    return true;
                }
                // allow or deny Ctrl+V (paste), Shift+Ins
                if ((e.ctrlKey && key == 118 /* firefox */ ) || (e.ctrlKey && key == 86) /* opera */ || (e.shiftKey && key == 45) || key == 118) {
                    $(this).val($(this).val().replace(regex.numeric, ""));
                    return true;
                }

                if (key != 13 && key != 8 && key != 0 && key != 9 && key != 37 && key != 39 && key != 46 && (key < 48 || key > 57)) {
                    $(this).val($(this).val().replace(regex.numeric, ""));
                    return false;
                }

                if ($(this).val().indexOf('.') != -1 && key == 46) {
                    return false;
                }
            }).on('paste', '.allownumeric_decimal', function(e) {
                var strPasted;
                setTimeout(function() {
                    strPasted = $(e.currentTarget).val().replace(regex.numericDecimal, "");
                    $(e.currentTarget).val(strPasted);
                }, 0);
            })
        }
    },
    //added to allow limited character in text field
    allowLtdSplChars: function() {
        
        $(document).on('keyup input paste', '.allowltdchars,[name$="firstname"],[name$="lastname"],[name$="stdFirstName"],[name$="stdLastName"]', function(e) {
            var value = $(this).val();
            $(this).val(value.replace( /[^a-zA-Z-,`']/gm, ''));
            //$(this).val(value.replace(/[\.\~\!\@\#\$\%\^\&\*\(\)\_\+\=\[\]\{\}\\\|\;\:\"\<\>\?\/\u00D7\u1F601\u00F70-9]/g, ""));
        });
    },
    // for search results classes page sync height
    searchResultClass: function() {
         /**TSED-1031 */
        if ($(window).width() >= 1024) {
            $('.search-result-items > li .class-wrapper').height('auto').syncHeight();
        } else {
            $('.search-result-items > li .class-wrapper').height('auto');
            $('.search-result-items > li').find('.class-wrapper > div').height('auto').syncHeight();
            var btnheight = $('.class-month-timings').outerHeight();
            $('.sign-me-up-set').addClass('sign-me-up');
            $('.class-wrapper').find('.sign-me-up, .class-in-cart').outerHeight(btnheight);
            $('.sign-me-up-set').removeClass('sign-me-up');
            $('.class-seats-price').css('top', btnheight);
            btnheight = 0;
        }
       
    },

    SingleOnlineClass: function() {
        var singleClass = $('.class-tiles-container.wide-tiles .search-result-items').find('li.grid-tile');
        var multipleClass = $('.class-tiles-container.wide-tiles .search-result-items').find('li.grid-tile');
        if (singleClass.length > 0 && multipleClass.length < 2) {
            $('.class-tiles-container.wide-tiles .sign-me-up').addClass('singleclassbtn');
            $('.search-result-items > li').find('.class-wrapper > div').height($('.class-wrapper .class-sign-up .sign-me-up').outerHeight());
        } else {
            $('.class-tiles-container.wide-tiles .sign-me-up').removeClass('singleclassbtn');
        }
    },

    // cart page sync height
    cartOuterheight: function() {
        //classonlineclass
        $(".class-day").width($(".class-col-0").outerWidth());
        $(".class-time").width($(".class-col-1").outerWidth());
        $(".class-details").width($(".class-col-2").outerWidth());
        $(".class-settings").width($(".class-col-3").outerWidth());
        $(".class-price").width($(".class-col-5").outerWidth());

        if ($('.onlineclass').is(':visible')) {
            var classdayWidth = $('.class-col-0.onlineclass').outerWidth();
            var dayWidth = classdayWidth / 2;
            var timeWidth = $('.section-header.class-time').outerWidth(dayWidth);
            var timeWidth = $('.section-header.class-day').outerWidth(dayWidth);
        }
        // product
        var a = $('.item-image').outerWidth() + $('.item-details').outerWidth();
        $('.cart-product-name').outerWidth(a);
        $(".cart-price").width($(".cart-product .item-price").outerWidth());
        $(".cart-qty").width($(".cart-product .item-quantity").outerWidth());
        $(".cart-total-price").width($(".cart-product .item-total").outerWidth());
    },
    cartclassHeight: function() {
        if ($(window).width() < 767) {
            var mobileHeight = $('.class-col-0').outerHeight();
            $('.class-col-1,.class-col-3').outerHeight(mobileHeight);
        }
    },

    //global slick by using the variables
    globalSlick: function() {
        var $product_recommend = $('.product-info .you-might-like-pro .search-result-items'),
            $class_recommend = $('.classes-content-tabs .search-result-items'),
            $cart_recommend = $('.cartpage-recommend .tiles-container'),
            $cart_recent = $('.cart-recentView-section .tiles-container'),
            $obj = {
                dots: false,
                infinite: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 2.5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1.3,
                            slidesToScroll: 1
                        }
                    }
                ]
            };
        if ($product_recommend.hasClass('slick-slider')) {
            $product_recommend.slick('unslick');
        }
        if ($class_recommend.hasClass('slick-slider')) {
            $class_recommend.slick('unslick');
        }
        if ($cart_recommend.hasClass('slick-slider')) {
            $cart_recommend.slick('unslick');
        }
        if ($cart_recent.hasClass('slick-slider')) {
            $cart_recent.slick('unslick');
        }
        $product_recommend.slick($obj);
        $class_recommend.slick($obj);
        $cart_recommend.slick($obj);
        $cart_recent.slick($obj);
    },
    cancelDialog: function() {
        // for the cancellation dialog in the class search results page and cdp page
        $('.canc-policy-link .service-link').off('click').on('click', function(e) {
            e.preventDefault();

            //adobe analytics
            if (SitePreferences.isATMEnabled) {
                atmevents.cancelationPolicy();
            }
            //GTM events
            if (SitePreferences.isGTMEnabled) {
                gtmevents.cancelationPolicyEvent();
            }

            $(this).parent().siblings('.cancel-policy-wrapper').dialog({
                autoOpen: false,
                width: '700',
                height: 'auto',
                modal: 'true',
                dialogClass: 'cancel-policy',
                close: function() {
                    $(this).dialog('destroy').remove;
                }
            }).dialog('open');
        });
    },
    pdpyoutubeIframe: function() {
        $('.thumb a').on('click', function(e) {
            if ($(this).hasClass('video-details')) {
                e.preventDefault();
                var url = $(this).attr('href');
                $('.product-primary-image').append('<div class="video-tab"><iframe width="100%" height="100%" src="' + url + '" frameborder="0" allowfullscreen></iframe></div>');
            } else {
                $('.video-tab').remove();
            }
        });
    },
    // for expanding level-4 category when user is in fourth level cat
    currentCategory: function() {
        var $level3 = $('#category-level-3 .refinement-link.active');
        $level3.closest('.sub-cat-refine').addClass('active');
        $level3.closest('.sub-cat-refine').prev('.dropdown-link-category').addClass('active');

        var $level4 = $('#category-level-4 .refinement-link.active');
        $level4.closest('.sub-cat-refine').addClass('active');
        $level4.closest('.sub-cat-refine').prev('.dropdown-link-category').addClass('active');
        $level4.closest('#category-level-3').addClass('active');
        $level4.closest('#category-level-3').prev('.dropdown-link-category').addClass('active');
    },

    formPopulated: function() {
        var $form = $('.address');
        $form.find('input.input-text').each(function() {
            if ($(this).val().length > 0) {
                $(this).closest('.form-row').find('label').addClass('focus');
                $(this).closest('.form-row').addClass('form-label');
            } else {
                $(this).closest('.form-row').find('label').removeClass('focus');
                $(this).closest('.form-row').removeClass('form-label');
            }
        });
    },

    lableFocus: function() {
        var $form = $('.address');
        $form.on('focus', 'input.input-text', function() {
            if (!$(this).closest('.form-row').find('label').hasClass('focus')) {
                $(this).closest('.form-row').find('label').addClass('focus');
                $(this).closest('.form-row').addClass('form-label');
            }
        });
        $form.on('input', 'input.input-text', function() {
            $form.find('input.input-text').each(function() {
                if ($(this).val().length > 0) {
                    $(this).closest('.form-row').find('label').addClass('focus');
                    $(this).closest('.form-row').addClass('form-label');
                   
                } else {
                    $(this).closest('.form-row').find('label').removeClass('focus');
                    $(this).closest('.form-row').removeClass('form-label');
                    

                }
            });
        });
        $form.on('blur', 'input.input-text', function() {
            $form.find('input.input-text').each(function() {
                if ($(this).val().length > 0) {
                    $(this).closest('.form-row').find('label').addClass('focus');
                    $(this).closest('.form-row').addClass('form-label');
                    
                } else {
                    $(this).closest('.form-row').find('label').removeClass('focus');
                    $(this).closest('.form-row').removeClass('form-label');
                   
                }
            });
        });
        this.formPopulated();
    },
    toolgeSummary: function() {
        var $main = $('#main');
        $main.find('.checkout-mini-heading .summary_count').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.checkout-mini-cart').toggleClass('show-summary');
        });
    },
    tooltipCheckout: function() {
        var $main = $('#main');
        $main.on('click', '.image-cvn', function() {
            $(this).closest('.form-row.cvn').addClass('tooltip-filed');
            if ($('.cvn .error').is(':visible')) {
                $('.cvn').addClass('visiable-error');
            } else {
                $('.cvn').removeClass('visiable-error');
            }
            var $form = $('.address');
            $form.on('keyup', 'input.input-text', function() {
                if ($(this).val().length >= 0) {
                    $(this).closest('.form-row.cvn').addClass('');
                } else {
                    $(this).closest('.form-row.cvn').removeClass('');
                }
            })
        }).on('click', '.class-tool-close', function() {
            $(this).closest('.form-row.cvn').removeClass('tooltip-filed');
        });
        $(document).on('click', function(e) {
            if (!$(e.target).closest('.form-row.cvn').length) {
                $('.form-row.cvn').removeClass('tooltip-filed');
            }
        });
    },
    apoToltip: function() {
        var $main = $('#main');
        $('body').off('click', '.tooltip-class a.tooltip').on('click', '.tooltip-class a.tooltip', function(e) {
            if (!$('.tooltip-class').hasClass('tooltip-visiable')) {
                $('.tooltip-class').addClass('tooltip-visiable');
            } else {
                $('.tooltip-class').removeClass('tooltip-visiable');
            }
        });
        $main.on('click', '.class-tool-close', function() {
            $(this).closest('.tooltip-class').removeClass('tooltip-visiable');
        });
        $(document).on('click', function(e) {
            if (!$(e.target).closest('.tooltip-class').length) {
                $('.tooltip-class').removeClass('tooltip-visiable');
            }
        });
    },
    apoToltipAddress: function() {
        var $main = $('.add-Address');
        $main.on('click', '.tooltip-class a.tooltip', function() {
            if (!$('.tooltip-class').hasClass('tooltip-visiable')) {
                $('.tooltip-class').addClass('tooltip-visiable');
            } else {
                $('.tooltip-class').removeClass('tooltip-visiable');
            }
        }).on('click', '.class-tool-close', function() {
            $(this).closest('.tooltip-class').removeClass('tooltip-visiable');
        });
        $(document).on('click', function(e) {
            if (!$(e.target).closest('.tooltip-class').length) {
                $('.tooltip-class').removeClass('tooltip-visiable');
            }
        });
    },
    calculateCheckoutSummaryHeight: function($this, prdsheight) {
        var heightVar = $('.shipping-page-mini-summary').eq(0).outerHeight() * (prdsheight);
        $this.$products.height(heightVar);
        $this.$products.perfectScrollbar();
    },
    checkoutScrollbar: function() {
        if ($(window).width() > 767) {
            this.$el = $('.checkout-mini-cart');
            this.$products = this.$el.find('.checkout-mini-cart-details');
            if (this.$products.find('.shipping-page-mini-summary').length >= 3 || ($(window).width() < 767 && this.$products.find('.shipping-page-mini-summary').length > 2)) {
                var prdsheight = 2;
                this.calculateCheckoutSummaryHeight(this, prdsheight);
            }
        }
    },
    addingClassCheckout: function() {
        if ($('.billing-sub-heading').is(':visible')) {
            $('.billing-sub-heading').closest('.billing-section').find('.heading-required').addClass('class-visible');
        } else {
            $('.billing-sub-heading').closest('.billing-section').find('.heading-required').removeClass('class-visible');
        }
    },
    cardnumberSpace: function() {
        $('.form-row.credit-number input').on('keypress keyup change', function(e) {
            var foo = $(this).val().split(" ").join("");
            if (foo.length > 0) {
                foo = foo.match(new RegExp('.{1,4}', 'g')).join(" ");
            }
            var key = e.keycode || e.which;
            if (key == 46 || key == 37 || key == 39 || key == 8) {
                var start = this.selectionStart,
                    end = this.selectionEnd;
                $(this).val(foo);
                this.setSelectionRange(start, end);
            } else {
                $(this).val(foo);
            }
        });
    },
    saveCalendar: function() {
        $('.addtocalendar').each(function() {
            var $this = $(this);
            if ($this.length === 0) {
                return;
            }

            var timeStr = $this.attr('data-attr');
            var className = $this.attr('data-attr1');
            var facilityAddr = $this.attr('data-attr2');

            var start;
            var end;
            var tmp = new Date();
            
            // TSED 1013 - Added null and empty condition
            if (timeStr == undefined || timeStr == null || timeStr == '') {
                tmp.setDate(tmp.getDate() + 1); // next 7 days
                start = tmp.toString();

                tmp.setDate(tmp.getDate() + 2); // next 8 days
                end = tmp.toString();
            } else {
                start = parseInt(timeStr.split('::')[0]);
                end = parseInt(timeStr.split('::')[1]);
            }

            var singleEventArgs = {
                title: className,
                start: start,
                end: end,
                location: facilityAddr,
                description: className
            };
            var singleEvent = new Add2Calendar(singleEventArgs);

            // widget 1 - normal
            singleEvent.createWidget(this, null, {
                'outlook': 'Add To Calendar'
            });
        });
    },
    orderDetailsToggle: function() {
        var $main = $('#main');
        $main.on('click', '.orderdetails .class-details-order', function() {
            if (!$('.orderdetails.main-container').hasClass('active')) {
                $('.orderdetails.main-container').addClass('active');
            } else {
                $('.orderdetails.main-container').removeClass('active');
            }
        });
    },
    addCalenderHidden: function() {
        if (!$('.cld-msg .cal-info.cal-ender').length > 0) {
            $('.rd-cross-msg').addClass('full-width-div');
        } else {
            $('.rd-cross-msg').removeClass('full-width-div');
        }
    },
    validateEmailMessage: function() {
        $(document).on("keypress", function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                setTimeout(function() {
                    var $loginErr = $('.login-account .username .email').next('span.error'),
                        $loginErrTxt = $loginErr.text(),
                        $forgotErr = $('.forget-password .email').next('span.error'),
                        $forgotErrTxt = $forgotErr.text();
                    if ($loginErrTxt == 'Required') {
                        $loginErr.removeClass('requiremessage');
                    } else if ($loginErrTxt == 'Please enter a valid email address.') {
                        $loginErr.addClass('requiremessage');
                    }
                    if ($forgotErrTxt == 'Required') {
                        $forgotErr.removeClass('requiremessage');
                    } else if ($forgotErrTxt == 'Please enter a valid email address.') {
                        $forgotErr.addClass('requiremessage');
                    }
                }, 10);
            }
        });
    },
    searchSuggestions: function() {
        if ($(window).width() < 415) {
            $('.header-search-flyout .simple-search-action input').on('focus', function() {
                if (!$(this).closest('.sticky-container').hasClass('focusstate')) {
                    $(this).closest('.sticky-container').addClass('focusstate');
                }
            });
        }
    },
    recentViewClassWidth: function() {
        $('.cart-recentView-section .thumb-link').not('.cart-recentView-section .thumb-link.courses').addClass('thumbproductview')
        $(".cart-recentView-section .thumb-link.courses img").width($(".cart-recentView-section .thumbproductview img").outerWidth());

        if ($('.cart-recentView-section .thumb-link').hasClass('courses')) {
            $('.cart-recentView-section .thumb-link').closest('.cart-recentView-section').addClass('coursesvisible');
            $('.thumb-link.courses').parent('.product-image').addClass('hidequickview');
        } else {
            $('.cart-recentView-section .thumb-link').closest('.cart-recentView-section').removeClass('coursesvisible');
        }
    },
    reconmmendtionNotShowing: function() {
        $('.quickview-notshowing .thumb-link').not('.quickview-notshowing .thumb-link.courses').addClass('thumbproductview')
        $(".quickview-notshowing .thumb-link.courses img").width($(".quickview-notshowing .thumbproductview img").outerWidth());

        if ($('.quickview-notshowing .thumb-link').hasClass('courses')) {
            $('.quickview-notshowing .thumb-link').closest('.quickview-notshowing').addClass('coursesvisible');
            $('.thumb-link.courses').parent('.product-image').addClass('hidequickview');
        } else {
            $('.quickview-notshowing .thumb-link').closest('.quickview-notshowing').removeClass('coursesvisible');
        }
    },
    nonASCII: function() {
        $(document).on('keypress keyup', '.nonascii', function(e) {
            var $this = $(this);
            $this.val($this.val().latinize());
            if ($this.val() == "") {
                $(".pac-container").css("opacity", "0");
            } else {
                $(".pac-container").css("opacity", "1");
            }
        }).on('paste', '.nonascii', function(e) {
            var $this = $(this);
            setTimeout(function() {
                $this.val($this.val().latinize());
                if ($this.val() == "") {
                    $(".pac-container").css("opacity", "0");
                } else {
                    $(".pac-container").css("opacity", "1");
                }
            }, 0);
        });
    },
    appendEmailNotification: function() {
        $(".made-dynamic-content label").clone().appendTo($(".newroducts .form-row label"));
        $(".made-dynamic-content-class label").clone().appendTo($(".newsclasses .form-row label"));
    },
    allownbDec: function() {
        $(".allow-decimalphone").bind("change keyup input", function() {
            var value = $(this).val();
            var position = this.selectionStart - 1;
            var fixed = $(this).val();
            if (fixed.charAt(0) === '.')
                fixed = fixed.slice(1);
            var pos = fixed.indexOf(".") + 1;
            if (pos >= 0) //avoid more than one .
                fixed = fixed.substr(0, pos) + fixed.slice(pos).replace('.', '');
            if (this.value !== fixed) {
                this.value = fixed;
                this.selectionStart = position;
                this.selectionEnd = position;
            }
        });
    },
    mobileAppendRefinement: function() {
        // for mobile appending select classes drop down
        if ($(window).width() < 768) {
            $(".mobile-refinement-list").insertAfter(".local-landing-social-icons-mobile");
        }
        $(window).resize(function() {
            if ($(window).width() < 768) {
                $(".mobile-refinement-list").insertAfter(".local-landing-social-icons-mobile");
            }
        });
    },
    expiredClassAppend: function() {
        // for mobile appending select classes drop down
        if ($(window).width() < 768) {
            $(".expired-class-wrap").insertBefore(".checkout-header-wrap");
        }
        $(window).resize(function() {
            if ($(window).width() < 768) {
                $(".expired-class-wrap").insertBefore(".checkout-header-wrap");
            }
        });
    },

    tooltipSearchresultpage: function() {
        // for search results classes page tooltip 
        $('.class-wrapper .class-format,.class-col-3 .class-format').on('click', function() {
            $('.class-format-thrld').removeClass('active');
            $(this).closest('.class-format-thrld').addClass('active');
        });
        $('.class-wrapper .class-tool-close,.class-col-3 .class-tool-close').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.class-format-thrld').removeClass('active');
        });
        $('.class-days-label').on('click', function() {
            $('.class-happening-days').removeClass('active');
            $(this).closest('.class-happening-days').addClass('active');
        });
        $('.days-tool-close').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.class-happening-days').removeClass('active');
        });
        // for checkout new design
        if (!SitePreferences.OLD_CHECKOUT_ENABLED) {
            $('.tool-question').on('click', function() {
                $(this).closest('.tooltip-box').addClass('active');
            });
            $('.class-tool-close').on('click', function(e) {
                e.preventDefault();
                $(this).closest('.tooltip-box').removeClass('active');
            });

        }



    },
    readMoreLink: function() {
        var showChar = 100;
        var moretext = "Read more";
        var lesstext = "Read less";
        var dots = "...";
        var $this = $('.categorypage_subtext').html();
        var content = $this;
        if(content != undefined) {
            if(content.length > showChar) {
                var c = content.substr(0, showChar);
                var h = content.substr(showChar, content.length - showChar);
                var html = c + '<span class="dots">'+ dots +'</span><span class="morecontent"><span>' + h + '</span>&nbsp; <span class="morelink">' + moretext + '</span></span>';
                $('.categorypage_subtext').html(html);
            }
        }

        $(".morelink").click(function(){
            if($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).html(moretext);
                $(this).addClass("dots");
            } else {
                $(this).addClass("less");
                $(this).removeClass("dots");
                $(this).html(lesstext);
            }
            $(this).parent().prev().toggle();
            $(this).prev().toggle();
            return false;
        });
    },
    //TSED-1140 & TSED-1142: Aria-label for Footer email signup
    labelFormPopulated: function(formName) {
        var form = formName;
        $(form).find('input.input-text').each(function() {
            if ($(this).val().length > 0) {
                $(this).closest('.form-row').find('label').addClass('focus');
                $(this).closest('.form-row').addClass('form-label');
            } else {
                $(this).closest('.form-row').find('label').removeClass('focus');
                $(this).closest('.form-row').removeClass('form-label');
            }
        });
    },
    ariaLabel: function(formName) {
        var form = formName;
        $(form).on('focus', 'input.input-text', function() {
            if (!$(this).closest('.form-row').find('label').hasClass('focus')) {
                $(this).closest('.form-row').find('label').addClass('focus');
                $(this).closest('.form-row').addClass('form-label');
            }
        });
        $(form).on('input', 'input.input-text', function() {
            $(form).find('input.input-text').each(function() {
                if ($(this).val().length > 0) {
                    $(this).closest('.form-row').find('label').addClass('focus');
                    $(this).closest('.form-row').addClass('form-label');
                } else {
                    $(this).closest('.form-row').find('label').removeClass('focus');
                    $(this).closest('.form-row').removeClass('form-label');
                }
            });
        });
        $(form).on('blur', 'input.input-text', function() {
            $(form).find('input.input-text').each(function() {
                if ($(this).val().length > 0) {
                    $(this).closest('.form-row').find('label').addClass('focus');
                    $(this).closest('.form-row').addClass('form-label');
                } else {
                    $(this).closest('.form-row').find('label').removeClass('focus');
                    $(this).closest('.form-row').removeClass('form-label');
                }
            });
        });
        this.labelFormPopulated(form);
    }
};

module.exports = util;