'use strict';

var util = require('../../util');
var shipping = require('./shipping');
var onepagecheckout = require('./onepagecheckout')

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
	
    var $form = $('.address');
    // select address from list
    $('select[name$="_billing_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        //$('#commonaddressid').hide();
        //$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', false);
        
        //Added For PHSSECOM-408
        if($('#addShipValue').length > 0 && $('#addShipValue').val() != undefined) {
        	if($('#addShipValue').val() == 'true') {
        		//TSED-296
            	//$('.shipping-section').find('.add-new-shipping').trigger('click');
            }
        }
               
        if (!selectedAddress) {
        	if ($(selected).val() != null && $(selected).val().trim() === 'addanew') {
        		$('#commonaddressid').show();
        		$('.billing-section').find('.add-new-address').trigger('click');
        		//$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', false).trigger('click');
        		return;
        	} else {
        		return;
        	}
        }
        util.fillBillingAddressFields(selectedAddress, $form);
        //TSED-1155:Shipping method for certain products defaults to priority overnight-> Commented below code
        //onepagecheckout.updateShippingMethodList();
        //shipping.updateShippingMethodList();
        // re-validate the form
        /* JIRA PREV-95 : Highlighting the Credit card  details field, when the user select any saved address from the 'Select An Address' drop down.
           (logged-in user) .Commented to prevent form from re-validation.
		*/
        //$form.validate().form();
        util.lableFocus();
        // calling blur event to trigger sms subscription event - PHSSECOM-2028
        $('.pt_checkout .billing-section .phone').trigger('blur');
    });
    $('select[name$="_singleshipping_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        //$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', true).trigger('click');
        //$('#commonaddressid').hide();
        if (!selectedAddress) {
        	if ($(selected).val() != null && $(selected).val().trim() === 'addanew') {
        		$('.shipping-section').find('.add-new-shipping').trigger('click');
        		$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', true).trigger('click');
        		return;
        	} else {
        		return;
        	}
        }
        util.fillShippingAddressFields(selectedAddress, $form);
        $('select[name$="_singleshipping_addressList"]').val(selectedAddress.ID);
        util.updateselect();
        
        if(selectedAddress.companyName != null){
        	var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
        	if($radios.is(':checked') === true) {
                $radios.filter('[value=false]').prop('checked', true);
            }
        	$('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val(selectedAddress.companyName);
        	$('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", false);
    		$('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').removeClass('row-disabled');
    		onepagecheckout.showCompanyName();
        } else {
        	var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
        	if($radios.is(':checked') === true) {
                $radios.filter('[value=true]').prop('checked', true);
            }
        	$('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
        	$('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
    		$('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
    		onepagecheckout.showCompanyName();
        }
                
        //shipping.updateShippingMethodList();
        onepagecheckout.updateShippingMethodList(true);
        
        // re-validate the form
        /* JIRA PREV-95 : Highlighting the Credit card  details field, when the user select any saved address from the 'Select An Address' drop down.
           (logged-in user) .Commented to prevent form from re-validation.
		*/
        //$form.validate().form();
        util.lableFocus();
    });
   
};

