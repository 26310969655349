'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var ajax = require('./ajax'),
	util = require('./util');
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: naPhone
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    zip: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    email:  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*([a-zA-Z0-9]@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
        if($(element).hasClass('error')){
        	$(element).parent().find('span.strike-error').remove();
        }
        if ($(element).hasClass('error') && $(element).next('span.error').length > 0 && $(element).next('span.error').text() == 'Required') {
        	$(element).next('span.error').removeClass('requiremessage');
        	$(element).parent().find('span.strike-error').remove();
        } else {
        	
        	if($(element).hasClass('strikeiron-failure')) {
        		$(element).parent().append('<span class="strike-error">Please enter a valid email address.</span>');
        	} else {
        		$(element).next('span.error').addClass('requiremessage');
        	}     	
        	if ($('.address1-row .input-text') && $('.address1-row .input-text').val() != null && $('.address1-row .input-text').val() != ''){
        		if($('.address1-row .input-text').val().length > 0) {
        			$('.address1-row').find('.input-text').removeClass('error');
        			$('.address1-row').find('.input-text').next('span.error').hide();
        		}
        	}
        }        	
    },
    errorPlacement: function(error, element){
     if($(element).hasClass('input-select')){
          $(element).closest('.custom-select').append(error);
     }else{
         error.insertAfter(element);
     }

    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if(country.length > 0 && country.val() == null && country.find('option').length > 0){
    	country = country.find('option').eq(0);
    } else if ($('input[name*="_login_loginAddress_phone"]').val() != undefined && $('input[name*="_login_loginAddress_phone"]').val().length>0 && $('input[name*="_login_loginAddress_phone"]').val().length<10){
    	return false;
    } else if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/*Start JIRA PREV-77 : Zip code validation is not happening with respect to the State/Country.*/
function validateZip(value, el) {
    var country = $(el).closest('form').find('.country');
    if(country.length > 0 && country.val() == null && country.find('option').length > 0){
    	country = country.find('option').eq(0);
    }else if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }
    var isOptional = this.optional(el);
    var isValid = regex.postal[country.val().toLowerCase()].test($.trim(value));
    return isOptional || isValid;
}
$.validator.addMethod('postal', validateZip, Resources.INVALID_ZIP);
$.validator.addMethod('zip', validateZip, Resources.INVALID_ZIP);
/*End JIRA PREV-77*/

/*Start JIRA PREV-678 : Email and confirm email fields validation in gift certificate page not as expected.*/
var validateEmail = function (value, el) {
    value = $.trim(value);
    $(el).val(value);
    var isOptional = this.optional(el);
    //var emailRegEx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*([a-zA-Z0-9]@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    var emailRegEx = /^[A-Za-z0-9-+~_'.]+@[A-Za-z0-9]+(?:[\.-][A-Za-z0-9]+)*$/;
    var isValid = emailRegEx.test(value);
    if(isValid){
    	if($(el).hasClass('invalid-email')){
    		isValid = false;
    	}else if($(el).hasClass('valid-email')){
    		isValid = true;
    	}else { 
    		$(el).addClass('valid-email');
    	}
   }
   util.validateEmailMessage();
   if($(el).hasClass('error') && isValid && !$(el).hasClass('invalid-email') && !$(el).hasClass('valid-email') && !$(el).hasClass('strikeiron-failure')){
    	isValid = false;
   } 
   $(el).removeClass('invalid-email valid-email strikeiron-failure');
   if($(el).hasClass('error') && isValid){
	   $(el).parent().find('span.strike-error').remove();
   } 
   return isOptional || isValid;
};
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);
/*End JIRA PREV-678*/

function validateFirstname(value,el) {
	var firstnameval = $(el).val(); 
	var charlength= firstnameval.length;
	if(firstnameval != null && firstnameval != '' && charlength <= 40) {
		return true;
	} else { 
		return false;
	}
}



function validatePassword(value,el) {
	var pwdVal = $(el).closest('form').find('.user-entered-pwd').val(); 
	var regexpwd = /^(?=.*[A-Z\.a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Z\.a-z\d$@$!%*#?&]{8,}$/;
	var isMatch = regexpwd.test(pwdVal);
	var charlength= $(".user-entered-pwd").val().length;
	if(pwdVal != null && pwdVal != '' && !isMatch && charlength >= 8) {
		$(".user-entered-pwd").closest('.create-password-login').find('.form-caption').hide();
		return false;
	} else { 
		$(".user-entered-pwd").closest('.create-password-login').find('.form-caption').show();
		return true;
	}
}
$('.user-entered-pwd').keyup(function(event){
	 var key = event.keyCode || event.charCode;
	    if( key == 8 || key == 46 ){
		if(!$('.user-entered-pwd').val().length > 0){ 
			$(".user-entered-pwd").closest('.create-password-login').find('.form-caption').show();
			return false;
		}
	}
}); 

function validateConfirmPassword(value,el) {
	var confirmPwd = $(el).closest('form').find('.confirm-entered-pwd').val();
	var pwdVal = $(el).closest('form').find('.user-entered-pwd').val(); 
	if(pwdVal != null && confirmPwd != null && pwdVal != '' && confirmPwd != '' && confirmPwd.length >= 8 && pwdVal != confirmPwd) {
    	return false;
    } else {
    	return true;
    }
}
function validateConfirmEmail(value,el) {
	var confirmPwd = $(el).closest('form').find('.email-conform-create').val();
	var pwdVal = $(el).closest('form').find('.email-create').val(); 
	if(pwdVal != null && confirmPwd != null && pwdVal != '' && confirmPwd != '' && confirmPwd.length >= 8 && pwdVal != confirmPwd) {
    	return false;
    } else {
    	return true;
    }
}


$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
        $('.allowltdchars').on('blur focusout', function(){
            $.validator.addMethod('allowltdchars', validateFirstname, Resources.ALLOWLTDCHARS_NAME);  
        });
        $('.user-entered-pwd').on('blur focusout', function(){
            $.validator.addMethod('user-entered-pwd', validatePassword, Resources.VALIDATE_PASSWORD);  
        });
        $('.confirm-entered-pwd').on('blur focusout', function(){
            $.validator.addMethod('confirm-entered-pwd', validateConfirmPassword, Resources.VALIDATE_CONFIRM_PASSWORD);  
        });
        $('.email-conform-create').on('blur focusout', function(){ 
        	 if($('.email-create').val() != $('.email-conform-create').val()){
        		 $.validator.addMethod('email-conform-create', validateConfirmEmail, Resources.VALIDATE_CONFIRM_EMAIL);
        	 }
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
