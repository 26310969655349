'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    atmevents = require('../atmevents'),
    ajax = require('../ajax'),
    progress = require('../progress');

var autocompleteProfile;
var google_api_inactive = SitePreferences.GOOGLE_API_DISABLED;

function updateStateDropDown() {

    var $form = $('.address');
    var country = "";
    if ($form.find('select[id$="_profile_address_country"]').length > 0) {
    	country = $form.find('select[id$="_profile_address_country"]').val();
    } else if ($form.find('select[id$="_profileaddress_country"]').length > 0) {
    	country = $('select[id$="_profileaddress_country"]').val();
    }
    if (country != 'US') {
        $(".state-row").addClass('hide');
        $(".state-other-row").removeClass('hide');
        $(".postal-row").addClass('hide');
        $(".postal-row-other").removeClass('hide');
    } else {
        $(".state-row").removeClass('hide');
        $(".state-other-row").addClass('hide');
        $(".postal-row").removeClass('hide');
        $(".postal-row-other").addClass('hide');
    }

}

/**
 * @function
 * @description Fills Shipping Address fields using data from Google Places Auto LookUp
 */
function fillProfileAddress() {
	var place = autocompleteProfile.getPlace();
	var componentForm = {
	        street_number: 'short_name',
	        route: 'short_name',
	        locality: 'long_name',
	        administrative_area_level_1: 'short_name',
	        postal_code: 'short_name',
	        country: 'short_name'
	      };
	
	var addressFieldProfile = {
			route : 'input[name$="profile_address_address1"]',
			locality: 'input[name$="profile_address_city"]',
	        administrative_area_level_1: 'select[name$="profile_address_states_state"]',
	        postal_code: 'input[name$="profile_address_postal"]'
	}
	var streetNo='';
	var stateCode='';
	var countryCode = '';
	
	$('input[name$="profile_address_postal"]').val('');
	$('select[name$="profile_address_states_state"]').val('');
	
	for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
        	if(addressType === 'street_number') {
        		streetNo = place.address_components[i][componentForm[addressType]];
        	}else{
        		var val = (addressType === 'route') ? streetNo+' '+place.address_components[i][componentForm[addressType]]: place.address_components[i][componentForm[addressType]];
        		
        		if (addressType === 'country') {
                    var territoryAddress = SitePreferences.TERRITORY_ADDRESS;
                    if (territoryAddress != undefined && territoryAddress != null && territoryAddress.indexOf(val) > 0) {
                        countryCode = 'US';
                        stateCode = val;
                    } else {
                        countryCode = val;
                    }
                    $('select[name$="profile_address_country"]').val(countryCode);
                    $('select[name$="profile_address_country"]').trigger('change');
                    updateStateDropDown();
                    if ($('select[name$="profile_address_states_state"]').is(':visible')) {
                        $('select[name$="profile_address_states_state"]').val(stateCode);
                        $('select[name$="profile_address_states_state"]').trigger('change');
                        var suggestedState = $('select[name$="profile_address_states_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="profile_address_states_state"]').val('');
                            $('select[name$="profile_address_states_state"]').trigger('change');
                        }
                    }
                    if ($('select[name$="profile_address_stateOther_state"]').is(':visible')) {
                        $('select[name$="profile_address_stateOther_state"]').val(stateCode);
                        $('select[name$="profile_address_stateOther_state"]').trigger('change');
                        var suggestedState = $('select[name$="profile_address_stateOther_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="profile_address_stateOther_state"]').val('OT');
                            $('select[name$="profile_address_stateOther_state"]').trigger('change');
                            $('select[name$="profile_address_states_state"]').val('OT');
                            $('select[name$="profile_address_states_state"]').trigger('change');
                        }
                    }
                }
        		
        		if(addressType === 'administrative_area_level_1') {
        			stateCode = val;
        			$('select[name$="profile_address_states_state"]').val(stateCode);
        			$('select[name$="profile_address_states_state"]').trigger('change');
        		} else {
        			$(addressFieldProfile[addressType]).val(val);
        			var suggestedPostal = $('input[name$="profile_address_postal"]').val();
        			$('input[name$="profile_address_otherPostal"]').val(suggestedPostal);
        			$('.postal-row,.city-row,.state-row').find('.input-text,.input-select').removeClass('error');
        			$('.postal-row,.city-row').find('.input-text').next('span.error').hide();
        			$('.state-row .custom-select').find('.selection-list').next('span.error').hide();
        		}        		
        	}	
        }
      }
	 util.lableFocus();
	 util.updateselect();
	 validator.init();
}

/**
 * @function
 * @description Fills Address fields using data from Google Places Auto LookUp
 */
function fillProfileAddressForm() {
	var place = autocompleteProfile.getPlace();
	var componentForm = {
		street_number: 'short_name',
		route: 'short_name',
		locality: 'long_name',
		administrative_area_level_1: 'short_name',
		postal_code: 'short_name',
		country: 'short_name'
	};

	var addressFieldProfile = {
		route: 'input[name$="_profileaddress_address1"]',
		locality: 'input[name$="_profileaddress_city"]',
		administrative_area_level_1: 'select[name$="_profileaddress_states_state"]',
		postal_code: 'input[name$="_profileaddress_postal"]'
	}
	var streetNo = '';
	var stateCode = '';
	var countryCode = '';

	$('input[name$="_profileaddress_postal"]').val('');
	$('select[name$="_profileaddress_states_state"]').val('');

	for (var i = 0; i < place.address_components.length; i++) {
		var addressType = place.address_components[i].types[0];
		if (componentForm[addressType]) {
			if (addressType === 'street_number') {
				streetNo = place.address_components[i][componentForm[addressType]];
			} else {
				var val = (addressType === 'route') ? streetNo + ' ' + place.address_components[i][componentForm[addressType]] : place.address_components[i][componentForm[addressType]];

				if (addressType === 'country') {
					var territoryAddress = SitePreferences.TERRITORY_ADDRESS;
					if (territoryAddress != undefined && territoryAddress != null && territoryAddress.indexOf(val) > 0) {
						countryCode = 'US';
						stateCode = val;
					} else {
						countryCode = val;
					}
					$('select[name$="_profileaddress_country"]').val(countryCode);
					$('select[name$="_profileaddress_country"]').trigger('change');
					updateStateDropDown();
					if ($('select[name$="_profileaddress_states_state"]').is(':visible')) {
						$('select[name$="_profileaddress_states_state"]').val(stateCode);
						$('select[name$="_profileaddress_states_state"]').trigger('change');
						$('select[name$="_profileaddress_states_state"]').focusout();
						var suggestedState = $('select[name$="_profileaddress_states_state"]').val();
						if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
							$('select[name$="_profileaddress_states_state"]').val('');
							$('select[name$="_profileaddress_states_state"]').trigger('change');
							$('select[name$="_profileaddress_states_state"]').focusout();
						}
					}
					if ($('select[name$="_profileaddress_stateOther_state"]').is(':visible')) {
						$('select[name$="_profileaddress_stateOther_state"]').val(stateCode);
						$('select[name$="_profileaddress_stateOther_state"]').trigger('change');
						var suggestedState = $('select[name$="profile_address_stateOther_state"]').val();
						if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
							$('select[name$="_profileaddress_stateOther_state"]').val('OT');
							$('select[name$="_profileaddress_stateOther_state"]').trigger('change');
							$('select[name$="_profileaddress_states_state"]').val('OT');
							$('select[name$="_profileaddress_states_state"]').trigger('change');
						}
					}
				}

				if (addressType === 'administrative_area_level_1') {
					stateCode = val;
					$('select[name$="_profileaddress_states_state"]').val(stateCode);
					$('select[name$="_profileaddress_states_state"]').trigger('change');
				} else {
					$(addressFieldProfile[addressType]).val(val);
					var suggestedPostal = $('input[name$="_profileaddress_postal"]').val();
					$('input[name$="_profileaddress_otherPostal"]').val(suggestedPostal);
					$('.postal-row,.city-row,.state-row').find('.input-text,.input-select').removeClass('error');
					$('.postal-row,.city-row').find('.input-text').next('span.error').hide();
					$('.state-row .custom-select').find('.selection-list').next('span.error').hide();
				}
			}
		}
	}
	$('input[name$="_profileaddress_city"]').focusout();
	util.lableFocus();
	util.updateselect();
	validator.init();
}

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm(isDialog) {
			
    var $form = $('#edit-address-form');
    
    if(isDialog){
    	var inputProfileAddr = jQuery("input[id$='address_address1']")[0];
    	autocompleteProfile = new google.maps.places.Autocomplete(inputProfileAddr);
    	//autocompleteProfile.setComponentRestrictions({'country': 'us'});
    	$form.find('input[name$="_profile_address_address1"]').attr("placeholder", "");
    	autocompleteProfile.addListener('place_changed', fillProfileAddress); 
    }
     
	
    if($form.find('input[name$="_profile_address_addressdetails"]').val() == '')
    {
    	var $radios = $('input:radio[name$=_profile_address_addresstype]');
        if($radios.is(':checked') === false) {
            $radios.filter('[value=true]').prop('checked', true);
        }
    } else {
    	var $radios = $('input:radio[name$=_profile_address_addresstype]');
        if($radios.is(':checked') === false) {
            $radios.filter('[value=false]').prop('checked', true);
        }
    }
    if ($form.find('input[name$="_profile_address_addresstype"]:checked').val() === 'true') {
    	$('input[name$="_profile_address_addressdetails"]').val('');
		$('input[name$="_profile_address_addressdetails"]').prop("disabled", true);
		$('input[name$="_profile_address_addressdetails"]').closest('.form-row').addClass('row-disabled');
    }
    $('.add-Address .form-row:eq(2)').addClass('label-inline');
    util.apoToltipAddress();
    $form.find('input[name="format"]').remove();
    $('.add-Address .postal-row').addClass('tooltip-class');
    $('<div class="class-tool-close"></div>').appendTo('.tooltip-class .tooltip-content');
    $('.tooltip-class .tooltip-content').appendTo('.postal-row.tooltip-class');
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        //@FIX-PHSSECOM-699 : copy the 'postalcode' to 'otherpostal' before serializing
    	var postal = $form.find('input[name$="_profile_address_postal"]').val();
    	var otherPostal = $form.find('input[name$="_profile_address_otherPostal"]').val();
        if ((postal != '' && postal != null && postal != undefined) && (otherPostal == '' || otherPostal == null || otherPostal == undefined)) {
        	$('input[name$="_profile_address_otherPostal"]').val($('input[name$="_profile_address_postal"]').val());
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else if (data.error) {
                    page.redirect(Urls.csrffailed);
                } else {
                    window.alert(data.message);
                    return false;
                }
            } else if(data === ''){
            	$('#dialog-container').html('<h2 class="duplicate-address">You already have an address with this name. Please unique the address name.</h2>');
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
                require('../addressvalidation').init(true);  
            }
        });
    })
    .on('click', '.cancel-button, .close-button', function (e) {
        e.preventDefault();
        dialog.close();
    })
    .on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val(),
                format: 'ajax'
            });
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    dialog.close();
                    page.refresh();
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                    return false;
                } else {
                    dialog.close();
                    page.refresh();
                }
            });
        }
    }).on('click','input[name$="_profile_address_addresstype"]', function(e) {
    	if ($('input[name$="_profile_address_addresstype"]:checked').val() === 'true') {
    		$('input[name$="_profile_address_addressdetails"]').val('');
    		$('input[name$="_profile_address_addressdetails"]').prop("disabled", true);
    		$('input[name$="_profile_address_addressdetails"]').closest('.form-row').addClass('row-disabled');
    	    util.lableFocus();   	    
    	} else { 
    		$('input[name$="_profile_address_addressdetails"]').prop("disabled", false);
    		$('input[name$="_profile_address_addressdetails"]').closest('.form-row').removeClass('row-disabled');		
    	    util.lableFocus(); 
    	}
    }).on('focusout','input[name$="_profile_address_otherPostal"]', function(e) {
    	var postal = $form.find('input[name$="_profile_address_postal"]').val();

        if (postal == '' || postal == null || postal == undefined) {
        	$('input[name$="_profile_address_postal"]').val($('input[name$="_profile_address_otherPostal"]').val());
        }
    });

    validator.init();
    util.lableFocus();
    $('.add-Address').find('.input-text').closest('.form-row:eq(0)').find('label').addClass('focus');
	$('.add-Address').find('label').closest('.form-row:eq(0)').addClass('form-label');
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
            	autoOpen: false,
            	width: '60%',
            	height: 'auto',
            	dialogClass: 'add-Address',               
                position: {
                	my: 'center',
                	at: 'center',
                	off: window,
                	collision: 'flipfit'
                },
                open: function(){
                	var timer;
                	if($('.add-Address.ui-dialog').is(':visible')){
                		window.clearTimeout(timer);
                	}
                	timer = setTimeout(function(){
                		initializeAddressForm(true);	
                	}, 200);   
                	util.buildselects();
                	var addingClassNonascii = $('.profile-edit-user label[for="dwfrm_profile_address_firstname"],.profile-edit-user label[for="dwfrm_profile_address_lastname"]').closest('.form-row').find('.input-text');
                	$(addingClassNonascii).addClass('nonascii');
                	util.nonASCII();
                }
            }
        });
    }).on('click', '.address-edit', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
            	autoOpen: false,
            	width: '60%',
            	height: 'auto',
            	dialogClass: 'add-Address',               
                position: {
                	my: 'center',
                	at: 'center',
                	off: window,
                	collision: 'flipfit'
                },
                open: function(){
                	updateStateDropDown(); 
                	var timer;
                	if($('.add-Address.ui-dialog').is(':visible')){
                		window.clearTimeout(timer);
                	}
                	timer = setTimeout(function(){
                		initializeAddressForm(true);	
                	}, 200);   
                	util.buildselects();
                	var addingClassNonascii = $('.profile-edit-user label[for="dwfrm_profile_address_firstname"],.profile-edit-user label[for="dwfrm_profile_address_lastname"]').closest('.form-row').find('.input-text');
                	$(addingClassNonascii).addClass('nonascii');
                	util.nonASCII();
                }
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                	progress.show('#main');
                	$.ajax({
            			url: Urls.accountSetting,
            			method: 'GET',
                        success: function(response) {
                        	if ($(response).length > 0) {
                        		$('.account-address-info').html($(response).find('.account-address-info').html());
                        	}
                        	progress.hide();
                        }
            		});
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    }).on('click', '.update-profile-address', function(e) {
        e.preventDefault();
        $('.edit-profile-address').empty();
        progress.show('#main');
        $('.add-profile-address').hide();
        var url = this.href;
        var $currentLoc = $(this);
        $.ajax({
            url: url,
            method: 'GET',
            success: function(response) {
                if ($(response).length > 0) {
                    if ($currentLoc.parent().hasClass('address-header') && $currentLoc.parent().next().hasClass('edit-profile-address')) {
                        $currentLoc.parent().next().html($(response).find('.profile-edit-user').html());
                    } else if ($currentLoc.parentsUntil('.address-details-wrap').parent().hasClass('address-details-wrap') && $currentLoc.parentsUntil('.address-details-wrap').parent().next().hasClass('edit-profile-address')) {
                        $currentLoc.parentsUntil('.address-details-wrap').parent().next().html($(response).find('.profile-edit-user').html());
                        $currentLoc.parentsUntil('.address-details-wrap').parent().hide();
                    }
                    if ($('.edit-profile-address').length > 0 && $('.edit-profile-address').find('#edit-address-form').length > 0) {
                        updateStateDropDown();
                        util.buildselects();
                        var addingClassNonascii = $('.profile-edit-user label[for="dwfrm_profile_address_firstname"],.profile-edit-user label[for="dwfrm_profile_address_lastname"]').closest('.form-row').find('.input-text');
                        $(addingClassNonascii).addClass('nonascii');
                        util.nonASCII();
                        var $form = $('.edit-profile-address').find('#edit-address-form');
                        util.scrollBrowser($form.offset().top - 200);
                        if ($form.find('input[name$="_profileaddress_addressdetails"]').val() == '') {
                            var $radios = $('input:radio[name$=_profileaddress_addresstype]');
                            if ($radios.is(':checked') === false) {
                                $radios.filter('[value=true]').prop('checked', true);
                            }
                        } else {
                            var $radios = $('input:radio[name$=_profileaddress_addresstype]');
                            if ($radios.is(':checked') === false) {
                                $radios.filter('[value=false]').prop('checked', true);
                            }
                        }
                        if ($form.find('input[name$="_profileaddress_addresstype"]:checked').val() === 'true') {
                            $('input[name$="_profileaddress_addressdetails"]').val('');
                            $('input[name$="_profileaddress_addressdetails"]').prop("disabled", true);
                            $('input[name$="_profileaddress_addressdetails"]').closest('.form-row').addClass('row-disabled');
                        }
                        $('input[name$="_profileaddress_addresstype"]').on('click', function(e) {
                            if ($('input[name$="_profileaddress_addresstype"]:checked').val() === 'true') {
                                $('input[name$="_profileaddress_addressdetails"]').val('');
                                $('input[name$="_profileaddress_addressdetails"]').prop("disabled", true);
                                $('input[name$="_profileaddress_addressdetails"]').closest('.form-row').addClass('row-disabled');
                                util.lableFocus();
                            } else {
                                $('input[name$="_profileaddress_addressdetails"]').prop("disabled", false);
                                $('input[name$="_profileaddress_addressdetails"]').closest('.form-row').removeClass('row-disabled');
                                util.lableFocus();
                            }
                        });
                        progress.hide();
                        var timer = setTimeout(function() {
                            util.lableFocus();
                            util.formPopulated();
                            $('.account-address-info .form-row:eq(13)').addClass('label-inline');
                            $('.account-address-info .form-row.country-drop-down').addClass('form-label');
                            $('.account-address-info .form-row.country-drop-down label').addClass('focus');
							/** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels Start */
							if($('select[name$="_profileaddress_states_state"]').val() !== ''){
								$('.form-row.state-row').find('label').show();
								$('select[name$="_profileaddress_states_state"]').closest('.form-row.state-row').find('label').addClass('focus');
							} else {
								$('.form-row.state-row').find('label').hide();
							}
							/** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels End */
                        }, 200);
                        validator.init();

                        if ($form.find('button[name$="_profileaddress_update"]').length > 0) {
                            $form.find('.phone_number_label').addClass('no-form-label');
                        }

                        if (!google_api_inactive) {
                            var inputProfileAddr = jQuery("input[id$='profileaddress_address1']")[0];
                            autocompleteProfile = new google.maps.places.Autocomplete(inputProfileAddr);

                            $form.find('input[name$="_profileaddress_address1"]').attr("placeholder", "");
                            autocompleteProfile.addListener('place_changed', fillProfileAddressForm);
                        }
                        $form.find('select[name$="_profileaddress_states_state"]').on('focusout', function(e) {
                            if ($(this).val().length == 0) {
                                $(this).addClass('error');
                                $form.find('.add-state-error').show();
                            } else if(($(this).val().length > 0)) {
                                $(this).removeClass('error');
                                $form.find('.add-state-error').hide();
								/** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels Start */
								if($('select[name$="_profileaddress_states_state"]').val() !== ''){
									$('.form-row.state-row').find('label').show();
									$('select[name$="_profileaddress_states_state"]').closest('.form-row.state-row').find('label').addClass('focus');
								} else {
									$('.form-row.state-row').find('label').hide();
								}
								/** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels End */
                            }
                        });
                        $form.find('input[name$="_profileaddress_addressid"]').on('keyup', function(e) {
                            if ($form.find('.duplicate-addressid').is(':visible')) {
                                $form.find('.duplicate-addressid').hide();
                            }
                        });
                        $('.update-address').on('click', function(e) {
                            e.preventDefault();
                            var $form = $(this).closest('form');
                            var applyName = $(this).attr('name');
                            var validForm = true;
                            var url = ' ';
                            var funCount = 1;
                            if ($form.find('input[name$="_profileaddress_address1"]').val().length > $form.find('input[name$="_profileaddress_address1"]').attr('maxlength')) {
                                $form.find('input[name$="_profileaddress_address1"]').focusout();
                                validForm = false;
                            }
                            $form.find('input.input-text').each(function() {
                                if ($(this).is(':visible') && $(this).val().length == 0 && !$(this).hasClass('sec-address') && $(this).attr('disabled') == undefined) {
                                    $(this).focusout();
                                    validForm = false;
                                    if (funCount == 1) {
                                    	 $(this).focus();
                                    }
                                    funCount++;
                                }
                                if ($(this).hasClass('phone') && $(this).hasClass('error') && funCount == 1) {
                                	$(this).focus();
                                }
                            });
                            $form.find('select.input-select').each(function() {
                                if ($(this).is(':visible') && $(this).val().length == 0) {
                                    $(this).focusout();
                                    $(this).addClass('error');
                                    validForm = false;
                                }
                            });
                            if (!$form.valid() || !validForm) {
                                return;
                            }
                            if ($form.find('input[name*="_profileaddress_postal"]').is(":visible")) {
                                $form.find('input[name*="_profileaddress_otherPostal"]').val($form.find('input[name*="_profileaddress_postal"]').val());
                            }
                            if ($form.find('input[name*="_profileaddress_otherPostal"]').is(":visible")) {
                                $form.find('input[name*="_profileaddress_postal"]').val($form.find('input[name*="_profileaddress_otherPostal"]').val());
                            }
                            if ($form.find('input[name*="AddressID"]').val() != "" && $form.find('input[name*="AddressID"]').val() != null) {
                                url = util.appendParamsToUrl($form.attr('action'), {
                                    addressid: $form.find('input[name*="AddressID"]').val(),
                                    format: 'ajax',
                                    updateAdd: true
                                });
                            } else {
                                url = util.appendParamsToUrl($form.attr('action'), {
                                    format: 'ajax',
                                    updateAdd: true
                                });
                            }
                            $.ajax({
                                url: url,
                                data: $form.serialize() + '&' + applyName + '=x',
                                method: 'POST',
                                success: function(response) {
                                    if (response.success) {
                                        progress.show('#main');
                                        $('.edit-profile-address').empty();
                                        $.ajax({
                                            url: Urls.accountSetting,
                                            method: 'GET',
                                            success: function(response) {
                                                if ($(response).length > 0) {
                                                    $('.account-address-info').html($(response).find('.account-address-info').html());
                                                    util.scrollBrowser($('.account-address-info').offset().top - 200);
                                                    progress.hide();
                                                }
                                            }
                                        });
                                    } else {
                                        $('.duplicate-addressid').show();
                                    }
                                }
                            });
                        });
                    }
                }
            }
        });
    }).on('click', '.cancel-update-address', function(e) {
        e.preventDefault();
        $('.add-profile-address').show();
        if ($(this).closest('form').parent().prev().hasClass('address-details-wrap')) {
            $(this).closest('form').parent().prev().show();
            $(this).closest('form').parent().prev().children().show();
        }
        $('.edit-profile-address').empty();
    }).on('click', '.all-addresses', function(e) {
        e.preventDefault();
        progress.show('#main');
        $.ajax({
            url: util.appendParamsToUrl(Urls.accountSetting, {
                format: 'ajax',
                showAllAddress: true
            }),
            method: 'GET',
            success: function(response) {
                if ($(response).length > 0) {
                    $('.account-address-info').html($(response).find('.account-address-info').html());
                }
                progress.hide();
            }
        });
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}

function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

}


function formErrors($this,formName){
	var formattrs = [];
	var $inputfields = $this;
	$($inputfields).each(function() {
		var formerrorField = $(this).closest('.form-row').find('.error').text();
		if($(this).closest('.form-row').find('.form-caption.error-message').text() != '' ){
			formerrorField = $(this).closest('.form-row').find('.form-caption.error-message').text();
		}
		if(formerrorField != ''){
		 var formattr = {
			formerrorMsg : formerrorField,
            formFieldName : $(this).closest('.form-row').find('label span:last').text(),
            formerrorName : formName
		 };
		 formattrs.push(formattr);
		}
	});
	if(formattrs.length > 0){
	 atmevents.formErrorMyAccounts(formattrs);
	}
}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    initializeAddressForm(); // JIRA PREV-63: 'Add Address' or 'Edit address' overlay changing to page after click on Apply button,when there is an error message on overlay.
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    login.init();
    util.lableFocus();
    util.apoToltipAddress();
    util.validateEmailMessage();
    $(window).on('load',function(){
    	 util.lableFocus();
        if ($('.login-account').find('.form-row.username').hasClass('form-label')){
  		 $('.login-account').find('.form-row.password').addClass('form-label');
  		 $('.login-account').find('.form-row.password label').addClass('focus');
	  	}
	  	else {
	  		$('.login-account').find('.form-row.password').removeClass('form-label');
			$('.login-account').find('.form-row.password label').removeClass('focus');
	  	}         
   });
    //Adobe analytics
    $('button[name$="_login_login"]').on("click",function(e){
    	$('#dwfrm_login').valid();
    	var $inputfields = $('#dwfrm_login').find('input'),
        formName = "sign in";
    	formErrors($inputfields,formName);
    });
    
    $('.create-btn').on("click" ,function(e){
    	$('#RegistrationForm').valid();
    	if(!$('.input-text').hasClass('error')){
		  if (SitePreferences.isATMEnabled) {
    		atmevents.createAccount();
		  }
    	}
    	var $inputfields =  $(this).closest('#RegistrationForm').find('input'),
    	formName = "Sign Up";
    	formErrors($inputfields,formName);
    });
    
    $('.create-btn-po').on("click" ,function(e){
    	if($('#PurchaseOrderRegistration').valid()) {
    		$(this).addClass('disable-mode-btn');
    	}   	
     });
    
    
    var $inputfields,formName;
    if($('#RegistrationForm').length > 0){
    	$inputfields  =  $('#RegistrationForm').find('input');
    	formName = "Sign Up";
    }else if($('#dwfrm_login').length){
	    $inputfields = $('#dwfrm_login').find('input');
	    formName = "sign in";
    }
	formErrors($inputfields,formName);
	
	window.onload = function() {
		var primary = $('#primary');
	    if (primary.find('.classcompletion').length > 0) {
	            $(".component1").each(function(){
	            	 var val = $(this).val();
	        	     var url = primary.find('.classcompletion').val();
	        	     var lmscancellationstatus = $(this).closest('.order-item-ship-row').find('.lmscancellationstatus').val();
	        	     var courseFormat = primary.find('input[name="CourseFormat_'+val+'"]').length > 0 ? primary.find('input[name="CourseFormat_'+val+'"]').val() : null;
	        	     var coursedatestatus = primary.find('input[name="coursedatestatus_'+val+'"]').length > 0 ? primary.find('input[name="coursedatestatus_'+val+'"]').val() : null;
	        	     var cancelId = val;
	        	     
 	                 url = util.appendParamsToUrl(url, {
	                        lmsid : val,
	                        format : 'ajax'
	                  });
	                  ajax.getJson({
	                        url : url,
	                        async : false,
	                        context: this,
	                        callback : function(data) {if(data && data!=null){
	                        	
                                if (data.status == 100 && data.completionStatus && lmscancellationstatus != 'Cancelled') {
                                	console.log(" url ::"+url+"status:::"+data.completionStatus);
                              	  if(data.completionStatus == 'Cancelled' || data.completionStatus == 'Suspended' ){
                              		  
                             		  $('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                             		 if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                             			 $('.main-label.'+cancelId).addClass('disabled');
                             			 $('.dropdown-value.'+cancelId).addClass('hide');
                             			$('.cancel-return.'+cancelId).addClass('hide');
                             		 } else {
                             			 $('.main-label.'+cancelId).removeClass('disabled');
                             			 $('.dropdown-value.'+cancelId).removeClass('hide');
                             			//$('.cancel-return.'+cancelId).removeClass('hide');
                             		 }
                             		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                             			 $('.oms-status.'+val).find('.classstatus').text('Cancelled');
                             		  }
                             		  $('.status-myclass').find('.'+cancelId).find('.classstatus').text('Cancelled');
                             		  $('.orderdetails').find('.' + cancelId + ' .launch-class').addClass('hide');
                             		  completedClass(false, cancelId); 
                                   } else if(data.completionStatus == 'Successful'){
                             		  $('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                             		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                              			 $('.oms-status.'+val).find('.classstatus').text('Successful');
                              		  }
                             		 if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                                 	     $('.main-label.'+cancelId).addClass('disabled');
                                 		 $('.dropdown-value.'+cancelId).addClass('hide');
                                 		 $('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                             		 } else {
                                 	     $('.main-label.'+cancelId).removeClass('disabled');
                                 		 $('.dropdown-value.'+cancelId).removeClass('hide');
                                 		 $('.'+cancelId).closest('.status-myclass').find('.cancel-return').removeClass('hide');
                             		 }
                             		 $('.review-container.'+val).removeClass('hide');
                             		  $('.status-myclass').find('.'+cancelId).find('.classstatus').text(data.completionStatus);
                             		 $('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').hide();
                             		$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').show();
                             		  completedClass(true, cancelId);
                                   } else if (data.completionStatus == 'Unsuccessful') {
                                	  $('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                             		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                               			 $('.oms-status.'+val).find('.classstatus').text(data.completionStatus);
                               		  }
                             		 if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                                 	     $('.main-label.'+cancelId).addClass('disabled');
                                 		 $('.dropdown-value.'+cancelId).addClass('hide');
                                 		 $('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                             		 } else {
                                 	     $('.main-label.'+cancelId).removeClass('disabled');
                                 		 $('.dropdown-value.'+cancelId).removeClass('hide');
                                 		 $('.'+cancelId).closest('.status-myclass').find('.cancel-return').removeClass('hide');
                             		 }
                              		$('.status-myclass').find('.'+cancelId).find('.classstatus').text(data.completionStatus);
                              		$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
                              		$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();
                              		  completedClass(false, cancelId);
                                   } else if(data.completionStatus == 'Not Evaluated'){ // $('.'+cancelId).closest('.status-product').find('.cancel-return').addClass('hide');
                                	   
                                  		 if(courseFormat != undefined && courseFormat != null && courseFormat == 'Online') {
                                  			// If online class order creation date > 48hrs allowing to cancell the class otherwise greyed out
                                  			var diffInHours =  parseInt($(this.context).closest('.i-would-liketo').find('.onlineDiffHrs').val(), 10);
                                  			var onlineCourseCancInHrs = parseInt($('.onlineCourseCancInHrs').val(), 10);
                                  			if(diffInHours > onlineCourseCancInHrs){
                                  				//$('.main-label.'+cancelId).addClass('disabled');
                                  				$(this.context).closest('.i-would-liketo').find('.main-label').addClass('disabled');
                                  				$('.status-myclass').find('.'+cancelId).find('.classstatus').text(data.completionStatus);
                                  				$('.oms-status.'+val).find('.classstatus').text(data.completionStatus);
                                  				
                                  			} else{
                                  				
                                  				if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                                          			 $('.oms-status.'+val).find('.classstatus').text('Not Evaluated');
                                          		}
                                  				$('.status-myclass').find('.'+cancelId).find('.classstatus').text('Not Evaluated');
                                  				$('.oms-status.'+val).find('.classstatus').text('Not Evaluated');
                                  				//$('.main-label.'+cancelId).removeClass('disabled');
                                  				//TSED-1095 & TSED-1096: My Account > Order History, Order Details - Grey Out Cancel Enrollment before course order exports to Cloud Craze
                                  				if($('.i-would-liketo').find('input[name$="orderExportedToSFCC"]').val() && $('.i-would-liketo').find('input[name$="orderExportedToSFCC"]').val() == 'true') {
                                  					$(this.context).closest('.i-would-liketo').find('.main-label').removeClass('disabled');
                                  				} /*else {
                                  					$(this.context).closest('.i-would-liketo').find('.main-label').addClass('disabled');
                                  				}*/
                                  				
                                       			$('.dropdown-value.'+cancelId).removeClass('hide');
                                       			$('.'+cancelId).closest('.status-myclass').find('.cancel-return').removeClass('hide');
                                       			$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
                                      			$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();
                                  				 /*if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                                           			 $('.oms-status.'+val).find('.classstatus').text('Not Evaluated');
                                           		  }
                                      			 $('.status-myclass').find('.'+cancelId).find('.classstatus').text('Not Evaluated');
                                      			if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                                         			$('.main-label.'+cancelId).addClass('disabled');
                                           			$('.dropdown-value.'+cancelId).addClass('hide');
                                           			$('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                                      			} else {
                                         			$('.main-label.'+cancelId).removeClass('disabled');
                                           			$('.dropdown-value.'+cancelId).removeClass('hide');
                                           			$('.'+cancelId).closest('.status-myclass').find('.cancel-return').removeClass('hide');
                                      			}

                                      			$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
                                      			$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();*/
                                  				
                                  			}
                                    		 
                                  		 } else {
                                             if(coursedatestatus != undefined && coursedatestatus != null && coursedatestatus == 'pastdate'){
                                       		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                                        			 $('.oms-status.'+val).find('.classstatus').text(data.completionStatus);
                                        		  }
                                             	$('.status-myclass').find('.'+cancelId).find('.classstatus').text(data.completionStatus);
                                             	$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
                                             	$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();
      	                                    } else { 
                                         		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                                         			 $('.oms-status.'+val).find('.classstatus').text('Registered');
                                         		  }
                                         		  $('.cancel-return.'+val).removeClass('hide');
     	                                  		$('.status-myclass').find('.'+cancelId).find('.classstatus').text('Registered');  
     	                                  		$('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
     	                                  		$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();
     	                                  	}
/*                                   			if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                                       			$('.'+cancelId).closest('.status-myclass').find('.cancel-return').addClass('hide');
                                  			} else {
                                  				$('.'+cancelId).closest('.status-myclass').find('.cancel-return').removeClass('hide');
                                  			}*/
                                  		 }
                                  	 
                                  	  completedClass(false, cancelId); 
                                  	}
                                } else if(lmscancellationstatus != null && lmscancellationstatus == 'Cancelled'){
                              		$('.oms-status.'+val).find('.classstatus').text('Cancelled');
                              		$('.main-label.'+cancelId).addClass('disabled');
                              		//TSED-1095 & TSED-1096: My Account > Order History, Order Details - Grey Out Cancel Enrollment before course order exports to Cloud Craze
                              		if($('.classstatus').text() == 'Cancelled' && $('.i-would-liketo').find('.main-label').hasClass('disabled') && $('.page-refresh-text').length) {
                      					$('.page-refresh-text').hide();
                      				}
                                } 
                                else{
                             	  $('.status-myclass').find('.'+cancelId).find('.classstatus').text('Registered');
                         		  if( $('.oms-status.'+val).length > 0 && $('.oms-status.'+val).find('.classstatus').length > 0) {
                          			 $('.oms-status.'+val).find('.classstatus').text('Registered');
                          		  }
                         		 $('.cancel-return.'+val).removeClass('hide');
                         		if($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+cancelId).find('input[name="agentrefundeligible"]').val() == "false")) {
                            		$('.main-label.'+cancelId).addClass('disabled');
                              		$('.dropdown-value.'+cancelId).addClass('hide');
                         		} else {
                            		$('.main-label.'+cancelId).removeClass('disabled');
                              		$('.dropdown-value.'+cancelId).removeClass('hide');
                         		}
                             	 $('.status-myclass').find('.'+cancelId).parent().siblings('.completed-myclass-certificate').hide();
                             	$('.status-myclass').find('.'+cancelId).parent().siblings('.my-classes-complete').show();
                             	  completedClass(false, cancelId);
                               }
                         }}
	                  });
	           });
	    }
	};
    
    $('.get-your-certificate').on('click', function(e){
    	page.redirect(Urls.getYourCertificate);
    });
    
    if($('.address-make-default').length > 0 && $('.default-address-class').val() != 'true') {
    	$('body').find('.address-make-default')[0].click();
    }
	
    // Added For PHSSDWRE-2708
    // Fixed For PHSSDWRE-3503
    function completedClass (completed, lmsOrdderId) {
    	if(lmsOrdderId != undefined && lmsOrdderId != null && lmsOrdderId != 'null') {
    	    if (completed) {
    	    	//$('body').find('.completed-class-certificate').removeClass('hide');
    	    	$('body').find('.completed-class-certificate.'+lmsOrdderId).show();
    	    	$('body').find('.classes-col-2').addClass('hide');
    	    	$('body').find('.cld-msg').addClass('hide');
    	    	$('body').find('.certification-info').addClass('hide');
    	    } else {
    	    	$('body').find('.completed-class-certificate.'+lmsOrdderId).hide();
    	    }
    	}
    }
    
    // for adding the remove padding class in order history page
    $('.pt_order .pagination').find('.dot-value').parent('li').addClass('padding-remove');
    
    //Update Email Address
    $('.edit-profile').on('click', function(e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        $form.find('input[name*="profile_customer_email"]').val('');
        $form.find('.profile-details-email').hide();
        $form.find('.edit-email').show();

        $('.cancel-update-email').on('click', function(e) {
            e.preventDefault();
            $form.find('.profile-details-email').show();
            $form.find('.edit-email').hide();
        });

        $('.save-updated-email').on('click', function(e) {
            e.preventDefault();
            var email = $form.find('input[name*="profile_customer_email"]').val();
            var applyName = $(this).attr('name');
            $.ajax({
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                method: 'POST',
                success: function(response) {
                    if (response.success) {
                        $form.find('.profile-email').html(email);
                        $form.find('.profile-details-email').show();
                        $form.find('.edit-email').hide();
                    }
                }
            });
        });
    });

    //Update Password
    $('.change-pass').on('click', function(e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        $form.find('input[name*="profile_login_newpassword"]').val('');
        $form.find('input[name*="profile_login_newpasswordconfirm"]').val('');
        $form.find('.profile-password').hide();
        $form.find('.change-profile-password').show();

        if ($form.find('input[name*="profile_login_newpasswordconfirm"]').hasClass('error')) {
            $form.find('input[name*="profile_login_newpasswordconfirm"]').removeClass('error');
            $form.find('span[id*="profile_login_newpasswordconfirm"]').hide();
        }

        $('.cancel-update-password').on('click', function(e) {
            e.preventDefault();
            $form.find('.profile-password').show();
            $form.find('.change-profile-password').hide();
        });

        $('.save-updated-password').on('click', function(e) {
            e.preventDefault();
            var applyName = $(this).attr('name');
            $.ajax({
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                method: 'POST',
                success: function(response) {
                    if (response.success) {
                        $form.find('.profile-password').show();
                        $form.find('.change-profile-password').hide();
                    }
                }
            });
        });
    });
}


var $order = $('.orderdetails');

//The below ajax will be used to cancel the order via saba 
$order.on('click', '.cancel-class', function (e) {
	e.preventDefault();
	var $this = $(this);
	var orderno = $(this).siblings('.order_num').val();
	var lmsid = $(this).siblings('.cancelOrderItemID').val();
	var paymentmethod = $(this).siblings('.paymentMethod').val();
	var classid = $(this).siblings('.class_ID').val();
	var productLineUUID = $(this).siblings('.productlineitem_uuid').val();
	var cancelreason = $('#cancelclass').find('option:selected').text();
   	var calcelReasonValue = $('.cancel-order-dt').find('select[name$="reason"] option:selected').text();
	var url = $(this).siblings('.cancel').val();
	url = util.appendParamsToUrl(url, {
		orderno : orderno,
		lmsid	: lmsid,
		classid : classid,
		productLineUUID : productLineUUID,
		payment : paymentmethod,
		cancelreason : calcelReasonValue,
	    format  :  'ajax'
	});
	ajax.getJson({
		url: url,
		callback: function (data) {
			if(data != null && data != 'null' && data !='' && data.status != null && data.status != '' && data.status == 100){
				
				progress.hide();
				$order.find('.status-product.active').find('span.classstatus').text('Cancelled');
       		    if( $('.oms-status.'+lmsid).length > 0 && $('.oms-status.'+lmsid).find('.classstatus').length > 0) {
       		    	$('.oms-status.'+lmsid).find('.classstatus').text('Cancelled');
       		    }
	       		 if($('.dropdown-value.'+lmsid).find('input[name="agentrefundeligible"]').length > 0 && ($('.dropdown-value.'+lmsid).find('input[name="agentrefundeligible"]').val() == false || $('.dropdown-value.'+lmsid).find('input[name="agentrefundeligible"]').val() == "false")) {
	        		    $('.main-label.'+lmsid).addClass('disabled');
	           		    $('.dropdown-value.'+lmsid).addClass('hide');
	           		    $('.cancel-return.'+lmsid).addClass('hide');
	       		 }
				$order.find('.status-product.active').find('.cancel-return').text('');
				
				var $content = $('.cancel-class-result.'+lmsid);
				$content.dialog({
					autoOpen: false,
					modal: true,
					width: '600',
					dialogClass: 'learnertrigger cancel-class-result',
					
					buttons: {
						Close: function () {
							$(this).dialog('close');
							//added this to get refund amount updated.
							window.location.reload();
						}
					},
					open: function() {
						$('.cancel-class-result').find('span.class-cancel-result-msg').html('');
						$('.cancel-class-result').find('span.class-cancel-result-msg').html(Resources.CLASS_CANCEL_SUCC_MSG);
					}
					
				}).dialog("open");				
			}else{
				progress.hide();
				var cancelErrorMsg = "";
				if( data == 14002 ) {
					cancelErrorMsg = "Cannot cancel enrollment, course has already been completed";
				} else if( data == 1004  ) {
					cancelErrorMsg = "One of the required fields is missing (order Id, class id)";
				} else if( data == 4000  ) {
					cancelErrorMsg = "Order Id does not exist (no voucher record found)";
				} else if( data == 4001  ) {
					cancelErrorMsg = "Class id does not exist";
				} else if( data == 4003  ) {
					cancelErrorMsg = "Student is already canceled from the class";
				} else {
					cancelErrorMsg = "Couldnt Cancel, please contact American RedCross customer care";
				}
				
				$order.find('.status-product.active').find('.cancel-return').text(cancelErrorMsg);	
				$order.find('.status-product.active').find('.cancel-return').css('color', '#FF0000');
			}
			
		}
	});
});


var $main = $('#main');

$main.find('.cancel-enrollment').on('click', function (e) { 
    e.preventDefault();
    var $current = $(this);
    var $classid  = $current.data('classid');
    var $content =  $('.current'+$classid); 
    $main.find('.status-product').removeClass('active');
    $current.closest('.status-product').addClass('active');
    var cancelClassDlg =  dialog.create({
        options:{ 
            width: "755px", 
            height: "auto",
            dialogClass : 'remove-coupon-cart cancel-order-dt',
            open: function() {
            	$('body').find('.cancelclassid').val($current.data('classid'));      		    
            	$('body').find('.class-name').html($current.data('classname'));
	      		$('body').off('click','.keep-coupon').on('click','.keep-coupon',function(e){
	      			dialog.close();
	          	});
	      		$('body').off('click','.class-cancel').on('click','.class-cancel',function(e){
	      			e.preventDefault();
	      			$('body').find('#cancelclass-'+$('.cancelclassid').val()).trigger('click');
	      			$('.orderdetails').find('.launch-class').addClass('hide');
	      			dialog.close();
	      			progress.show($('#main'));
	      		});	      		 
            }
         } 
    });
    $(cancelClassDlg).html($content.html());
    cancelClassDlg.dialog("open");
});

$main.find('.agent-additional-cancel').on('click', function (e) {
    e.preventDefault();
    var $current = $(this);
    var $content = $('#dialog-class-additionalcancellation');
	var orderno = $current.data('orderno');
	var paymentmethod = $current.data('payment');
	var productlineitem_uuid = $current.data('uuid');
	
    var cancelClassDlg =  dialog.create({
        options:{ 
            width: "550px", 
            height: "auto",
            dialogClass : 'remove-coupon-cart cancel-order-dt refunddialog',
            open: function() {
            	var form = $('.amount-refund');
        		//$('input[name="refundamount"]').removeClass('error');
            	$('body').find('.refend-amount-class span.value').html('$'+$current.data('classprice'));
            	$('body').find('.class-name').html($current.data('classname'));
       		 	$('.amount-refund').find('.refund_exceeded').addClass('hide');
       			$('.amount-refund').find('.unknown_error').addClass('hide');
       			var amountField = '<div class="form-horizontal address refund-lms-amount"><div class="form-row allow-decimalphone required" aria-required="true"><label for="refundamount" class=""><span class="required-indicator">•</span><span>Amount</span></label><div class="field-wrapper checkbox-wrapper"><input class="input-text allow-decimalphone  required" type="text" name="refundamount" maxlength="5" aria-required="true" aria-invalid="true" ><span class="error validate-input hide">Required</span></div';
       			$(this).append(amountField);
            	$(this).append('<button class="submit-refund  button-fancy-large">Submit</button>');
            	util.lableFocus();
            	$(".allow-decimalphone").bind("change keyup input",function (value) {
        	        var position = this.selectionStart - 1;
        	        var fixed = this.value.replace(/[^0-9\.]/g, '');
        	        if (fixed.charAt(0) === '.')                 
        	            fixed = fixed.slice(1);
        	        var pos = fixed.indexOf(".") + 1;
        	        if (pos >= 0)//avoid more than one .
        	            fixed = fixed.substr(0, pos) + fixed.slice(pos).replace('.', '');
        	        if (this.value !== fixed) {
        	            this.value = fixed;
        	            this.selectionStart = position;
        	            this.selectionEnd = position;
        	        }
            	 });
            	var $form = $('.address');
            	$form.on('blur','input.input-text', function() {
            		if(!$('.refund-lms-amount .input-text').val().length > 0){
            			var addingErrorClass = $('.refund-lms-amount .input-text');
      					$(addingErrorClass).addClass('error');
      					$('.validate-input').removeClass('hide');
      		            return false;
      				}
            		else{
            			var addingErrorClass = $('.refund-lms-amount .input-text');
      					$(addingErrorClass).removeClass('error');
      					$('.validate-input').addClass('hide');
            		}
                });
            	util.allownbDec(); 
      			$('.submit-refund').on('click', function(e){
      				e.preventDefault();
      				if(!$('.refund-lms-amount .input-text').val().length > 0){
            			var addingErrorClass = $('.refund-lms-amount .input-text');
      					$(addingErrorClass).addClass('error');
      					$('.validate-input').removeClass('hide');
      					$('.refund_exceeded').addClass('hide');
      		            return false;
      				}
                	var url = Urls.agentAdditionalRefund;
          			url = util.appendParamsToUrl(url, {
          				orderno : orderno,
          				refundAmount : $('input[name="refundamount"]').val(),
          				payment : paymentmethod,
          				productLineUUID : productlineitem_uuid
          			});
	                  ajax.getJson({
	                	  url: url,
	                	  data: form.serialize(), 
	                        async : false,
	                        callback : function(data) {
	                        	if(data.success) {
	                        		dialog.close();
	                        		window.location.reload();
	                        	} else if(!data.success && data.error == 'invalidamount') {
	                        		$('input[name="refundamount"]').addClass('error');
	                        		 $('.refund_exceeded.'+orderno).removeClass('hide');
	                        	} else if(!data.success && data.error == 'refundfailed' || data.error == 'unknownerror') {
	                        		$('input[name="refundamount"]').addClass('error');
	                        		$('.unknown_error.'+orderno).removeClass('hide');
	                        	}
	                        }
	                  });
	          	});
            }
         } 
    });
    $(cancelClassDlg).html($content.html());
    cancelClassDlg.dialog("open");
});
 

var $primary = $('#primary');
$primary.on('click', 'span.value.orderdate, span.value.ordernum', function() {
	$(this).closest('.user-order-list').find('#orderdetailsbutton').trigger('click'); 
}); 


$main.find('.cancel-link-product').on('click', function (e) {
    e.preventDefault();
    var $current = $(this);
    var $content = $('#dialog-product-cancellation');
    var cancelProductDlg = dialog.create({
        options:{ 
            width: "755px", 
            height: "auto",
            dialogClass : 'remove-coupon-cart cancel-order-dt',
            open: function() {  
            	$('body').find('.class-name.product-nm').html($current.data('productname'));
            	$('body').find('span.return-qty-product').html($current.data('qtyreturn'));
	      		$('body').off('click','.coupon-remove-button .keep-coupon').on('click','.coupon-remove-button .keep-coupon',function(e){
	      			dialog.close();
	          	});
	      		$('body').off('click','.coupon-remove-button .coupon-remove').on('click','.coupon-remove-button .coupon-remove',function(e){
	      			e.preventDefault();
	      			//ajax the order cancellation
	      			var url = $current.data('cancelurl');
	      			var orderno = $current.data('jdenumber');
	      			var lineno = $current.data('cancelline');
	      			var cancelreason = $('#dialog-container #cancelclass select').val();
	      			url = util.appendParamsToUrl(url, {
	      				orderno : orderno,
	      				lineno : lineno,
	      				cancelreason : cancelreason
	      			});
	      			$.ajax({
	                    url: url,
	                    method: 'GET',
	                    dataType: 'json',
	                    success: function(response) {
	                    	if (response.success) {
	                    		$current.parents('.cart-product').before("<div><span class='cancelled-item'>This item has been cancelled!</span></div>");
	                    	} else {
	                    		$current.parents('.cart-product').before("<div><span class='cancelled-item'>There was an issue cancelling this product. Please try again later.</span></div>");
	                    	}
	                    	$current.remove();
	                    	dialog.close();
	                    }	                   
	                });
	      		});	      		
            }
         } 
    });
    $(cancelProductDlg).html($content.html());
    cancelProductDlg.dialog("open");
});

// For Account Edit Profile Functionality - Commenting for PHSSDWRE-2712
/*var emailCust = $('input[name$="_profile_customer_email"]').val();
var emailConfirmCust = $('input[name$="_profile_customer_emailconfirm"]').val();
var fnameCust = $('input[name$="_profile_customer_firstname"]').val();
var lnameCust = $('input[name$="_profile_customer_lastname"]').val();
$main.find('.cancel-edit').on('click', function (e) {
	e.preventDefault();
	$('input[name$="_profile_customer_email"]').val(emailCust);
	$('input[name$="_profile_customer_emailconfirm"]').val(emailConfirmCust);
	$('input[name$="_profile_customer_firstname"]').val(fnameCust);
	$('input[name$="_profile_customer_lastname"]').val(lnameCust);
	$('.user-entered-pwd').val('');
	$('.confirm-entered-pwd').val('');
	util.lableFocus();
}); */ 
$main.find('.cancel-edit').on('click', function (e) {
	e.preventDefault();
    page.redirect(Urls.cancelEditProfile);
});

function purchaseOrderEvents() {
	var $form = $('#PurchaseOrderRegistration');
	var invoiceMethod = "";
	if ($('.create-account-section.purchaseOrder').length) {
		$('#secondary').hide();
	} //temp hide the left nav for PO form page
	$('.selected-invoice .checkbox-wrapper label:first-child input[name$="_purchaseorder_customer_selectedInvoiceMethod"]').prop('checked', true); //initialize radio button
	$('input[name$="_purchaseorder_customer_POAmount"]').addClass('positivenumber'); //add number validation
	$('input[name$="_purchaseorder_customer_userID"]').addClass('email'); //validate as email
	$('input[name$="_purchaseorder_customer_invoiceEmail"]').addClass('email-create'); //add these classes for confirm compare validator
	$('input[name$="_purchaseorder_customer_invoiceEmailConfirm"]').addClass('email-conform-create'); //add these classes for confirm compare validator
	$('.purchaseorder-section.billing .postal-row').insertAfter($('.purchaseorder-section.billing .state-row')); //move fields for custom design
	$('.purchaseorder-section.billing .country-row').insertAfter($('.purchaseorder-section.billing .postal-row')); //move fields for custom design
	$('.purchaseorder-section.shipping .postal-row').insertAfter($('.purchaseorder-section.shipping .state-row')); //move fields for custom design
	$('.purchaseorder-section.shipping .country-row').insertAfter($('.purchaseorder-section.shipping .postal-row')); //move fields for custom design
	$('.purchaseorder-section.billing .phone-row').insertAfter($('.purchaseorder-section.billing .lastname-row').next()); //move fields for custom design
	$('.purchaseorder-section.shipping .phone-row').insertAfter($('.purchaseorder-section.shipping .lastname-row').next()); //move fields for custom design

	//copy billing to shipping listener
	$form.on('change', 'input[name$="_shippingAddress_useAsBillingAddress"]', function() {
		if ($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')){
			$('input[name$="_shippingAddress_addressFields_firstName"]').val($('input[name$="_billingAddress_addressFields_firstName"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_lastName"]').val($('input[name$="_billingAddress_addressFields_lastName"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_email"]').val($('input[name$="_billingAddress_addressFields_email"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_address1"]').val($('input[name$="_billingAddress_addressFields_address1"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_address2"]').val($('input[name$="_billingAddress_addressFields_address2"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_city"]').val($('input[name$="_billingAddress_addressFields_city"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_postal"]').val($('input[name$="_billingAddress_addressFields_postal"]').val()).blur();
			$('input[name$="_shippingAddress_addressFields_country"]').val($('input[name$="_billingAddress_addressFields_country"]').val()).blur();
			$('select[name$="_shippingAddress_addressFields_states_state"]').val($('select[name$="_billingAddress_addressFields_states_state"]').val());
			$('input[name$="_shippingAddress_addressFields_phone"]').val($('input[name$="_billingAddress_addressFields_phone"]').val()).blur();
			util.lableFocus();
			util.formPopulated();
			util.updateselect();
		} else{
			$('input[name$="_shippingAddress_addressFields_firstName"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_lastName"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_email"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_address1"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_address2"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_city"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_postal"]').val('').blur();
			$('input[name$="_shippingAddress_addressFields_country"]').val('').blur();
			$('select[name$="_shippingAddress_addressFields_states_state"]').val('');
			$('input[name$="_shippingAddress_addressFields_phone"]').val('').blur();
			util.lableFocus();
			util.formPopulated();
			util.updateselect();
		}
	});
	
	//show/hide invoiceemail fields 
	$form.on('change', 'input[name$="_selectedInvoiceMethod"]', function() {
		if ($(this).val() == 'email') {
			invoiceMethod = "email";
			$('input[name$="_invoiceEmail"]').parent().parent().show();
			$('input[name$="_invoiceEmailConfirm"]').parent().parent().show();
		} else {
			invoiceMethod = "mail";
			$('input[name$="_invoiceEmail"]').parent().parent().hide();
			$('input[name$="_invoiceEmailConfirm"]').parent().parent().hide();
		}
	});
	
	//convert uploaded file into base64 encode for passing
	function readFile() {
		if (this.files && this.files[0]) {
		    var FR= new FileReader();
		    
		    FR.addEventListener("load", function(e) {
		    	var b64 = e.target.result.toString();
		    	b64 = b64.substring(b64.indexOf("base64,") + 7);
		    	$("#dwfrm_purchaseorder_customer_b64").val(b64);
		    }); 
		    
		    var uploadedFileSize = this.files[0].size; 
 		    if(uploadedFileSize > SitePreferences.pouploadFileSize){
		    	$('#filesizeerror').show();	
 		    	$('.button-fancy-large').attr('disabled', 'disabled');
		    } else {
		    	$('#filesizeerror').hide();	
 		    	$('.button-fancy-large').prop("disabled", false);
		    }
		    
		    FR.readAsDataURL( this.files[0] );
		    $("#dwfrm_purchaseorder_customer_fileName").val(this.files[0].name);
		    $("#dwfrm_purchaseorder_customer_fileType").val(this.files[0].type);
		}
	}

	$form.on('change', '#uploadFiles', readFile);
	
	$(document).on('submit', '#PurchaseOrderRegistration', function(e) {
		//prevent default form submission
		e.preventDefault();
		
		//put base64 encoded attachments
		if ($('input[name$="_purchaseorder_customer_b64"]').val() != '') {
			var attachments = [{"Body" : $('input[name$="_purchaseorder_customer_b64"]').val(), "ContentType": $('input[name$="_purchaseorder_customer_fileType"]').val(), "Name": $('input[name$="_purchaseorder_customer_fileName"]').val()}];
		}
		else {
			var attachments = [];
		}
		
		//attaching comments section with additionals
		var comments = $('textarea[name$="_purchaseorder_customer_comments"]').val();
		if (invoiceMethod == "mail") {
			comments = comments + "\nInvoice Delivery Method: Postal Mail";
		}
		
		//fulfill proper format of date objects
		var POStartDate, POEndDate = "";
		if ($('input[name$="_purchaseorder_customer_POStartDate"]').val() != '')
			POStartDate = new Date($('input[name$="_purchaseorder_customer_POStartDate"]').val());
		
		if ($('input[name$="_purchaseorder_customer_POEndDate"]').val() != '')
			POEndDate = new Date($('input[name$="_purchaseorder_customer_POEndDate"]').val());
		
		//AJAX POST request to controller - modified to avoid base64 string nulling all form fields
		$.ajax({
             url: $(this).attr('action'),
             method: "POST",
             dataType: 'json',
             contentType: 'application/json',
             data: JSON.stringify({
            	 CompanyName : $('input[name$="_purchaseorder_customer_companyName"]').val(),
            	 CustomerFirstName : $('input[name$="_purchaseorder_customer_customerFirstName"]').val(),
            	 CustomerLastName  : $('input[name$="_purchaseorder_customer_customerLastName"]').val(),
            	 Usage				: "BILL TO",
            	 BillingContactFirstName : $('input[name$="_purchaseorder_billingAddress_addressFields_firstName"]').val(),
            	 BillingContactLastName : $('input[name$="_purchaseorder_billingAddress_addressFields_lastName"]').val(),
            	 BillingContactEmail : $('input[name$="_purchaseorder_billingAddress_addressFields_email"]').val(),
            	 BillingContactPhone : $('input[name$="_purchaseorder_billingAddress_addressFields_phone"]').val(),
            	 BillingAddress1 : $('input[name$="_purchaseorder_billingAddress_addressFields_address1"]').val(),
            	 BillingAddress2 : $('input[name$="_purchaseorder_billingAddress_addressFields_address2"]').val(),
            	 BillingCity : $('input[name$="_purchaseorder_billingAddress_addressFields_city"]').val(),
            	 BillingState : $('select[name$="_purchaseorder_billingAddress_addressFields_states_state"]').val(),
            	 BillingZip : $('input[name$="_purchaseorder_billingAddress_addressFields_postal"]').val(),
            	 BillingCountryName : "USA",
            	 BillingCountryCode : $('select[name$="_purchaseorder_billingAddress_addressFields_country"]').val(),
            	 ShippingContactFirstName : $('input[name$="_purchaseorder_shippingAddress_addressFields_firstName"]').val(),
            	 ShippingContactLastName : $('input[name$="_purchaseorder_shippingAddress_addressFields_lastName"]').val(),
            	 ShippingContactPhone : $('input[name$="_purchaseorder_shippingAddress_addressFields_phone"]').val(),
            	 ShippingContactEmail : $('input[name$="_purchaseorder_shippingAddress_addressFields_email"]').val(),
            	 ShippingAddress1 : $('input[name$="_purchaseorder_shippingAddress_addressFields_address1"]').val(),
            	 ShippingAddress2 : $('input[name$="_purchaseorder_shippingAddress_addressFields_address2"]').val(),
            	 ShippingCity : $('input[name$="_purchaseorder_shippingAddress_addressFields_city"]').val(),
            	 ShippingState : $('select[name$="_purchaseorder_shippingAddress_addressFields_states_state"]').val(),
            	 ShippingZip : $('input[name$="_purchaseorder_shippingAddress_addressFields_postal"]').val(),
            	 ShippingCountryName : "USA",
            	 ShippingCountryCode : $('select[name$="_purchaseorder_shippingAddress_addressFields_country"]').val(),
            	 Terms : $('select[name$="_purchaseorder_customer_paymentTerms"]').val(),
            	 ExternalLOGINEMAIL : $('input[name$="_purchaseorder_customer_userID"]').val(),
            	 InvoiceDeliveryEmail : $('input[name$="_purchaseorder_customer_invoiceEmail"]').val(),
            	 PONumber : $('input[name$="_purchaseorder_customer_PONumber"]').val(),
            	 POAmount : $('input[name$="_purchaseorder_customer_POAmount"]').val(),
            	 POStartDate : POStartDate,
            	 POEndDate : POEndDate,
            	 Comments : comments,
            	 CustomerReferenceNumber : $('input[name$="_purchaseorder_customer_referenceNumber"]').val(),
            	 attachments: attachments
             })
         }).done(function (data) {
             if (data.success) {
            	 window.location = Urls.poComplete;
             } else {
            	 window.location = Urls.poError;
             }
         });
	});
	
	validator.init();
};

var digitalDownload = function() {
	$(document).on('click','#digital-download-link', function(e){
		e.preventDefault();
		
		//prevent false click on disabled button
		if ($(this).parent().prop('disabled')) {
			return false;
		}
		
		//capture the product id
		var pid = $(this).data("pid");
		
		//use redirect to grab attachment from http headers, hiding library url
		window.location = Urls.digitalDownload + "?pid=" + pid;
	});
};

 	//@FIX- TSED-247 - Not allowing special characters and letters For zipcode
 	$(document).on('input paste', '[name$="_profileaddress_postal"]', function(e) {
		var value = $(this).val();
		$(this).val(value.replace(/[\.\,\'\~\`\!\@\#\$\%\^\&\*\(\)\_\+\=\[\]\{\}\\\|\;\:\"\<\>\?\-\/\u00D7\u00F7a-zA-Z]/g, ""));
		if(value.length > 5){            
            $(this).val(value.slice(0,5));
        }
	});
	
	$(document).on('input paste', '.input-text.phone', function(e) {
        var value = $(this).val();
        $(this).val(value.slice(0,10));
    });


var account = {
    init: function () {
        initializeEvents();
        purchaseOrderEvents();
        digitalDownload();
        giftcert.init();
        util.saveCalendar();
        util.cartclassHeight();
    	util.orderDetailsToggle();
    	util.addCalenderHidden();
    },
    initCartLogin: function () {
        login.init();
    }
};

module.exports = account;
