'use strict';

var giftcert = require('../giftcert'),
	formPrepare = require('../validator'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    atmevents = require('../atmevents'),
    gtmevents = require('../gtm'),
    minicart = require('../minicart'),
    Promise = require('promise'),
    ajax = require('../ajax');


var certificates = { 
    init: function () {  
    	var $certificateLayout = $('.certificate-layout');
    	$(document).on('click','.certificateprint', function(){ 
    		window.print();
    	});

    	$(document).on('click','.certificatedownload', function(e){
			var downloadParams = [];
			var size = $('#size').val();
			var pdfType = '';

			$('.eachcertPDF').each(function(i) {
				var selectedCert = $(this).text();
				var dynamicParm = selectedCert.split('|');
				//var urlParams = dynamicParm[2]+'&selectedCerts='+dynamicParm[0]+'&size='+size;
				var certID = dynamicParm[17];
				var templateTypeJSON = JSON.parse(SitePreferences.pdfTemplateType);

				if(size != undefined && certID != undefined && certID != null) {
					if(certID.length > 6 &&  size != null) {
						
						//TSED-1365 - Find My Cert fast follow fix for displaying instructors on PDF downloads
						if(size == "1185" && dynamicParm[16].length != 0 && dynamicParm[16] != null && dynamicParm[16] != ''){
							pdfType = templateTypeJSON.pdftemplateNewInstructor;
						} else{
							pdfType = size == '1185'? templateTypeJSON.pdftemplateNew : templateTypeJSON.walletpdftemplateNew;
						}
						
					} else {
						pdfType = size == '1185'? templateTypeJSON.pdftemplate : templateTypeJSON.walletpdftemplate;
					}
				}

				var eachCerts = {
					"pdfType": pdfType,
					"qrURL": dynamicParm[2],
					"organizationName": dynamicParm[3],
					"offerId": dynamicParm[4],
					"courseName": dynamicParm[5],
					"courseNumber": dynamicParm[6],
					"transactionNumber": dynamicParm[7],
					"deliveryType": dynamicParm[8],
					"status": dynamicParm[9],
					"achievementType": dynamicParm[10],
					"data": {
						"courseName": dynamicParm[5],
						"name": dynamicParm[11],
						"conductedBy": dynamicParm[12],
						"completionDate": dynamicParm[13],
						"expirationDate": dynamicParm[14],
						"validPeriod": dynamicParm[15],
						"instructorName": dynamicParm[16],
						"qrCodeLink": dynamicParm[2],
						"id": dynamicParm[17]
					}
				}
				downloadParams.push(eachCerts);
			});
			
			var url = util.appendParamsToUrl(Urls.downloadMulesoftPDF);
			certificateDownloadResp(url,downloadParams);
		});
    		 $(document).on('click', '.allcerts', function() {
    			 var selectedCnt = 0;
    				if ($(this).is(':checked')) {
    					$('.eachcert').each(function(i) {
    						$('.eachcert').prop('checked', true);
    					});
    				} else {
    					$('.eachcert').each(function(i) {
    						$('.eachcert').prop('checked', false);
    					});
    				}
    				
    				$(".atsizesection").empty();
    				$(".atsizesection").append("selected "+selectedCnt);
    				$(".secectedcertficates").empty();
    				if(selectedCnt == 0 ){
    					$(".atsizesection").append("Select a certificate.");
    					$(".atboottm").append("None selected.");
    				}else{
    					$(".secectedcertficates").append("selected "+selectedCnt);
    				}
    				
    			});
    		         
    		    $certificateLayout.on('click','.certificatesubmit', function (e) {
    		    	$('#emailform').valid();
    		    	$('#namesfrom').valid();
    		    	$('#certificatefrom').valid();
          
      	    	e.preventDefault();
    		       var email = $("#dwfrm_certificate_email").val();
    				var certnumber = $("#dwfrm_certificate_certnumber").val();
    				var firstname = $("#dwfrm_certificate_firstname").val();
    				var lastname = $("#dwfrm_certificate_lastname").val();
    				var year = $('.year').children("option:selected").val() == undefined ? '2017': $('.year').children("option:selected").val(); 
    				var month = $(".month.valid").val() == undefined ?'0': $(".month.valid").val() ;
    				var searchBy = $('#searchby').val();
    		       var certificateUrl = "";
    		       var params ;
    		       if( email != null && email != "" && searchBy == 'email' ){
    		    	   if(!$("#emailform").valid())return false;
    		    	   params = {
    		    		   email : email,
    		    		   format : 'ajax' 
    		    	   };
    		       } else if( certnumber != null && certnumber != "" && searchBy == 'certificatenumber' ){
    		         	   params = {
    		         		   certnumber : certnumber,
    		        		   format : 'ajax' 
    		        	   };  
    		       }  else  if (firstname != null && firstname != "" || lastname != null && lastname != "" || month != null && month != "" && month != 0 &&  searchBy == 'nameanddate'  ) {
    		    	   	params = {
    		     		   firstname :  firstname != null &&  firstname != ""? firstname : null,
    		    		   lastname :  lastname != null &&  lastname != ""? lastname : null,
    		    		   year :  year,
    		    		   month :  month != null &&  month != 0? month : null,
    		    		   format : 'ajax'  
    		          };
    		       } else {
    		    	   return false;
    		       }
    		            certificateUrl = util.appendParamsToUrl(Urls.showCertificates,params);
     		       
    		      e.preventDefault();
    		      $('.certificatesearchdata').load(certificateUrl , function () {
    		    	if($('.searchmgs').length > 0){
 	    		    	 $('.certificate-img-static').hide();
 	    		    }
 	    		    else{
 	    		    	 $('.certificate-img-static').show();
 	    		    }
    		    	if($('.input-checkbox.expeditshipping').hasClass('disable-load')){
    		    	     $('.input-checkbox.expeditshipping').prop("disabled", true);
    		    	}
    		    	else{
    		    	     $('.input-checkbox.expeditshipping').prop("disabled", false);
    		    	}
    		      });
    		      
    		      
    		    }).on('click','.expeditshipping', function(e) {
    		      if(!$('.addtocartsection').hasClass('active')) {
                  	$('.addtocartsection').addClass('active');
                  	$('.expitedprice').removeClass('hide');
                  	$('.expited-value').addClass('hide');
                  }
                  else {
                  	$('.addtocartsection').removeClass('active');
                  	$('.expitedprice').addClass('hide');
                  	$('.expited-value').removeClass('hide');
                  }
    		       }).on('click','.certificate-heading-list .details-drop-section', function(e) {
		          		e.preventDefault();
 		          		var $this = $(this);
		          		var $parentLi = $(e.target).closest('.certificate-heading-list ');
		          		$parentLi.find('.certificate-heading-list ').removeClass('active');
		          		$parentLi.toggleClass('active');   
		          	});
    		     
    		    
    		    $(document).on('click',"#allcertificateid", function(){
    		    	var selectedCnt = 0;// parseInt($("#noofselects").val());
    		    	if ($(this).is(':checked')) {
		    			$('.eachcert').each(function(i) {
		    				selectedCnt++;
		    			});
	    				$('.eachcert').prop('checked', true);
	    				var $main = $('.main');
	    				$main.find('.certificatesearchdata .form-row,.certificatesearchdata button,.checked-ship .input-checkbox').removeClass('disable-load');
	    				$main.find('.certificatesearchdata .size').removeClass('disable-load');
	    				$main.find('.certificatesearchdata .size .wallet .input-radio, .certificatesearchdata .size .cross-size .input-radio').attr('disabled',false);
	    				$main.find('.certificatesearchdata .expeditshipping').removeClass('disable-load');
	    				$('.input-checkbox.expeditshipping').prop("disabled", false);
    		    			
    		    	} else {
	    				$('.eachcert').prop('checked', false);
	    				var $main = $('.main');
	    				$main.find('.certificatesearchdata .form-row,.certificatesearchdata button,.checked-ship .input-checkbox').addClass('disable-load');
	    				$main.find('.certificatesearchdata .size' ).addClass('disable-load');
	    				$main.find('.certificatesearchdata .checked-ship .expeditshipping').prop('checked', false);
	    				$main.find('.certificatesearchdata .size .wallet .input-radio, .certificatesearchdata .size .cross-size .input-radio').attr('disabled',true);
	    				$('.addtocartsection').removeClass('active');
	                  	$('.expitedprice').addClass('hide');
	                  	$('.expited-value').removeClass('hide');
	                  	$('.wallet-message').addClass('hide');
	                  	$('.input-checkbox.expeditshipping').prop("disabled", true);
    		    	}
    		    	$(".atsizesection").empty();
    		    	$(".atsizesection").append(selectedCnt+" Selected ");
    				$(".secectedcertficates").empty();
    				if( selectedCnt == 0){
    					$(".atsizesection").append("Select a certificate.");
    					$(".atboottm").append("None selected.");
    				} else{
    					$(".secectedcertficates").append(selectedCnt+" Selected ");
    				}
    				
    				if( selectedCnt >= 0){
    					$("#noofselects").val(selectedCnt);	
    				}else{
    					selectedCnt = 0;
    					$("#noofselects").val(selectedCnt);	
    				}
    		    	});
    		    	$(document).on('click',".eachcert", function(){
    		    		var noofselects = parseInt($("#noofselects").val());
    		    		
    		    		if ($(this).is(':checked')) {
    		    			noofselects = noofselects + 1;
    		    				$(this).prop('checked', true);
    		    		} else {
     		    			noofselects = noofselects - 1;
    		    				$(this).prop('checked', false);
    		    		}
    		    		$("#noofselects").val(noofselects);	
    		    		
    		    		$(".atsizesection").empty();
    		    		$(".atsizesection").append(noofselects +" selected ");
        				$(".secectedcertficates").empty();
        				if(noofselects == 0){
        				$(".atsizesection").append("Select a certificate.");
    					$(".atboottm").append("None selected.");
        				}else{
        					$(".secectedcertficates").append(noofselects+" selected ");	
        				}
    		    		var $main = $('#main');
    		    		if($(".eachcert:checked").length > 0){
    		    			$main.find('.certificatesearchdata .form-row,.certificatesearchdata button,.checked-ship .input-checkbox').removeClass('disable-load');
    		    			$main.find('.certificatesearchdata .size').removeClass('disable-load');
    		    			$main.find('.certificatesearchdata .size .wallet .input-radio, .certificatesearchdata .size .cross-size .input-radio').attr('disabled',false);
    		    			$('.input-checkbox.expeditshipping').prop("disabled", false);
    		    		}
    		    		else{
    		    			$main.find('.certificatesearchdata .form-row,.certificatesearchdata button,.checked-ship .input-checkbox').addClass('disable-load');
    		    			$main.find('.certificatesearchdata .size').addClass('disable-load');
    		    			$main.find('.certificatesearchdata #allcertificateid, .certificatesearchdata .checked-ship .expeditshipping').prop('checked', false);
    		    			$main.find('.certificatesearchdata .size .wallet .input-radio, .certificatesearchdata .size .cross-size .input-radio').attr('disabled',true);
    		    			$('.addtocartsection').removeClass('active');
    	                  	$('.expitedprice').addClass('hide');
    	                  	$('.expited-value').removeClass('hide');
    		    			$('.wallet-message').addClass('hide');
    		    			$('.input-checkbox.expeditshipping').prop("disabled", true);
    		    		}
    		    	});
    		    	/*$(document).on('click',".certificatesearchdata" , function(){
    		    		var $main = $('.main');
    		    		var $walletRadio = $(".certificatesearchdata .size .wallet .input-radio");
    		    		
    		    		if( ($walletRadio.is(':checked')) && ($('#allcertificateid').is(':checked')) || ($walletRadio.is(':checked')) && ($('.eachcert').is(':checked')) ){
    		    			$main.find('.certificatesearchdata .price-selectd .add-to-cart').removeClass('disable-load');
    		    			$main.find('.certificatesearchdata .expeditshipping').removeClass('disable-load');
    		    		}	
    		    		else {
    		    			//$main.find('.certificatesearchdata .price-selectd .add-to-cart').addClass('disable-load');
    		    			$main.find('.certificatesearchdata .expeditshipping').addClass('disable-load');
    		    		}
    		    	});*/
    		    	$(document).on('click',".wallet-value .size .radio-label" , function(){
    		    		var $main = $('.main');
    		    		var $walletRadio = $(".certificatesearchdata .size .wallet .input-radio");
    		    		
    		    		if( ($walletRadio.is(':checked')) && ($('#allcertificateid').is(':checked')) || ($walletRadio.is(':checked')) && ($('.eachcert').is(':checked')) ){
    	                	$('.wallet-message').addClass('hide');
    		    		}	
    		    		else {
    	                	$('.wallet-message').removeClass('hide');

    		    		}
    		    	});
    		    	$(document).on('click','.viewandprint', function(e){
    		      				var email = $("#dwfrm_certificate_email").val();
                                var today = new Date();
								var curryear = today.getFullYear();
								var certnumber = $("#dwfrm_certificate_certnumber").val(); 
    		    				var firstname = $("#dwfrm_certificate_firstname").val();
    		    				var lastname = $("#dwfrm_certificate_lastname").val();
    		    				var year = $(".year.valid").val() == undefined ? curryear : $(".year.valid").val(); 
    		    				var month = $(".month.valid").val() == undefined ?'0': $(".month.valid").val() ;
    		    				var size = $('input[name=size]:checked').val();
    		    				var searchBy = $('#searchby').val();
    		    				var certificateUrl = "";
    		    				var params;
    		    				
    		    			 
    		    				
    		    				
    		           var selectedCerts = '';
    		     	 	   $('.eachcert').each(function(i) {
    		    				if ($(this).is(':checked')) {
    		    					var selectedCert = $(this).siblings(".certpdfurl").val();
    		    					var dynamicParm = selectedCert.split("|");
    		     					selectedCerts = selectedCerts + dynamicParm[0]+',';
    		     				}
    		     		  }); 
    		    	 	   
    		    	 	  if (email != null && email != "" && searchBy == 'email' ){
    		    				params = {
    		    					email : email,
    		    					selectedCerts : selectedCerts,
    		    					size : size
    		    				};
    		    			} else if (certnumber != null && certnumber != "" && searchBy == 'certificatenumber' ) {
    		    				params = {
    		    					certnumber : certnumber,
    		    					selectedCerts : selectedCerts,
    		    					size : size
    		    				}
    		    			} else  if (firstname != null && firstname != "" || lastname != null && lastname != "" &&  searchBy == 'nameanddate'  ) {
    		    				params = {
    		    					firstname : firstname != null && firstname != null ? firstname : null,
    		    					lastname : lastname != null && lastname != null ? lastname : null,
    		    					year : year,
    		    					month : month,
    		    					selectedCerts : selectedCerts,
    		    					size : size
    		    				}
    		    			} else {
    		    				return false;
    		    			}
    		    	 	 e.preventDefault();
    		    	 	  var URL = util.appendParamsToUrl(Urls.viewanddownloadUrl,params);
    		    		  window.open(URL,'','');
    		    		  return false;
    		    	});
    		    	   
			    	$(document).on('click', '#download', function() {
	    		    	var downloadParams = [];
	    		    	var size = $('input[name=size]:checked').val();
	    		    	var pdfType = "";
	    		    	
	    		    	$('.eachcert').each(function(i) {
	    		    		if ($(this).is(':checked')) {
	    		    			var selectedCert = $(this).siblings(".certpdfdownload").val();
	    		    			var dynamicParm = selectedCert.split("|");
								//var urlParams = dynamicParm[2]+'&selectedCerts='+dynamicParm[0]+'&size='+size;
								
								var certID = dynamicParm[17];
								var templateTypeJSON = JSON.parse(SitePreferences.pdfTemplateType);
								
								if(size != undefined && certID != undefined && certID != null) {
									if(certID.length > 6 &&  size != null) {
										//TSED-1365 - Find My Cert fast follow fix for displaying instructors on PDF downloads
										if(size == "1185" && dynamicParm[16].length != 0 && dynamicParm[16] != null && dynamicParm[16] != ''){
											pdfType = templateTypeJSON.pdftemplateNewInstructor;
										} else{
											pdfType = size == '1185'? templateTypeJSON.pdftemplateNew : templateTypeJSON.walletpdftemplateNew;
										}
									} else {
										pdfType = size == '1185'? templateTypeJSON.pdftemplate : templateTypeJSON.walletpdftemplate;
									}
			    		    	}
								
	    		    			var eachCerts = {
									"pdfType": pdfType,
									"qrURL": dynamicParm[2],
									"organizationName": dynamicParm[3],
									"offerId": dynamicParm[4],
									"courseName": dynamicParm[5],
									"courseNumber": dynamicParm[6],
									"transactionNumber": dynamicParm[7],
									"deliveryType": dynamicParm[8],
									"status": dynamicParm[9],
									"achievementType": dynamicParm[10],
									"data": {
										"courseName": dynamicParm[5],
										"name": dynamicParm[11],
										"conductedBy": dynamicParm[12],
										"completionDate": dynamicParm[13],
										"expirationDate": dynamicParm[14],
										"validPeriod": dynamicParm[15],
										"instructorName": dynamicParm[16],
										"qrCodeLink": dynamicParm[2],
										"id": dynamicParm[17]
									}
								}
	    		    			downloadParams.push(eachCerts);
	    		    		}
	    		    	});
	    		    	
	    		    	var url = util.appendParamsToUrl(Urls.downloadMulesoftPDF);
						certificateDownloadResp(url,downloadParams);
	    		    });

    		     $(document).on("click", "#add-to-cartcertificate", function(e){
    		    	var pcertLineItemSelect = "";
    		    	 var downloadParams = [];
    		    		$('.eachcert').each(function(i) {
    		    			if ($(this).is(':checked')) {
    		    				var selectedCert = $(this).siblings(".certpdfurl").val();
    		    				var dynamicParm = selectedCert.split("|");
    		    				var eachCerts = {
    		    		                "instructorlist": dynamicParm[6],
    		    		                "coursename": dynamicParm[4],
    		    		                "firstname": dynamicParm[1],
    		    		                "lastname": dynamicParm[2],
    		    		                "date": dynamicParm[3],
    		    		                "courselist": dynamicParm[5],
    		    		                "certnumber": dynamicParm[0],
		    			                "coursenum": dynamicParm[7],
		    			                "coursevalidity" : dynamicParm[8]
    		    	 					}
    		    				
    		    				pcertLineItemSelect = pcertLineItemSelect+dynamicParm[0]+",";
    		    				downloadParams.push(eachCerts);
    		     			}
    		    		});
    		    		
    		    		if(!SitePreferences.isEnablewalletPurchase){
    		    			return false;
    		    		}
    		    	 
    		    		 var expectedshipping = $("#expectedShippingCheckbox").is(':checked');
    		    		
    		    		$("#selectedcerts").val(pcertLineItemSelect);
    		    		
    		     	 e.preventDefault();
    		    	    var $form = $("#certificateformid"),
    		             $qty = $form.find('input[name="Quantity"]');
    		    	    $form.find('input[name="expectedshipping"]').val(expectedshipping);
    		         if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
    		            $qty.val('1'); 
    		        }
    		         
    		         if(SitePreferences.isATMEnabled) {
    		        	 atmevents.addToCartCertifications($form);
     		    	 }
    		         if(SitePreferences.isGTMEnabled) {
    		        	 gtmevents.addToCartCertifications($form);
    		         }

    		         Promise.resolve($.ajax({
    		            type: 'POST',
    		            async: false, // JIRA PREV-305:SG issue: No of items
										// in cart value not displayed as
										// expected.Added async:false.
    		            url: util.ajaxUrl(Urls.addProduct),
    		            data: $form.serialize()
    		        })).then(function (response) {
    		            // handle error in the response
    		            if (response.error) {
    		                throw new Error(response.error);
    		            } else {
    		                return response;
    		            }
    		        }).then(function (response) {
    		    	        var $uuid = $form.find('input[name="uuid"]');
    		    	        if ($uuid.length > 0 && $uuid.val().length > 0) {
    		    	            page.refresh();
    		    	        } else {
    		    	            if (!$(this).hasClass('sub-product-item')) {
    		    	                dialog.close();
    		    	            }
    		    	            minicart.show(response);
    		    	        }
    		    	    }.bind(this));
    		          window.location.href = Urls.classCheckOut;
    		      });
    		     
    		     $('.editcertificatesubmit').on('click', function (e) {   
    	  		       var email = $("#dwfrm_certificate_email").val();
    	  				var certnumber = $("#editsearchcertid").val();
    	  				$('#dwfrm_certificate_certnumber').val(certnumber);
    	  				var firstname = $("#dwfrm_certificate_firstname").val();
    	  				var lastname = $("#dwfrm_certificate_lastname").val();
    	  				var year = $(".year.valid").val(); 
    	  				var month = $(".month.valid").val(); 
    	  				var searchBy = $('#searchby').val();
    	  		       var certificateUrl = "";
    	  		       var params ;
    	  		       if( email != null && email != "" && searchBy == 'email' ){
    	  		    	   params = {
    	  		    		   email : email,
    	  		    		   format : 'ajax' 
    	  		    	   };
    	  		       } else if( certnumber != null && certnumber != "" && searchBy == 'certificatenumber' ){
    	  		         	   params = {
    	  		         		   certnumber : certnumber,
    	  		        		   format : 'ajax' 
    	  		        	   };  
    	  		       }  else  if (firstname != null && firstname != "" || lastname != null && lastname != ""  &&  searchBy == 'nameanddate' ) {
    	  		    	   	params = {
    	  		     		   firstname :  firstname != null &&  firstname != ""? firstname : null,
    	  		    		   lastname :  lastname != null &&  lastname != ""? lastname : null,
    	  		    		   year :  year,
    	  		    		   month :  month != "0" ? month : '',
    	  		    		   format : 'ajax'  
    	  		          };
    	  		       } else {
    	  		    	   return false;
    	  		       }
    	  		            certificateUrl = util.appendParamsToUrl(Urls.EditCertificate,params);
    	  		      e.preventDefault();
    	  		      $('.certificatesearchdata').load(certificateUrl);
    	  		              	var $main = $('.main');
    	  		          		$main.on('click','.certificate-heading-list .details-drop-section', function(e) {
    	  		          		e.preventDefault();
    	  		          		var $this = $(this);
    	  		          		var $parentLi = $(e.target).closest('.certificate-heading-list ');
    	  		          		$parentLi.siblings('.certificate-heading-list ').removeClass('active');
    	  		          		$parentLi.toggleClass('active');  
    	  		          	});
    	  		       });
    		     
    		     $(document).on("click", "#edit-to-cartcertificate", function(e){
     		    	var pcertLineItemSelect = "";
     		    	 var downloadParams = [];
     		    		var uquantity = $('.uquantity').val();
     		    		var uupdatedCert = $('.uupdatedCert').val();
     		    		var uuuid = $('.uuuid').val();
     		     	   var expectedshipping = $("#expectedShippingCheckbox").is(':checked');
     		    	    var $form = $("#certificateformid");
     		    	    $form.find('input[name="Quantity"]').val(uquantity);
     		    		$form.find('input[name="updatedCert"]').val(uupdatedCert);
     		    		$form.find('input[name="uuid"]').val(uuuid);
     		    		$form.find('input[name="expectedshipping"]').val(expectedshipping);
     		           var  $qty = $form.find('input[name="Quantity"]');
     		         if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
     		            $qty.val('1'); 
     		        }
     		         e.preventDefault();
     		         Promise.resolve($.ajax({
     		            type: 'POST',
     		            async: false, //JIRA PREV-305:SG issue: No of items in cart value not displayed as expected.Added async:false.
     		            url: util.ajaxUrl(Urls.UpdateCertificateLineItem),
     		            data: $form.serialize()
     		        })).then(function (response) {
     		            // handle error in the response
     		        	 window.location.href =  Urls.classCheckOut;
     		            if (response.error) {
     		                throw new Error(response.error);
     		            } else {
     		                return response;
     		            }
     		        }).then(function (response) {
     		    	        var $uuid = $form.find('input[name="uuid"]');
     		    	        if ($uuid.length > 0 && $uuid.val().length > 0) {
     		    	            page.refresh();
     		    	        } else {
     		    	            // do not close quickview if adding individual item that is part of product set
     		    	            // @TODO should notify the user some other way that the add action has completed successfully
     		    	            if (!$(this).hasClass('sub-product-item')) {
     		    	                dialog.close();
     		    	            }
     		    	            minicart.show(response);
     		    	        }
     		    	    }.bind(this));
     		         
     		      } );	     
    		     
    		     
    		     
    		     
    		  // certificate tab click event page code goes here
    		     $('.certificate-layout .edit-address-label span').off('click').on('click', function() {
    		 		$('.section-email').addClass('active').siblings().removeClass('active');
    		 		$(this).parent('li').addClass('active').siblings().removeClass('active');
    		 		if($('.certificatesearch .form-row.certnumber .input-text,.datesearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').hasClass('error')){
    		 			$('.certificatesearch .form-row.certnumber .input-text,.datesearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').removeClass('error');
    		 			$('.certificatesearch .form-row.certnumber .input-text,.datesearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').next('span').hide();
    		 		}
    		 		$('#searchby').val('email');
    		 		//$("#dwfrm_certificate_email").val("");
    		 		util.lableFocus();
    		     });
    		     $('.certificate-layout .data-time-label span').off('click').on('click', function() {
    		 		$('.section-date').addClass('active').siblings().removeClass('active');
    		 		$(this).parent('li').addClass('active').siblings().removeClass('active');
    		 		if($('.certificatesearch .form-row.certnumber .input-text,.emailsearch .form-row.email .input-text').hasClass('error')){
    		 			$('.certificatesearch .form-row.certnumber .input-text,.emailsearch .form-row.email .input-text').removeClass('error');
    		 			$('.certificatesearch .form-row.certnumber .input-text,.emailsearch .form-row.email .input-text').next('span').hide();
    		 		}
    		 		//$("#dwfrm_certificate_email").val("");
    		 		$('#searchby').val('nameanddate');
    		 		util.lableFocus();
    		     });
    		     $('.certificate-layout .certificate-label span').off('click').on('click', function() {
    		 		$('.certificate-search').addClass('active').siblings().removeClass('active');
    		 		$(this).parent('li').addClass('active').siblings().removeClass('active');
    		 		if($('.datesearch .form-row.firstname .input-text,.emailsearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').hasClass('error')){
    		 			$('.datesearch .form-row.firstname .input-text,.emailsearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').removeClass('error');
    		 			$('.datesearch .form-row.firstname .input-text,.emailsearch .form-row.firstname .input-text,.datesearch .form-row.lastname .input-text').next('span').hide();
    		 		}
    		 		//$("#dwfrm_certificate_email").val("");
    		 		$('#searchby').val('certificatenumber');
    		 		util.lableFocus();
    		     });
    		     
    		     //initialize app
    		     $(document).ready(function () { 
    		    	var cartSearchBy = $('#cartSearch').val() == undefined ? '' : $('#cartSearch').val(); 
    		    	if(cartSearchBy != '' ){
    		    		$( ".certificatesubmit" ).trigger( "click" );
    		    	}
    		     
    		         var certificate =  $("#certificate").val();
    		     		if( certificate != null && certificate != ""){
    		     	    	  $('.certificate-layout .certificate-label span').off('click').on('click', function() {
    		     	    			$('.certificate-search').addClass('active').siblings().removeClass('active');
    		     	    			 $('.certificate-layout .certificate-label span').parent('li').addClass('active').siblings().removeClass('active');
    		     	    			$('#emailform')[0].reset();
    		     	    			$('#namesfrom')[0].reset();
    		     	    			$('#certificatefrom')[0].reset();
    		     	    	    });
    		     	    	  
    		     	    	   $( ".editcertificatesubmit" ).trigger( "click" );
    		     	    }  
    		     		
    		      });
    		
    		// Mulesoft common ajax to download the PDF
			function certificateDownloadResp(url,downloadParams){
				$.ajax({
					type: "POST",
					url: url,
					data: JSON.stringify(downloadParams),
					contentType: 'application/json',
					dataType : 'json'
				}).done(function(data){
					var pdf = "data:application/pdf;base64, " + escape(data.pdfFile);
					if(navigator.msSaveBlob) { // IE 10+
						var byteCharacters = atob(data.pdf_base64);
						var byteNumbers = new Array(byteCharacters.length);
						for (var i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
						}
						var byteArray = new Uint8Array(byteNumbers);
						var blob = new Blob([byteArray], {
						type: 'application/pdf'
						});
						navigator.msSaveOrOpenBlob(blob, 'certificate.pdf');
					} else { // for other browsers
						var dlnk = document.getElementById('downloadLink');
						dlnk.href = pdf;
						dlnk.click();
					}
				});
			}
    }
};

module.exports = certificates;
