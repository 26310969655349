'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    addressvalidation = require('../../addressvalidation'),
    validator = require('../../validator'),
    page = require('../../page'),
    util = require('../../util'),
    atmevents = require('../../atmevents'),
    gtmevents = require('../../gtm'),
    dialog = require('../../dialog');

var shippingMethods, autocompleteBilling, autocompleteShipping;
var google_api_inactive = SitePreferences.GOOGLE_API_DISABLED;
var $main = $('#main');

if ($main.find('.error_status').length > 0) {
    //alert($main.find('.error_status').val());
    if ($main.find('.error_status').val() == '1002') {
        $(document).on('keypress', function(e) {
            var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
            if (key == 13) {
                if (!$('.learner_id_missed').is(":visible")) {
                    e.preventDefault();
                    return false;
                } else if ($('.learner-pass').hasClass('focus')) {
                    $('.ui-dialog-buttonset button:first-child').trigger('click');
                    return false;
                }
            }
        });
        var counter = 0;
        $('.learner_id_missed').dialog({
            autoOpen: false,
            modal: true,
            width: '600',
            height: 'auto',
            dialogClass: 'learnertrigger',
            buttons: {
                Submit: function() {
                    counter++;
                    var password = $('.learner_password').val();
                    var stddata = $('.learner_std_data').val();
                    var url = util.appendParamsToUrl(Urls.sabaCreateUpdateUser, {
                        password: password,
                        stddata: stddata,
                        format: 'ajax'
                    });

                    $.ajax({
                        url: url,
                        dataType: 'html',
                        success: function(data) {
                            //alert(data);
                            if (data != 100) {
                                if (data.trim() == 1001) {
                                    $('.learner_id_missed .learner_error').addClass('hide');
                                    $('.learner_id_missed .learner_error.pwd-error').removeClass('hide');
                                } else {
                                    $('.learner_id_missed .learner_error').removeClass('hide');
                                    $('.learner_id_missed .learner_error.pwd-error').addClass('hide');
                                }
                            } else if (data == 100) {
                                $('.learner_id_missed').dialog('close');
                                $('.cvn .image-cvn').removeClass('learner-visible');
                                $('.form-row.cvn').removeClass('tooltip-filed');
                            }
                        }
                    });
                },
                Cancel: function() {
                    $(this).dialog('close');
                    $('.cvn .image-cvn').removeClass('learner-visible');
                    $('.form-row.cvn').removeClass('tooltip-filed');
                }
            },
            open: function() {
                setTimeout(function() {
                    if ($('.learnertrigger').is(':visible')) {
                        $('.cvn .image-cvn').addClass('learner-visible');
                    } else {
                        $('.cvn .image-cvn').removeClass('learner-visible');
                        $('.form-row.cvn').removeClass('tooltip-filed');
                    }
                }, 10);
            }
        }).dialog('open');
    }
}

/**
 * @function
 * @description Address Type Radio Button Behavior on Checkout page load
 */
function pageLoadCompany() {
    if ($('#dwfrm_singleshipping_addressList').length > 0) {
        var selected = $('form.address').find('select[name$="_singleshipping_addressList"]').children(':selected').first();
        var selectedAddress = $(selected).data('address');
        var companyNameValue = $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val(); // Added For PHSSECOM-408
        if ((selectedAddress.companyName != null && $('#dwfrm_singleshipping_addressList').val() != '') || (companyNameValue != '' && companyNameValue != null)) {
            var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
            if ($radios.is(':checked') === true) {
                $radios.filter('[value=false]').prop('checked', true);
            }
            if (companyNameValue != '') {
                $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val(companyNameValue);
            } else {
                $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val(selectedAddress.companyName);
            }
            $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", false);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').removeClass('row-disabled');
        } else {
            var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
            if ($radios.is(':checked') === true) {
                $radios.filter('[value=true]').prop('checked', true);
            }
            $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
            $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
        }
    }
}

/**
 * @function
 * @description Address Type Radio Button Behavior
 */
function showCompanyName() {
    var $checkoutForm = $('form.address');
    // Company Name Field getting Activated For Business Radio Button
    if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val() === 'true') {
        $('#residentialbusiness').val('resident');
        $('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
        $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
        $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
        util.lableFocus();
        if ($('.billing-information .form-row.address-type .input-text').hasClass('error')) {
            $('.billing-information .form-row.address-type .input-text').removeClass('error');
            $('.billing-information .form-row.address-type .input-text').next('span').hide();
        }
        if ($('#dwfrm_singleshipping_shippingAddress_addressFields_address1').is(':Visible') || $('#dwfrm_singleshipping_addressList').length > 0) {
            var fname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
            var country = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val();
            var state = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            var city = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
            }
        } else {
            var fname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val();
            var state = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val();
            var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
            var city = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val();
            }
        }
        if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
            updateShippingMethodList();
        }
    } else {
        $('#residentialbusiness').val('business');
        $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", false);
        $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').removeClass('row-disabled');
        util.lableFocus();
        if ($('#dwfrm_singleshipping_shippingAddress_addressFields_address1').is(':Visible') || $('#dwfrm_singleshipping_addressList').length > 0) {
            var fname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
            var country = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val();
            var state = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            var city = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
            }
        } else {
            var fname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val();
            var state = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val();
            var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
            var city = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val();
            }
        }
        var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
        if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
            updateShippingMethodList();
        }
    }
}

/**
 * @function
 * @description handling Undefined values in checkout page when cart contains class product and physical product.
 */
function companyNameWithUndefinedValue() {
	if ($('input[name$="_singleshipping_shippingAddress_addressType"]').val() === 'true') {
		var addressTypeValue = $('#dwfrm_singleshipping_shippingAddress_companyname').val();
		if(addressTypeValue == 'undefined' && addressTypeValue != '' ){
			$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').trigger('click');
			$('#residentialbusiness').val('resident');
			$('input[name$="_singleshipping_shippingAddress_addressType"]:first').prop('checked', true).trigger('click');
			$('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
	        $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
	        $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
	        util.lableFocus();
    	}
	}
}

/**
 * @function
 * @description Phone Type Radio Button Behavior
 */
function billingPhoneType() {
    // Billing Phone Type Behavior
    if ($("input[name$='_singleshipping_shippingAddress_phoneNo']:checked").val() != undefined && $('input[name$="_singleshipping_shippingAddress_phoneNo"]:checked').val() !== 'true') {
        $('.phone-message-text').hide();
        $('.phone-message-text').closest('.billing-section').addClass('ladline-textbox-radio');
    } else {
        $('.phone-message-text').show();
        $('.phone-message-text').closest('.billing-section').removeClass('ladline-textbox-radio');
    }
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary(skipcouponrefresh,skipTax,exemptionId, poBoxAddressExist) {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);
    var taxExemptionID = '';
    if(exemptionId != null && $('.tax-exemption-error.error').length == 0) {
    	taxExemptionID = $(".form-row.tax-exemption").find(".input-text").val();
    }
    var url = util.appendParamsToUrl(getShippingMethodURL(Urls.summaryRefreshURL), {
		skipTax: skipTax,
		exemptionId: exemptionId != null ? exemptionId : taxExemptionID,
		poBoxAddressExist: poBoxAddressExist == true ? poBoxAddressExist : false
    });

    $.ajax({
        url: url,
        dataType: 'HTML',
        success: function(res) {
            var $ordercont = $(res).find('.checkout-ordersummary-wrap').length == 0 ? $(res).filter('.checkout-ordersummary-wrap') : $(res).find('.checkout-ordersummary-wrap');
            $summary.find('.checkout-ordersummary-wrap').html($ordercont.html());
            
            if(exemptionId == undefined && exemptionId == null) {
            	exemptionId = $(".form-row.tax-exemption").find(".input-text").val();
    		} else {
    			$('input[name$="validID"]').val(exemptionId);
    		}
            if($('.pt_checkout').find('input[name$="isInValidTaxExemptionID"]').length > 0 && $('.pt_checkout').find('input[name$="isInValidTaxExemptionID"]').val() && $('.pt_checkout').find('input[name$="taxExemptionID"]').length > 0) {
        		var exemptionID = $('.pt_checkout').find('input[name$="taxExemptionID"]').val();
        		$('body').find('.tax-exemption-form').show();
        		$('#main').find('.tax-exemption-error.error').removeClass('hide');
        		$(".form-row.tax-exemption").find(".input-text").val(exemptionID);
                //TSED-1142: Aria-label for Checkout Page Tax Exempt ID
                util.ariaLabel('.tax-exemption-field');
        		$('input[name$="validID"]').val('');
        	} else {
        		if($('.taxExemptionValidValue').val() == "true" && $('body').find('.tax-exemption-form').is(':visible')){
            		$('body').find('.tax-exemption-form').hide();
            		$main.find('.tax-exemption-error.error').addClass('hide');
            	}
        	}
            
            //TSED-386: Display error message for when an invalid tax-exempt ID is entered
            if($('.taxExemptionValidValue').val() == 'false' && exemptionId != null && exemptionId != '') {
        		$('body').find('.tax-exemption-form').show();
        		$main.find('.tax-exemption-error.error').removeClass('hide');
        		$(".form-row.tax-exemption").find(".input-text").val(exemptionId);
                 //TSED-1142: Aria-label for Checkout Page Tax Exempt ID
                 util.ariaLabel('.tax-exemption-field');
        		$('input[name$="validID"]').val('');
        	} else {
        		if($('.taxExemptionValidValue').val() == 'true' && $('body').find('.tax-exemption-form').is(':visible')){
            		$('body').find('.tax-exemption-form').hide();
            		$main.find('.tax-exemption-error.error').addClass('hide');
            	}
        	}
            
            $summary.fadeIn('fast');
            $summary.find('.checkout-mini-cart .minishipment .header a').hide();
            $summary.find('.order-totals-table .order-shipping .label a').hide();
            progress.hide();
            util.toolgeSummary();
            util.checkoutScrollbar();
            //TSED-298
            if ($('.billing-coupon-code').length > 0 && $('.billing-coupon-code .error-messages > div').length <= 1 && !skipcouponrefresh) {
                updateCouponSection();
            }
            updatePaymentSection();
            checkPOAmount();
            
            //TSED-7: Shipping tooltip in order summary
            $('.pt_checkout .estimated-ship-tooltip').on('mouseover',function(){
            	$('.contentestimated').show();
            });
            $('.pt_checkout .estimated-ship-tooltip').on('mouseout',function(){
            	$('.contentestimated').hide();
            });
            /*$('.pt_checkout .contentestimated .fa.fa-close').on('click',function(){
            	$('.contentestimated').hide();
            });*/
            //TSED-321: Add Sales Tax & Tax Exemption Disclaimer to Checkout
            $('.pt_checkout .estimated-tax-tooltip').on('mouseover',function(){
            	$('.order-sales-tax .contentestimatedtax').show();
            });
            $('.pt_checkout .estimated-tax-tooltip').on('mouseout',function(){
            	$('.order-sales-tax .contentestimatedtax').hide();
            });
        }
    })
}

//Added for PHSSECOM-458
util.expiredClassAppend();

/**
 * @function
 * @description updates the coupon section after removal of any coupon.
 */
function updateCouponSection() {
    $.ajax({
        url: Urls.couponRefreshURL,
        dataType: 'HTML',
        success: function(data) {
            $('.redemption.rowcoupons.coupon').html(data);
        }
    })
}

function updateCountryDropDown() {
    var $shippingMethodList = $('#shipping-method-list');
    if ($shippingMethodList && $shippingMethodList.length > 0) {
        var $form = $('.address');
        var state = $form.find('select[id$="_billingAddress_addressFields_states_state"]').val();
        if (state == '' && state == null && state == undefined) {
            state = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
        }
        if (state === 'OT' || state === 'AE' || state === 'AP' || state === 'AA') {
            $(".billing-section .country-row").addClass('hide');
            $(".billing-section .country-row-other").removeClass('hide');
            $(".billing-section .postal-row").addClass('hide');
            $(".billing-section .postal-row-other").removeClass('hide');
        } else {
            $(".billing-section .country-row").removeClass('hide');
            $(".billing-section .country-row-other").addClass('hide');
            $(".billing-section .postal-row").removeClass('hide');
            $(".billing-section .postal-row-other").addClass('hide');
        }
    }
}


function updateStateDropDown() {
    var $shippingMethodList = $('#shipping-method-list');
    if ($shippingMethodList && $shippingMethodList.length > 0) {
        var $form = $('.address');
        if ($('#dwfrm_singleshipping_shippingAddress_addressFields_address1').is(':Visible')) {
            country = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
            if (country != 'US') {
                $(".shipping-section .state-row").addClass('hide');
                $(".shipping-section .state-other-row").removeClass('hide');
                $(".shipping-section .postal-row").addClass('hide');
                $(".shipping-section .postal-row-other").removeClass('hide');
            } else {
                $(".shipping-section .state-row").removeClass('hide');
                $(".shipping-section .state-other-row").addClass('hide');
                $(".shipping-section .postal-row").removeClass('hide');
                $(".shipping-section .postal-row-other").addClass('hide');
            }
        } else {
            var country = $form.find('select[id$="_billing_billingAddress_addressFields_country"]').val();
            if (country != 'US') {
                $(".billing-section .state-row").addClass('hide');
                $(".billing-section .state-other-row").removeClass('hide');
                $(".billing-section .postal-row").addClass('hide');
                $(".billing-section .postal-row-other").removeClass('hide');
            } else {
                $(".billing-section .state-row").removeClass('hide');
                $(".billing-section .state-other-row").addClass('hide');
                $(".billing-section .postal-row").removeClass('hide');
                $(".billing-section .postal-row-other").addClass('hide');
            }
        }
    }
}


/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    
    if($('input[name$="_shippingAddress_useAsBillingAddress"]').length == 0){
    	var params = {
			firstName: $form.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val(),
			lastName: $form.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val(),
    		address1: $form.find('input[name$="_billing_billingAddress_addressFields_address1"]').val(),
            address2: $form.find('input[name$="_billing_billingAddress_addressFields_address2"]').val(),
            countryCode: $form.find('select[id$="_billing_billingAddress_addressFields_country"]').val(),
            stateCode: $form.find('select[id$="_billing_billingAddress_addressFields_states_state"]').val(),
            postalCode: $form.find('input[name$="_billing_billingAddress_addressFields_postal"]').val(),
            city: $form.find('input[name$="_billing_billingAddress_addressFields_city"]').val(),
            phone: $form.find('input[name*="_billing_billingAddress_phone"]').val(),
            sameAsBilling: 'true'
        };
    } else if ($('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0 && $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
        //TSED-296
        var country = $form.find('select[id$="_shippingAddress_addressFields_country"]').val();
        var state = $form.find('select[id$="_shippingAddress_addressFields_stateOther_state"]').val();
        var stateCode;
        var postalCode;
        var sameAsBilling = $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked');

        if (country != 'US') {
            updateStateDropDown();
            //TSED-296
            if (state != null && state != '' && $("select[id$='_shippingAddress_addressFields_stateOther_state'] option[value=" + state + "]").length > 0) {
                stateCode = $form.find('select[id$="_shippingAddress_addressFields_stateOther_state"]').val();
                $form.find('select[id$="_shippingAddress_addressFields_stateOther_state"]').val($form.find('select[id$="_shippingAddress_addressFields_stateOther_state"]').val());               
                postalCode = $form.find('input[name$="_shippingAddress_addressFields_otherPostal"]').val();
                $form.find('input[name$="_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_shippingAddress_addressFields_otherPostal"]').val());
            }
        } else {
            updateStateDropDown();
            // TSED-296
            $form.find('select[id$="_shippingAddress_addressFields_states_state"]').val($form.find('select[id$="_shippingAddress_addressFields_states_state"]').val());
            $form.find('input[name$="_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_shippingAddress_addressFields_postal"]').val());
            stateCode = $form.find('select[id$="_shippingAddress_addressFields_states_state"]').val();
            postalCode = $form.find('input[name$="_shippingAddress_addressFields_postal"]').val();
        }
        //TSED-296
       /* var params = {
        	firstName: $form.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val(),
    		lastName: $form.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val(),
        	address1: $form.find('input[name$="_billingAddress_addressFields_address1"]').val(),
            address2: $form.find('input[name$="_billingAddress_addressFields_address2"]').val(),
            countryCode: $form.find('select[id$="_billingAddress_addressFields_country"]').val(),
            stateCode: stateCode,
            postalCode: postalCode,
            city: $form.find('input[name$="_billingAddress_addressFields_city"]').val(),
            addressType: $('body').find('input[id="residentialbusiness"]').val(),
            phone: $form.find('input[name*="_billing_billingAddress_phone"]').val(),
            sameAsBilling: sameAsBilling
        };*/
        
        var params = {
        	firstName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val(),
    		lastName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val(),
    		email: $form.find('input[name$="billingAddress_addressFields_Email"]').val(),
        	address1: $form.find('input[name$="_shippingAddress_addressFields_address1"]').val(),
            address2: $form.find('input[name$="_shippingAddress_addressFields_address2"]').val(),
            countryCode: $form.find('select[id$="_shippingAddress_addressFields_country"]').val(),
            stateCode: stateCode,
            postalCode: postalCode,
            city: $form.find('input[name$="_shippingAddress_addressFields_city"]').val(),
            addressType: $('body').find('input[id="residentialbusiness"]').val(),
            phone: $form.find('input[name*="_shippingAddress_addressFields_phone"]').val(),
            sameAsBilling: sameAsBilling
        };
        //TSED-131: For Register user a extra address drop down is coming on checkout page and for TSED-296
        //$('select[name$="_singleshipping_addressList"]').parents('.select-address.form-row').hide();
        $('select[name$="dwfrm_billing_addressList"]').parents('.select-address .form-row').hide();
        //TSED-131 ends
    } else {
        //removing multiple countries from billing 
    	// TSED-131: For Register user a extra address drop down is coming on checkout page
    	//$('select[name$="_singleshipping_addressList"]').parents('.select-address.form-row').show();
    	$('select[name$="dwfrm_billing_addressList"]').parents('.select-address .form-row').show();
    	// TSED-131 ends
        $(".billing-section .country-row").removeClass('hide');
        $(".billing-section .postal-row").removeClass('hide');
        $(".billing-section .postal-row-other").addClass('hide');

        var country = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
        var state = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
        var stateCode;
        var postalCode;
        var sameAsBilling = $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked');

        if (country != 'US') {
            updateStateDropDown();
            $(".shipping-section .state-row").addClass('hide');
            $(".shipping-section .state-other-row").removeClass('hide');
            $(".shipping-section .postal-row").addClass('hide');
            $(".shipping-section .postal-row-other").removeClass('hide');

            if (state != null && state != '' && $("select[id$='_singleshipping_shippingAddress_addressFields_stateOther_state'] option[value=" + state + "]").length > 0) {
                stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
                $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val($form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val());
                postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
                $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val());
            }
        } else {
            updateStateDropDown();
            $(".shipping-section .state-row").removeClass('hide');
            $(".shipping-section .state-other-row").addClass('hide');
            $(".shipping-section .postal-row").removeClass('hide');
            $(".shipping-section .postal-row-other").addClass('hide');

            $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"] option[value=""]').prop('selected', 'selected').trigger('change');
            $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val());
            stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
        }
        var params = {
        	firstName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val(),
        	lastName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val(),
        	address1: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val(),
            address2: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val(),
            countryCode: $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val(),
            stateCode: stateCode,
            postalCode: postalCode,
            city: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val(),
            addressType: $('body').find('input[id="residentialbusiness"]').val(),
            phone: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val(),
            sameAsBilling: sameAsBilling,
            billFirstName: $form.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val(),
            billLastName: $form.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val(),
        	email: $form.find('input[name$="billingAddress_addressFields_Email"]').val(),
        	billAddress1: $form.find('input[name$="_billing_billingAddress_addressFields_address1"]').val(),
            billAddress2: $form.find('input[name$="_billingAddress_addressFields_address2"]').val(),
            billCountryCode: $form.find('select[id$="_billingAddress_addressFields_country"]').val(),
            billStateCode: $form.find('select[id$="_billing_billingAddress_addressFields_states_state"]').val(),
            billPostalCode: $form.find('input[name$="_billing_billingAddress_addressFields_postal"]').val(),
            billCity: $form.find('input[name$="_billing_billingAddress_addressFields_city"]').val(),
            addressType: $('body').find('input[id="residentialbusiness"]').val(),
            billPhone: $form.find('input[name$="_billing_billingAddress_phone"]').val()            
        };

        //TSED-296 - Fix for mixed basket
        /*if($form.find($('.billing-section').length > 0 && 'input[name$="_billingAddress_addressFields_address1"]').val() == '' && $form.find('input[name$="_billing_billingAddress_addressFields_city"]').val() == '') {
        	var params = {
            	firstName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val(),
            	lastName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val(),
            	address1: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val(),
                address2: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val(),
                countryCode: $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val(),
                stateCode: stateCode,
                postalCode: postalCode,
                city: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val(),
                addressType: $('body').find('input[id="residentialbusiness"]').val(),
                phone: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val(),
                sameAsBilling: sameAsBilling
            };
        } else {
        	var params = {
            	firstName: $form.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val(),
            	lastName: $form.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val(),
            	email: $form.find('input[name$="billingAddress_addressFields_Email"]').val(),
            	address1: $form.find('input[name$="_billing_billingAddress_addressFields_address1"]').val(),
                address2: $form.find('input[name$="_billingAddress_addressFields_address2"]').val(),
                countryCode: $form.find('select[id$="_billingAddress_addressFields_country"]').val(),
                stateCode: stateCode,
                postalCode: postalCode,
                city: $form.find('input[name$="_billing_billingAddress_addressFields_city"]').val(),
                addressType: $('body').find('input[id="residentialbusiness"]').val(),
                phone: $form.find('input[name$="_billing_billingAddress_phone"]').val(),
                sameAsBilling: sameAsBilling
            };
        }*/
    }
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description updates the Shipping Methods section after add/removal of any shpping type coupon.
 */
function updateShippingMethods() {
    //var url = getShippingMethodURL(Urls.shippingMethodsJSON);
    $.ajax({
        url: Urls.shippingCouponRefreshURL,
        dataType: 'HTML',
        success: function(data) {
            $('#shipping-method-list').html(data);
        }
    })
}

if ($('.show_billing_email').length > 0 && $('.show_billing_email').val() == "true") {
    $('.form-row.email-row.hide').eq(0).removeClass('hide');
}


/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    var sameAsBilling = $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked');
    var validTaxExemptionID = $('input[name$="validID"]').val();
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {
        shippingMethodID: shippingMethodID,
        sameAsBilling: sameAsBilling
    });
    ajax.getJson({
        async: false,
        url: url,
        callback: function(data) {
            showShippingMethodDetails(shippingMethodID);
            var skipTax=true;
            if(data.shippingMethodID){
            	skipTax=false;
            }
            //TSED-782:Estimated Sales Tax is adding to the cart after applying tax exempt ID for registered user
            updateSummary(false,skipTax,validTaxExemptionID);
            //Update coupon section on selection of shipping method. 
            //TSED-298
            if ($('.billing-coupon-code .item-details.valid-coupon-exists').length > 0 && $('.billing-coupon-code .error-messages > div').length <= 1) {
                updateCouponSection();
            }
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();
        }
    });
}

/**
 * @function
 * @description Address Type Radio Button Behavior
 */
function updatePaymentSection() {
    if ($('.right-row.order-value').html() == '$0.00') {
        $('body').find('.credit-number .input-text').val('');
        $('select[name$="_billing_paymentMethods_creditCard_expiration_month"]').val('');
        $('select[name$="_billing_paymentMethods_creditCard_expiration_year"]').val('');
        $('body').find('.cvn .input-text').val('');
        util.updateselect();
        $('body').find('.payment-information').hide();
        $('body').find('.checkout-heading.payment-section').html('Contact Information');
        $('body').find('.billing-sub-heading').html('');
    } else {
        $('body').find('.payment-information').show();
        $('body').find('.checkout-heading.payment-section').html('Billing Information');
        //TSED-296: Show this text when billing section is open.
        if ($('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0 && !$('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
        	$('body').find('.billing-sub-heading').html('Please enter the billing information associated with this card.');
        }
    }
}

function isBillingAddressValid($form) {
    var firstName = $form.find('input[name$="_billingAddress_addressFields_firstName"]').val();
    var lastName = $form.find('input[name$="_billingAddress_addressFields_lastName"]').val();
    //var email = $form.find('input[name$="_billingAddress_addressFields_Email"]').val();
    var address1 = $form.find('input[name$="_billingAddress_addressFields_address1"]').val();
    var address2 = $form.find('input[name$="_billingAddress_addressFields_address2"]').val();
    var countryCode = $form.find('select[id$="_billingAddress_addressFields_country"]').val();
    var stateCode = $form.find('select[id$="_billingAddress_addressFields_states_state"]').val();
    var postalCode = $form.find('input[name$="_billingAddress_addressFields_postal"]').val();
    var city = $form.find('input[name$="_billingAddress_addressFields_city"]').val();
    //var phone = $form.find('input[name*="_billing_billingAddress_phone"]').val();
    if (firstName != '' && firstName != undefined && lastName != '' && lastName != undefined && address1 != '' && address1 != undefined && countryCode != '' && countryCode != undefined && stateCode != '' && stateCode != undefined && postalCode != '' && postalCode != undefined && city != '' && city != undefined) {
        return true;
    }
    return false;
}

function isShippingAddressValid($form) {
	var firstName = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
    var lastName = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
    var address1 = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
    var address2 = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val();
    var countryCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
    var stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
    var postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
    var city = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
    //var phone = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val();
    if (firstName != '' && firstName != undefined && lastName != '' && lastName != undefined && address1 != '' && address1 != undefined && countryCode != '' && countryCode != undefined && stateCode != '' && stateCode != undefined && postalCode != '' && postalCode != undefined && city != '' && city != undefined) {
        return true;
    }
    return false;
}

//@FIX-PHSSECOM-249
//Checks if shipping form is valid
function isShippingFormValid($form) {
    var firstName = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
    var lastName = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
    var address1 = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
    var address2 = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val();
    var countryCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
    var stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
    var postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
    var city = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
    var phone = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val();
    var companyname = $form.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
    if (firstName != '' && firstName != undefined && lastName != '' && lastName != undefined && companyname != '' && companyname != undefined && address1 != '' && address1 != undefined && countryCode != '' && countryCode != undefined && stateCode != '' && stateCode != undefined && postalCode != '' && postalCode != undefined && city != '' && city != undefined && phone != '' && phone != undefined) {
        return true;
    }
    return false;
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */

var poBoxAddressExist = false;
function updateShippingMethodList(skipcouponrefresh) {
    var $shippingMethodList = $('#shipping-method-list');
    var $checkoutForm = $('.checkout-shipping');
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);
    var sameAsBilling = $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked');
    //TSED-1057: USPS Wrong Carrier Code
    var excludeList = ['AE', 'AA', 'AP'];
    var shipStateCode = $checkoutForm.find('select[id$="_shippingAddress_addressFields_states_state"]').val();
    poBoxAddressExist = false;

    var checkAddressValid = false;
    var validTaxExemptionID = $('input[name$="validID"]').val();
    if(sameAsBilling){
		checkAddressValid = isShippingAddressValid($checkoutForm);
	} else {
		checkAddressValid = isBillingAddressValid($checkoutForm);
	}

    if($('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0 && checkAddressValid) {
        var shipaddr1 = $checkoutForm.find('input[name$="_shippingAddress_addressFields_address1"]').val(),
            shipaddr2 = $checkoutForm.find('input[name$="_shippingAddress_addressFields_address2"]').val();

        if (shipaddr1 != '' || shipaddr1 != null || shipaddr2 != '' || shipaddr2 != null) {
        	if(excludeList.indexOf(shipStateCode) != -1) {
        		//TSED-1243: PO Order Not Able to be Submitted: APO Customer can ship to a Military Address (APO/FPO location) BOX # but not a PO Box #
                var poBoxPatternMilitaryLocation = /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|POB|P.O.B|P. O. B|P.O.B.|P. O. B.|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;
                var poBoxShipaddr1 = poBoxPatternMilitaryLocation.test(shipaddr1),
                	poBoxShipaddr2 = poBoxPatternMilitaryLocation.test(shipaddr2)
                
                if(!poBoxShipaddr1 || !poBoxShipaddr2) {
                	poBoxAddressExist = true;
                	updateSummary(true,true,'',poBoxAddressExist);
                    $('.pt_checkout .pobox-error').removeClass('hide');
                    $('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                    if($('#shipping-method-list').find('.shipping-list-method .custom-select.applicable-methods').length > 0){
                    	$('.applicable-shipping').show();
                    	$('.custom-select.applicable-methods').hide();
                    	$('.shipping-method-description').hide();
                    }
                    return;
                } else {
                    poBoxAddressExist = false;
                    updateSummary(true,true,'',poBoxAddressExist);
                    $('.pt_checkout .pobox-error').addClass('hide');
                    $('.place-order-div .checkoutbutton').removeClass('disable-mode-btn');
                }
            } else {
            	//TSED-1252: Updated pattern for PO Issue: "B" In Shipping Address Causing Order Not To Be Placed
            	var poBoxPattern = /\b(((box|bin)[-. \/\\]?\d+)|(.*p[ \s]?[ \.]? ?(o|0)[ \s]?[-. \/\\]? *-?((box|bin)|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin))? *\d+)|(p *-?\/?(o)? *-?box)|POB|P.O.B|P. O. B|P.O.B.|P. O. B.|Post Box|postoffice|PostOfficeBox|Post Office Box|PostOffice Box|Post OfficeBox|PostOfficeBin|Post Office Bin|Post OfficeBin|PostOffice Bin|postbox|post office|P\.? *OFFICE\.? *BOX\.?|P *-?\/? *O *-? *BOX|PO *-? *BOX|((box|bin)) *(number|num|#)? *\d*\d+)\b/i;
            	var poBoxShipaddr1 = poBoxPattern.test(shipaddr1),poBoxShipaddr2 = poBoxPattern.test(shipaddr2);
                if(poBoxShipaddr1 || poBoxShipaddr2) {
                	poBoxAddressExist = true;
                	updateSummary(true,true,'',poBoxAddressExist);
                    $('.pt_checkout .pobox-error').removeClass('hide');
                    $('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                    if($('#shipping-method-list').find('.shipping-list-method .custom-select.applicable-methods').length > 0){
                    	$('.applicable-shipping').show();
                    	$('.custom-select.applicable-methods').hide();
                    	$('.shipping-method-description').hide();
                    }
                    return;
                } else{
                    $('.pt_checkout .pobox-error').addClass('hide');
                    $('.place-order-div .checkoutbutton').removeClass('disable-mode-btn');
                }
            }
        }
    } else {
        /** TSED-1243: If the billing address is not entered and only the shipping address is entered. And on page load if address is not entered*/
        if(!checkAddressValid && $('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0) {
        	updateSummary(true,true,validTaxExemptionID);
        	if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length > 0) {
                //Disable the checkout button if applicable shipping methods are not exist.
                $('.place-order-div .checkoutbutton').closest('.checkout-shipping').find('.checkoutbutton').addClass('disable-mode-btn');
            }
        	return;
        }
    }

    if ((!$shippingMethodList || $shippingMethodList.length === 0) && $('#commonaddressid').length == 0) {
    	if (isBillingAddressValid($checkoutForm)){
    		ajax.getJson({
    			async: false,
    	        url: url,
    	        sameAsBilling: sameAsBilling,
    	        callback: function(data) {
    	        	updateSummary(false,false);
    	        }
    		});
    	} else {
    		updateSummary(true);
    	}
        return;
    } else if ((!$shippingMethodList || $shippingMethodList.length === 0) && $('#commonaddressid').length == 1) {
    	var sameAsBilling = $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked');
    	var checkAddressValid = false;
    	if(sameAsBilling){
    		checkAddressValid = isShippingAddressValid($checkoutForm);
    	} else {
    		checkAddressValid = isBillingAddressValid($checkoutForm);
    	}
    	if (($('#dwfrm_singleshipping_addressList').length > 0) || ($shippingMethodList.length === 0 && checkAddressValid)){
    		/*var params = prepareParams($checkoutForm);
    	    var userAddressurl = Urls.saveUserAddressInSession;
    	    userAddressurl = util.appendParamsToUrl(userAddressurl, params);
    	    var validTaxExemptionID = $('input[name$="validID"]').val();
    	    $.ajax({
    	        url: userAddressurl
    	    });*/
    		if(isShippingAddressValid($checkoutForm) && !isBillingAddressValid($checkoutForm) && !sameAsBilling) {
        		return;
        	}
    		ajax.getJson({
    			async: false,
    	        url: url,
    	        sameAsBilling: sameAsBilling,
    	        callback: function(data) {
    	        	updateSummary(false,false,validTaxExemptionID,poBoxAddressExist);
    	        }
    		});
    	} else {
    		updateSummary(true,true);
    	}
    	return;
    }

    $('.address').on('change', '.shipping-section .sameasbilling, .billing-section', function() {
        $('.checkout-content').addClass('active');
    });
    
    var checkAddressValid = false;
    var validTaxExemptionID = $('input[name$="validID"]').val();
    if(sameAsBilling){
		checkAddressValid = isShippingAddressValid($checkoutForm);
	} else {
		checkAddressValid = isBillingAddressValid($checkoutForm);
	}
    
    if (($('#dwfrm_singleshipping_addressList').length > 0) || ($('#dwfrm_singleshipping_addressList').length == 0 && checkAddressValid)) {
    	/*var params = prepareParams($checkoutForm);
	    var userAddressurl = Urls.saveUserAddressInSession;
	    userAddressurl = util.appendParamsToUrl(userAddressurl, params);
	    $.ajax({
	        url: userAddressurl
	    });*/
	    // Fix for not to call Vertex tax when billing address is not valid or filled
    	if(isShippingAddressValid($checkoutForm) && !isBillingAddressValid($checkoutForm) && !sameAsBilling) {
    		return;
    	}
    	ajax.getJson({
            async: false,
            url: url,
            sameAsBilling: sameAsBilling,
            callback: function(data) {
                if (!data) {
                    window.alert('Couldn\'t get list of applicable shipping methods.');
                    return false;
                }
                if (false && shippingMethods && shippingMethods.toString() === data.toString()) { // PREVAIL-Added for 'false' to handle SPC.
                    // No need to update the UI.  The list has not changed.
                    return true;
                }

                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                shippingMethods = data;
                // indicate progress
                progress.show($shippingMethodList);
                //TSED-782:Estimated Sales Tax is adding to the cart after applying tax exempt ID for registered user
                var validTaxExemptionID = $('input[name$="validID"]').val();

                // load the shipping method form
                var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
                $shippingMethodList.load(smlUrl, function() {
                    $shippingMethodList.fadeIn('fast');
                    // rebind the radio buttons onclick function to a handler.
                    $shippingMethodList.find('select[id$="applicable-shipping-methods"]').change(function() {
                        selectShippingMethod($(this).val());
                    });

                    // Commenting the update the summary to avoid altering issue of shipping method.
                    if (skipcouponrefresh != undefined && skipcouponrefresh) {
                    	updateSummary(true,true,validTaxExemptionID);
                    } else {
                    	updateSummary(false,false,validTaxExemptionID,poBoxAddressExist);
                    }
                    progress.hide();
                    tooltip.init();
                    //if nothing is selected in the shipping methods select the first one
                    if ($shippingMethodList.find('.shipping-list-method .custom-select.applicable-methods').length > 0 && !poBoxAddressExist) {
                        var selectedShippingMethodID = $('input[name$="selectedShippingMethod"]').val();
                        if (selectedShippingMethodID != undefined && selectedShippingMethodID != null && selectedShippingMethodID != 'null' && selectedShippingMethodID != '') {
                            $shippingMethodList.find('select[id$="applicable-shipping-methods"] option[value="' + selectedShippingMethodID + '"]').prop('selected', 'selected').trigger('change');
                        } else if ($shippingMethodList.find('select[id$="applicable-shipping-methods"] option:selected').length > 0) {
                            var selected = $shippingMethodList.find('select[id$="applicable-shipping-methods"] option:selected').val();
                            $shippingMethodList.find('select[id$="applicable-shipping-methods"] option[value="' + selected + '"]').prop('selected', 'selected').trigger('change');
                        } else {
                            $shippingMethodList.find('select[id$="applicable-shipping-methods"] option:first').attr('selected', 'selected').trigger('change');
                        }
                        //Enable the checkout button if applicable shipping methods are exist.
                        if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length === 0) {
                            $('.place-order-div .checkoutbutton').closest('.checkout-shipping').find('.checkoutbutton').removeClass('disable-mode-btn');
                        }
                        util.updateselect();
                        util.lableFocus();
                    } else {
                    	updateSummary(true,true,validTaxExemptionID);
                        if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length > 0) {
                            //Dissable the checkout button if applicable shipping methods are not exist.
                            $('.place-order-div .checkoutbutton').closest('.checkout-shipping').find('.checkoutbutton').addClass('disable-mode-btn');
                        }
                    }
                });
            }
        });
    } else {
    	progress.hide();
        tooltip.init();
        updateSummary(true,true,validTaxExemptionID);
        if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length > 0) {
            //Dissable the checkout button if applicable shipping methods are not exist.
            $('.place-order-div .checkoutbutton').closest('.checkout-shipping').find('.checkoutbutton').addClass('disable-mode-btn');
        }
    }
}

/**
 * @function
 * @description Shipping method description Display on change of shipping methods list.
 */
function showShippingMethodDetails($this) {
    var $this = $this;
    if ($this != undefined && $this != null && $this != '') {
        var selected = $this;
        var msgDetails = $('#shipping-method-list').find('select[id$="applicable-shipping-methods"] option[value="' + selected + '"]').data('shippingdetails');
        var $methodDetails = $('.shipping-method-description');
        if (msgDetails != null && msgDetails != '') {
            $methodDetails.removeClass('hide');
            $methodDetails.find('span.bussiness-day').html('');
            $methodDetails.find('span.shipping-details').html('');
            var details = msgDetails.split('|');
            if (details[0] != null && details[0] != '') {
                $methodDetails.find('span.bussiness-day').html(details[0]);
            }
            if (details.length > 1 && details[1] != null && details[1] != '') {
                $methodDetails.find('span.shipping-details').html(details[1]);
            }
        }
    }
}


/**
 * @function
 * @description Coupon Form Display on click of have promo Link
 */
function showCouponForm() {
   $('input[name="haveCouponCode"]').on('click', function(e) {
        if ($("#couponYes").is(":checked")) {
          $(".coupon-form-checkout").show();
          $(".coupon-form-checkout").find(".input-text").attr("placeholder", "ENTER COUPON CODE");
        } else {
          $(".coupon-form-checkout").hide();
        }
      });
}

/**
 * @function
 * @description Removing Sold Out Item From Order Summary Section
 */
function removeItem(itemid) {
    var url = util.appendParamToURL(Urls.removeClassOrderSummary, 'pid', itemid);
    $.ajax({
        url: url,
        success: function(response) {
            if (response != null) {
                page.refresh();
            }
        }
    });
}

/**
 * @function
 * @description Fills Billing Address fields using data from Google Places Auto LookUp
 */
function fillAddress() {
    var place = autocompleteBilling.getPlace();
    var componentForm = {
        street_number: 'short_name',
        route: 'short_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'short_name',
        country: 'short_name'
    };

    var addressFields = {
        route: 'input[name$="billingAddress_addressFields_address1"]',
        locality: 'input[name$="billingAddress_addressFields_city"]',
        administrative_area_level_1: 'select[name$="billingAddress_addressFields_states_state"]',
        postal_code: 'input[name$="billingAddress_addressFields_postal"]'
    }
    var streetNo = '';
    var stateCode = '';
    var countryCode = '';
    $('input[name$="billingAddress_addressFields_postal"]').val('');
    $('select[name$="billingAddress_addressFields_states_state"]').val('');
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            if (addressType === 'street_number') {
                streetNo = place.address_components[i][componentForm[addressType]];
            } else {
                var val = (addressType === 'route') ? streetNo + ' ' + place.address_components[i][componentForm[addressType]] : place.address_components[i][componentForm[addressType]];
                if (addressType === 'country') {
                    var territoryAddress = SitePreferences.TERRITORY_ADDRESS;
                    if (territoryAddress != undefined && territoryAddress != null && territoryAddress.indexOf(val) > 0) {
                        countryCode = 'US';
                        stateCode = val;
                    } else {
                        countryCode = val;
                    }
                    $('select[name$="billingAddress_addressFields_country"]').val(countryCode);
                    $('select[name$="billingAddress_addressFields_country"]').trigger('change');
                    updateStateDropDown();
                    if ($('select[name$="billingAddress_addressFields_states_state"]').is(':visible')) {
                        $('select[name$="billingAddress_addressFields_states_state"]').val(stateCode);
                        $('select[name$="billingAddress_addressFields_states_state"]').trigger('change');
                        var suggestedState = $('select[name$="billingAddress_addressFields_states_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="billingAddress_addressFields_states_state"]').val('');
                            $('select[name$="billingAddress_addressFields_states_state"]').trigger('change');
                        }
                    }
                    if ($('select[name$="billingAddress_addressFields_stateOther_state"]').is(':visible')) {
                        $('select[name$="billingAddress_addressFields_stateOther_state"]').val(stateCode);
                        $('select[name$="billingAddress_addressFields_stateOther_state"]').trigger('change');
                        var suggestedState = $('select[name$="billingAddress_addressFields_stateOther_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="billingAddress_addressFields_stateOther_state"]').val('OT');
                            $('select[name$="billingAddress_addressFields_stateOther_state"]').trigger('change');
                        }
                    }
                }
                if (addressType === 'administrative_area_level_1') {
                    stateCode = val;
                } else {
                    $(addressFields[addressType]).val(val);
                    $('.address1-row').find('.input-text').removeClass('error');
                    $('.address1-row').find('.input-text').next('span.error').hide();
                    $('.postal-row,.city-row').find('.input-text').removeClass('error');
                    $('.postal-row,.city-row').find('.input-text').next('span.error').hide();
                }
            }
        }
    }
    util.lableFocus();
    util.updateselect();
    validator.init();
    $('select[name$="billingAddress_addressFields_country"]').focusout();
    updateShippingMethodList();
}

/**
 * @function
 * @description Fills Shipping Address fields using data from Google Places Auto LookUp
 */
function fillShippingAddress() {
    var place = autocompleteShipping.getPlace();
    var componentForm = {
        street_number: 'short_name',
        route: 'short_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'short_name',
        country: 'short_name'

    };

    var addressFieldship = {
        route: 'input[name$="shippingAddress_addressFields_address1"]',
        locality: 'input[name$="shippingAddress_addressFields_city"]',
        administrative_area_level_1: 'select[name$="shippingAddress_addressFields_states_state"]',
        postal_code: 'input[name$="shippingAddress_addressFields_postal"]'
    }
    var streetNo = '';
    var stateCode = '';
    var countryCode = '';
    $('input[name$="shippingAddress_addressFields_postal"]').val('');
    $('select[name$="shippingAddress_addressFields_states_state"]').val('');

    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            if (addressType === 'street_number') {
                streetNo = place.address_components[i][componentForm[addressType]];
            } else {
                var val = (addressType === 'route') ? streetNo + ' ' + place.address_components[i][componentForm[addressType]] : place.address_components[i][componentForm[addressType]];
                if (addressType === 'country') {
                    var territoryAddress = SitePreferences.TERRITORY_ADDRESS;

                    if (territoryAddress != undefined && territoryAddress != null && territoryAddress.indexOf(val) > 0) {
                        countryCode = 'US';
                        stateCode = val;
                    } else {
                        countryCode = val;
                    }
                    $('select[name$="shippingAddress_addressFields_country"]').val(countryCode);
                    $('select[name$="shippingAddress_addressFields_country"]').trigger('change');
                    updateStateDropDown();
                    if ($('select[name$="shippingAddress_addressFields_states_state"]').is(':visible')) {
                        $('select[name$="shippingAddress_addressFields_states_state"]').val(stateCode);
                        $('select[name$="shippingAddress_addressFields_states_state"]').trigger('change');
                        var suggestedState = $('select[name$="shippingAddress_addressFields_states_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="shippingAddress_addressFields_states_state"]').val('');
                            $('select[name$="shippingAddress_addressFields_states_state"]').trigger('change');
                        }
                    }
                    if ($('select[name$="shippingAddress_addressFields_stateOther_state"]').is(':visible')) {
                        $('select[name$="shippingAddress_addressFields_stateOther_state"]').val(stateCode);
                        $('select[name$="shippingAddress_addressFields_stateOther_state"]').trigger('change');
                        var suggestedState = $('select[name$="shippingAddress_addressFields_stateOther_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="shippingAddress_addressFields_stateOther_state"]').val('OT');
                            $('select[name$="shippingAddress_addressFields_stateOther_state"]').trigger('change');
                        }
                    }
                }
                if (addressType === 'administrative_area_level_1') {
                    stateCode = val;
                } else {
                    $(addressFieldship[addressType]).val(val);
                    $('.address1-row').find('.input-text').removeClass('error');
                    $('.address1-row').find('.input-text').next('span.error').hide();
                    $('.postal-row,.city-row').find('.input-text').removeClass('error');
                    $('.postal-row,.city-row').find('.input-text').next('span.error').hide();
                }
            }
        }
    }
    util.lableFocus();
    util.updateselect();
    validator.init();
    $('select[name$="shippingAddress_addressFields_country"]').focusout();
    updateShippingMethodList();
}

function checkPOAmount() {
    //Check POAmount against Order Total
    if ($('select#POList').length > 0 && $('#is-PO').is(':checked')) {
        var index = $('select#POList')[0].selectedIndex;
        var POData = $('.PO-content').find('input[name$="poData"]').data('podata');
        if (parseFloat(POData[index].POAmount) < amountTotalPO) {
            $('select#POList').parent().addClass("PO-error");
            $('.PO-amount-error').addClass("error");
            $('.PO-class-error').removeClass("error");
            $('.place-order-div button').prop('disabled', true);
        } else {
        	// TSED-1187 - PO cannot be used with courses
        	if($('#courseonly').val() === 'true'){
        		$('select#POList').parent().addClass("PO-error");
        		$('.PO-class-error').addClass("error");
        		$('.place-order-div button').prop('disabled', true);
        	} else{
        		$('select#POList').parent().removeClass("PO-error");
                $('.PO-amount-error').removeClass("error");
                $('.PO-class-error').removeClass("error");
                $('.place-order-div button').prop('disabled', false);
        	}
        }
    }
}

exports.init = function() {
	/*Vertex code
	var $form = $('.checkout-shipping');

	if ($form.find('select[name$="_country"]').val() != 'us') {
	    $form.find('select[name$="_state"]').parents('.form-row').hide();
	}

	$form.on('change','select[name$="_country"]', function(e){
	    if (e.target.value != 'us') {
	        $form.find('select[name$="_state"]').parents('.form-row').hide();
	    } else {
	        $form.find('select[name$="_state"]').parents('.form-row').show();
	    }
	});*/
	
    //Added For PHSSECOM-335
    
    /** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels Start */
    $('.form-row.country-row').find('label').addClass('focus');
    $('.form-row.country-code-row').find('label').addClass('focus');
    if($('select[name$="_addressFields_states_state"]').val() !== ''){
        $('.form-row.state-row').find('label').show();
        $('select[name$="_addressFields_states_state"]').closest('.form-row.state-row').find('label').addClass('focus');
    } else {
        $('.form-row.state-row').find('label').hide();
    }
    $('.form-row.month').find('label').hide();
    $('.form-row.year').find('label').hide();
    $('select[name$="_shippingAddress_addressFields_states_state"], select[name$="_billingAddress_addressFields_states_state"]').on('change', function(){
        if($(this).val() !== ''){
            $(this).closest('.form-row.state-row').find('label').show();
            $(this).closest('.form-row.state-row').find('label').addClass('focus');
        }
    });
    $('select[name$="_paymentMethods_creditCard_expiration_month"], select[name$="_paymentMethods_creditCard_expiration_year"]').on('change', function(){
        if($(this).val() !== ''){
            $(this).closest('.form-row').find('label').show();
            $(this).closest('.form-row').find('label').addClass('focus');
        }
    });
     /** TSED-1141: ADA Compliance – Checkout Page – Combo Box Field Labels End */

    // below mention code regarding handle error message..
    $('.error-form.card-num-wrong').appendTo('.payment-information .form-row.credit-number');
    $('.error-form.date-card').appendTo('.payment-information .form-row.year');
    $('.error-form.cvncard').appendTo('.payment-information .form-row.cvn');
    //for PHSSECOM-112 fix 

    $('.form-row.credit-number .input-text').on('blur focusout', function() {
        if ($('.error-form.card-num-wrong').closest('.payment-information').find('.form-row.credit-number .input-text') && $('.error-form.card-num-wrong').closest('.payment-information').find('.form-row.credit-number .input-text').val() != null && $('.error-form.card-num-wrong').closest('.payment-information').find('.form-row.credit-number .input-text').val() != '') {
            if ($('.error-form.card-num-wrong').closest('.payment-information').find('.form-row.credit-number .input-text').val().length > 0) {
                $('.error-form.card-num-wrong').hide();
            }
        }
    });

    // cvn field code goes here focusout
    $('.form-row.cvn .input-text').on('blur focusout', function() {
        if ($('.error-form.cvncard').closest('.payment-information').find('.form-row.cvn .input-text') && $('.error-form.cvncard').closest('.payment-information').find('.form-row.cvn .input-text').val() != null && $('.error-form.cvncard').closest('.payment-information').find('.form-row.cvn .input-text').val() != '') {
            if ($('.error-form.cvncard').closest('.payment-information').find('.form-row.cvn .input-text').val().length > 0) {
                $('.error-form.cvncard').hide();
            }
        }
    });
    if ($('.error-form.card-num-wrong').is(':visible')) {
        $('.error-form.card-num-wrong').closest('.payment-information').find('.form-row.credit-number .input-text').addClass('error');
    }
    if ($('.error-form.cvncard').is(':visible')) {
        $('.error-form.cvncard').closest('.payment-information').find('.form-row.cvn .input-text').addClass('error');
    }
    if ($('.error-form.date-card').is(':visible')) {
        $('.error-form.date-card').closest('.payment-information').find('.form-row.year .input-select').addClass('error')
    }

    // end error message code
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector: '[id$="singleshipping_shippingAddress"]'
    });

    util.saveCalendar();

    var $checkoutForm = $('form.address');

    if ($checkoutForm.find('.addresslist').val() == 'true') {
        //$checkoutForm.find('.billing-addr-form').addClass('hide');
    }

    if ($checkoutForm.find('.shippingAddrList').val() == 'true') {
        //$checkoutForm.find('.sameasbilling .shipping-addr-form').addClass('hide');
    }

    if ($('.class-login-user').val() != null && $('.class-login-user').val() != '' && $('.class-login-user').val() && $('input[name$="_billing_billingAddress_dummyCheck"]').val() == 'true') {
        if (($('input[name$="billingAddress_addressFields_Email"]').val() != $('.auth-stud-email').text()) ||
            ($('input[name$="billingAddress_addressFields_firstName"]').val() != $('.auth-stud-fname').text()) ||
            ($('input[name$="billingAddress_addressFields_lastName"]').val() != $('.auth-stud-lname').text())) {
            $checkoutForm.find('input[name$="_billing_billingAddress_sameasstd"]').prop('checked', false);
        }
    } else {
        $checkoutForm.find('input[name$="_billing_billingAddress_sameasstd"]').prop('checked', true);
    }

    var $radiosphone = $('input:radio[name$=_singleshipping_shippingAddress_phoneNo]');
    if ($("input:radio[name$='_singleshipping_shippingAddress_phoneNo']").is(":checked")) {
        if ($("input[name$='_singleshipping_shippingAddress_phoneNo']:checked").val() == 'true') {
            if ($radiosphone.is(':checked') === false) {
                $radiosphone.filter('[value=true]').prop('checked', true);
            }
        } else if ($("input[name$='_singleshipping_shippingAddress_phoneNo']:checked").val() == 'false') {
            if ($radiosphone.is(':checked') === false) {
                $radiosphone.filter('[value=false]').prop('checked', true);
            }
        }
    } else {
        if ($('input[name$="_singleshipping_shippingAddress_phoneField"]').val() == 'true') {
            $radiosphone.filter('[value=true]').prop('checked', true);
        } else {
            $radiosphone.filter('[value=false]').prop('checked', true);
        }
    }

    var $radiosaddress = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
    if ($checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val() == '') {
        if ($radiosaddress.is(':checked') === false) {
            $radiosaddress.filter('[value=true]').prop('checked', true);
        }
    } else {
        //if ($radiosaddress.is(':checked') === false) {
        $radiosaddress.filter('[value=false]').prop('checked', true);
        //}
    }

    if ($('.product-unauth-user').val() != null && $('.product-unauth-user').val() != '' && $('.product-unauth-user').val()) {
        $('input[name$="billingAddress_addressFields_Email"]').val($('input[name$="_billing_billingAddress_email_emailAddress"]').val());
        $('input[name$="billingAddress_addressFields_firstName"]').val($('input[name$="billingAddress_addressFields_firstName"]').val());
        $('input[name$="billingAddress_addressFields_lastName"]').val($('input[name$="billingAddress_addressFields_lastName"]').val());
        $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_emailAddress"]').val());
    } else if ($('.auth-only-product').val() != null && $('.auth-only-product').val() != '' && $('.auth-only-product').val() && ($checkoutForm.find('.addressguest').val() == 'false') && ($checkoutForm.find('.addresslist').val() != 'true')) {
        $('input[name$="billingAddress_addressFields_Email"]').val($('input[name$="billingAddress_addressFields_Email"]').val());
        $('input[name$="billingAddress_addressFields_firstName"]').val($('input[name$="billingAddress_addressFields_firstName"]').val());
        $('input[name$="billingAddress_addressFields_lastName"]').val($('input[name$="billingAddress_addressFields_lastName"]').val());
        $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_emailAddress"]').val());
    } else {

        $('input[name$="billingAddress_addressFields_firstName"]').val($('input[name$="billingAddress_addressFields_firstName"]').val());
        $('input[name$="billingAddress_addressFields_lastName"]').val($('input[name$="billingAddress_addressFields_lastName"]').val());

        if ($('.class-login-user').val() != null && $('.class-login-user').val() != '' && $('.class-login-user').val()) {
            $('input[name$="_singleshipping_stdFirstName"]').val($('.auth-stud-fname').text());
            $('input[name$="_singleshipping_stdLastName"]').val($('.auth-stud-lname').text());

            $('input[name$="_billingAddress_addressFields_Email"]').val($('.auth-stud-email').text());
            $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('.auth-stud-email').text());
        } else {
            $('input[name$="billingAddress_addressFields_Email"]').val($('input[name$="_email_stdemailAddress"]').val());
            $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_email_stdemailAddress"]').val());
        }
    }

    // On Page Load, Making the Shipping Address Same as Billing Address Checked
    if ($('body').find('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0) {
        if ($('input[name$="_singleshipping_shippingAddress_dummyField"]').val() == 'true') {
            $checkoutForm.find('input[name$="_shippingAddress_useAsBillingAddress"]').prop('checked', false);
        } else {
        	//TSED-796-changes starts
            //$checkoutForm.find('input[name$="_shippingAddress_useAsBillingAddress"]').prop('checked', true);
        	if(!isBillingAddressValid($checkoutForm)) {
        		//$('.sameasbilling').addClass('hide');
                $('.sameasshipping').addClass('hide');
                $('.billing-email').removeClass('hide');
        		$('input[name$="_shippingAddress_addressFields_firstName"]').val('');
                $('input[name$="_shippingAddress_addressFields_lastName"]').val('');
                $('input[name$="_shippingAddress_addressFields_address1"]').val('');
                $('input[name$="_shippingAddress_addressFields_address2"]').val('');
                $('input[name$="_shippingAddress_addressFields_city"]').val('');
                $('input[name$="_shippingAddress_addressFields_postal"]').val('');
                $('input[name$="_shippingAddress_addressFields_country"]').val('');
                $('select[name$="_shippingAddress_addressFields_states_state"]').val('');
                $('input[name$="_shippingAddress_addressFields_phone"]').val('');
                
                //TSED-845:Update hidden input field tax exemption id to empty value when useSameAsBilling check box is not checked.
                $('input[name$="validID"]').val('');
                //TSED-796-changes ends
        	}
        }
    }
    if ($('body').find('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0) {
        if ($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
            //TSED-296
            $('input[name$="_billingAddress_addressFields_firstName"]').val($('input[name$="_shippingAddress_addressFields_firstName"]').val());
            $('input[name$="_billingAddress_addressFields_lastName"]').val($('input[name$="_shippingAddress_addressFields_lastName"]').val());
            $('input[name$="_billingAddress_addressFields_address1"]').val($('input[name$="_shippingAddress_addressFields_address1"]').val());
            $('input[name$="_billingAddress_addressFields_address2"]').val($('input[name$="_shippingAddress_addressFields_address2"]').val());
            $('input[name$="_billingAddress_addressFields_city"]').val($('input[name$="_shippingAddress_addressFields_city"]').val());
            $('input[name$="_billingAddress_addressFields_postal"]').val($('input[name$="_shippingAddress_addressFields_postal"]').val());
            $('input[name$="_billingAddress_addressFields_country"]').val($('input[name$="_shippingAddress_addressFields_country"]').val());
            $('select[name$="_billingAddress_addressFields_states_state"]').val($('select[name$="_shippingAddress_addressFields_states_state"]').val());
            $('input[name$="_billing_billingAddress_phone"]').val($('input[name$="_shippingAddress_addressFields_phone"]').val());
            $('input[name$="_singleshipping_shippingAddress_companyname"]').val($('input[name$="_singleshipping_shippingAddress_companyname"]').val());
            
            
            //$('.sameasbilling').addClass('hide');
            $('.sameasshipping').addClass('hide');
            //TSED-296: Hide billing section email when course product there in the basket with physical product.
            if($('input[name$="_billing_billingAddress_email_stdemailAddress"]').length > 0 && $('input[name$="_billing_billingAddress_email_stdemailAddress"]').is(':visible')) {
             	$('.billing-email').addClass('hide');
            	$('body').find('.billing-sub-heading').html('');
            	//TSED-1137
            	$('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_stdemailAddress"]'));
            } else {
            	$('.billing-email').removeClass('hide');
            }
            //Added For PHSSECOM-408
            if ($('select[name$="_billing_addressList"]').is(':visible')) {
                $('select[name$="_singleshipping_addressList"]').val($('select[name$="_billing_addressList"]').val());
                util.updateselect();
            }
            util.formPopulated();
            //updateShippingMethodList();
        } else {
            //$('.sameasbilling').removeClass('hide');
            //TSED-1156: Billing Address on PO Checkout Page Not Showing Enough Information
        	if($('#dwfrm_singleshipping_addressList').length > 0) {
        		$('.shipping-section').find('.select-address.form-row').removeClass('hide');
        	}
        	//TSED-1156: Code ends
            $('.sameasshipping').removeClass('hide');
            $('.billing-email').addClass('hide');
            $('input[name$="_shippingAddress_addressFields_firstName"]').val($('input[name$="_shippingAddress_addressFields_firstName"]').val());
            $('input[name$="_shippingAddress_addressFields_lastName"]').val($('input[name$="_shippingAddress_addressFields_lastName"]').val());
            $('input[name$="_shippingAddress_addressFields_address1"]').val($('input[name$="_shippingAddress_addressFields_address1"]').val());
            $('input[name$="_shippingAddress_addressFields_address2"]').val($('input[name$="_shippingAddress_addressFields_address2"]').val());
            $('input[name$="_shippingAddress_addressFields_city"]').val($('input[name$="_shippingAddress_addressFields_city"]').val());
            $('input[name$="_shippingAddress_addressFields_postal"]').val($('input[name$="_shippingAddress_addressFields_postal"]').val());
            $('input[name$="_shippingAddress_addressFields_country"]').val($('input[name$="_shippingAddress_addressFields_country"]').val());
            $('select[name$="_shippingAddress_addressFields_states_state"]').val($('select[name$="_shippingAddress_addressFields_states_state"]').val());
            $('input[name$="_shippingAddress_addressFields_phone"]').val($('input[name$="_shippingAddress_addressFields_phone"]').val());
            $('input[name$="_singleshipping_shippingAddress_companyname"]').val($('input[name$="_singleshipping_shippingAddress_companyname"]').val());
            //TSED-1137
            if($('input[name$="_billing_billingAddress_email_stdemailAddress"]').length > 0 && $('input[name$="_billing_billingAddress_email_stdemailAddress"]').is(':visible')) {
            	$('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_stdemailAddress"]'));
            }
        	util.formPopulated();
        }
    }

    $('.address').on('change', 'input[name$="_shippingAddress_addressFields_address1"], input[name$="_shippingAddress_addressFields_address2"], select[name$="_shippingAddress_addressFields_states_state"], select[name$="_shippingAddress_addressFields_stateOther_state"], input[name$="_shippingAddress_addressFields_city"], input[name$="_shippingAddress_addressFields_postal"], select[name$="_shippingAddress_addressFields_country"], input[name$="_shippingAddress_addressFields_otherPostal"], input[name$="_singleshipping_addressList"], select[name$="_singleshipping_addressList"], input[name$="_singleshipping_shippingAddress_companyname"]', function() {

        if (!$('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
            $('input[name$="_singleshipping_shippingAddress_dummyField"]').val(true);
        }
        if($('#is-PO').is(':checked') && $('select#dwfrm_singleshipping_addressList').val() === "") {
        	$('.shipping-section').find('.sameasbilling').removeClass('hide');
        }
        if (!$('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') || $('#dwfrm_singleshipping_addressList').length > 0) {
            var fname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
            var country = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val();
            var state = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            var city = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();

            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
            }
            if (country != '' || country != null) {
                updateStateDropDown();
            }
            //TSED-296: Updating Billing Form with User Entered Shipping Address when checkbox is selected.
            if($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') && $('#dwfrm_singleshipping_addressList').length > 0) {
            	$checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val());
            	$checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val());
                $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val());
                $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val());
                $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val());
                $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val());
                $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val());
                var billingPostal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val());
                if (billingPostal == '' || billingPostal == null || billingPostal == undefined) {
                    $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val());
                }
                $checkoutForm.find('input[name$="_billing_billingAddress_phone"]').val($('input[name$="_shippingAddress_addressFields_phone"]').val());
            }//ends
            
            var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
            if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val().toString() === 'true') {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible') && $('#is-PO').is(':checked')) {
                		//TSED-1156: Billing Address on PO Checkout Page Not Showing Enough Information
                		$('#dwfrm_billing_addressList').val("POBillTo");
                        var selected = $('#dwfrm_billing_addressList').val();
                        var data = $('#dwfrm_billing_addressList option[value="' + selected + '"]').data('address');
                        
                        //Fill in hidden form with data
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_firstName"]').val(data.firstName);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_lastName"]').val(data.lastName);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_address1"]').val(data.address1);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_address2"]').val(data.address2);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_city"]').val(data.city);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_postal"]').val(data.postalCode);
                        $('.billing-addr-form select[name$="_billingAddress_addressFields_country"]').val(data.countryCode);
                        $('.billing-addr-form select[name$="_billingAddress_addressFields_states_state"]').val(data.stateCode);
                        $('.billing-addr-form input[name$="_billingAddress_addressFields_phone"]').val(data.phone);
                		updateShippingMethodList();
                		//TSED-1156 code ends here
                	} else {
                		// TSED-1057 - Fix for when user changing the state summary was not updated for registered user
                		if($('.pac-container').length > 0 && !$('.pac-container').is(':visible') && ((state != '' && state != null) || (stateOther != '' && stateOther != null))) {
                    		updateShippingMethodList();
                    	}
                	}
                } else {
                    return;
                }
            } else {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            }
        } else if ($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') && $('#dwfrm_singleshipping_addressList').length == 0) {
        	var fname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val();
            var country = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val();
            var state = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            var city = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();

            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
            }
            if (country != '' || country != null) {
                updateStateDropDown();
            }
            
            //TSED-296: Updating Billing Form with User Entered Shipping Address when checkbox is selected.- Guest user
            $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val());
        	$checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val());
            $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val());
            $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_states_state"]').val());
            $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val());
            $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val($('select[name$="_singleshipping_shippingAddress_addressFields_country"]').val());
            $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val());
            var billingPostal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val());
            if (billingPostal == '' || billingPostal == null || billingPostal == undefined) {
                $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val($('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val());
            }
            $checkoutForm.find('input[name$="_billing_billingAddress_phone"]').val($('input[name$="_shippingAddress_addressFields_phone"]').val());
            // ends
            
            var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
            if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val().toString() === 'true') {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            } else {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            }
        } else {
            //Below code is for Billing address as Business type Shipping address with company name 
            var fname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val();
            var state = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val();
            var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
            var city = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val();
            }
            if (country != '' && country != null) {
                updateStateDropDown();
            }
            var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
            if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val() === 'true') {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            } else {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            }
        }

    });
	
	//TSED-1155: changed input to select - this logic will executes when checkout page get loaded for guest/registered 
    $('.address').on('change','input[name$="_billingAddress_addressFields_address1"], input[name$="_billingAddress_addressFields_address2"], select[name$="_billingAddress_addressFields_states_state"], select[name$="_billingAddress_addressFields_stateOther_state"], input[name$="_billingAddress_addressFields_city"], input[name$="_billingAddress_addressFields_postal"], select[name$="_billingAddress_addressFields_country"], input[name$="_billingAddress_addressFields_otherPostal"], select[name$="_billing_addressList"]', function() {
        if ($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') || $('#dwfrm_singleshipping_addressList').length == 0) {
            var fname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val();
            var state = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val();
            var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
            var city = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val();
            }
            if (country != '' && country != null) {
                updateStateDropDown();
            }
            var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
            if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val() === 'true') {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                	return;
                }
            } else {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            }
        } else if (!$('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') || $('#dwfrm_billing_addressList').length > 0) {
        	var fname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_firstName"]').val();
            var lname = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_lastName"]').val();
            var addr1 = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').val();
            var state = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_states_state"]').val();
            var stateOther = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_stateOther_state"]').val();
            var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
            var city = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]').val();
            var postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]').val();
            if (postal == '' || postal == null || postal == undefined) {
                postal = $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_otherPostal"]').val();
            }
            if (country != '' && country != null) {
                updateStateDropDown();
            }
            var companyName = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_companyname"]').val();
            if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val() === 'true') {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                	return;
                }
            } else {
                if (addr1 != '' && ((state != '' && state != null) || (stateOther != '' && stateOther != null)) && city != '' && postal != '' && fname != '' && lname != '' && companyName != '') {
                	if($('.pac-container').length > 0 && !$('.pac-container').is(':visible')) {
                		updateShippingMethodList();
                	}
                } else {
                    return;
                }
            }
        } else {
            return;
        }
    });

/* TSED-1057 - */
$('.address').on('blur', 'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"]', function() {
    var $checkoutForm = $('.checkout-shipping');
    var addr1 = $checkoutForm.find('input[name$="_addressFields_address1"]').val();
    if (addr1 != '' || addr1 != null){
        updateShippingMethodList();
    }
});

    $checkoutForm.on('click', 'input[name$="_singleshipping_shippingAddress_addressType"]', showCompanyName);
    $checkoutForm.on('click', 'input[name$="_singleshipping_shippingAddress_phoneNo"]', billingPhoneType);


    if ($main.find('.coupon-state-checkout').length > 0) {
        $main.find('.have-another-promo').text(Resources.CHECKOUT_ANOTHER_COUPON_CODE);
    }

    $checkoutForm.on('click', '#add-coupon', function(e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $('input[name$="_couponCode"]').val();
        if (code.trim().length === 0) {
            $('.redemption.coupon').html('');
            $main.find('.coupon-error.error').show();
            $error.html(Resources.COUPON_CODE_MISSING);
            //$main.find('.error-messages').addClass('hide'); 
            return;
        } else {
            $main.find('.coupon-error.error').hide();
        }
        //GTM events for applying coupon code in checkout page
        if (SitePreferences.isGTMEnabled) {
            gtmevents.applyCoupon(code);
        }
        if (SitePreferences.isATMEnabled) {
            atmevents.applyCoupon(code);
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {
            couponCode: code,
            format: 'ajax'
        });
        ajax.load({
            url: url,
            callback: function(data) {
                $('.redemption.coupon').html(data);
                //$main.find('.coupon-form-checkout').addClass('hide');
                if ($main.find('.cartcoupon').length > 0) {
                    $main.find('.have-another-promo').text(Resources.CHECKOUT_ANOTHER_COUPON_CODE);
                    $main.find('.have-promo').show();
                    $main.find('.have-another-promo').show();
                    $('#couponNo').prop('checked', true);
                    $('.coupon-form-checkout').find('.input-text').val('');
                    $main.find('.coupon-form-checkout').hide();
                } else {
                    $main.find('.have-another-promo').text(Resources.CHECKOUT_COUPON_CODE);
                    //$main.find('.have-another-promo').show();
                }
                if ($main.find('.cartcoupon').length >= 5) {
                    $main.find('.coupon-form-checkout').hide();
                    $main.find('.have-another-promo').hide();
                }
            }

        });
        updateShippingMethodList(true);

    }).on('keydown', 'input[name$="_couponCode"]', function(e) { // trigger events on enter
        if (e.which === 13) {
            e.preventDefault();
            $('#add-coupon').click();
        }
    }).on('click', '.removeButtonCoupon', function(e) { //remove coupon on billing page
        e.preventDefault();
        var url = Urls.removeCouponCheckout;
        var uuid = $(this).data('uuid');
        url = util.appendParamsToUrl(url, {
            uuid: uuid,
            format: 'ajax'
        });
        ajax.load({
            url: url,
            method: 'POST',
            callback: function(data) {
                $('.redemption.rowcoupons.coupon').html(data);
                updateSummary(true,false);
                updateShippingMethodList(); 
                if ($main.find('.cartcoupon').length == 0) {
                    $main.find('.have-another-promo').text(Resources.CHECKOUT_COUPON_CODE);
                } else {
                    $main.find('.have-another-promo').show();
                    $main.find('.have-another-promo').text(Resources.CHECKOUT_ANOTHER_COUPON_CODE);
                }
            }
        });
    });
    
   //TSED-386: Incorporate Tax Exemption ID Field & Tax Exemption Disclaimer on Checkout- **starts here**
    $('body').on('click', '.tax-exemption-id-link', function (e) {
    	e.preventDefault();
        //TSED-1142: Aria-label for Checkout Page Tax Exempt ID
        util.ariaLabel('.tax-exemption-field');
    	if($('body').find('.tax-exemption-form').is(':visible')){
    		$('body').find('.tax-exemption-form').hide();
    	} else {
    		var exemptionId = $('input[name$="_billing_taxExemptionId"]').val();
    		if(exemptionId.trim().length === 0 && $main.find('.tax-exemption-error.error').length > 0){
    			$main.find('.tax-exemption-error.error').addClass('hide');
    		}
    		$('body').find('.tax-exemption-form').show();
    	}
    });
    $('body').on('click', '#add-tax-exemption', function(e) {
    	e.preventDefault();
    	var $error = $('.checkout-ordersummary').find('.tax-exemption-error'),
    	exemptionId = $('input[name$="_billing_taxExemptionId"]').val();
	    if (exemptionId.trim().length === 0) {
	        $main.find('.tax-exemption-error.error').removeClass('hide');
	        $error.html(Resources.TAX_EXEMPTION_ERROR);
            //TSED-1142: Aria-label for Checkout Page Tax Exempt ID
            util.ariaLabel('.tax-exemption-field');
	        return;
	    } else {
	        var $form = $('.checkout-shipping');
            if (isBillingAddressValid($form) || isShippingFormValid($form)) {
            	//TSED-782:Estimated Sales Tax is adding to the cart after applying tax exempt ID for registered user
            	$('input[name$="validID"]').val(exemptionId.toUpperCase());
            	updateSummary(true,false,exemptionId.toUpperCase());
            } else {
            	if (exemptionId.trim().length > 0) {
            		$main.find('.tax-exemption-error.error').removeClass('hide');
            		$('input[name$="validID"]').val('');
            		$error.html(Resources.TAX_EXEMPTION_ERROR);
                     //TSED-1142: Aria-label for Checkout Page Tax Exempt ID
                    util.ariaLabel('.tax-exemption-field');
            	}
            }
	    }
    });
   //TSED-386: Incorporate Tax Exemption ID Field & Tax Exemption Disclaimer on Checkout - **ends here**
    
    //TSED-321: Add Sales Tax & Tax Exemption Disclaimer to Checkout for course product
    $('body').find('.pt_checkout .estimated-tax-tooltip').on('mouseover',function(){
    	$('.order-sales-tax .contentestimatedtax').show();
    });
    $('body').find('.pt_checkout .estimated-tax-tooltip').on('mouseout',function(){
    	$('.order-sales-tax .contentestimatedtax').hide();
    });
    
    $('input[name$="_billing_billingAddress_addressFields_Email"]').bind('keypress keyup blur', function() {
        $('input[name$="_billing_billingAddress_email_emailAddress"]').val($(this).val());
    });
    //TSED-1137
    $('input[name$="_billing_billingAddress_email_stdemailAddress"]').bind('keypress keyup blur', function() {
        $('input[name$="_billing_billingAddress_email_emailAddress"]').val($(this).val());
    });

    if ($main.find('.cartcoupon').length > 0) {
        $main.find('.coupon-form-checkout').hide();
        $main.find('.have-another-promo').text(Resources.CHECKOUT_ANOTHER_COUPON_CODE);
        $main.find('.have-another-promo').show();
    }
    if ($main.find('.cartcoupon').length >= 5) {
        $main.find('.cart-coupon-code').hide();
        $main.find('.have-another-promo').hide();
    }

    $main.on('click', '.similar-class', function() {
            //adodbe analytics for FIND A SIMILAR CLASS
            if (SitePreferences.isATMEnabled) {
                atmevents.findAnotherClass();
            }
            var url = $main.find('.redirectURLLink').val();
            page.redirect(url);
        }).on('click', '.similar-product', function() {
            var category = $main.find('.unavailableproduct').val();
            page.redirect(util.appendParamToURL(Urls.findSimilarProduct, 'cgid', category));

        }).on('change', 'input[name$="_billing_billingAddress_sameasstd"]', function() { //To handle same as student address
            if ($('input[name$="_billing_billingAddress_sameasstd"]').is(':checked')) {
                $('input[name$="billingAddress_addressFields_Email"]').val($('input[name$="_email_stdemailAddress"]').val());
                $('input[name$="billingAddress_addressFields_firstName"]').val($('input[name$="_singleshipping_stdFirstName"]').val());
                $('input[name$="billingAddress_addressFields_lastName"]').val($('input[name$="_singleshipping_stdLastName"]').val());
                $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_email_stdemailAddress"]').val());
                $('input[name$="billingAddress_addressFields_firstName"]').closest('div.form-row').addClass('hide');
                $('input[name$="billingAddress_addressFields_lastName"]').closest('div.form-row').addClass('hide');
                $('input[name$="billingAddress_addressFields_Email"]').closest('div.form-row').addClass('hide');
                $('input[name$="_billing_billingAddress_sameasstd"]').closest('.billing-information').find('.billing-addr-form.hide').removeClass('showing-row');
                util.formPopulated();
            } else if ($main.find('input[name="isAddressList"]').val()) {
                $('input[name$="billingAddress_addressFields_Email"]').val('');
                $('input[name$="_billing_billingAddress_email_emailAddress"]').val($(this).val());
                $('input[name$="billingAddress_addressFields_firstName"]').closest('div.form-row').removeClass('hide');
                $('input[name$="billingAddress_addressFields_lastName"]').closest('div.form-row').removeClass('hide');
                $('input[name$="billingAddress_addressFields_Email"]').closest('div.form-row').removeClass('hide');
                $('input[name$="_billing_billingAddress_sameasstd"]').closest('.billing-information').find('.billing-addr-form.hide').addClass('showing-row');
                util.formPopulated();
            } else {
                $('input[name$="billingAddress_addressFields_Email"]').val('');
                $('input[name$="billingAddress_addressFields_firstName"]').val('');
                $('input[name$="billingAddress_addressFields_lastName"]').val('');
                $('input[name$="_billing_billingAddress_email_emailAddress"]').val($(this).val());
                $('input[name$="billingAddress_addressFields_firstName"]').closest('div.form-row').removeClass('hide');
                $('input[name$="billingAddress_addressFields_lastName"]').closest('div.form-row').removeClass('hide');
                $('input[name$="billingAddress_addressFields_Email"]').closest('div.form-row').removeClass('hide');
                $('input[name$="_billing_billingAddress_sameasstd"]').closest('.billing-information').find('.billing-addr-form.hide').addClass('showing-row');
                util.formPopulated();
            }
        }).on('change', 'input[name$="_shippingAddress_useAsBillingAddress"]', function() { //To handle same as Billing address
            if ($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
                //TSED-296
                $('input[name$="_billingAddress_addressFields_firstName"]').val($('input[name$="_shippingAddress_addressFields_firstName"]').val());
                $('input[name$="_billingAddress_addressFields_lastName"]').val($('input[name$="_shippingAddress_addressFields_lastName"]').val());
                
                //TSED-1137
                $('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_emailAddress"]').val());
                
                if($('input[name$="billingAddress_addressFields_Email"]').val() != 'undefined' && $('input[name$="billingAddress_addressFields_Email"]').val() == '') {
                	$('input[name$="billingAddress_addressFields_Email"]').val($('input[name$="_billing_billingAddress_email_emailAddress"]').val());
                }
                //SMS - TSED-296
                $('.sameasshipping').siblings('.subscribedaction').hide();
                $('.sameasshipping').siblings('.subscribeaction').hide();
                //$('.sameasshipping').find('.checkout-message.phonetext').hide();
                $('.sameasbilling').siblings('.subscribeaction').show();

                $('input[name$="_billingAddress_addressFields_address1"]').val($('input[name$="_shippingAddress_addressFields_address1"]').val());
                $('input[name$="_billingAddress_addressFields_address2"]').val($('input[name$="_shippingAddress_addressFields_address2"]').val());
                $('input[name$="_billingAddress_addressFields_city"]').val($('input[name$="_shippingAddress_addressFields_city"]').val());
                $('input[name$="_billingAddress_addressFields_postal"]').val($('input[name$="_shippingAddress_addressFields_postal"]').val());
                $('input[name$="_billingAddress_addressFields_country"]').val($('input[name$="_shippingAddress_addressFields_country"]').val());
                $('select[name$="_billingAddress_addressFields_states_state"]').val($('select[name$="_shippingAddress_addressFields_states_state"]').val());
                $('input[name$="_billing_billingAddress_phone"]').val($('input[name$="_shippingAddress_addressFields_phone"]').val());
                
                util.updateselect();
                //TSED-296
                //$('input[name$="_shippingAddress_addressFields_phone"]').val($('input[name$="_billing_billingAddress_phone"]').val());
                //$('.sameasbilling').addClass('hide');
                $('.sameasshipping').addClass('hide');
                
                //TSED-296: Added this condition for mixed basket 
                if($('input[name$="_billing_billingAddress_email_stdemailAddress"]').length > 0 && $('input[name$="_billing_billingAddress_email_stdemailAddress"]').is(':visible')) {
                	$('.billing-email').addClass('hide');
                	$('body').find('.billing-sub-heading').html('');
                	$('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_stdemailAddress"]'));
                } else if ($('#dwfrm_singleshipping_addressList').length > 0 && $('select[name$="_singleshipping_addressList"]').is(':visible')) {
                	$('.billing-email').addClass('hide');
                	$('body').find('.billing-sub-heading').html('');
                } else {
                	//TSED-296: Email field hide and show for the guest and registered users when user clicks same as shipping address.
                	if($('.billing-information').find('.registereduser').val() === 'true') {
                		$('input[name$="_billing_billingAddress_email_emailAddress').parents('.field-wrapper').parents('.form-row.email-row').hide();
                	} else {
                		$('input[name$="_billing_billingAddress_email_emailAddress').parents('.field-wrapper').parents('.form-row.email-row').show();
                	}
                	
                	if($('input[name$="billingAddress_addressFields_Email"]').val() != 'undefined' && $('input[name$="billingAddress_addressFields_Email"]').val() != '') {
                    	$('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="billingAddress_addressFields_Email"]').val());
                    } else {
                    	//$('input[name$="_billing_billingAddress_email_emailAddress"]').val('');
                    }
                }
                /*if ($('select[name$="_billing_addressList"]').is(':visible')) {
                    $('select[name$="_singleshipping_addressList"]').val($('select[name$="_billing_addressList"]').val());
                    util.updateselect();
                }*/
                //TSED-296: The shipping address select dropdown should display shipping address when user clicks checkbox as selected. 
                if ($('select[name$="_singleshipping_addressList"]').is(':visible')) {
                    $('select[name$="_singleshipping_addressList"]').val($('select[name$="_singleshipping_addressList"]').val());
                    util.updateselect();
                }
              //TSED-845:Update hidden input field tax exemption id to empty value when user applied tax exemption for shipping address 
              // and clicked useSameAsBilling check box.
                $('input[name$="validID"]').val('');
                util.formPopulated();
                updateShippingMethodList();
                /*SMS - Subscription TSED-296 */
                if($('input[name$="_shippingAddress_addressFields_phone"]').val() !== undefined && $('input[name$="_shippingAddress_addressFields_phone"]').val() !== null ){
                    var ele = $('input[name$="_shippingAddress_addressFields_phone"]');
                    smsSubscription(ele);
                }

            } else {
                if ($('input[name$="_shippingAddress_addressFields_address1"]').is(':visible') == false) {
                    $('select[name$="_singleshipping_addressList"]').val('');
                }
                //SMS - TSED-296
                $('.sameasbilling').siblings('.subscribeaction').hide();
                $('.sameasbilling').siblings('.subscribedaction').hide();
                $('.sameasshipping').siblings('.subscribeaction').show();

                //TSED-131:For Register user a extra address drop down is coming on checkout page.
                //$('select[name$="_singleshipping_addressList"]').parents('.select-address.form-row').show();
                $('select[name$="dwfrm_billing_addressList"]').parents('.select-address .form-row').show();
                //TSED-131 code ends.
                //TSED-296: on change of same as shipping checkbox for registered users.  
                $('select[name$="dwfrm_billing_addressList"] option[value=""]').prop('selected', 'selected').trigger('change');
                
                //$('.sameasbilling').removeClass('hide');
                $('.sameasshipping').removeClass('hide');
                $('.billing-email').addClass('hide');
                 //TSED-296
                $('input[name$="_billingAddress_email_emailAddress"]').parents('.field-wrapper').parents('.form-row').hide();
                $('input[name$="_billingAddress_addressFields_firstName"]').val('');
                $('input[name$="_billingAddress_addressFields_lastName"]').val('');
                $('input[name$="billingAddress_addressFields_Email"]').val();
                $('input[name$="_billingAddress_addressFields_address1"]').val('');
                $('input[name$="_billingAddress_addressFields_address2"]').val('');
                $('input[name$="_billingAddress_addressFields_city"]').val('');
                $('input[name$="_billingAddress_addressFields_postal"]').val('');
                $('input[name$="_billingAddress_addressFields_country"]').val('');
                $('select[name$="_billingAddress_addressFields_states_state"]').val('');
                $('body').find('.billingphone').val('');
                if($('input[name$="_billing_billingAddress_email_stdemailAddress"]').length > 0 && $('input[name$="_billing_billingAddress_email_stdemailAddress"]').is(':visible')) {
                	$('input[name$="_billing_billingAddress_email_emailAddress"]').val($('input[name$="_billing_billingAddress_email_stdemailAddress"]').val());
                }
                //$('input[name$="billingAddress_addressFields_Email"]').val('');
                //TSED-845:Update hidden input field tax exemption id to empty value when useSameAsBilling check box is not checked.
                //TSED-920:Tax exempt is not applying for Register user with Group one pop up address validation.- Added condition to fix the issue.
                if($('select[name$="_billing_addressList"]').length == 0) {
                	$('input[name$="validID"]').val('');
                }
                util.updateselect();
                util.formPopulated();
                if ($('#dwfrm_singleshipping_addressList').length > 0 && $('select[name$="_singleshipping_addressList"]').is(':visible')) {
                	updateShippingMethodList();
                } else {
                	updateShippingMethodList(true);
                }
                
            }
        }).on('click', '.removeCouponCheckout', function(e) {
            e.preventDefault();
            var $current = $(this);
            var $content = $('#dialog-confirm');
            var couponRemoveDlg = dialog.create({
                options: {
                    width: "755px",
                    height: "auto",
                    dialogClass: 'remove-coupon-cart',
                    open: function() {
                        $('body').find('span.couponcode').html($current.data('couponcode'));
                        $('body').find('.couponuuid').val($current.data('uuid'));
                        $('body').off('click', '.keep-coupon').on('click', '.keep-coupon', function(e) {
                            dialog.close();
                        });
                        $('body').off('click', '.coupon-remove-checkout').on('click', '.coupon-remove-checkout', function(e) {
                            e.preventDefault();

                            if (SitePreferences.isATMEnabled) {
                                var code = $('.removeCouponCheckout').attr('data-couponcode');
                                atmevents.removeCoupon(code);
                            }
                            //GTM events for remove coupon
        	      		    if(SitePreferences.isGTMEnabled) {
        	      		    	var code = $('.removeCouponCheckout').attr('data-couponcode');
        	      		    	gtmevents.removeCoupon(code);
        	      		    }
                            $('body').find('#remove-coupon-' + $('.couponuuid').val()).trigger('click');
                            dialog.close();
                        });
                    }
                }
            });
            $(couponRemoveDlg).html($content.html());
            couponRemoveDlg.dialog("open");
        }).on('click', '.student-information  .std_edit', function(e) {
            e.preventDefault();
            if ($('.student_info').hasClass('hide')) {
                $('.auth-stud-info').addClass('hide');
                $('.student_info').removeClass('hide');
                $('input[name$="_billing_billingAddress_email_stdemailAddress"]').val($main.find('input.student-email').val());
                $('input[name$="_singleshipping_stdFirstName"]').val($main.find('input.student-firstname').val());
                $('input[name$="_singleshipping_stdLastName"]').val($main.find('input.student-lastname').val());
            }
            util.formPopulated();
        }).on('click', '.add-new-address', function(e) {
            e.preventDefault();
            //$(this).addClass('hide');
            //TSED-296: Commented below code to support only billing fields should be get empty
            //$('.shipping-section').find('.add-new-shipping').trigger('click');
            //$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', false).trigger('click');
            $('#commonaddressid').show();
            $('.billing-addr-form').removeClass('hide');
            $('input[name$="_billingAddress_addressFields_address1"]').val('');
            $('input[name$="_billingAddress_addressFields_address2"]').val('');
            $('input[name$="_billingAddress_addressFields_city"]').val('');
            $('input[name$="_billingAddress_addressFields_postal"]').val('');
            $('input[name$="_billingAddress_addressFields_country"]').val('');
            $('select[name$="_billingAddress_addressFields_states_state"]').val('');
            $('body').find('.billingphone').val('');
            $('select[name$="_billing_addressList"]').val('');
            $('input[name$="_billingAddress_addressFields_firstName"]').val('');
            $('input[name$="_billingAddress_addressFields_lastName"]').val('');
            $('input[name$="_billingAddress_addressFields_Email"]').val('');
            //$("#dwfrm_billing_addressList option:first").attr('selected','selected');
            updateShippingMethodList();
            util.updateselect();
            util.lableFocus();
        }).on('click', '.add-new-shipping', function(e) {
            e.preventDefault();
            //$(this).addClass('hide');
            //$('.shipping-section').find('.add-new-address').trigger('click');
            $('#commonaddressid').show();
            //$('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', false).trigger('change');
            //$('.sameasbilling, .sameasbilling .shipping-addr-form').removeClass('hide');
            $('.sameasshipping').removeClass('hide');
            $('input[name$="_shippingAddress_addressFields_address1"]').val('');
            $('input[name$="_shippingAddress_addressFields_address2"]').val('');
            $('input[name$="_shippingAddress_addressFields_city"]').val('');
            $('input[name$="_shippingAddress_addressFields_postal"]').val('');
            $('input[name$="_shippingAddress_addressFields_country"]').val('');
            $('select[name$="_shippingAddress_addressFields_states_state"]').val('');
            $('input[name$="_shippingAddress_addressFields_phone"]').val('');
            $('select[name$="_singleshipping_addressList"]').val('');
            $('input[name$="_shippingAddress_addressFields_firstName"]').val('');
            $('input[name$="_shippingAddress_addressFields_lastName"]').val('');
            $('input:radio[name$=_singleshipping_shippingAddress_addressType]').filter('[value=true]').prop('checked', true);
            $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
            $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
            //$('#commonaddressid').hide();
            $('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress').prop('checked', false).trigger('change');
            $('.shipping-section').find('.add-new-address').trigger('click');
            updateShippingMethodList();
            util.updateselect();
            util.lableFocus();
        })
        .on('click', '#is-PayPal, #is-PO', function() { //checking paypal chacked or not
            $('.payment-method-expanded').removeClass('payment-method-expanded');
            $(this).closest('.checkout-content').find('.credit-number .input-text').val('');
            $('select[name$="_billing_paymentMethods_creditCard_expiration_month"]').val('');
            $('select[name$="_billing_paymentMethods_creditCard_expiration_year"]').val('');
            $(this).closest('.checkout-content').find('.cvn .input-text').val('');
            util.updateselect();
            util.lableFocus();
        }).on('click', '#is-PayPal, #is-CREDIT_CARD', function() { //reset fields
            $('.PO-billing, .PO-shipping').addClass('hide');
            //TSED-296: Commenting it as per the new design
            //$('.add-new-address, .add-new-shipping').click();
            $('.billing-section .select-address, .add-new-address, .add-new-shipping, .address-type').show();
            if ($('#is-PO').is(':visible')) { // Changes For PHSSECOM-239
                $checkoutForm.find('input[name$="_shippingAddress_useAsBillingAddress"]').prop('checked', false).parents('.form-row').show();
                //TSED-1156: Phone number field issue fix for Address on PO Checkout Page.
                var phoneNumber = $('input[name$="_shippingAddress_addressFields_phone"]').val();
                var updatedPhone = '';
                if(phoneNumber != null && phoneNumber != '') {
                	if(phoneNumber.includes('+1|')) {
                		updatedPhone = phoneNumber.replace('+1|', '');
                		$checkoutForm.find('input[name$="_shippingAddress_addressFields_phone"]').val(updatedPhone);
                	}
                }
            } else {
            	//TSED-796-changes
                //$checkoutForm.find('input[name$="_shippingAddress_useAsBillingAddress"]').prop('checked', true).parents('.form-row').show();
            }

            $checkoutForm.find('input[name$="_billingAddress_addToAddressBook"], input[name$="_shippingAddress_addToAddressBook"]').prop('checked', true).val(true).parents('.form-row').show();
            //TSED-1156: Billing Address on PO Checkout Page Not Showing Enough Information
            if($('#dwfrm_singleshipping_addressList').length > 0) {
            	$('.sameasbilling').removeClass('hide');
            }
            if($('#dwfrm_billing_addressList').length > 0) {
            	$('.billing-addr-form').removeClass('hide');
            }
            // TSED-1057 PO BOX validation
            if ($('#is-PO').length > 0) {
                updateShippingMethodList();
            }

            //TSED-1156: Code ends
            $('#isPOorder').val(false);
            $('.place-order-div button').prop('disabled', false);
            
        }).on('click', '#is-PO', function() {
            $(this).closest('.checkout-content').find('.PO-content').addClass('payment-method-expanded');
            $('.PO-billing, .PO-shipping').removeClass('hide');
            //TSED-1156: Billing Address on PO Checkout Page Not Showing Enough Information
            $('.shipping-section').find('.select-address.form-row').removeClass('hide');
            //$('.shipping-section .select-address, .add-new-address, .add-new-shipping, .address-type').show();
            //TSED-1156: code ends
            $('.billing-addr-form, .sameasbilling').addClass('hide');
            $('.billing-section .select-address, .add-new-address, .add-new-shipping, .address-type').hide();
            $checkoutForm.find('input[name$="_shippingAddress_useAsBillingAddress"]').prop('checked', false).parents('.form-row').hide();
            $checkoutForm.find('input[name$="_billingAddress_addToAddressBook"], input[name$="_shippingAddress_addToAddressBook"]').prop('checked', false).val(false).parents('.form-row').hide();
            $('#isPOorder').val(true); //set hidden variable for submit js to know if PO order
            $('select#POList').trigger('change'); //simulate change to activate initial amountTotal check
            //Set Billing and Shipping address from PO
            setBillingfromPO();
            setShippingfromPO($('select#POList')[0].selectedIndex);
            updateShippingMethodList();
        }).on('click', '#is-CREDIT_CARD', function() {
            $('.payment-method-expanded').removeClass('payment-method-expanded');
            $(this).closest('.checkout-content').find('.expend-details').addClass('payment-method-expanded');
        }).on('click', '.remove-link-summary', function(e) {
            e.preventDefault();
            var itemid = $(this).closest('.mini-summary-data').find('.item-id').val();
            removeItem(itemid);
        }).on('change', 'select#POList', function() {
            //Check if Course in Cart
            if ($('.PO-class-error').hasClass('error')) {
                $(this).parent().addClass("PO-error");
                $('.place-order-div button').prop('disabled', true);
            } else {
                checkPOAmount();
            }

            //Set Shipping address from PO
            setShippingfromPO(this.selectedIndex);
            updateShippingMethodList();
        });
    util.toolgeSummary();


    util.apoToltip();
    // apanding the tooltip
    $('<span class="image-cvn">').insertAfter('.cvn .field-wrapper');
    $(".form-field-tooltip.cvn-toltip").appendTo(".form-row.cvn");
    $('.shipping-section .postal-row').addClass('tooltip-class');
    $('<div class="class-tool-close"></div>').appendTo('.tooltip-class .tooltip-content');
    $('.tooltip-class .tooltip-content').appendTo('.postal-row.tooltip-class');

    var $shippingMethodList = $('#shipping-method-list');
    if (!google_api_inactive) {
	    var inputBilling = jQuery("input[id$='billingAddress_addressFields_address1']")[0];
	    autocompleteBilling = new google.maps.places.Autocomplete(inputBilling);
	    if (!$shippingMethodList || $shippingMethodList.length === 0) {
	        autocompleteBilling.setComponentRestrictions({
	            'country': 'us'
	        });
	    }
	    $checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]').attr("placeholder", "");
	    autocompleteBilling.addListener('place_changed', fillAddress);
	
	    var inputShipping = jQuery("input[id$='shippingAddress_addressFields_address1']")[0];
	    autocompleteShipping = new google.maps.places.Autocomplete(inputShipping);
	    if (!$shippingMethodList || $shippingMethodList.length === 0) {
	        autocompleteShipping.setComponentRestrictions({
	            'country': 'us'
	        });
	    }
	    $checkoutForm.find('input[name$="_shippingAddress_addressFields_address1"]').attr("placeholder", "");
	    autocompleteShipping.addListener('place_changed', fillShippingAddress);
    }
    
    if ($checkoutForm.find('input[name$="_billing_paymentMethods_selectedPaymentMethodID"]:checked').val() == "PayPal") {
        $('.payment-method.expend-details').removeClass('payment-method-expanded');
    }
    $('input[name*="_creditCard_number"]').focusout(function() {
        var ccnum = $(this).val();
        var checknum = ccnum.replace(/\s/g, "");
        var cctype = detectCardType(checknum);
        if (cctype != '' && cctype != 'undefined' && cctype != null) {
            $('select[name$="_type"]').val(cctype);
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        } else if (ccnum.length > 15) {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
            $('.card-number').find('.field-wrapper').append('<span class="invalidcard error">' + Resources.VALIDATE_CARDTYPE + '</span>');
        } else {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        }
    });
    
    //TSED-386:Incorporate Tax Exemption ID Field on Checkout - Calling Vertex call once user key in the value
    
    $('input[name*="_taxExemptionId"]').focusout(function() {
    	var exemptionId = $(this).val();
    	if(exemptionId != '' && exemptionId != null){
    		var $form = $('.checkout-shipping');
            if (isBillingAddressValid($form) || isShippingFormValid($form)) {
            	updateSummary(true,false,exemptionId.toUpperCase());
            }
    	}
    });

    function detectCardType(number) {
        var re = {
            Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            Master: /^((5[1-5]|2[2-7])[0-9]{14})$/,
            Amex: /^3[47][0-9]{13}$/,
            Discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
            JCB: /^(?:2131|1800|35\d{3})\d{11}$/
        }

        for (var key in re) {
            if (re[key].test(number)) {
                return key
            }
        }
    }


    //Adobe analytics
    $('.addtocalendar a').on("click", function(e) {
        if (SitePreferences.isATMEnabled) {
            atmevents.addToCalendar();
        }
        if(SitePreferences.isGTMEnabled) {
        	gtmevents.addToCalendar();
        }
    });
    
    //SMS subscription status call - PHSSECOM-2028
    $('.pt_checkout .billing-section .phone, .pt_checkout .shipping-section .phone').on('blur',function(e){
        e.preventDefault();
        var $this = $(this);
         /* SMS - Subscription TSED-296 */
        smsSubscription($this);
    });
    $('.select-address #dwfrm_singleshipping_addressList').on('change',function(e){
        var $this = $('.shipping-section .select-address').siblings('.sameasbilling').find('.phone-row').find('input[name$="_shippingAddress_addressFields_phone"]');
        smsSubscription($this);
    });
     /* SMS - Subscription TSED-296 */
    function smsSubscription(elem){
        var $this = elem;
        if(!(($this).hasClass('error'))){
            if($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked') || $this.hasClass('billingphone')){
                var $form = $('.checkout-shipping');
                var $code, $phone;
                if($('.shipping-section').find('.subscribeaction').is(':visible') || $('.shipping-section').find('.subscribedaction').is(':visible')) {
                    $code = $('.shipping-section').find('#dwfrm_singleshipping_shippingAddress_addressFields_countryCodes_phoneCountryCode').val();
                    $phone = $('.shipping-section').find('.phone').val();
                }
                if($('.billing-section').find('.subscribeaction').is(':visible') || $('.billing-section').find('.subscribedaction').is(':visible')) {
                    $code = $('.billing-section').find('#dwfrm_billing_billingAddress_countryCodes_phoneCountryCode').val();
                    $phone = $('.billing-section').find('.billingphone').val();
                }
                var params = {
                        billingCountryCode : $code,
                        billingPhone : $phone
                }
                if(params.billingPhone != null && params.billingPhone != ' ' && params.billingPhone != undefined){
                    var url = Urls.smsSubscriptionStatus;
                    url = util.appendParamsToUrl(url, params);
                    $.ajax({
                        url: url,
                        success: function(data) {
                            var subscriptionStatus = data.status;
                            if(subscriptionStatus == 'active'){
                                $this.parents().parents().parents().siblings('.subscribeaction').hide();
                                $this.parents().parents().parents().siblings('.subscribedaction').show();
                            } else{
                                $this.parents().parents().parents().siblings('.subscribeaction').show();
                                $this.parents().parents().parents().siblings('.subscribedaction').hide();
                            }
                        }
                    });
        	    }
            }
        }
    }
  //SMS subscription status call - PHSSECOM-2028 - End
    
    function setShippingfromPO(value) {
        if ($('#dwfrm_singleshipping_addressList').length) {
        	var POData = $('.PO-content').find('input[name$="poData"]').data('podata');
        	var POnum = POData[value].PONumber.toString();
            $('#dwfrm_singleshipping_addressList').val(POnum);
            var data = $('#dwfrm_singleshipping_addressList option[value="' + POnum + '"]').data('address');

            //Fill in display view
            $('.PO-shipping .fName').html(data.firstName);
            $('.PO-shipping .lName').html(data.lastName);
            $('.PO-shipping .add1').html(data.address1);
            $('.PO-shipping .add2').html(data.address2);
            $('.PO-shipping .pCode').html(data.postalCode);
            $('.PO-shipping .city').html(data.city);
            $('.PO-shipping .sCode').html(data.stateCode);
            $('.PO-shipping .cCode').html(data.countryCode);
            $('.PO-shipping .phone').html(data.phone);

            //Fill in hidden form with data
            $('.sameasbilling input[name$="_shippingAddress_addressFields_firstName"]').val(data.firstName);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_lastName"]').val(data.lastName);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_address1"]').val(data.address1);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_address2"]').val(data.address2);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_city"]').val(data.city);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_postal"]').val(data.postalCode);
            $('.sameasbilling select[name$="_shippingAddress_addressFields_country"]').val(data.countryCode);
            $('.sameasbilling select[name$="_shippingAddress_addressFields_states_state"]').val(data.stateCode);
            $('.sameasbilling input[name$="_shippingAddress_addressFields_phone"]').val(data.phone);
        }
    }

    function setBillingfromPO() {
        $('#dwfrm_billing_addressList').val("POBillTo");
        var selected = $('#dwfrm_billing_addressList').val();
        var data = $('#dwfrm_billing_addressList option[value="' + selected + '"]').data('address');
        $('.PO-billing .fName').html(data.firstName);
        $('.PO-billing .lName').html(data.lastName);
        $('.PO-billing .add1').html(data.address1);
        $('.PO-billing .add2').html(data.address2);
        $('.PO-billing .pCode').html(data.postalCode);
        $('.PO-billing .city').html(data.city);
        $('.PO-billing .sCode').html(data.stateCode);
        $('.PO-billing .cCode').html(data.countryCode);
        $('.PO-billing .phone').html(data.phone);

        //Fill in hidden form with data
        $('.billing-addr-form input[name$="_billingAddress_addressFields_firstName"]').val(data.firstName);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_lastName"]').val(data.lastName);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_address1"]').val(data.address1);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_address2"]').val(data.address2);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_city"]').val(data.city);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_postal"]').val(data.postalCode);
        $('.billing-addr-form select[name$="_billingAddress_addressFields_country"]').val(data.countryCode);
        $('.billing-addr-form select[name$="_billingAddress_addressFields_states_state"]').val(data.stateCode);
        $('.billing-addr-form input[name$="_billingAddress_addressFields_phone"]').val(data.phone);
    }
    
     $('.place-order-div .original-placesubmit').on("click", function(e) {
    	$('#primary input[name$="billing_billingAddress_addToEmailList"]').val($('#secondary input[name$="billing_billingAddress_addToEmailList"]').is(':checked'));
        $('#dwfrm_singleshipping_shippingAddress').valid();
        var formattrs = [];
        var $inputfields = $(this).closest('.checkout-shipping').find('.billing-section input,.student-information input,.payment-method-expanded input');
        if (!$('input[name$="_singleshipping_shippingAddress_useAsBillingAddress"]').is(':checked')) {
            var $inputfields = $(this).closest('.checkout-shipping ').find('.shipping-section input,.billing-section input,.student-information input,.payment-method-expanded input');
        }
        $($inputfields).each(function() {
            var formerrorField = $(this).closest('.form-row').find('.error').text();
            if ($(this).closest('.form-row').find('.form-caption.error-message').text() != '') {
                formerrorField = $(this).closest('.form-row').find('.form-caption.error-message').text();
            }
            if (formerrorField != '') {
                var formattr = {
                    formerrorMsg: formerrorField,
                    formFieldName: $(this).closest('.form-row').find('label span:last').text(),
                    formerrorName: "Checkout"
                };
                formattrs.push(formattr);
            }
        });
        if (formattrs.length > 0) {
            atmevents.formErrorMyAccounts(formattrs);
            // GTM event for form errors 
            if (SitePreferences.isGTMEnabled) {
            	gtmevents.formErrorMyAccounts(formattrs);
            }
        }

        if ($('input[name$="billing_billingAddress_addToEmailList"]').is(':checked')) {
            if (SitePreferences.isATMEnabled && $('.checkout-shipping').valid()) {
                atmevents.EmailOptinCheckout();
            }
            // GTM event for email opt in at checkout 
            if (SitePreferences.isGTMEnabled && $('.checkout-shipping').valid()) {
            	gtmevents.newsLetterSignupCheckout();
            }
        }
        if (!$('.checkout-shipping').valid()) {
            $('.checkout-shipping .error:Visible').eq(0).focus();
            $('.checkout-shipping .error:Visible').eq(0).focus();
            return false;
        }
        //TSED-1146: Checking for PO Error in the checkout page for PO customers.
        if($('.checkout-shipping').find('.payment-method.PO-content #POList').length > 0) {
        	if($('.checkout-shipping .PO-amount-error.error').length > 0 && $('.checkout-shipping .PO-amount-error.error:Visible').length > 0) {
        		$('.checkout-shipping .PO-amount-error.error:Visible').eq(0).focus();
            	$('#main').find('.loader').css("display: none");
            	return false;
        	} else if($('.checkout-shipping .PO-class-error.error').length > 0 && $('.checkout-shipping .PO-class-error.error:Visible').length > 0) {
                $('.checkout-shipping .PO-class-error.error:Visible').eq(0).focus();	
                $('#primary .checkout-content').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                $('#secondary .checkout-ordersummary').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                return false;
            } 
        }
        /*$('.place-order-div .checkoutbutton').on("click", function() {
            if ($('.checkout-shipping').valid()) {
                $('.place-order-div .checkoutbutton').closest('.checkout-shipping').find('.checkoutbutton').addClass('disable-mode-btn');
                $('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
            }
        });*/
    });

    // TSED-762: 
    $('.place-order-div .checkoutbutton, .checkoutbutton.minisummarycheckoutButton').on("click", function(e) {
    	e.preventDefault();
    	// Add reCaptcha logic
    	if (SitePreferences.CAPTCHA_ENABLED) {
    		var $form = $('.checkout-shipping');
            var formActionURL = $form.attr('action');
            var data = $form.serialize();
            var $recaptcha = $form.find('[name="g-recaptcha-response"]');
            var siteKey = $recaptcha.data('sitekey');
            var action = $form.find('[name="g-recaptcha-action"]').val();
            grecaptcha.ready(function() {
            	grecaptcha.execute(siteKey, {action: action}).then(function (token) {
                	formActionURL = util.appendParamsToUrl(formActionURL, {token: token});
                	$('.primary-content form.checkout-shipping').find('#googleToken').val(token);
                	$('#primary .checkout-content').find('.place-order-div .original-placesubmit').click();
                	if ($('.checkout-shipping').valid()) {
                		//TSED-1146: Checking for PO Error in the checkout page for PO customers. 
                		if($('.checkout-shipping').find('.payment-method.PO-content #POList').length > 0) {
                			if($('.checkout-shipping .PO-amount-error.error').length > 0 && $('.checkout-shipping .PO-amount-error.error:Visible').length > 0) {
                				$('.checkout-shipping .PO-amount-error.error:Visible').eq(0).focus();
                    			$('#primary .checkout-content').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                    			$('#secondary .checkout-ordersummary').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                            	return false;
                			} else if($('.checkout-shipping .PO-class-error.error').length > 0 && $('.checkout-shipping .PO-class-error.error:Visible').length > 0) {
                                $('.checkout-shipping .PO-class-error.error:Visible').eq(0).focus();	
                                $('#primary .checkout-content').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                                $('#secondary .checkout-ordersummary').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                                return false;
                            } else {
                				$('#primary .checkout-content').find('.place-order-div .original-placesubmit').addClass('disable-mode-btn');
                                $('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
                			}
                		} else {
                			$('#primary .checkout-content').find('.place-order-div .original-placesubmit').addClass('disable-mode-btn');
                            $('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
                		}
                    }
                });
            });    
        } else {
        	$('#primary .checkout-content').find('.place-order-div .original-placesubmit').click();
        	if ($('.checkout-shipping').valid()) {
        		//TSED-1146: Checking for PO Error in the checkout page for PO customers.
        		if($('.checkout-shipping').find('.payment-method.PO-content #POList').length > 0) {
        			if($('.checkout-shipping .PO-amount-error.error').length > 0 && $('.checkout-shipping .PO-amount-error.error:Visible').length > 0) {
        				$('.checkout-shipping .PO-amount-error.error:Visible').eq(0).focus();
            			$('#primary .checkout-content').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
            			$('#secondary .checkout-ordersummary').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                    	return false;
        			}  else if($('.checkout-shipping .PO-class-error.error').length > 0 && $('.checkout-shipping .PO-class-error.error:Visible').length > 0) {
                        $('.checkout-shipping .PO-class-error.error:Visible').eq(0).focus();	
                        $('#primary .checkout-content').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                        $('#secondary .checkout-ordersummary').find('.place-order-div .checkoutbutton').addClass('disable-mode-btn');
                        return false;
                    } else {
        				$('#primary .checkout-content').find('.place-order-div .original-placesubmit').addClass('disable-mode-btn');
                        $('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
        			}
        		} else {
        			$('#primary .checkout-content').find('.place-order-div .original-placesubmit').addClass('disable-mode-btn');
            		$('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
        		}
        	}
        }
    	e.stopImmediatePropagation();
    });
    
    //TSED-7 Mini Summary checkout button - If this button clicks. It calls below Checkout button
    /*$('.place-order-div .checkoutbutton.minisummarycheckoutButton').on("click", function(e) {
        $('#primary .checkout-content').find('.checkoutbutton').click();
    });*/
    
    //@FIX-PHSSECOM-249
    // Utility method to prepare billing information as a parameter 
    //to store the values in session
    function prepareParams($form) {
        var params = {};
        var url = Urls.saveUserAddressInSession;
        if ($form != null && $form != undefined && $form.length > 0) {
            var stdMail = $form.find('input[name$="_billing_billingAddress_email_stdemailAddress"]').val();
            var stdFirstName = $form.find('input[name$="_singleshipping_stdFirstName"]').val();
            var stdLastName = $form.find('input[name$="_singleshipping_stdLastName"]').val();
            var usStates = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
            var otherStates = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            //var Email = $form.find('input[name$="_billingAddress_addressFields_Email"]').val();
            
            var emailAddress = $form.find('input[name$="_billing_billingAddress_email_emailAddress"]').val();
            if($form.find('input[name$="_billing_billingAddress_email_stdemailAddress"]').length > 0 && $form.find('input[name$="_billing_billingAddress_email_stdemailAddress"]').is(':visible')) {
            	emailAddress = $form.find('input[name$="_billing_billingAddress_email_stdemailAddress"]').val();
            }

             //TSED-767:PCI Issue: Card Card Numbers Not Masked
             var creditCardNumber = $form.find('input[name*="_billing_paymentMethods_creditCard_number"]').val();
             /* Start - TSED-759 - On changing the master card mask is creating issue 
              so raw card number is saving in local storage and displaying in input box */
              window.localStorage.setItem('card_number', creditCardNumber);
              // End - TSED-759
             var maskedCCNumber = creditCardNumber;
             //var maskedCCNumber = creditCardNumber.replace(/.(?=.{4})/g, "*");
            params = {
                checkout: 'true',
                firstName: $form.find('input[name$="_billingAddress_addressFields_firstName"]').val(),
                lastName: $form.find('input[name$="_billingAddress_addressFields_lastName"]').val(),
                email: emailAddress != undefined && emailAddress != null ? emailAddress : '',
                Email: $form.find('input[name$="_billingAddress_addressFields_Email"]').val(emailAddress),
                address1: $form.find('input[name$="_billingAddress_addressFields_address1"]').val(),
                address2: $form.find('input[name$="_billingAddress_addressFields_address2"]').val(),
                countryCode: $form.find('select[id$="_billingAddress_addressFields_country"]').val(),
                stateCode: $form.find('select[id$="_billingAddress_addressFields_states_state"]').val(),
                postalCode: $form.find('input[name$="_billingAddress_addressFields_postal"]').val(),
                city: $form.find('input[name$="_billingAddress_addressFields_city"]').val(),
                phone: $form.find('input[name*="_billing_billingAddress_phone"]').val(),
                ccNumber: maskedCCNumber.replace(/.(?=.{4})/g, "*"),
                //ccNumber: $form.find('input[name*="_billing_paymentMethods_creditCard_number"]').val(),
                expMonth: $form.find('select[id$="_billing_paymentMethods_creditCard_expiration_month"]').val(),
                expYear: $form.find('select[id$="_billing_paymentMethods_creditCard_expiration_year"]').val(),
                stdMail: stdMail != undefined && stdMail != null ? stdMail : '',
                stdFirstName: stdFirstName != undefined && stdFirstName != null ? stdFirstName : '',
                stdLastName: stdLastName != undefined && stdLastName != null ? stdLastName : '',
                occupation: $form.find('select[id$="_singleshipping_shippingAddress_occupation"]').val(),
                shippingAsBilling: $('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked'),
                shipFirstName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_firstName"]').val(),
                shipLastName: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_lastName"]').val(),
                shipAddress1: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val(),
                shipAddress2: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val(),
                shipCountryCode: $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val(),
                shipStateCode: usStates != undefined && usStates != null ? usStates : otherStates,
                shipPostalCode: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val(),
                shipCity: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val(),
                shipPhone: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_phone"]').val(),
                companyname: $form.find('input[name$="_singleshipping_shippingAddress_companyname"]').val()
            };

        }
        return params;

    }
    //@FIX-PHSSECOM-531
    //validates the profile addresses
    function isValidAddress($address) {
        var $isValid = false;
        if ($address != undefined && $address.firstName != null && $address.firstName != undefined && $address.lastName != null && $address.lastName != undefined && $address.address1 != null && $address.address1 != undefined && $address.stateCode != undefined && $address.stateCode != null && $address.city != null && $address.city != undefined && $address.countryCode != null && $address.countryCode != undefined && $address.postalCode != null && $address.postalCode != undefined) {
            $isValid = true;
        }
        return $isValid;
    }

    //@FIX-PHSSECOM-408
    //trigger the same option of session address value
    if ($('input[name="billtoaddress"]').length > 0 && $('input[name="billtoaddress"]').data('address') != undefined) {
        var selected = '';
        var sessionBilltoAddress = $('input[name="billtoaddress"]').data('address');
        $("#dwfrm_billing_addressList option").each(function() {
            var optionAddress = $(this).data('address');
            var isAdressValid = isValidAddress(optionAddress);
            if (isAdressValid == true && optionAddress != '' && optionAddress != undefined && sessionBilltoAddress.firstName.trim() == optionAddress.firstName.trim() &&
                sessionBilltoAddress.lastName.trim() == optionAddress.lastName.trim() &&
                sessionBilltoAddress.address1.trim() == optionAddress.address1.trim() &&
                sessionBilltoAddress.states.state.trim() == optionAddress.stateCode.trim() &&
                sessionBilltoAddress.city.trim() == optionAddress.city.trim() &&
                sessionBilltoAddress.country.trim() == optionAddress.countryCode.toUpperCase().trim() &&
                sessionBilltoAddress.postalCode.trim() == optionAddress.postalCode.trim()) {
                $('#addShipValue').val('false');
                $('select[name*="_billing_addressList"]').val($(this).val()).trigger('change');
                $('#addShipValue').val('true');
            }
        });
    }

    //Added For PHSSECOM-408
    if ($('input[name="shiptoaddress"]').length > 0 && $('input[name="shiptoaddress"]').data('address') != undefined) {
        var selected = '';
        var sessionShiptoAddress = $('input[name="shiptoaddress"]').data('address');
        $("#dwfrm_singleshipping_addressList option").each(function() {
            var optionAddress = $(this).data('address');
            var isAdressValid = isValidAddress(optionAddress);
            if (isAdressValid == true && optionAddress != '' && optionAddress != undefined && sessionShiptoAddress.shipFirstName.trim() == optionAddress.firstName.trim() &&
                sessionShiptoAddress.shipLastName.trim() == optionAddress.lastName.trim() &&
                sessionShiptoAddress.shipAddress1.trim() == optionAddress.address1.trim() &&
                sessionShiptoAddress.shipStates.shipState.trim() == optionAddress.stateCode.trim() &&
                sessionShiptoAddress.shipCity.trim() == optionAddress.city.trim() &&
                sessionShiptoAddress.shipCountryCode.trim() == optionAddress.countryCode.toUpperCase().trim() &&
                sessionShiptoAddress.shipPostalCode.trim() == optionAddress.postalCode.trim()) {
                $('select[name*="_singleshipping_addressList"]').val($(this).val()).trigger('change');
            }
        });
    }



    //@FIX-PHSSECOM-249
    //Checks if billing form is valid
    function isBillingFormValid($form) {
        var firstName = $form.find('input[name$="_billingAddress_addressFields_firstName"]').val();
        var lastName = $form.find('input[name$="_billingAddress_addressFields_lastName"]').val();
        //var email = $form.find('input[name$="_billingAddress_addressFields_Email"]').val();
        var address1 = $form.find('input[name$="_billingAddress_addressFields_address1"]').val();
        var address2 = $form.find('input[name$="_billingAddress_addressFields_address2"]').val();
        var countryCode = $form.find('select[id$="_billingAddress_addressFields_country"]').val();
        var stateCode = $form.find('select[id$="_billingAddress_addressFields_states_state"]').val();
        var postalCode = $form.find('input[name$="_billingAddress_addressFields_postal"]').val();
        var city = $form.find('input[name$="_billingAddress_addressFields_city"]').val();
        //var phone = $form.find('input[name*="_billing_billingAddress_phone"]').val();
        if (firstName != '' && firstName != undefined && lastName != '' && lastName != undefined && address1 != '' && address1 != undefined && countryCode != '' && countryCode != undefined && stateCode != '' && stateCode != undefined && postalCode != '' && postalCode != undefined && city != '' && city != undefined) {
            return true;
        }
        return false;
    }

    //@FIX-PHSSECOM-249
    //Checks if credit card fields are  valid
    function isCCFormValid($form) {
        var ccNumber = $form.find('input[name*="_billing_paymentMethods_creditCard_number"]').val();
        var expMonth = $form.find('select[id$="_billing_paymentMethods_creditCard_expiration_month"]').val();
        var expYear = $form.find('select[id$="_billing_paymentMethods_creditCard_expiration_year"]').val();
        if (ccNumber != '' && ccNumber != undefined && expMonth != '' && expMonth != undefined && expYear != '' && expYear != undefined) {
            return true;
        }
        return false;
    }

    //@FIX-PHSSECOM-249
    //Checks if Studenet Information fields are  valid
    function isStudInfoFormValid($form) {
        var stdMail = $form.find('input[name$="_billing_billingAddress_email_stdemailAddress"]').val();
        var stdFirstName = $form.find('input[name$="_singleshipping_stdFirstName"]').val();
        var stdLastName = $form.find('input[name$="_singleshipping_stdLastName"]').val();
        if (stdMail != '' && stdMail != undefined && stdFirstName != '' && stdFirstName != undefined && stdLastName != '' && stdLastName != undefined) {
            return true;
        }
        return false;
    }

    $('.returning-customer').find('.imtermediate-signin').on('click', function(e) {
        e.preventDefault();
        var $form = $('.checkout-shipping');
        //Added For PHSSECOM-408
        if (isBillingFormValid($form) || isCCFormValid($form) || isStudInfoFormValid($form) || isShippingFormValid($form)) {
            var url = Urls.saveUserAddressInSession;
            var params = prepareParams($form);
            url = util.appendParamsToUrl(url, params);
            $.ajax({
                url: url,
                success: function(data) {
                    page.redirect(util.appendParamToURL(Urls.cancelEditProfile, 'checkout', 'true'));
                }
            });
        } else {
            page.redirect(util.appendParamToURL(Urls.cancelEditProfile, 'checkout', 'true'));
        }
    });

    //@FIX-PHSSECOM-249
    //populates the user entered credit card info stored in the session when page loads
    // TSED-464 - Checkout Form is Displaying CC information after Page Refresh.
    /*if ($('.payment-information').find('input[name="ccNumber"]').length > 0 && $('.payment-information').find('input[name="ccNumber"]').val() != null && $('.payment-information').find('input[name="ccNumber"]').val() != undefined) {
        $('.payment-information').find('input[name*="_creditCard_number"]').val($('.payment-information').find('input[name="ccNumber"]').val()).trigger('change');
    }
    if ($('.payment-information').find('input[name="ccExpMonth"]').length > 0 && $('.payment-information').find('input[name="ccExpMonth"]').val() != null && $('.payment-information').find('input[name="ccExpMonth"]').val() != undefined) {
        $('.payment-information').find('[name$="_month"]').val($('.payment-information').find('input[name="ccExpMonth"]').val()).trigger('change');
    }
    if ($('.payment-information').find('input[name="ccExpYear"]').length > 0 && $('.payment-information').find('input[name="ccExpYear"]').val() != null && $('.payment-information').find('input[name="ccExpYear"]').val() != undefined) {
        $('.payment-information').find('[name$="_year"]').val($('.payment-information').find('input[name="ccExpYear"]').val()).trigger('change');
    }ends -TSED-464*/
      // TSED-464 - Checkout Form is Displaying CC information after Page Refresh.
      var url = window.location.href.split('/');
      if ($('.payment-information').find('input[name="ccNumber"]').length > 0 && $('.payment-information').find('input[name="ccNumber"]').val() != null && $('.payment-information').find('input[name="ccNumber"]').val() != undefined && (url.indexOf('checkout-confirmation') !== -1)) {
          
          var unMaskedCC =  window.localStorage.getItem('card_number');
          $('.payment-information').find('input[name*="_creditCard_number"]').val(unMaskedCC).trigger('change');
          //$('.payment-information').find('input[name="ccNumber"]').val($('.payment-information').find('input[name="ccNumber"]').val()).trigger('change');
          //$('.payment-information').find('input[name*="_creditCard_number"]').val($('.payment-information').find('input[name="ccNumber"]').val()).trigger('change');
        } else {
          window.localStorage.removeItem('card_number');  
          $('.payment-information').find('input[name*="_creditCard_number"]').val('');
      }
      if ($('.payment-information').find('input[name="ccExpMonth"]').length > 0 && $('.payment-information').find('input[name="ccExpMonth"]').val() != null && $('.payment-information').find('input[name="ccExpMonth"]').val() != undefined && (url.indexOf('checkout-confirmation') !== -1)) {
          $('.payment-information').find('[name$="_month"]').val($('.payment-information').find('input[name="ccExpMonth"]').val()).trigger('change');
      } else {
          $('.payment-information').find('[name$="_month"]').val('');
      }
      if ($('.payment-information').find('input[name="ccExpYear"]').length > 0 && $('.payment-information').find('input[name="ccExpYear"]').val() != null && $('.payment-information').find('input[name="ccExpYear"]').val() != undefined && (url.indexOf('checkout-confirmation') !== -1)) {
          $('.payment-information').find('[name$="_year"]').val($('.payment-information').find('input[name="ccExpYear"]').val()).trigger('change');
      } else {
          $('.payment-information').find('[name$="_year"]').val('');
      }
      // ends -TSED-464 
    
    if ($('#dwfrm_singleshipping_shippingAddress_occupation').length > 0) {
        if ($('body').find('input[name="occupation"]').val() != '' && $('body').find('input[name="occupation"]').val() != 'undefined' && $('body').find('input[name="occupation"]').val() != null) {
            $('#dwfrm_singleshipping_shippingAddress_occupation').val($('body').find('input[name="occupation"]').val()).trigger('change');
        }
    }
    //Adding For CC Validation @FIX-PHSSECOM-408
    if ($('input[name*="_creditCard_number"]').val() != '' && $('input[name*="_creditCard_number"]').val() != null && $('input[name*="_creditCard_number"]').val() != undefined) {
        var ccnum = $('input[name*="_creditCard_number"]').val();
        var checknum = ccnum.replace(/\s/g, "");
        var cctype = detectCardType(checknum);
        if (cctype != '' && cctype != 'undefined' && cctype != null) {
            $('select[name$="_type"]').val(cctype);
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        } else if (ccnum.length > 15) {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
            $('.card-number').find('.field-wrapper').append('<span class="invalidcard error">' + Resources.VALIDATE_CARDTYPE + '</span>');
        } else {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        }
    }

    //@FIX- TSED-247 - Not allowing special characters and letters For zipcode
    $(document).on('input paste', '[name$="_addressFields_postal"]', function(e) {
        var value = $(this).val();
        $(this).val(value.replace(/[\.\,\'\~\`\!\@\#\$\%\^\&\*\(\)\_\+\=\[\]\{\}\\\|\;\:\"\<\>\?\-\/\u00D7\u00F7a-zA-Z]/g, ""));
        if(value.length > 5){            
            $(this).val(value.slice(0,5));
        }
    });

    $(document).on('input paste', '.input-text.phone', function(e) {
        var value = $(this).val();
        $(this).val(value.slice(0,10));
    });

    //@FIX-PHSSECOM-249
    //On focus out of each fileds in billing, the url hit to save the values in session 
    $('select[name*="_singleshipping_shippingAddress"], select[name*="_billing_addressList"], select[name*="_singleshipping_addressList"], input[name*="_singleshipping_shippingAddress"], select[name*="_billing_billingAddress"], input[name*="_billing_billingAddress"], select[name*="_paymentMethods_creditCard"], input[name*="_paymentMethods_creditCard"], input[name*="_singleshipping"], select[name*="_singleshipping_shippingAddress_occupation"], input[name$="_singleshipping_shippingAddress_addressType"]').on('change, focusout', function(e) {
        var $form = $('.checkout-shipping');
        // @FIX TSED-712 for register user in the addresslist drop down after selecting address if zipcode is more than 5 digits slicing and displaying in zipcode input box
        if($('.billing-addr-form').find('[name$="_addressFields_postal"]').length && $('.billing-addr-form').find('[name$="_addressFields_postal"]').val().length > 5){            
            var postalVal = $('.billing-addr-form').find('[name$="_addressFields_postal"]').val();
            $('.billing-addr-form').find('[name$="_addressFields_postal"]').val(postalVal.slice(0,5));
        }
        if($('.shipping-addr-form').find('[name$="_addressFields_postal"]').length && $('.shipping-addr-form').find('[name$="_addressFields_postal"]').val().length > 5){           
            var postalVal = $('.shipping-addr-form').find('[name$="_addressFields_postal"]').val();
            $('.shipping-addr-form').find('[name$="_addressFields_postal"]').val(postalVal.slice(0,5));
        }
        //TSED-296 - This code is needed for even Group One pop address. The background values should be there in the field if it entered before.
        if (isBillingFormValid($form) || isCCFormValid($form) || isStudInfoFormValid($form) || isShippingAddressValid($form)) {
            e.preventDefault();
            var params = prepareParams($form);
            var url = Urls.saveUserAddressInSession;
            url = util.appendParamsToUrl(url, params);
            $.ajax({
                url: url
            });
        }
        
     // TSED-613 - For Register user phone number field is coming null, when user enters wrong address manually.
        if ($('body').find('input[name*="_singleshipping_shippingAddress_addressFields_phone"]').val() === 'null' || $('body').find('input[name*="_singleshipping_shippingAddress_addressFields_phone"]').val() === null) {
            $('body').find('input[name*="_singleshipping_shippingAddress_addressFields_phone"]').val('');
        }
		
        if ($('body').find('.billingphone').val() === 'null' || $('body').find('.billingphone').val() === null) {
            $('body').find('.billingphone').val('');
        }
     // TSED-613
        
    });

    //TSED-613 For Register user phone number field is coming null, when user enters wrong address manually.
    if ($('body').find('.billingphone').val() === 'null' || $('body').find('.billingphone').val() === null) {
        $('body').find('.billingphone').val('');
    } //TSED-613
    
 // TSED-676 - Automatically update year dropdown for credit card expiration
    if($('.payment-information').find('[name$="_year"]').val() != ''){
    	$('.payment-information').find('[name$="_year"]').val($('.payment-information').find('input[name="ccExpYear"]').val()).trigger('change');
    } else{
    	$('.checkout-shipping.address select.input-select[name*="_paymentMethods_creditCard_expiration_year"] option[value=""]').text('EXP.'+' '+'Year').attr('selected', true);
	    $('.checkout-shipping.address select.input-select[name*="_paymentMethods_creditCard_expiration_year"] option[value=""]').attr('label', 'EXP.'+' '+'Year');
	    $('.checkout-shipping.address select.input-select[name*="_paymentMethods_creditCard_expiration_year"]').siblings('.selection-list').find('li.hide.selected').attr('label', 'EXP.'+' '+'Year').text('EXP.'+' '+'Year');
    }
    // TSED-676

    //SMS - TSED-296
    $('.sameasbilling').siblings('.subscribeaction').hide();
    $('.sameasbilling').siblings('.subscribedaction').hide();

    util.checkoutScrollbar();
    util.addingClassCheckout();
    showCouponForm();
    util.lableFocus();
    util.cardnumberSpace();

    pageLoadCompany();
    showCompanyName();
    billingPhoneType();
    util.tooltipCheckout();
    updateShippingMethodList(true);
    addressvalidation.init();
    companyNameWithUndefinedValue();    
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.updatePaymentSection = updatePaymentSection;
exports.updateSummary = updateSummary; //JIRA PREV-99 : shipping methods is not displayed for 2nd address in right nav.
exports.showCouponForm = showCouponForm;
exports.checkPOAmount = checkPOAmount;
exports.showCompanyName = showCompanyName;
exports.companyNameWithUndefinedValue = companyNameWithUndefinedValue;
