'use strict';

function initializeEvents() {

    $('.find-a-class-newdesign').find('.geo-complete-new').on('click', function(e){
		e.stopPropagation();
		$('.overlay').show();
		if($('.geo-complete-new span.geo-complete-value').text() != '') {
			$('.location-popup').find('.user-location-input').val($('.geo-complete-new span.geo-complete-value').text());
		}
		$('.find-a-class.new-design').find('.location-popup').show();
		$('body').css('overflow', 'hidden');
	}); 

	$('.find-a-class-newdesign').find('.about-formats-link').on('click', function(e){
		e.stopPropagation();
		$('.overlay').show();
		$('.find-a-class.new-design').find('.formats-popup').show();
		$('body').css('overflow', 'hidden');
	});
	

	//When Online Only category is chosen, then the Course Format dropdown should default to Online and not be changeable.
	$('.find-a-class-newdesign .select-class.cat-search').on('change', function() {
		var selectedCategory = $(this).find('option:selected').val();
		if(selectedCategory != '' && selectedCategory.length > 0 && selectedCategory == 'online-safety-classes') {
			$('.course-formats-list').find('option[value="Online"]').prop('selected', 'selected').trigger('change');
			$('.course-formats-list').attr('disabled', 'disabled');
		} else {
			$('.course-formats-list').find('option[value="All Formats"]').prop('selected', 'selected').trigger('change');
			$('.course-formats-list').removeAttr('disabled');
		}
	});
	
	//Find my location - Location pop up
	$('.location-popup .location-container').find('.use-current-location .use-location').on('click', function(e){
		if (window.currentaddress != "") {
            $(this).closest('.geo-complete-results').find('.geo-complete-input').val(window.currentaddress);
            $(this).closest('.geo-complete-results').find('.geo-complete-lat').val(window.geolocation.lat);
            $(this).closest('.geo-complete-results').find('.geo-complete-lng').val(window.geolocation.lng);
            $(this).closest('.location-popup').find('.user-location-input').val(window.currentaddress);
        } else {
            $(this).closest('.geo-complete-results').find('.geo-complete-input').val($('.geo-akmai').val().toLowerCase().replace(/, united states$/, ""));
            $(this).closest('.geo-complete-results').find('.geo-complete-lat').val($('.geo-latitude').val());
            $(this).closest('.geo-complete-results').find('.geo-complete-lng').val($('.geo-longitude').val());
            $(this).closest('.location-popup').find('.user-location-input').val($('.geo-akmai').val().toLowerCase().replace(/, united states$/, ""));
        }
	});
	
	$('.location-popup .location-container').find('.location-search').on('click', function(e){
		var userLocationValue;
		var radiusValue = '';
		e.preventDefault();
		if($('.location-popup').find('.user-location-input').val() === '') {
			$('div.label-container .location-error').css('display','block');
			return false;
		}
		if($('.location-popup').find('.user-location-input').val() != "" && $('div.label-container .location-error').length > 0) {
            $('div.label-container .location-error').css('display','none');
		}
		if($('.location-popup').find('.user-location-input').val() != "") {
			userLocationValue = $('.location-popup').find('.user-location-input').val().replace(/, USA$/, "").replace(/\d+/, '');
			$('.geo-complete-new span.geo-complete-value').text(userLocationValue);
			$('.geo-location.geo-complete-new').prop('title', userLocationValue);
			$('.find-a-class-newdesign').find('.geo-complete-input').val(userLocationValue);
		}
		if($('.location-radius select.select-class').find('option:selected').length > 0) {
			radiusValue = $('.location-radius select.select-class').find('option:selected').val();
			if($('.find-a-class.new-design').find('input.within-miles').length > 0) {
				$('.find-a-class.new-design').find('input.within-miles').val(radiusValue);
			}
		}
		$('.overlay').hide();
		$('.find-a-class.new-design').find('.location-popup').hide();
		$('body').css('overflow', 'auto');
		
		if($('.find-a-class.new-design').find('.findaclass-error-meesage').length > 0) {
			$('.find-a-class.new-design').find('.findaclass-error-meesage').hide();
			$('.find-a-class.new-design').find('.find-a-class-submit').prop("disabled", false);
		}
	});
	$('.find-a-class.new-design .custom-select').on('change', function(){
		if ($(this).closest('.cat-search').find('.select-class').val() !== "Choose a Class") {
			if($('.find-a-class.new-design').find('.findaclass-error-meesage').length > 0) {
				$('.find-a-class.new-design').find('.findaclass-error-meesage').hide();
				$('.find-a-class.new-design').find('.find-a-class-submit').prop("disabled", false);
			}
		}
	});
	
	$('.location-popup .user-location-input').on('blur', function(e) {
		if($('.location-popup').find('.user-location-input').val() !== '') {
		   $('div.label-container .location-error').hide();
		}
	});

	$(document).on('click',function(e) {
		if (e.target.matches(".close") || !$(e.target).is(".location-popup, .location-popup *, .formats-popup, .formats-popup *")) {
			if($('div.label-container .location-error').length && $('div.label-container .location-error').is(':visible')){
				$('div.label-container .location-error').hide();
			}
			$('.find-a-class.new-design').find('.location-popup').hide();
			$('.find-a-class.new-design').find('.formats-popup').hide();
			$('.overlay').hide();
			$('body').css('overflow-y', 'auto');
		}
	});

}

exports.init = function () {

    $(window).on('load', function() {
        if($('.select-class.cat-search').length && $('.course-formats-list').length){
            var course_selected = $('.select-class.cat-search').find('option:selected').val();
            var class_selected = $('.course-formats-list').find('option:selected').val();
            if(course_selected === 'online-safety-classes' && class_selected === 'Online'){
                $('.course-formats-list').attr('disabled', 'disabled');
            }
        }
    });

    initializeEvents();
};