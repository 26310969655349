'use strict';

var dialog = require('./dialog');
var util = require('./util');
var ajax = require('./ajax'),
progress = require('./progress');
var $cache = {}; 

function initializeCache(isDialog) {
    $cache.primary = $('#primary');
    $cache.checkoutForm = $cache.primary.find('.checkout-shipping.address').length > 0 ? $cache.primary.find('.checkout-shipping.address') : $cache.primary.find('.checkout-billing.address');
    //$cache.submitFormButton = $cache.checkoutForm.find('button.saveShipping').length > 0 ? $cache.checkoutForm.find('button.saveShipping') : $cache.checkoutForm.find('button[name$=_save]');
    $cache.submitFormButton = $cache.checkoutForm.find('button.checkoutbutton');
    $cache.validatoionDialog = $cache.primary.find('#address-validation-dialog');
    $cache.byPassDAV = $cache.checkoutForm.find('.bypassDAV');
    $('.place-order-div .checkoutbutton').on("click" ,function(){
    	if($('.checkout-shipping').valid()) {
    		$('.place-order-div .checkoutbutton').closest('form').find('.checkoutbutton').addClass('disable-mode-btn');
    		$('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
    	}
	});
    if(isDialog) {
    	$cache.primary = $('.profile-edit-user');
        $cache.addressForm = $cache.primary.find('#edit-address-form'); 
        $cache.submitFormButton = $cache.addressForm.find('.apply-button');
        $cache.validatoionDialog = $cache.primary.find('#address-validation-dialog');
        $cache.byPassDAV = $cache.addressForm.find('.bypassDAV');
        $cache.isDialog = true;
        $('.place-order-div .checkoutbutton').on("click" ,function(){
	    	if($('.checkout-shipping').valid()) {
	    		$('.place-order-div .checkoutbutton').closest('form').find('.checkoutbutton').addClass('disable-mode-btn');
	    		$('#main').append('<div class="loader"><div class="loader-indicator"></div><div class="loader-bg"></div></div>')
	    	}
    	});
    }
    
    $cache.billingaddress1 = $cache.checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address1"]');
    $cache.billingaddress2 = $cache.checkoutForm.find('input[name$="_billing_billingAddress_addressFields_address2"]');
    $cache.billingcity = $cache.checkoutForm.find('input[name$="_billing_billingAddress_addressFields_city"]');
    $cache.billingstateCode = $cache.checkoutForm.find('select[id$="_billing_billingAddress_addressFields_states_state"]');
    $cache.billingcountryCode = $cache.checkoutForm.find('select[id$="_billing_billingAddress_addressFields_country"]');
    $cache.billingpostalCode = $cache.checkoutForm.find('input[name$="_billing_billingAddress_addressFields_postal"]');
    
    $cache.shippingaddress1 = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]');
    $cache.shippingaddress2 = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]');
    $cache.shippingcity = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]');
    $cache.shippingstateCode = $cache.checkoutForm.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]');
    $cache.shippingcountry = $cache.checkoutForm.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]');
    $cache.shippingpostalCode = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]');
    
    $cache.profileaddress1 = $cache.checkoutForm.find('input[name$="_profile_address_address1"]');
    $cache.profileaddress2 = $cache.checkoutForm.find('input[name$="_profile_address_address2"]');
    $cache.profilecity = $cache.checkoutForm.find('input[name$="_profile_address_city"]');
    $cache.profilestateCode = $cache.checkoutForm.find('select[id$="_profile_address_states_state"]');
    $cache.profilecountryCode = $cache.checkoutForm.find('select[id$="_profile_address_country"]');
    $cache.profilepostalCode = $cache.checkoutForm.find('input[name$="_profile_address_postal"]');
}

function initializeDom() {
    if ($cache.validatoionDialog.length > 0) {
    	//$(this).delay(10000);
        var dlg = dialog.create({
            target: $cache.validatoionDialog,
            options: {
                width: 670,
                dialogClass: 'address-validation-dialog',
                open: function () {
                	
                	// TSED-586 - Shipping Information Submitted Without Full Valid Street Address
                	if ($(".streetError")) {
                        $(".streetError").next().find("#ship-to-original-address").attr("disabled", true);
                    } else {
                        $(".streetError").next().find("#ship-to-original-address").removeAttr("disabled")
                    } // TSED-586
                	
                    $cache.validatoionDialog.on('click', '#original-address-edit', function () {
                    	dialog.close();
                    	if($cache.isDialog){return;}
                        jQuery('html, body').animate({
                            scrollTop: $('#navigation').position().top
                        }, 500);
                    });

                    $cache.validatoionDialog.on('click', '#ship-to-original-address', function () {

                    	dialog.close();
                        $cache.byPassDAV.attr('value', 'true');
                        $cache.submitFormButton.click();
                    });

                    $cache.validatoionDialog.on('click', '[id|="suggested-address-edit"]', function () {
                        var selectedAddress = $(this).data('address').split('||');
                        $cache.address1.val(selectedAddress[0]);
                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                            $cache.address2.val(selectedAddress[1]);
                        }
                        $cache.city.val(selectedAddress[2]);
                        $cache.stateCode.val(selectedAddress[3]);
                        $cache.countryCode.val(selectedAddress[4]);
                        $cache.postalCode.val(selectedAddress[5]);
                        dialog.close();
                        jQuery('html, body').animate({
                            scrollTop: $('#navigation').position().top
                        }, 500);
                    });
 
                    $cache.validatoionDialog.on('click', '[id|="shipping-ship-to-address-selected"]', function () {
                    	if($('body').find('input[name$="_shippingAddress_useAsBillingAddress"]').length > 0){
                    		// Below Condition is for Updating Addresses based on Shipping same as Billing Checkbox  
                    		if($('input[name$="_shippingAddress_useAsBillingAddress"]').is(':checked')) {
                    			//TSED-1129: Add GroupOne address validation to billing section on Ecomm Check out page
                    			var selectedAddress = $(this).data('address').split('||');
    	                        var enteredZip = $cache.shippingpostalCode.val();
    	                        $cache.shippingaddress1.val(selectedAddress[0]);
    	                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
    	                            $cache.shippingaddress2.val(selectedAddress[1]);
    	                        }
    	                        $cache.shippingcity.val(selectedAddress[2]);
    	                        $cache.shippingstateCode.val(selectedAddress[3]);
    	                        $cache.shippingstateCode.trigger('change');
    	                        var country = selectedAddress[4];
    	                        $cache.shippingcountry.val(country);
    	                        $cache.shippingpostalCode.val(selectedAddress[5]);
    	                        $cache.byPassDAV.attr('value', 'true');
    	                        dialog.close();
                                
                                $cache.submitFormButton.click(); 
                    		} else {
                    			var selectedAddress = $(this).data('address').split('||');
    	                        var enteredZip = $cache.shippingpostalCode.val();
    	                        $cache.shippingaddress1.val(selectedAddress[0]);
    	                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
    	                            $cache.shippingaddress2.val(selectedAddress[1]);
    	                        }
    	                        $cache.shippingcity.val(selectedAddress[2]);
    	                        $cache.shippingstateCode.val(selectedAddress[3]);
    	                        $cache.shippingstateCode.trigger('change');
    	                        var country = selectedAddress[4];
    	                        $cache.shippingcountry.val(country);
    	                        $cache.shippingpostalCode.val(selectedAddress[5]);
    	                        $cache.byPassDAV.attr('value', 'false');
    	                        dialog.close();
    	                        $cache.submitFormButton.click();
                    		}
                    	} else {
	                        //TSED-1129: Add GroupOne address validation to billing section on Ecomm Check out page
                    		var selectedAddress = $(this).data('address').split('||');
                            $cache.billingaddress1.val(selectedAddress[0]);
                            if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                                $cache.billingaddress2.val(selectedAddress[1]);
                            }
                            $cache.billingcity.val(selectedAddress[2]);
                            $cache.billingstateCode.val(selectedAddress[3]);
                            $cache.billingcountryCode.val(selectedAddress[4]);
                            $cache.billingpostalCode.val(selectedAddress[5]);
                            $cache.byPassDAV.attr('value', 'true');
                            dialog.close();
	                        //Commenting for the PHSSECOM-41 ticket requirement.
	                       //if(enteredZip === selectedAddress[5]) {
	                        	$cache.submitFormButton.click();
	                       // }
                    	}   	
                    });
                                                         
                    $cache.validatoionDialog.on('click', '[id|="billing-ship-to-address-selected"]', function () {
                        var selectedAddress = $(this).data('address').split('||');
                        $cache.billingaddress1.val(selectedAddress[0]);
                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                            $cache.billingaddress2.val(selectedAddress[1]);
                        }
                        $cache.billingcity.val(selectedAddress[2]);
                        $cache.billingstateCode.val(selectedAddress[3]);
                        $cache.billingcountryCode.val(selectedAddress[4]);
                        $cache.billingpostalCode.val(selectedAddress[5]);
                        $cache.byPassDAV.attr('value', 'true');
                        dialog.close(); 
                        
                        $cache.submitFormButton.click(); 
                    });
                    
                    $cache.validatoionDialog.on('click', '[id|="profileaddress-ship-to-address-selected"]', function () {
                        var selectedAddress = $(this).data('address').split('||');
                        $cache.profileaddress1.val(selectedAddress[0]);
                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                            $cache.profileaddress2.val(selectedAddress[1]);
                        }
                        $cache.profilecity.val(selectedAddress[2]);
                        $cache.profilestateCode.val(selectedAddress[3]);
                        var country = selectedAddress[4] ;
                        $cache.profilecountryCode.val(country.toLowerCase());
                        $cache.profilepostalCode.val(selectedAddress[5]);
                        $cache.byPassDAV.attr('value', 'true');
                        dialog.close();  
                        
                        $cache.submitFormButton.click();  
                    });
                   
                }

            }
        });

        $cache.validatoionDialog.show();
        dlg.dialog('open');
    }
}

exports.init = function(isDialog) {
    initializeCache(isDialog);
    initializeDom();
};
