/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    autocomplete = require('./autocomplete'),
    atmevents = require('./atmevents'),
    gtmevents = require('./gtm'),
    tls = require('./tls'),
    ajax = require('./ajax'),
    contentmigration = require('./content-migration'),
    certificates = require('./pages/certificates'),
	homepage =  require('./pages/homepage');

var form_regex = {
	form_gt: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_tto: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}, {fld:'country', regx: /^[a-zA-Z0-9-_.,\s]+$/}],
	form_oad: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_aed: [{fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_aupd: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_aup: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_bns: [{fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}, {fld:'country', regx: /^[a-zA-Z0-9-_.,\s]+$/}],
	form_bgs: [{fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_ltp: [{fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}, {fld:'country', regx: /^[a-zA-Z0-9-_.,\s]+$/}],
	form_tme: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_tmed: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_wps: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_wpsd: [{fld: 'company', regx:/^[A-Za-z0-9!@#$&*_.-\s]+$/}, {fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/},{fld: 'city', regx: /^[a-zA-Z0-9-_\s]+$/}, {fld:'state', regx: /^[a-zA-Z]+$/}],
	form_dgf: [{fld: 'first_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}, {fld: 'last_name', regx: /^[a-zA-Z0-9-_.,\s]+$/}]
}
// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();

function initQuickViewButtons() {
	$('body').on('mouseenter','.tiles-container .product-image', function () {
		var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            var qucikviewurl=  $(this).attr('href').split('#')[0];
            var quickviewnow = require('./quickview'); 
            quickviewnow.show({
                url: qucikviewurl,
                source: 'quickview'
            });
        });
    });
}

function initializeEvents() {
	 $('.categorypageredirect').on('click', function(e){
		 e.preventDefault();
		 var catHref = $('#categoryurl').val();
		 window.location.href = catHref+'';
 	 });
	 
	initQuickViewButtons();
	util.searchSuggestions();
    $('.no-fouc').removeClass('no-fouc');
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    //PREVAIL-Added to handle form dialog boxes server side issues.
    $('body').on('click', '.dialogify, [data-dlg-options], [data-dlg-action]', require('./dialogify').setDialogify)
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    // print handler
    $('.print-page').on('click', function () {
        window.print();
        return false;
    });
    
    util.cancelDialog();
    
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });
    
    // for the FAQ's page drawer section
    $('.primary-content-data .information-wrppr').click(function() {
        var sel = getSelection().toString();
        if(!sel){
        $(this).toggleClass('expanded').siblings().removeClass('expanded');
        }
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function (e) { 
        //e.preventDefault(); //JIRA PREV-90 : When click on advance search from gift registry login page, focus is happening towards top of the page.
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({
                color: '#999999'
            }, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({
                    color: '#333333'
                }, 500, 'linear');
        });
    }

    //footer subsciption change
    var $footer = $('.footer-contact-us-email')
    $footer.on('click', '.home-email', function (e) {
    	e.preventDefault();
    	//PHSSECOM-2048
    	//var emailRegEx = /^[\w\.\&\+\-\/\_]+@[\w\-]+\.[\w]{2,6}$/;
    	var emailRegEx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*([a-zA-Z0-9]@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    	var emailinput = $('.email-sign-up .email-up').val();
    	var result = emailRegEx.test(emailinput);
    	//var objData = JSON.parse($('.emailsubscription').html()); //commented as per PHSSECOM-2105: removed SubsribeAction from code
    	if(!result){
    		e.preventDefault();
    		$('.email-sign-up .validate-message').removeClass('hide');
    		$('.email-sign-up').addClass('error');
    		return false;
    	}else{
    		$('.email-sign-up').removeClass('error');
    		$('.email-sign-up .validate-message').addClass('hide');
    	}	
    	var action = $('#email-alert-signup').attr('action');
    	var emailinput = $('#email-alert-signup .email-up').val();
    	var url = util.appendParamsToUrl(action, {
    		email : emailinput,
    	    format  :  'ajax'
    	});
    	
    	ajax.load({
    		url: url,
    		callback: function (data) {
    			$('.email-sign-up').addClass('active');
    			
    			//GTM analytics for email signup footer
    			if (SitePreferences.isGTMEnabled) {
    				gtmevents.emailSubmitFooter();
    			}
    			 //adobe analytics for email signup footer
    			 if (SitePreferences.isATMEnabled) {
    		    	atmevents.emailSubmitFooter();
    		     }
    			 // commented as per PHSSECOM-2105: removed SubsribeAction from code
    			 /*objData.agent = emailinput;
    			 $('.emailsubscription').html(JSON.stringify(objData));*/
    		}
    	});
    });
    
    // footer closing the thanks message
    $('.email-success .thanks-close').on('click', function() {
    	$('.email-sign-up').removeClass('active');
    	$('.email-sign-up .email-up').val("");
		//TSED-1140: Aria-label for Footer email signup
		$('#email-alert-signup').find('.form-row').find('label').removeClass('focus');
		$('#email-alert-signup').find('.form-row').removeClass('form-label');
    });
    
    // for global navigation desktop
    if($(window).width() > 1024) {
        $('.level-1 > li').hover(function(e) {  
            e.preventDefault();
            var menuListHyt, max, arrMax3=[], arrMax4=[], maxLengthLevel2, maxLengthLevel3, maxLengthLevel4;
            $('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').removeClass('activeCar');
            $('.level-2').height('');
            $('.level-2').removeClass('hide'); 
            $('.has-sub-menu').removeClass('focus-menu-out');
            $(this).toggleClass('active');
            var $cartFly = $('#mini-cart .mini-cart-content');
            if ($cartFly.hasClass('active')) {
            	$cartFly.removeClass('active');
            	$('.mini-cart-link').removeClass('icon-change');
            }
            maxLengthLevel2 = $('.firstLevel.active li#main-2').length;            
            //menuListHyt = maxLengthLevel2 * 47;
            menuListHyt = $('.firstLevel.active .level-2 .menu-horizontal').outerHeight();
            if($(window).height() < menuListHyt + 150) {
                $('.firstLevel.active .level-2 .menu-horizontal').height($(window).height()-$('.header-parent').height()-50);
                $('.firstLevel.active .level-2 .menu-horizontal').addClass('ltws');
            } else {
            	$('.firstLevel.active .level-2 .menu-horizontal').height(menuListHyt);
            }
            
        });
    
        // TSED-342 On Hover of Level-2
        $('.level-2 > ul > li').hover( function(e) {
        	e.preventDefault();

        	var parentLength = $(this).siblings('li').length + 1;
        	var level2_length = $(this).find('.level-3 ul > li').length;
        	var total_height = $(this).parent('ul.menu-horizontal').outerHeight();
        	var level2_height = parentLength * 47;

        	if($(window).height() < (level2_height + 150)) {
        		var final_height = $(window).height()-$('.header-parent').height()-50;
                $(this).parent('ul.menu-horizontal').height(final_height - 2);
                $(this).parent('ul.menu-horizontal').addClass('ltws');
                $(this).find('div.level-3 ul').height(final_height - 2);
        	} else {
	        	if(level2_length > parentLength) {
	        		$(this).parent('ul').height(total_height - 2);
	        		total_height = $(this).find('div.level-3 ul').outerHeight();
	        		$(this).parent('ul').height(total_height - 2);
	        	} else {
	        		if($(this).parent('ul.menu-horizontal').parent('.level-2').siblings('a.has-sub-menu').hasClass('organization')) {
						var ht = $(this).parent('ul.menu-horizontal').outerHeight();
		        		$(this).parent('ul').height(ht);
		        		$(this).find('div.level-3 ul').height(ht);
	        		} else {
						//TSED-389: Code changes added
						$(this).parent('ul').height('');
						total_height = $(this).parent('ul.menu-horizontal').outerHeight();
						$(this).parent('ul').height(total_height);
						$(this).find('div.level-3 ul').height(total_height);
	        	   }
	        	}
        	}
            
        });
        
        // TSED-342 On Hover of Level-3
        $('div.level-3 ul > li').hover( function(e) {
        	e.preventDefault();
        	var level2_height = $(this).parents('.level-2 > ul').outerHeight();
        	var parentLength = $(this).siblings('li').length + 1;
        	var level3_height = $(this).parent('ul').outerHeight();
        	var level4_height = $(this).find('div.level-4 ul').outerHeight();
        	
        	var final_height = Math.max(level2_height, level3_height-2, level4_height-2);
        	//if(!$('.firstLevel.active .level-2 .menu-horizontal').hasClass('ltws')) {
        		$(this).parent('ul').height(final_height);
            	$(this).parents('.level-2 > ul').height(final_height);
            	$(this).find('div.level-4 ul').height(final_height);
        	/*} else {
        		var final_height = $(window).height()-$('.header-parent').height()- 50;
                $(this).parent('div.level-3 ul').height(final_height - 2);
                $(this).find('div.level-4 ul').height(final_height - 2);
        	}*/
        });
        
        $('.level-2 li, div.level-3 ul li, div.level-4 ul li').hover(function(e){
        	e.preventDefault();
        	$(this).siblings().removeClass('active');
        	$(this).addClass('active');
        	$('.has-sub-menu').removeClass('focus-menu-out');
            $(this).closest('.level-2').siblings('.has-sub-menu').addClass('hover');
        	if($('li.firstLevel.active li#main-2').siblings().first().hasClass('active')) {
        		$('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').addClass('activeCar');
        		if(!$('li.firstLevel.active li#main-3').is(':visible')) {
    				$('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').removeClass('activeCar');
    			}
        	} else {
        		if($('li.firstLevel.active li#main-2').siblings().length == 0) {
        			if(!$('li.firstLevel.active li#main-3').is(':visible')) {
        				$('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').removeClass('activeCar');
        			} else if($('li.firstLevel.active li#main-2').hasClass('active')) {
        				$('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').addClass('activeCar');
        			} else 
        				$('li.firstLevel a.has-sub-menu, li.firstLevel.active a.has-sub-menu').removeClass('activeCar');
        		}
        	}
        });
    }
    
    // tablet at 1024 navigation menu
    if($(window).width() == 1024) {
	    $('.level-1 .has-sub-menu').on('click', function(e){
	    	e.preventDefault();
	    	$(this).closest('li').toggleClass('tab-navigation');
	    	$(this).closest('li').siblings().removeClass('tab-navigation'); 
	    	var maxLengthLevel2 = $('.firstLevel.tab-navigation li#main-2').length;  
			/* TSED-389: Code changes Start */ 
	    	var menuListHyt = $('.firstLevel.tab-navigation ul.menu-horizontal').outerHeight();
			if($(window).height() < menuListHyt + 150) {
                $('.firstLevel.tab-navigation .level-2 .menu-horizontal').height($(window).height()-$('.header-parent').height()-50);
                $('.firstLevel.tab-navigation .level-2 .menu-horizontal').addClass('ltws');
            } else {
            	$('.firstLevel.tab-navigation .level-2 .menu-horizontal').height(menuListHyt);
            }
			/* TSED-389: Code changes End */ 
	    });
        
	 // TSED-342 On Click of Level-2
        $('.level-2 > ul > li').on('click', function(e) {
			
        	var parentLength = $(this).siblings('li').length + 1;
        	var level2_length = $(this).find('.level-3 ul > li').length;
        	var total_height = $(this).parent('ul.menu-horizontal').outerHeight();
        	var level2_height = parentLength * 47;
  
    		$(this).parent('ul.menu-horizontal').removeClass('ltws');
        	if(level2_length > parentLength) {
        		$(this).parent('ul').height(total_height - 2);
        		total_height = $(this).find('div.level-3 ul').outerHeight();
        		$(this).parent('ul').height(total_height - 2);
        	} else {
        		if($(this).parent('ul.menu-horizontal').parent('.level-2').siblings('a.has-sub-menu').hasClass('organization')) {
        			var ht = $(this).parent('ul.menu-horizontal').outerHeight();
	        		$(this).parent('ul').height(ht);
	        		$(this).find('div.level-3 ul').height(ht);
        		} else {
	        		total_height = parentLength * 47;
	        		$(this).parent('ul').height(total_height);
	        		$(this).find('div.level-3 ul').height(total_height);
        	   }
        	}
            
        });
        
        // TSED-342 On Click of Level-3
        $('div.level-3 ul li').on('click', function(e) {
        	var level2_height = $(this).parents('.level-2 > ul').outerHeight();
        	var parentLength = $(this).siblings('li').length + 1;
        	var level3_height = $(this).parent('ul').outerHeight();
        	var level4_height = $(this).find('div.level-4 ul').outerHeight();
        	var final_height = Math.max(level2_height, level3_height-2, level4_height-2);
    		$(this).parent('ul').height(final_height);
        	$(this).parents('.level-2 > ul').height(final_height);
        	$(this).find('div.level-4 ul').height(final_height);
        });
	}
    //on click of search icon
  
    $('.prod-search').on("click",function(e){
    	$('.header-geo-submit').click();
    });
    
    //adobe analytics for Header Navigation links
    $('.menu-vertical li a').on("click",function(e){
    	e.preventDefault();
    	var linkName = $(this).attr('alt');
    	var linkParent = $(this).parents('ul.menu-category').find('li.active a.has-sub-menu span' ).text();
    	var linkURL = $(this).attr('href');
    	if (SitePreferences.isATMEnabled) {
    		atmevents.headerNavigationlinks(linkName,linkParent,linkURL);
    	}
    	
		var $URL = $(this).attr('href');
	    var finalURL = util.appendParamsToUrl($URL, {
		   latitude : $('.geo-latitude').val(),
		   longitude : $('.geo-longitude').val()
	    });
        window.location.href = finalURL;
    });
    
    //adobe analytics and GTM events for products Header Navigation links
    $('.menu-horizontal li a').on("click",function(e){
    	
    	var linkName = $(this).attr('alt');
    	var linkParent = $(this).parents('ul.menu-category').find('li.active a.has-sub-menu span' ).text();
    	var linkURL = $(this).attr('href');
    	if (SitePreferences.isATMEnabled) {
    		atmevents.headerNavigationlinks(linkName,linkParent,linkURL);
    	}
    	if (SitePreferences.isGTMEnabled) {
    		gtmevents.headerMenuNavigationlinks(this);
    	}
    });
  
    $('.menu-vertical li a, .ourclasses .class-tiles a ').on("mousedown",function(e){  
	    if(e.which == 3){
			 var $URL = $(this).attr('href');
			    var finalURL = util.appendParamsToUrl($URL, {
			    	 latitude : $('.geo-latitude').val(),
					 longitude : $('.geo-longitude').val()
			    });
		 return  $(this).attr('href', finalURL);
	    }
	 });
   //adobe analytics for launch my class
    $('.online-launch').on("click",function(e){
    	if(SitePreferences.isATMEnabled) {
	      	  atmevents.launchMyCourse(this);
    	}
    	if(SitePreferences.isGTMEnabled) {
	      	  gtmevents.launchMyCourse(this);
    	}
    });
    //adobe analytics for Footer Navigation links
    $('.footer-shipping-returns a,.privacy-policy-main a,.copyright-info-anchor a').on("click",function(e){
    	var linkName = $(this).attr('alt');
    	var linkURL = $(this).attr('href');
    	
     if (SitePreferences.isATMEnabled) {
    	atmevents.footerrNavigationlinks(linkName,linkURL);
      }
    });
    
    
  //adobe analytics and GTM events for Social Icon click
    $('.social-links-main a').on("click",function(e){
    	var linkName = $(this).attr('alt');
    	var linkURL = $(this).attr('href');
    	
    	if (SitePreferences.isATMEnabled) {
    		atmevents.footerSocialIconlinks(linkName,linkURL);
    	}
    	if(SitePreferences.isGTMEnabled){
    		gtmevents.footerSocialIconClick(this);
    	}
    });
    
  //adobe analytics for Customer Service Phone Click - Footer
    $('.footer-cus-service a').on("click",function(e){
    	var linkName = $(this).attr('alt');
    	var linkURL = $(this).attr('href');
    	
    	if (SitePreferences.isATMEnabled) {
    		atmevents.footerCustomerServicePhoneLink(linkName,linkURL);
    	}
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.footerCustomerServiceLink(this);
    	}
     
    });
    
  //adobe analytics for Live Chat click
    $('.footer-chat a,.header-chat ,.chat-class').on("click",function(e){
     if (SitePreferences.isATMEnabled) {
    	atmevents.footerLiveChatLink();
      }
    });
    
    
    //adobe analytics for Real Estate Tracking - Home Page
    $('.ourclasses .class-tiles a,.learn-more a,.for-instructors a').on("click" ,function(e){
    	if($(this).parents('.class-tiles').find('a.topLevelClass').length > 0){
		var linkName =  $(this).parents('.class-tiles').find('a.topLevelClass').attr('alt')
    		var linkURL =  $(this).parents('.class-tiles').find('a.topLevelClass').attr('href')
    	}else{
    		var linkName = $(this).attr('alt');
        	var linkURL = $(this).attr('href');
    	}
    	
    	if (SitePreferences.isATMEnabled) {
    		atmevents.realEstateTrackinhHomePage(linkName,linkURL);
    	}
    	// GTM events for Real Estate Tracking - Home Page
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.realEstateTrackingHomePage($(this));
    	}
    	
    	if($(this).closest('.class-tiles').length > 0){
    		e.preventDefault();
    	    var finalURL = util.appendParamsToUrl(linkURL, {
    		   latitude : $('.geo-latitude').val(),
    		   longitude : $('.geo-longitude').val()
    	    });
            window.location.href = finalURL;
    	}
    });
    
    // for the mobile navigation
    if($(window).width() < 1024) {
    	$('.menu-toggle').on('click', function () {
        	$('.navigation').hide();
            $('#wrapper').toggleClass('menu-active');
            $('.mobile-menu-overlay').show();
            $('.mobile-nav-close').show();
            $('.navigation').show("slide", { direction: "up" }, 300);
            $('body').addClass('fixed');
        });
    	
        $('.main-1').first().addClass('firstItem');
        
        $('.menu-category li.submenu').on('click', function (e) {
        	e.stopPropagation();
        	e.preventDefault();
        	var $parentLi = $(this), findingLink;
            var redirect = $parentLi.children().filter('span.mobile-grouping').children().filter('.fa.menu-item-toggle').is(':visible');
            findingLink = $parentLi.children('.mobile-grouping');
            if(!redirect) {
            	//PHSSECOM-1551
            	if($parentLi.hasClass('firstLevel') && $parentLi.hasClass('active')) {
            		var link = $('.firstLevel.active .has-sub-menu.active').attr('href');
            		return window.location.href = link;
            	} //Ends PHSSECOM-1551
            	else if($parentLi.hasClass('supplies-products')) {
        			$('.top-menu-category').find('h2').hide();
        		}
            	if(findingLink.find('span').length == 1) {
            		return;
            	}
            	if(findingLink.find('a').length == 1) {
            		var link = findingLink.find('a').attr('href');
            		 return window.location.href = link;
            	}
            }
            if($parentLi.children().filter('span.mobile-grouping').children().filter('.fa.menu-item-toggle').length || $parentLi.children().filter('i.menu-item-toggle').length) {
            	$parentLi.siblings('li').removeClass('active block').find('.menu-item-toggle').removeClass('active block');
                $parentLi.toggleClass('active');
                $parentLi.removeClass('submenu');
                $parentLi.siblings('li').addClass('forceHide');
                $('.navigation').addClass('active');
                $('.top-menu-category').addClass('open');
                $('.main-1.active.firstItem li#main-2').addClass('block');
                var id = $parentLi.attr('id');
                $('.back-to-supplies').removeAttr('id');
                $('.back-to-supplies').attr('id', id);
                
                if(id == 'main-5') {
                	$parentLi.removeClass('active');
                } else if(id == 'main-2') {
                	$('li#main-1.active > a, li#main-1.active > span').hide();
                	$('.top-menu-category.open h2').hide();
                	$('li#main-3, ul#main-3').removeAttr('style');
                	$('ul#main-3, div.level-3').addClass('block');
                } else if(id == 'main-3') {
                	$('li#main-2.active > a, li#main-2.active > span').hide();
                	$('.menu-category li.active .level-4').hide();
                    $('.menu-category li.active .level-4').toggle('slide', {direction: 'right'}, 200);
                    $('li#main-4, ul#main-4').removeAttr('style');
                    $('ul#main-4, div.level-4').addClass('block');
                } else if(id == 'main-4') {
                	$('li#main-3.active > a, li#main-3.active > span').hide();
                	$('.menu-category li.active .level-5').hide();
                    $('.menu-category li.active .level-5').toggle('slide', {direction: 'right'}, 200);
                    $('li#main-5, ul#main-5').removeAttr('style');
                    $('ul#main-5, div.level-5').addClass('block');
                } else {
                	$('.menu-category li.active .level-2').hide();
                    $('.menu-category li.active .level-2').toggle('slide', {direction: 'right'}, 200);
                    $('li#main-2, ul#main-2').removeAttr('style');
                    $('ul#main-2').addClass('block');
                }
                $('.back-to-main-menu').removeClass('active');
                $('.back-to-supplies').addClass('active');
                $('.level-1 .has-sub-menu').addClass('active');
                $('.supplies-text').text('back');
            }
        });
        
        $('.back-to-supplies').on('click', function() {
        	var id = $(this).attr('id');
        	if(id == 'main-4') {
        		$('li#main-3.active > a, li#main-3.active > span').show();
        		$('li#main-4').removeClass('active forceHide');
        		$('li#main-4').addClass('submenu');
        		$('.back-to-supplies').attr('id', 'main-3');
        		$('.menu-category li.active .level-5').show();
        		$('.menu-category li.active .level-5').toggle('slide', {direction: 'right'}, 200);
            	setTimeout(function(){$('.menu-category li.active .level-5 li').hide();},200);
        	} else if(id == 'main-3') {
        		$('li#main-2.active > a, li#main-2.active > span').show();
        		$('li#main-3').removeClass('active forceHide');
        		$('li#main-3').addClass('submenu');
        		$('.back-to-supplies').attr('id', 'main-2');
        		$('.menu-category li.active .level-4').show();
        		$('.menu-category li.active .level-4').toggle('slide', {direction: 'right'}, 200);
            	setTimeout(function(){$('.menu-category li.active .level-4 ul li').hide();},200);
        	} else if(id=='main-2') {
        		if($('#main-1.supplies-products').hasClass('active')) {
    				$('.top-menu-category h2').hide();
    			} else {
    				$('.top-menu-category h2').show();
    			}
        		$('li#main-2').removeClass('active forceHide');
        		$('li#main-2').addClass('block submenu');
        		$('.back-to-supplies').attr('id', 'main-1');
        		$('li#main-1.active > a, li#main-1.active > span').show();
        		$('.menu-category li.active .level-3').show();
        		$('.menu-category li.active .level-3').toggle('slide', {direction: 'right'}, 200);
            	setTimeout(function(){$('.menu-category li.active .level-3 ul li').hide();},200);
        	} else {
        		$('.top-menu-category h2').show();
        		$('li#main-1').removeClass('active forceHide');
        		$('li#main-1').addClass('submenu');
        		$('.top-menu-category').removeClass('open');
        		$('.navigation').removeClass('active');
        		$('.menu-category li.active .level-2').show();
        		$('.menu-category li.active .level-2').toggle('slide', {direction: 'right'}, 200);
        		setTimeout(function(){
            		$('.menu-category li.active .level-2 li, .menu-category li .level-2 li').hide();
            		$('.menu-category li .level-2 li').removeClass('block');
            	},200);
            	$('.back-to-supplies').removeClass('active block');
        	}
        });
        
        // removing the overlay on click of X in the mobile view
        $('.mobile-nav-close .fa').on('click',function(){
        	$('.mobile-menu-overlay').hide();
        	$('.mobile-nav-close.third-menu').hide();
        	$('.top-menu-category h2').show();
        	$('.top-menu-category').removeClass('open');
            $('.navigation .menu-category li').removeClass('active block forceHide');
            $('.menu-category li').addClass('submenu');
            $('.navigation').removeClass('active block');  
            $('.back-to-supplies').removeClass('active block');
            $('li#main-1, li#main-2, li#main-3, li#main-4, li#main-5, .navigation li a, .menu-category li a, .menu-category').removeClass('active forceHide');
            $('li#main-1, li#main-2, li#main-3, li#main-4, li#main-5, .menu-category li, .menu-category li a, .menu-category, span.mobile-grouping').removeAttr('style');
            $('ul#main-2, ul#main-3, ul#main-4, ul#main-5').removeClass('block');
            $('.level-2, .level-3, .level-4, .level-5').removeAttr('style');
        	$('.navigation').removeClass('active');
        	$('.navigation').hide();
            $('#wrapper').removeClass('menu-active');
            if($(window).width() > 767) {
                $('.navigation').show();
            }
            $('body').removeClass('fixed');
        });
    }
    
    //mobile navigation ends here
    
    //desktop close btn to remove overlay in main navigation
    $('.desktop-close-btn').on('click',function(){
        $('.level-2').addClass('hide');
        $('.has-sub-menu').addClass('focus-menu-out');
    	if($(window).width() == 1024) {
    		 $('.menu-category li').removeClass('tab-navigation');  
    	}       
    }); 
     
    // for the global header closed state
	 var $findtext = $('.products-page .find-section');
	     $findtext.on('click', function () {
		     $(this).closest('.navigation').toggleClass('expanded'); 
	     }); 
    
    // for user account and global search overlays
    $('.menu-utility-user > li > a').off('click').on('click', function (e) {
    	if($(e.target).parent().hasClass('user-learnerlink')){
    		return true;
    	}
        e.preventDefault();
        //headerFlyout();
        var $searchContainer = $('div.search-tabs-input div.simple-search');
    	var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
    	$searchForm.find('input[name="q"]').val('');
        $(this).parent('li').toggleClass('active').siblings().removeClass('active');
        if($('.header-search').hasClass('active')) {
        	$('.header-search-flyout').addClass('active');
        	$('.autocomplete-results').hide();
        }
        else {
        	$('.header-search-flyout').removeClass('active');
        }
        if($('.user-info').hasClass('active')) {
            $('.user-panel').addClass('active');
            $(this).closest('body').find('.autocomplete-results').hide();
        }
        else {
            $('.user-panel').removeClass('active');
        }
    });
    
    // Switching of Search Tabs for the global search dropdown and Error Page
    $('.header-search-flyout .class-label span').off('click').on('click', function() {
    	$(this).closest('.search-tabs').find('.class-search').addClass('active').siblings().removeClass('active');
		$(this).parent('li').addClass('active').siblings().removeClass('active');
		//$('.class-search .header-geo-location').find('input[name="zip"]').val('');
    });
    
    $('.header-search-flyout .product-label span').off('click').on('click', function() {
		if ($('.header-search-flyout #search-suggestions').length > 0) {
			$('.header-search-flyout #search-suggestions').remove();
		}
    	$(this).closest('.search-tabs').find('.simple-search').addClass('active').siblings().removeClass('active');
		$(this).parent('li').addClass('active').siblings().removeClass('active');
		
		var $searchContainer = $(this).closest('.search-tabs').find('div.search-tabs-input div.simple-search');
    	var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
    	$searchForm.find('input[name="q"]').val('');
    	$searchForm.find('input[name="q"]').attr('placeholder', Resources.SIMPLE_SEARCH_PRODUCT);
    	var searchType = $searchForm.find('input[name="searchtype"]').val('product');
    	//Search Suggestions for Products
        searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH_PRODUCT);
    });

	//Search Suggestions for home page field added as a part of A/B testing TSED-1348
	if($('.homepage-search-wrapper.search-tabs-input input[name="q"]').length > 0) {
		$(document).keydown(function(e) {
			if (e.keyCode == 9) {
				e.preventDefault();
				$('.homepage-search-wrapper input[name="q"]').focus();
			}
		});
		var $homePageSearchContainer = $('.homepage-search-wrapper div.simple-search');
		$('.category-slot').addClass('adjust-top-space');
		searchsuggest.init($homePageSearchContainer, Resources.SIMPLE_SEARCH_PRODUCT);
	}

    $('.header-search-flyout .article-label span ').off('click').on('click', function(){
		if ($('.header-search-flyout #search-suggestions').length > 0) {
			$('.header-search-flyout #search-suggestions').remove();
		}
    	$(this).closest('.search-tabs').find('.simple-search').addClass('active').siblings().removeClass('active');
		$(this).parent('li').addClass('active').siblings().removeClass('active');
		
		var $searchContainer = $(this).closest('.search-tabs').find('div.search-tabs-input div.simple-search');
    	var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
    	$searchForm.find('input[name="q"]').val('');
    	$searchForm.find('input[name="q"]').attr('placeholder', Resources.SIMPLE_SEARCH_ARTICLE);
    	var searchType = $searchForm.find('input[name="searchtype"]').val('article');
    	//Search Suggestions for Articles
        searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH_ARTICLE);
    });
           
    //removing error on focus out
    $('.email-sign-up').on('focusout',function(e) {
        $('.email-sign-up').removeClass('error');
        $('.email-sign-up .validate-message').addClass('hide');
    });
    
    $('.email-sign-up .email-up').on('keypress',function(e) {
    	var k = e ? e.which : window.e.keyCode;
        if (k == 32) return false;
    });
    
    $('.email-sign-up .email-up').on('blur',function(e) {
    	var k = e ? e.which : window.e.keyCode;
        if (k == 32) return false;
        //PHSSECOM-2048
    	//var emailRegEx = /^[\w\.\&\+\-\/\_]+@[\w\-]+\.[\w]{2,6}$/;
    	var emailRegEx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*([a-zA-Z0-9]@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    	var emailinput = $('.email-sign-up .email-up').val();
    	var result = emailRegEx.test(emailinput);
    	if(!result){
    		e.preventDefault();
    		$('.email-sign-up .validate-message').removeClass('hide');
    		$('.email-sign-up').addClass('error');
    	}	
    });

     
        // closing overlays on click of the outside
    $(document).on('click', function(e) {
        if (!$(e.target).closest('.header-search-flyout,.header-search,.user-info, .user-panel, .class-format-thrld, .tooltip-box , .i-would-liketo , .class-happening-days, .class-setting, .simple-search-action.new-field-homepage').length){
            $('.header-search-flyout,.header-search,.user-info, .user-panel, .class-format-thrld, .tooltip-box , .i-would-liketo , .class-happening-days , .class-setting-language').removeClass('active');
			setTimeout(function(){
                $('.homepage-search-wrapper input[name="q"]').val('');
            }, 500);
        }
        if (!$(e.target).closest('.header-search-flyout .simple-search-action input').length){ 
        	$('.sticky-container').removeClass('focusstate'); 
        }
        if (($(e.target).hasClass('geo-complete-input') || $(e.target).hasClass('autocomplete-results') || $(e.target).closest('.autocomplete-results').length > 0)){
            $(this).closest('.geo-complete-results').find('.autocomplete-results').show();
        }
        else{
            $('.autocomplete-results').hide();
        }
    });
    
    // closing the overlays on hover of the global navigation
    $('.level-1 > li').hover(function(e){
    	$('.header-search-flyout,.header-search,.user-info, .user-panel').removeClass('active');
    	$('.header-geo-field input[type="text"]').trigger('blur');
    	$('.autocomplete-results').hide();
    	$('.custom-select').removeClass('current_item');
    	$('.form-row.postal-row').removeClass('tooltip-visiable');
       	$('.form-row.cvn').removeClass('tooltip-filed');
    });
     // for the search results classes page
	 /**TSED-1031 */
    if($(window).width() > 767) {
        if ($(window).width() <= 1024) {
			util.searchResultClass();
	   }
        util.SingleOnlineClass();
    }
    else{
    	var start = 0;
    	$('.search-result-items li').each(function(){
    		var height = $(this).find('.class-month-timings').outerHeight();
    		if(height > start) {
    			start = height;
    		}
    		height = 0;
    	});
    	$('.search-result-items .class-seats-price,.search-result-items .class-month-timings').outerHeight(start); 
    }
    
    util.tooltipSearchresultpage();
    // for making the dialog box center in the window
    function dialogCenter() {
    	var windowWidth = $(window).width();
    	var dialogWidth = $('.sign-up-dialog').width();
    	var diff = windowWidth - dialogWidth;
    	var left = diff/2;
    	$('.sign-up-dialog').css('left', left);
    }
    dialogCenter();
    
    // category class hide border
    function categoryClass(){
    	if ($(window).width() < 767) {
    		var hideBorder = $('.category-class .training-employee');
    		$(hideBorder).prev('.class-tiles').css('border','none');
    	}
    }
    categoryClass();
    
    //PHSSECOM-1402 - Below function is showing the error message for only IE browser
    
    function findMyBrowser() {
      var checkDialogCookie = document.cookie.indexOf('reopenDialog');
	  if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1)) {
	      if (checkDialogCookie < "0") {
		      dialog.open({
					html: 'American Red Cross Training Services no longer supports your browser. Use Chrome, Firefox, Safari, or Edge to access the site or call (888) 919-0868 and one of our representatives will assist you with this matter.',
					options: {
						dialogClass: 'openIfIE',
						width: 500,
						title: 'Message from Training Services',
						create: function (e, ui) {
				            var pane = $(this).dialog("widget").find(".ui-dialog-buttonpane");
				            $("<label class='dont-show-again'><input type='checkbox'/><span>Don't show this message again!</span></label>").prependTo(pane);
				        },
				        close: function() {
							if($('.dont-show-again input[type=checkbox]').prop('checked')) {
								if (checkDialogCookie < "0") {
									document.cookie = "reopenDialog=No; path=/; secure; samesite=none";
								}
							}
			    		},
						buttons: [{
							text: 'OK',
							click: function () {
								if($('.dont-show-again input[type=checkbox]').prop('checked')) {
									if (checkDialogCookie < "0") {
										document.cookie = "reopenDialog=No; path=/; secure; samesite=none";
									}
								}
								$(this).dialog('close');
							}
						}, {
							text: 'Cancel',
							click: function () {
								if($('.dont-show-again input[type=checkbox]').prop('checked')) {
									if (checkDialogCookie < "0") {
										document.cookie = "reopenDialog=No; path=/; secure; samesite=none";
									}
								}
								$(this).dialog('close');
							}
						}]
					}
			  });
	      } 
	  }
   } 
    
    $(window).load(function(){
    	findMyBrowser();
		 /**TSED-1031 */
		if($(window).width() > 767) {  
			util.searchResultClass();
		}
    });
    // end- PHSSECOM-1402
    
  //start back to top functionality
	    var btt = $('.back-to-top');
	    btt.on('click',function(e){
	      util.scrollBrowser(0);
	      e.preventDefault();
	    });
	    $(window).scroll(function(){
	    	if($(window).scrollTop() > 200) {
	    		$(".back-to-top").addClass("show-back-top");
	    	} else {
	    		$(".back-to-top").removeClass("show-back-top");
	    	}
	    	var scroll = $(window).scrollTop();
	    	var value = $(window).height() + $(window).scrollTop(); 
	    	var elementTop = $('.footer-container').offset().top;
	    	if( value >= elementTop ) {
	    	   $('.footer-container').addClass('back-position');
	    	}
	    	else if( value < elementTop ) {
	    	    $('.footer-container').removeClass('back-position');  
	    	}
	    });
    //end back to top functionality
    
	 
    // Sticky header code goes here
        $('.global-header-main').wrap('<div class="sticky-container"></div>');
        function stickeyContainer() {
            var $stickHeight = $('.global-header-main').outerHeight();
            $('.sticky-container').css('height', $stickHeight);
        }
        stickeyContainer();

        function stickeyHeader() {
            var previousScroll = 0;
            $(window).scroll(function() {
                var currentScroll = $(window).scrollTop();
	            var outerbanerheight = $('.header-banner').outerHeight();
                if (currentScroll > outerbanerheight){
	                if ((currentScroll > previousScroll) && (currentScroll > outerbanerheight)) {
		                $('.global-header-main').removeClass('default').addClass('fixed');
                        $('.global-header-main.fixed').css('top',-outerbanerheight);
                    } else if ((currentScroll < previousScroll) && (currentScroll > outerbanerheight)) {
                        $('.global-header-main').removeClass('fixed').addClass('default');
                        $('.global-header-main.default').css('top','0');
                    }
	    	        previousScroll = currentScroll;
	            }
            });
        }
        stickeyHeader();
        
    // for footer live chat when it is disabled in BM
    if(!$('.liveChatOffInBM').val()) {
    	$('.footer_chat_online_cont').css('display', 'none');
    	
    	// for the no search results product,Article page
    	//$('#liveagent_button_online3_57355000000CalH').css('display', 'none');
    	
    	// for the account page live chat link
    	$('#liveagent_button_online55_57355000000CalH').css('display', 'none');
    	
    	//for checkout and order confirmation pages
    	$('#liveagent_button_online66_57355000000CalH').css('display', 'none');
    }

	// TSED-1241 LiveChat footer content update on toggle.
	if($('.header-chat').is(':visible')) {
		$('#liveagent_footer_offline_cont').css('display', 'none');
		$('.footer_chat_online_cont').css('display', 'block');
	} else {
		$('#liveagent_footer_offline_cont').css('display', 'block');
		$('.footer_chat_online_cont').css('display', 'none');
	}

	//for the product search suggestions
    function searchSuggestion() {
    	if ($(window).width() > 767) {
    		$('.product-suggestions .product-suggestion').syncHeight();
    	}
    	else {
    		$('.product-suggestions .product-suggestion').each(function() {
    			$(this).height('auto');
    		});
    	}	
    }
    searchSuggestion();
    
    function equalWidth(){
    	$('find-a-class .autocomplete-results').css({'width':($('.form-class-search .formfield').width()+'px')});
    	$('find-a-class .autocomplete-results').css('width', '-=2px');
    }
    $(window).resize(function(){
        $('.slot-level-class .assetone').removeAttr('style');
    	    equalWidth();
    	    searchSuggestion();
    	    dialogCenter();
    	    stickeyHeader();
    	    stickeyContainer();
    	    //util.globalSlickslider();
    	    
    	    // for search results classes page
    	    if($(window).width() > 767) {
    	        util.searchResultClass();
    	        util.cartOuterheight();
    	        util.SingleOnlineClass();
    	    }
    	    else{
    	    	var start = 0;
    	    	$('.search-result-items li').each(function(){
    	    		var height = $(this).find('.class-month-timings').outerHeight();
    	    		if(height > start) {
    	    			start = height;
    	    		}
    	    		height = 0;
    	    	});
	    	$('.search-result-items .class-seats-price,.search-result-items .class-month-timings').outerHeight(start); 
	    }
	    if($(window).width() < 767) {
	        util.cartclassHeight();
    	 }
    });
    
    var banner_close = ('.header-banner-close');
    $(banner_close).on('click', function (e) {
    	e.preventDefault();
    	var dateObj = new Date();
    	dateObj.setTime(dateObj.getTime() + (6*60*60000));
    	document.cookie="closebanner="+'yes'+"; expires="+dateObj.toUTCString()+"; path=/; secure; samesite=none;";
    	$('.html-slot-container, .header-banner-close').hide();
    	stickeyContainer(); 
    });

    var header_banner = readCookie('closebanner');
    if(header_banner == 'yes'){
    	$('.html-slot-container, .header-banner-close').hide();
    }
    
    // show-hide password
    $(function() {
        $('span.showpwd').click(function() {
            var change = '';
            if ($(this).html() === 'Show') {
                $(this).html('Hide');
                $(this).closest('.field-wrapper').find($('input')).attr('type', 'text');
            } else {
                $(this).html('Show');
                change = 'password';
                $(this).closest('.field-wrapper').find($('input')).attr('type', 'password');
            }
        });
    });
    
    //customer service toggle active class for secondary navigation
    $('.pt_customer-service .nav .toggle, .pt_content .nav .toggle').on('click', function(){
    	$(this).toggleClass('active');
    });
    // faq's page scrolling to particular section
    $('.faq-landind-wrapper .red-cross-product').on('click', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($('.faq-landind-wrapper #red-cross-product').offset().top - 100);
    });
    
    $('.faq-landind-wrapper .ordering-and-payment').on('click', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($('.faq-landind-wrapper #ordering-and-payment').offset().top - 100);
    });
    
    $('.faq-landind-wrapper .shipping-info').on('click', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($('.faq-landind-wrapper #shipping-info').offset().top - 100);
    });
    
    $('.faq-landind-wrapper .reurns-and-exchange').on('click', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($('.faq-landind-wrapper #reurns-and-exchange').offset().top - 100);
    });
    
    $('.faq-landind-wrapper .american-red-cross-faq').on('click', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($('.faq-landind-wrapper #american-red-cross-faq').offset().top - 100);
    });
    
    //Adding Extra Classe For Left Navigation Section Based On Current Page
    if ($('.pagetype').length > 0) {
        var pageval = $('.pagetype').data('pagetype');
        var element = $('#secondary.nav').find('.' + pageval);
        element.addClass('currentpage');
    } 
    
	// no search result recommendation goes here
	
	 var sliderLength = $('.slider-recomond .tiles-container');
	 if($(sliderLength).find('.grid-tile').length > 5){
		 //$(sliderLength).find('.product-tile').removeClass('layout-recomond');
		 $(sliderLength).find('button').show();
	 }
	 else{
		 //$(sliderLength).find('.product-tile').addClass('layout-recomond');
		 $(sliderLength).find('button').hide();
	 }
	 
	 $('.slider-recomond .tiles-container').slick({
		  infinite: false,
		  speed: 300,
		  slidesToShow: 5,
		  slidesToScroll: 1,
		  arrows: true,
		  responsive: [
		    {
		      breakpoint: 1023,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 1
		      }
		    },
		    {	
		      breakpoint: 767,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
		});

		var speedInterval = $('.testimonialSpeed').val();

		// Slick slider for testimonial section
		function testimonialSlider() {
			$('.testimonial-content .testimonial-wrapper').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				speed: speedInterval,
				autoplay:true,
				responsive: [{
					breakpoint: 1023,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}]
			});
		}

		if($('.testimonial-content').length > 0) {
			testimonialSlider();
		}
}	


$('.supply-slider').slick({
	  infinite: true,
	  speed: 500,
	  slidesToScroll: 1,
	  dots: true,
	  autoplay:true,
	  autoplaySpeed: SitePreferences.HOMEPAGE_CAROUSEL_DELAY
	});

//TSED-98 
if($('#spot-slider').find('.spot-container').length > 1) {
	$('#spot-slider').slick({
		  infinite: true,
		  speed: 500,
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows: false,
		  dots: true,
		  autoplay:true,
		  cssEase: 'linear',
		  autoplaySpeed: SitePreferences.HOMEPAGE_B_SPOT_CAROUSEL_DELAY
	});
}

function headerSlick(){ 
    if ($('.header-banner').find('.banner-asset').length > 1) {
		$('.global-header-promotion .text-middle').not('.slick-initialized').slick({
           infinite: true,
           slidesToShow: 1,
           slidesToScroll: 1,
           autoplay: true,
           vertical: true,
           arrows: false,
           cssEase: 'linear',
           autoplaySpeed: SitePreferences.HOMEPAGE_ROTATING_BANNER_DELAY
		 });
		
	};
	
	var maxHeight = -1;
	$('.global-header-promotion .text-middle .slick-slide').each(function() {
	  maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	});

	$('.global-header-promotion .text-middle .slick-slide').each(function() {
	 $(this).height(maxHeight);
	 
	});
		
    }
function headerPosition() {
	if($('.global-header-main').width() < 1366) {
    	$('.global-header-main.default').css('left','0');
    	$('.global-header-main.fixed').css('left','0');
    } else {
    	$('.global-header-main.default').css('left','auto');
    	$('.global-header-main.fixed').css('left','auto');
    }
}
/** TSED-169 Read More func in category pages */
function readMoreLink() {
	var showChar = 100;
	var moretext = "Read more";
	var lesstext = "Read less";
	var dots = "...";
	var $this = $('.categorypage_subtext').html();
    var content = $this;
    if(content != undefined) {
		if(content.length > showChar) {
			var c = content.substr(0, showChar);
			var h = content.substr(showChar, content.length - showChar);
			var html = c + '<span class="dots">'+ dots +'</span><span class="morecontent"><span>' + h + '</span>&nbsp; <span class="morelink">' + moretext + '</span></span>';
			$('.categorypage_subtext').html(html);
		}
    }

	$(".morelink").click(function(){
		if($(this).hasClass("less")) {
			$(this).removeClass("less");
			$(this).html(moretext);
			$(this).addClass("dots");
		} else {
			$(this).addClass("less");
			$(this).removeClass("dots");
			$(this).html(lesstext);
		}
		$(this).parent().prev().toggle();
		$(this).prev().toggle();
		return false;
	});
}

//This is used for Pre-populating current location
function readCookie (name) {
	 var nameEQ = name + "=";
	 var ca = document.cookie.split(';');
	 for(var i=0;i < ca.length;i++) 
	 {
		   var c = ca[i];
		   while (c.charAt(0)==' ') c = c.substring(1,c.length);
		   if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	 }
	 return null;
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
	 
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll'); 
    }
    // load js specific styles
    util.limitCharacters();
    
    $(window).on('load', function() {
    	//TSED-360:Disclaimer Message Displaying on No review in course PDP.- starts
    	if($(".bv-reviews .product-reviews").length > 0) {
    		var layout = document.querySelector(".bv-reviews .product-reviews").shadowRoot.querySelector("#reviews_container");
        	if(layout) {
        		$('.disclaimer-content').show();
				$(".bv-reviews .product-reviews").css('min-height', '1400px');
        	} else {
        		$('.disclaimer-content').hide();
        	}
    	}
    	//TSED-360:Disclaimer Message Displaying on No review in course PDP.- Ends
    	
    	/* TSED-249 - Start */
	    /*if($('.bv-content-list-container .bv-content-placeholder').length){
	    	$('.disclaimer-content').hide();
	    } else{
	    	$('.disclaimer-content').show();
	    }*/
	    /* TSED-249 - End */
    }).on('resize', function() {
        $('.ui-dialog').position({
            my: 'center',
            at: 'center',
            of: window
        });
        headerPosition();
    });
    if($(window).width() < 767) {
        util.cartclassHeight();
    }
    util.alphanumeric();
    util.alphaonly();
    util.allowLtdSplChars();
    util.allowNumeric();
    util.nonASCII();
    console.log('***initializeDom***');
    
}

//TSED-519 & TSED-710 Content assets webforms validation
// TSED-917 Calling validationWebforms function in reCaptcha implementation in content assets
function validationWebforms(e, form, arr) {
	e.preventDefault();
	e.stopPropagation();
	//var regexp = /^[a-zA-Z-_]+$/;
	var isValidForm = true;
	var validMap = arr;
	var companyRgx = /google|jumboleadmagnet/i;
	if($(form).find("input[type=radio]:checked").length) {
		var checkedVal = $(form).find("input[type=radio]:checked").val();
	}
	for(var i=0;i<validMap.length;i++) {
		var item = validMap[i].fld,
		    regx = validMap[i].regx,
			idx = i,
			fld = $(form).find('input[name="'+ item +'"]').val(),
			errMsg = $(form).find('.error-msg')[idx];

		if(fld && item === 'company') {
			fld = fld.replace(/\s/g, '');
			if(fld && item === 'company' && companyRgx.test(fld)) {
				$(form).find('.company-msg').show();
				isValidForm = false;
			} else if(fld && item === 'company' && !companyRgx.test(fld)){
				$(form).find('.company-msg').hide();
			} 
		}
		
		if(fld && regx && regx.test(fld) === false) {
			errMsg.style.display="block";
			isValidForm = false;
		} else if(!fld){
			errMsg.style.display="none";
			isValidForm = false;
		} else {
			errMsg.style.display="none";
		}
		if($(form).find("input[type=radio]:checked").length) {
			if(!checkedVal) {
				isValidForm = false;
			}else if(checkedVal && checkedVal === 'Student') {
				document.getElementsByClassName('student-errormsg')[0].style.display="block";
				document.getElementsByClassName('instructor-errormsg')[0].style.display="none";
				isValidForm = false;
			} else if(checkedVal && checkedVal === 'Instructor'){
				document.getElementsByClassName('instructor-errormsg')[0].style.display="block";
				document.getElementsByClassName('student-errormsg')[0].style.display="none";
				isValidForm = false;
			}
		}

	}
	return isValidForm;
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    //purchaseorder: require('./pages/purchaseorder'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    prestan: require('./pages/product')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();
        util.selectbox();
    	util.readMoreLink();
        util.appendEmailNotification();
        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        contentmigration.init();
        certificates.init();
		autocomplete.init();
		homepage.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
        if(ns = 'prestan'){
        	pages['prestan'].init();
        }
        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        
        
        
        //PREVAIL-Initializations
        if (!SitePreferences.OLD_CHECKOUT_ENABLED) {
        	require('./spc').init();
        }
    /*    if (isSPCEnabled) {
            require('./spc').init();
        }
	*/
        if (SitePreferences.isGTMEnabled) {
            require('./gtm').init();
        }
        
     /*   if (isBISNEnabled) {
            require('./bisn').init();
        }

        require('./sessionbased').init();
    */ 
    }
};


// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

$(window).on('load',function(){
	util.updateselect();
	util.apoToltip();
	headerPosition();
	// for updating the stickey header height after removing the promotion banner
	var $stickHeight = $('.global-header-main').outerHeight();
    $('.sticky-container').css('height', $stickHeight);
    $('.header-banner').find('.global-header-promotion').removeClass('header-banner-promo');
	// for hiding the select a class type option in the select box
	$('.global-header-main').find('.custom-select .selection-list li[label = "select a class type"]').css('display', 'none');
	$('.hero-images .custom-select .selection-list').find('li:first-child').css('display', 'none');
	headerSlick();
	//Filtering saerch tabs based on category navigation
	var catType = $('input[name="categoryType"]').val();
	if (catType !=='') {
		if (catType === 'class') {
			$('.header-search-flyout .class-label span').not('.certificate-layout .header-search-flyout .class-label span').trigger('click');		
		} else if (catType === 'product') {
			$('.header-search-flyout .product-label span').not('.certificate-layout .header-search-flyout .product-label span').trigger('click');
		} else {
			$('.header-search-flyout .article-label span').not('.certificate-layout .header-search-flyout .article-label span').trigger('click');
		}
	} else {
		$('.header-search-flyout .article-label span').not('.certificate-layout .header-search-flyout .article-label span').trigger('click');
	}
	  
  if($('.view-all-classes').length > 0) {
		var $subcatlink = $('a.view-all-classes').closest('.url-data');
		var params = {
			cgid: $subcatlink.find('.currentURL').val(),
			latitude: $subcatlink.find('.updatedlat').val(),
			longitude: $subcatlink.find('.updatedlong').val(),
			searchtype: $subcatlink.find('.classType').val(),
			zip:$subcatlink.find('.zip').val() 
		};
		var redirectURL = util.appendParamsToUrl(Urls.subCatRedirect, params);
		$('a.view-all-classes').attr('href', redirectURL);
  }
  // for removing the border in search results classes page
  $('.class-only-24').closest('.class-month-timings').addClass('no-border');
  
console.log('***onload ****');
$('.bv-learnmore-link').each(
		function onloadAddClass(a){
		var $this = $(this);
		$this.addClass('readmelink');
 });

$('.bv-product-title-link').each(
		function onloadAddClass(a){
		var $this = $(this);
		$this.addClass('imagehyperlink');
 });

$('.readmelink').on('click', function(e){
	 e.preventDefault();
   	var href = $(this).attr('href');
   	setTimeout(function(){
   	 var instanceType = $('#instanceType').val();
			 var finalHref = '';
			if(instanceType != undefined && instanceType == 'production') {
		    	finalHref = href;
			} else {
				var requestedHosturl = $('#hostUrl').val();	 
		    	var finalHref = 'https://'+requestedHosturl+'/s/RedCross/take-a-class/'+href.substring( href.indexOf('classes'), href.length);	
			}
			window.location.href =  finalHref+"&csrmater=true";	 
   	
   	},100);
   }); 

//calling blur event to trigger sms subscription event - PHSSECOM-2028
if($('.pt_checkout .billing-section .phone').val() != null && $('.pt_checkout .billing-section .phone').val() != ''){
 	$('.pt_checkout .billing-section .phone').trigger('blur');
}else{
	if($('.pt_checkout .billing-section .phone').length && $('.pt_checkout').hasClass('smsreguser')){
		$('.pt_checkout .billing-section .phone').trigger('focus');
		$('.pt_checkout .billing-section .phone').trigger('blur');
		if($('.pt_checkout .billing-section .phone').hasClass('error')){
			$('.pt_checkout .billing-section .phone').removeClass('error');
			$('.pt_checkout .billing-section .phone').closest('.form-row').find('span.error').remove();
		}
	}
}
// ends - PHSSECOM-2028
  
//TSED-7
$('.pt_checkout .estimated-ship-tooltip').on('click',function(){
	$('.contentestimated').show();
	});
$('.pt_checkout .contentestimated .fa.fa-close').on('click',function(){
	$('.contentestimated').hide();
});
$('.imagehyperlink').on('click',function(e){
	 e.preventDefault();
	 var $this = $(this);
	 var href = $(this).attr('href');
		 setTimeout(function(){
			var instanceType = $('#instanceType').val();
		var finalHref = '';
		
		if(instanceType != undefined && instanceType == 'production') {
			finalHref = href;
		} else {
			var requestedHosturl = $('#hostUrl').val();	 
	    	    finalHref = 'https://'+requestedHosturl+'/s/RedCross/take-a-class/'+href.substring( href.indexOf('classes'), href.length);
		}
		
	window.location.href =  finalHref+"&csrmater=true";	 
	},100);
	 
	 });
	const observer = lozad(); // lazy loads elements with default selector as '.lozad'
	observer.observe();
}); 

// initialize app
$(document).ready(function () {
    app.init();
    headerSlick();
	//TSED-1140: Aria-label for Footer email signup
	util.ariaLabel('#email-alert-signup');
    //TSED-917: Implement ReCaptcha on Contact Us
    $('.contact-us-wraper #RegistrationForm').find('#sendBtn').on("click", function(e) {
    	e.preventDefault();
    	var $form = $('#RegistrationForm');
    	// Add reCaptcha logic
    	if (SitePreferences.CAPTCHA_ENABLED) {
            var formActionURL = $form.attr('action');
            var data = $form.serialize();
            var $recaptcha = $form.find('[name="g-recaptcha-response"]');
            var siteKey = $recaptcha.data('sitekey');
            var action = $form.find('[name="g-recaptcha-action"]').val();
            grecaptcha.ready(function() {
            	grecaptcha.execute(siteKey, {action: action}).then(function (token) {
                	formActionURL = util.appendParamsToUrl(formActionURL, {token: token});
                	$('.contact-us-wraper').find('#googleToken').val(token);
                	$form.trigger('submit');
                });
            });    
        } else {
        	$form.trigger('submit');
        }
    });
    
    function validateSpamEmail(emailID) {
    	var isValidEmail = false;
    	var spamDomains = JSON.parse(SitePreferences.EMAILCHECKLIST);
		$.each( spamDomains, function( key, value ) {
			var spamEmail = value;
	    	if(spamEmail === emailID) {
	    		isValidEmail = false;
	    		return false; // breaks
	    	} else {
	    		isValidEmail = true;
	    	}
		});
		return isValidEmail;
    }
    
    //917: Implement ReCaptcha for form submissions
    $(".reCaptchaValidation").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		var form = $(e.target).parents('form')[0];
		var $thisform = $(this).closest('form');
		$('#error_message').remove();
		var $scrollElement;
		if ($(this).parents().is('#form-popup')) {
			$scrollElement = $('.mfp-content');
		} else {
			$scrollElement = $('html, body');
		}
		var formId = form.id;
		var formName = form.dataset.name;
		var validMap = form_regex[formName];

		var isFormValid = validationWebforms(e, form, validMap);
		var url = Urls.validateRecaptcha;
		var emailID = $(form).find('input[name="email"]').val();
		var isValidEmail = false;
		var isEmailValid = false;

    	if(isFormValid) {
    		// Add reCaptcha logic
        	if (SitePreferences.CAPTCHA_ENABLED) {
                var $recaptcha = $(form).find('input[name="g-recaptcha-response"]');
                var siteKey = $recaptcha.data('sitekey');
                var action = $(form).find('input[name="g-recaptcha-action"]').val();
                grecaptcha.ready(function() {
                	grecaptcha.execute(siteKey, {action: action}).then(function (token) {
                		url = util.appendParamsToUrl(url, {token: token});
                		//Calling back end logic to validate bot request 
                		$.ajax({
                            url: url,
                            dataType: 'json',
                            success: function(data) {
                            	var botStatus = data.status;
                            	var submitStatus = data.formSubmitStatus;
    							console.log(botStatus);
                            	if(botStatus) {
                            		//TSED-1223: Limit Forms to Only Accept One Submission Per User Per Session
                            		if(!submitStatus){
										$('#error_message').remove();
                            			//Display the error message
										$thisform.prepend(`<div id="error_message" class="error">${Resources.VALIDATE_SUBMISSION_TIME}</div>`);
										$scrollElement.animate({
											scrollTop: $('#error_message').position().top
										}, 800);
                            		} else {
                            			//TSED-1166: Investigation: Web to Lead Spam Submissions on organizations contact-us pages
                                		if(emailID != undefined && emailID != '' && emailID != null){
											$('#error_message').remove();
                                			isEmailValid = validateSpamEmail(emailID);
                                    		if(isEmailValid) {
                                    			$(form).trigger('submit');
                                    			setTimeout(function() {$(form)[0].reset()}, 100);
                                    		}
                                		}
                            		}
                                } else {
                                	window.location = Urls.continueHomePage;
                                }
                            }
                        })
                    });
                });    
            } else {
            	//TSED-1166: Investigation: Web to Lead Spam Submissions on organizations contact-us pages
            	if(emailID != undefined && emailID != '' && emailID != null){
            		isEmailValid = validateSpamEmail(emailID);
                	if(isEmailValid) {
                		$(form).trigger('submit');
                    	setTimeout(function() {$(form)[0].reset()}, 100);
            		}
            	}
            }
    	}

    });
    
    /*
    var certificate =  $("#certificate").val();
		if( certificate != null && certificate != ""){
	    	  $('.certificate-layout .certificate-label span').off('click').on('click', function() {
	    			$('.certificate-search').addClass('active').siblings().removeClass('active');
	    			 $('.certificate-layout .certificate-label span').parent('li').addClass('active').siblings().removeClass('active');
	    			$('#emailform')[0].reset();
	    			$('#namesfrom')[0].reset();
	    			$('#certificatefrom')[0].reset();
	    	    });
	    	  $( ".certificatesubmit" ).trigger( "click" );
	    } */
		
    // TSED-472: Fix for space issue on order details page with shipping rows.
    $('.orderdetails .order-shipping').each(function(e,i) {	
    	var rowText = $(i).text().trim();
    	if(!rowText) {
    		$(i).hide();
    	}
    });

	/** TSED-475 - Tech Audit: Category URL filter does not change */
	$('.top-menu-category li a').on('click', function(){
		var $url = $(this).attr('href');
		history.pushState($url, '', $url);
	});

	/** TSED-484 - Tech Audit: Course Finder Module URL is changing upon different selctions */
	$('.header-class-search.cat-search .custom-select, .custom-select.class-categories .select-class').on('change', function(){
		var url = window.location.href;
		history.pushState(url, '', url);
	});

	/**  Start: Jira TSED-1188 - Static Pages Scrolling to anchor id */
	$('.nav-header a, .linkForm a').on('click', function(e){
		e.preventDefault();
		var $ele = $(this).attr('href');
		util.scrollBrowser($('.jump-link' +$ele).offset().top - 100);
	});
    /**  End: Jira TSED-1188 - Static Pages Scrolling to anchor id */
});

$('.main-label').on('click',function(e){
	if($(this).hasClass('disabled')) {
		$(this).closest(".i-would-liketo").removeClass("active");
	}
	else {
		$(this).closest(".i-would-liketo").toggleClass("active");  
	}
	
});

// for checkout new design
if (!SitePreferences.OLD_CHECKOUT_ENABLED) {
	$(".chkout-toolbox").appendTo(".business-tooltip .field-wrapper"); 

	$('.visa-card-image').appendTo('.spc-cvn .field-wrapper'); 
}




