'use strict';

var util = require('./util'),
	page = require('./page'),
	atmevents = require('./atmevents'),
    bonusProductsView = require('./bonus-products-view');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
     this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');
        this.$products = this.$el.find('.mini-cart-products');
        if (this.$products.find('.mini-cart-product').length > 3 || ($(window).width() < 767 && this.$products.find('.mini-cart-product').length > 2)) {
        	var prdsheight = 3;
        	if($(window).width() < 767){
        		prdsheight = 2;
        	}
        	if(this.$content.is(':visible')){
        		calculateMiniCartHeight(this, prdsheight);
    		} else {
    			this.$content.addClass('active');
    			calculateMiniCartHeight(this, prdsheight);
    			this.$content.removeClass('active');
    		}
        }
        /*if($(window).width() < 767) { 
	        if (this.$products.find('.mini-cart-product').length > 2) {
	        	var heightVar = $('.mini-cart-product').eq(0).outerHeight() * ($('.mini-cart-product').length > 2 ? 2 : 1 );
	            this.$products.height(heightVar);
	            this.$products.perfectScrollbar();
	        }
        }*/
        $('.mini-cart-product').eq(0).find('.mini-cart-toggle').addClass('fa-caret-down');
        $('.mini-cart-product').not(':first').addClass('collapsed')
            .find('.mini-cart-toggle').addClass('fa-caret-right');

        $('.mini-cart-toggle').on('click', function () {
            $(this).toggleClass('fa-caret-down fa-caret-right');
            $(this).closest('.mini-cart-product').toggleClass('collapsed');
        });

        // events
        this.$el.find('.mini-cart-link').on('click',function(e) {
        	if(($('#wrapper').hasClass('pt_checkout')) || ($('#wrapper').hasClass('pt_cart'))) {
        		return;
        	} else {
            	e.preventDefault();
            	if(!$('.mini-cart-content').hasClass('active')) {
                	$('.mini-cart-content').addClass('active');
                	$('.mini-cart-link,.mini-cart-link .minicart-quantity').addClass('icon-change');
                }
                else {
                	$('.mini-cart-content').removeClass('active');
                	$('.mini-cart-link,.mini-cart-link .minicart-quantity').removeClass('icon-change');
                }
        	}
        });
    	/*$(document).mouseup(function(e) {
    		if (!$(e.target).closest('.mini-cart-content').length && $('.mini-cart-content').is(':visible')){
    			$('.mini-cart-content').hide();
    		} else {
    			$('.mini-cart-content').show();
    		}
    	});*/
        /*this.$el.find('.mini-cart-total').on('mouseenter', function () {
            if (this.$content.not(':visible')) {
                this.slide();
            }
        }.bind(this)); */

        /*this.$content.on('mouseenter', function () {
            timer.clear();
        }).on('mouseleave', function () {
            timer.clear();
            timer.start(30, this.close.bind(this));
        }.bind(this));*/
        $(document).on('click', function(e) {
            if (!$(e.target).closest('.mini-cart-content,.mini-cart-link,.mini-cart-link .minicart-quantity').length){
            	$('.mini-cart-content').removeClass('active');
            	$('.mini-cart-link,.mini-cart-link .minicart-quantity').removeClass('icon-change');
            }
        });
        $('body').find('.mini-cart-content .button-text.cartpagecontinue').on('click', function (e) {
            e.preventDefault();
            
            $('.mini-cart-content').removeClass('active');
            $('.mini-cart-link,.mini-cart-link .minicart-quantity').removeClass('icon-change');
            //page.redirect(Urls.continueHomePage); 
        }); 
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.$el.html(html);
        //PHSSECOM-1954 - Docking page on Category Gridwall or Quickview
        if(!$('#QuickViewDialog').is(':visible')) {
        	//util.scrollBrowser(0);
        }
        if($('#pdpMain').find('.add-to-cart').length > 0 && $('#pdpMain').find('.quickview-nav').length == 0) {
        	util.scrollBrowser(0);
        }
        this.init();
        this.slide();
        bonusProductsView.loadBonusOption();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(3000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
    },
    refresh: function(){
    	$.ajax({
    		url: Urls.minicarthtml,
    		dataType: 'html',
    		success: function(response){
    			minicart.show(response);
    		}
    	});
    }
  
};

var $main = $('#mini-cart');
$main.on('click', '.remove-link', function (e) { 
	e.preventDefault();
	$main.find('.remove-link').css('pointer-events', 'none'); 
	var itemid = $(this).closest('.minicart-data').find('.item-id').val();
	
	//adobe analytics for Cart Removal - Shopping Cart Page
	 if(SitePreferences.isATMEnabled) {
     	atmevents.removeItemFromCart(this);
     }
	 if($(this).closest('.mini-cart-product').hasClass('course')) {
       removeItem(itemid,true);
	 } else {
       removeItem(itemid,false);
	 }


    //removeItem(itemid);
  });


function removeItem(itemid,reloadPage) {
    var url = util.appendParamToURL(Urls.removeMinicart, 'pid',itemid);
    $.ajax({
          url : url,
          success: function (response) {
             if( response!= null) {
                    if(typeof reloadPage != 'undefined' && reloadPage) {
                        window.location.reload();
                    } else {
                        $('#mini-cart').html(response);
                        $('#mini-cart').find('.mini-cart-content').addClass('active');
                        $('.mini-cart-link,.mini-cart-link .minicart-quantity').addClass('icon-change');
                        minicart.init();
                    } 
             }
          }
    }); 
}
 

function calculateMiniCartHeight($this, prdsheight) {
	var heightVar = $('.mini-cart-product').eq(0).outerHeight() * (prdsheight);
	$this.$products.height(heightVar);
	$this.$products.perfectScrollbar();
}

module.exports = minicart;
