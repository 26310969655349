'use strict';
var formPrepare = require('./pages/checkout/formPrepare');
exports.init = function init () {
	
	// Local Landing Page Template
	// Drop 6 Toggle functionality goes here   
    var $main = $('#main');
    $main.on('click', '.folder-refinement #selectProgramDropdown', function () {
		if(!$('.folder-refinement').hasClass('active')) {
			$('.folder-refinement').addClass('active');
	    }
	    else {
	    	$('.folder-refinement').removeClass('active');
	    }
    });
    
    if($(".content_template #category-level-2 li").hasClass('active')) {
    	$(".content_template #category-level-2 .active span").addClass("activenav2");
    }
    
    if($(".content_template #category-level-3 li span").hasClass('activenav2')) {
    	$(".content_template #category-level-3 li span").removeClass("activenav2");
    }
    
    $("#category-level-2 .secondary-menu-arrow-mobile").click(function() {
    	if(!$('#category-level-2 .active').hasClass('activenav')) {
    		$('#category-level-2 .active').addClass('activenav');
    	} else {
    		$('#category-level-2 .active').removeClass('activenav');
    	}
    });
    
    $('.folder_control').on('click',function(e) {
    	
    	if ($(this).siblings('.hideFolders').is(':visible')) {
    		$(this).siblings('.hideFolders').hide();
    	} else{
    		$(this).siblings('.hideFolders').show();
    	}
    });
    var $this = $('.hideFolders').find('li.active');
    if($this.length>0){
    	$('.hideFolders').hide();
    	$this.parent().show();
    }else {
    	$('.hideFolders').hide();
    	$this.parent().hide();
    }
    formPrepare.init({
        continueSelector: '[name$="submit"]',
        formSelector:'[id$="RegistrationForm"]'
    });
};
	