'use strict';

var _ = require('lodash'),
    util = require('./util');

var formatString = function(str){
	if(str != undefined && str != ''){
		str =  str.replace(/&/g, 'and').replace(/;/g, ' ').replace(/,/g, ' ').replace(/\+/g, 'and').replace(/%/g, ' ').replace(/@/g, ' ').replace(/#/g, ' ')
			   .replace(/!/g, ' ').replace(/\(/g, ' ').replace(/\)/g, ' ').replace(/\[/g, ' ').replace(/]/g, ' ').replace(/{/g, ' ').replace(/}/g, ' ').replace(/=/g, ' ')
			   .replace(/\*/g, ' ').replace(/\^/g, ' ').replace(/-/g, ' ').replace(/\$/g, ' ').replace(/\*/g, ' ').replace(/\|/g,'/').replace(/"/g, '').replace(/'/g, ' ');
	   }
	return str; 
};
var index = 1;
exports.realEstateTrackinhHomePage = function(linkName,linkURL) {

	digitalData.event = {
       eventInfo:{
             eventName:"content click" 
          },
      category:{
             primaryCategory : "home page" 
          },
       attributes:{
             linkName : formatString(linkName), 
             linkURL: linkURL 
          }
      }
};

exports.headerNavigationlinks = function(linkName,linkParent,linkURL) {
	
	digitalData.event = {
    	   eventInfo : {
    		   eventName : "header nav click" 
    	     },
	       category : {
	    	   primaryCategory : formatString(document.title)
	       },
    	   attributes :{
    		   linkName : formatString(linkName),
    		   linkParent : linkParent,
    		   linkURL : linkURL,
    	   }  
       }
};

exports.footerrNavigationlinks = function(linkName,linkURL) {
	
	digitalData.event = {
	    eventInfo:{
	          eventName:"footer click"
	       },
	   category:{
	          primaryCategory: formatString(document.title)
	       },
	    attributes:{
	          linkName:formatString(linkName),
	          linkURL:linkURL
	       }
	   }
};

exports.footerSocialIconlinks = function(linkName,linkURL){
	
	digitalData.event = {
    	  eventInfo:{
              eventName:"social click"+String(index)
           },
    	   category:{
             primaryCategory : formatString(document.title) 
          },
          attributes:{
             linkName : linkName,
             linkURL : linkURL
          }
       }
	index = index+1;
 };
 
 
 exports.footerCustomerServicePhoneLink = function(linkName,linkURL){
		
	digitalData.event = {
	    	  eventInfo:{
                  eventName:"customer service phone click"+String(index)
               },
        	   category:{
                 primaryCategory : formatString(document.title)
              },
              attributes:{
                 linkName : linkName,
                 linkURL : linkURL
              }
           }
	   index = index+1;
	 };
	 
exports.footerLiveChatLink = function(){
	
	digitalData.event = {
	    	  eventInfo:{
                  eventName : "live chat click"+String(index)
               },
        	   category:{
                 primaryCategory : formatString(document.title)
              },
              attributes:{
                 linkName : "chat",
                 linkURL : "https://apu.la4-c1cs-was.salesforceliveagent.com"
              } 	 
           }
	index = index+1;
 };
 

exports.emailSubmitFooter = function(linkName,linkURL) {
	digitalData.event = {
       eventInfo:{
             eventName:"email signup footer"+String(index)
          },
      category:{
             primaryCategory : formatString(document.title)
          },
       attributes:{
             linkName:"Email news" 
          }
      };
	index = index+1;
};

exports.EmailOptinCheckout = function(){
	
digitalData.event = {
        eventInfo:{
              eventName:"email signup checkout" 
           },
       category:{
              primaryCategory : formatString(document.title)
           },
        attributes:{
              linkName:"Email news" 
           }
       };
};


exports.createAccount = function() {
		
	digitalData.event = {
	         eventInfo:{
	               eventName:"new account creation" 
	            },
	        category:{
	               primaryCategory : formatString(document.title)
	            },
	         attributes:{
	               linkName:"sign me up"
	            }
	        };
     };

exports.findAClass = function($this) {
	var classSearchtype,classModule,classCity;
	 if($($this).closest('.header-class-search').length > 0){
		  classSearchtype = $('.header-class-search .selection-list').find('li.selected').text();
		  classCity = $('.header-class-search').find('.geo-location').val();
		  classModule = "search box";
	  }else if($($this).closest('.classes-search-action').length > 0){
		 classSearchtype = $('.classes-search-action .selection-list').find('li.selected').text();
		 classCity = $('.classes-search-action').find('.geo-location').val();
		 classModule = "utility search box";
	  }else if($($this).closest('.home-class').length > 0){
		  classSearchtype =  $('.home-find-classes-form .selection-list').find('li.selected').text().trim();
		  classModule = "content box search";
		  classCity = $('.home-class').find('.geo-location').val();
	  }
	digitalData.event = {
       eventInfo:{
             eventName:"find a class" 
          },
      category:{
             primaryCategory: formatString(document.title)
          },
       attributes:{
             linkName:"find a class", 
             linkParent:"header-geo-submit", 
             classCity: classCity, 
             classZip : $('.geo-complete-zip').val(),
             classModule: classModule,
             classsearchType : formatString(classSearchtype)
          }
      }
};

exports.courseListRefinement = function(linkName) {
		
	digitalData.event = {
        eventInfo:{
              eventName:"course refinement"+String(index)
           },
       category:{
              primaryCategory: formatString(document.title)
           },
        attributes:{
              linkName: formatString(linkName), 
              classCity:$('.geo-location').val(),
              classZip :$('.geo-zipcode').val(), 
              classsearchType: formatString($('.product-cat').val())
           }
	};
	index = index+1;
};


exports.productListRefinement = function(linkName) {
		
digitalData.event = {
       eventInfo:{
             eventName:"product refinement"+String(index)
          },
      category:{
             primaryCategory : formatString(document.title)
          },
       attributes:{
             linkName:linkName

          }
      }
  index = index+1;
};



exports.sortBy = function(linkName) {
	
	digitalData.event = {
	       eventInfo:{
	             eventName:"sort by click"
	          },
	      category:{
	             primaryCategory : formatString(document.title)  
	          },
	       attributes:{
	             linkName : linkName
	
	          }
    };
};


exports.productquickView = function(productObj) {
	
	var category;
	var productid = $(productObj).closest('.product-tile').attr('data-itemid');
	var name = formatString($(productObj).closest('.product-tile').find(' .name-link').text().trim());
	category = formatString($('.product-cat').val());
	
	digitalData.product =  {
              productInfo:{
                   productID : productid, 
                   productName: name 
             },
             category:{
                 primaryCategory : category,
                 productType:"supplies" 
            }
         };
        
         digitalData.event = {
           eventInfo:{
              eventName:"product quick view"+String(index) 
              }
		};
        index = index+1;
	};

exports.classSeeDetails = function(classObj) {
	var category;
	var productid = $(classObj).closest('.product-tile').attr('data-itemid');
	var name = formatString($(classObj).closest('.product-tile').find('.class-name-main').text().trim());
	var classSetting = formatString($(classObj).closest('.product-tile').find('.class-format').text());
	var classTime = $(classObj).closest('.product-tile').find('.strtime').text()+$(classObj).closest('.product-tile').find('.str_am_pm').text()  +" - "+$(classObj).closest('.product-tile').find('.endtime').text()+$(classObj).closest('.product-tile').find('.end_am_pm').text();
	var classdayofWeek = $(classObj).closest('.product-tile').find('.offer-day').text();
	var classDate =  $(classObj).closest('.product-tile').find('.offer-month').text()+" "+$(classObj).closest('.product-tile').find('.offer-date').text();
	category = formatString($('.product-cat').val());
	
	
	digitalData.product = {
             productInfo : {
            	productID : productid,
            	productName : name
            },
            category : {
                primaryCategory : category,
                productType:"course" 
           },
            attributes : {
              classSetting : classSetting,
              classzipCode: $('.geo-zipcode').val(), 
              classTime : classTime,
              classdayofWeek : classdayofWeek,
              classDate: classDate
            }
        }
     
	digitalData.event = {
          eventInfo:{
             eventName:"product quick view"+String(index) 
             }
         } 
	index = index+1;
	};
	
exports.signMeUp = function(category,productid,name,classSetting,classTime,classdayofWeek,classDate) {
	
	digitalData.cart = {
	        item:[{
             productInfo : {
            	productID : productid,
            	productName : formatString(name)
            },
            category : {
                primaryCategory : formatString(category),
                productType:"course" 
           },
            attributes : {
              classSetting : formatString(classSetting),
              classzipCode: $('.geo-zipcode').val(),
              classTime : classTime,
              classdayofWeek : classdayofWeek,
              classDate: classDate
            }
        }]
	}
	digitalData.event = {
          eventInfo:{
             eventName:"cart add"+String(index)
             }
	  };
	index = index+1;
	};

exports.videodetails = function(linkName){
	
	digitalData.event = {
             eventInfo:{
                   eventName:"video play"+String(index) 
                },
            category:{
                   primaryCategory : formatString(document.title)
                },
             attributes:{
                   linkName : linkName
                }
            };
	index = index+1;
	
}

exports.cancelationPolicy = function(){
	
	digitalData.event = {
       eventInfo:{
             eventName:"cancellation policy click" 
          },
      category:{
             primaryCategory : formatString(document.title)
          },
       attributes:{
             linkName:"cancellation policy link" 
          }
	 };
  };
  
 exports.findAnotherClass  = function(){
		
		digitalData.event = {
           eventInfo:{
                 eventName:"find another class click" 
              },
          category:{
                 primaryCategory:"sign me up modal" 
              },
           attributes:{
                 linkName:"Find Another Class" 
              }
	     };
  };

 exports.addToCartbutton = function($form){
	 var category = formatString($('.product-cat').val()); 
	 if($($form).closest('.product-col-2.product-detail.product-set').length > 0){
		 var  name = formatString($($form).closest('.product-col-2.product-detail.product-set').find('.product-set-name').text()),
	      pid = $form.find("input[name='mpid']").val(),
	      qty= "1";
		  var price = $('.standardprice').text(),
		  price = $('.salesprice').text();
		  var priceval = price.replace('$','').trim();
	 } else if($($form).closest('.plp-tile').length > 0) {       // adding adobe analytics for PLP add to cart
		 var  name = $form.find("input[name='product-name']").val(),
	      pid = $form.find("input[name='pid']").val(),
	      qty= "1",
	      price = $form.find('input[name="product-price"]').val();
		  var priceval = price.replace('USD','').trim();
	 } else {
		 var  name = formatString($($form).closest('.product-col').find('.product-detail .product-name').text()),
	      pid = $form.find("input[name='mpid']").val(),
	      qty= $form.find('input[name="Quantity"]').val(),
	      price = $($form).closest('.product-col-2.product-detail').find('.price-sales').text();
		  var priceval = price.replace('$','').trim();
	 }
	digitalData.cart =  {
        item:[{
            productInfo : {
                   productID : pid, 
                   productName: name
	             },
	         category : {
                 primaryCategory : category, 
                 productType:"supplies" 
                },
           quantity : qty, 
           price : priceval, 
          }] 
	}; 
	digitalData.event = {
       eventInfo:{
          eventName:"cart add"+String(index) 
          }
	};
	index = index+1;
 };
 
 
exports.removeItemFromCart = function($this){
	var category;
	var name = formatString($($this).closest('.mini-cart-product').find('.name').text().trim());
	var productid  = $($this).closest('.mini-cart-product').find('.item-id').val();
	var classSetting = formatString($($this).closest('.mini-cart-product').find('.class-format').text());
	var classTime = $($this).closest('.mini-cart-product').find('.strtime').text()+$($this).closest('.mini-cart-product').find('.str_am_pm').text()  +" - "+$($this).closest('.mini-cart-product').find('.endtime').text()+$($this).closest('.mini-cart-product').find('.end_am_pm').text();
	var classdayofWeek = $($this).closest('.mini-cart-product').find('.offer-day').text();
	var classDate =  $($this).closest('.mini-cart-product').find('.offer-month').text()+" "+$($this).closest('.mini-cart-product').find('.offer-date').text();
	var primaryCat = formatString($($this).closest('.mini-cart-product').find('.item-cat').val());
    var qty = $($this).closest('.mini-cart-product').find('.qty-product').find('.value').text()
    
    var price;
    if($('.prod-price').length > 0){
    	price = $('.prod-price').val();
    }else{
	    price = $('.item-price').val();
    }
    var priceval = price.replace('$','').trim();
    if($($this).closest('.mini-cart-product').hasClass('course')){
 		digitalData.cart = {
		        item:[{
	                productInfo : {
	                   productID : productid, 
	                   productName : name
	                },
	                category : {
	                  primaryCategory : primaryCat, 
	                  productType:"course"
	                },
	               
	               price : priceval, 
	               attributes : {
	                   classSetting : classSetting, 
	                   classzipCode : $('.geo-zipcode').val(),
	                   classTime : classTime, 
	                   classdayofWeek : classdayofWeek, 
	                   classDate : classDate,
	                }
		        }] 
	         };
	         digitalData.event = {
	  	       eventInfo:{
	  	          eventName:"product remove"+String(index)
	  	          }
	  	      }; 
	         index = index+1;
    	 };
     
  if($($this).closest('.mini-cart-product').hasClass('supplies')){
		
 		digitalData.cart = {
		        item:[{
	                productInfo : {
	                   productID : productid, 
	                   productName : name
	                },
	                category : {
	                  primaryCategory : primaryCat, 
	                  productType:"supplies" 
	                },
	               quantity : qty, 
	               price : priceval 
	            }]
	        }
        digitalData.event = {
	 	       eventInfo:{
	 	          eventName:"product remove"+String(index)
	 	          }
	 	      };
 		index = index+1;
    }
}
exports.applyCoupon = function(code){
	digitalData.event = {
         eventInfo:{
               eventName:"apply coupon"+String(index)
            },
        category:{
               primaryCategory: formatString(document.title)
            },
         attributes:{
               linkName:"apply coupon", 
               couponCode : code 
            }
	 };
	index = index+1;
  };
   
 exports.removeCoupon = function(code){
		
	digitalData.event = {
          eventInfo:{
                eventName:"remove coupon"+String(index) 
             },
         category:{
                primaryCategory: formatString(document.title)
             },
          attributes:{
                linkName:"remove coupon", 
                couponCode : code 
             }
         };
	index = index+1;
  };
  
 exports.anotherCoupon = function(){

	digitalData.event = {
       eventInfo:{
             eventName:"have a coupon code click"+String(index) 
          },
      category:{
             primaryCategory :  formatString(document.title)
          },
       attributes:{
             linkName:"have a coupon code" 
          }
	 };
	index = index+1;
  };
  
 exports.exploreMoreClasses = function(code){
 
	digitalData.event = {   
           eventInfo:{
                 eventName:"explore more classes click" 
              },
          category:{
                 primaryCategory : formatString(document.title)
              },
           attributes:{
                 linkName:"explore more classes"
              }
          };
};

exports.formErrorMyAccounts = function(formattrs){
	digitalData.event = {
               eventInfo:{
                   eventName:"form error" 
                },
            category:{
                   primaryCategory : formatString(document.title)
                },
             attributes : formattrs
	    };
};
  
exports.realEstateTrackingCartPage = function(linkName,linkURL){
 
	digitalData.event = {
         eventInfo:{
               eventName:"content click" 
            },
        category:{
               primaryCategory : "shopping cart page"
            },
         attributes:{
               linkName : formatString(linkName), 
               linkURL : linkURL
            }
	};
};

exports.realEstatetrackingSubCategory = function(linkName,linkURL){
	
	digitalData.event = {
        eventInfo:{
              eventName:"content click" 
           },
       category:{
              primaryCategory : "home page"
           },
        attributes:{
              linkName :  formatString(linkName),
              linkURL : linkURL
           }
	};
	
};

exports.addToCalendar = function(){
 	
	digitalData.event = {
         eventInfo:{
               eventName:"add to calendar"+String(index)
            },
        category:{
               primaryCategory : formatString(document.title)
            },
         attributes:{
               linkName:"add to calendar" 
            }
	};
	index = index+1;
};

exports.launchMyCourse = function(orderobj){
	var category;
	var productid = $(orderobj).closest('.cart-full-product').find('.product-id').text();
	var name = formatString($(orderobj).closest('.cart-full-product').find('.product-details-col .name a').text());
	var classSetting = formatString($('.online-launch').closest('.cart-full-product').find('.class-col-3.desktop-device .class-format').text());
	var classTime = $(orderobj).closest('.cart-full-product').find('.strtime').text()+$(orderobj).closest('.cart-full-product').find('.str_am_pm').text()  +" - "+$(orderobj).closest('.cart-full-product').find('.endtime').text()+$(orderobj).closest('.cart-full-product').find('.end_am_pm').text();
	var classdayofWeek = $(orderobj).closest('.cart-full-product').find('.offer-day').text();
	var classDate =  $(orderobj).closest('.cart-full-product').find('.offer-month').text()+" "+$(orderobj).closest('.cart-full-product').find('.offer-date').text();
	category = formatString($('.product-cat').val());
	
	digitalData.product = [{
          productInfo : {
              productID : productid, 
              productName : name
           },
           category : {
             primaryCategory : category, 
             productType:"course"
           },
          
          attributes : {
              classSetting : classSetting, 
              classzipCode : $('.geo-zipcode').val(),
              classTime : classTime, 
              classdayofWeek : classdayofWeek, 
              classDate : classDate,
           }
        }]
	
	digitalData.event = {
        eventInfo:{
              eventName:"launch my course" 
           },
       category:{
              primaryCategory:"order confirm" 
           },
        attributes:{
              linkName:"launch my course" 
           }
    };
	

};

exports.removeItemFromCartpage = function($this){

    if($($this).closest('.cartpage-class-section').length > 0){
    	var category;
    	var name = formatString($($this).closest('.cartpage-class-section').find('.name').text().trim());
    	var productid  = $($this).closest('.cartpage-class-section').find('.item-id').val();
    	var classSetting = formatString($($this).closest('.cartpage-class-section ').find('.class-col-3.desktop-device .class-format').text());
    	var classTime = $($this).closest('.cartpage-class-section').find('.strtime').text()+$($this).closest('.cartpage-class-section').find('.str_am_pm').text()  +" - "+$($this).closest('.cartpage-class-section').find('.endtime').text()+$($this).closest('.cartpage-class-section').find('.end_am_pm').text();
    	var classdayofWeek = $($this).closest('.cartpage-class-section').find('.offer-day').text();
    	var classDate =  $($this).closest('.cartpage-class-section').find('.offer-month').text()+" "+$($this).closest('.cartpage-class-section').find('.offer-date').text();
    	var primaryCat = formatString($($this).closest('.cartpage-class-section').find('.item-cat').val());
    	var price =  $($this).closest('.cartpage-class-section').find('.price-sales.price-standard').text().trim();
    	price =  $($this).closest('.cartpage-class-section').find('.promo-price.price-sales').text().trim();
    	
    	 var priceval = price.replace('$','').trim();
 		
 		digitalData.cart = {
		        item:[{
	                productInfo : {
	                   productID : productid, 
	                   productName : name
	                },
	                category : {
	                  primaryCategory : primaryCat, 
	                  productType:"course"
	                },
	               
	               price : priceval, 
	               attributes : {
	                   classSetting : classSetting, 
	                   classzipCode : $('.geo-zipcode').val(),
	                   classTime : classTime, 
	                   classdayofWeek : classdayofWeek, 
	                   classDate : classDate,
	                }
		        }] 
	         }
 		digitalData.event = {
  	       eventInfo:{
  	          eventName:"product remove" 
  	          }
    	 };
     }
	       
  if($($this).closest('.cartpage-product-section').length > 0){
	  var name = formatString($($this).parents('.cart-product').find('.name').text().trim());
  	  var productid  = $($this).closest('.cart-product').find('.sku .value').text();
  	  var primaryCat = formatString($($this).closest('.cart-product').find('.item-cat').val());
  	  var qty = $($this).closest('.cart-product').find('#updateqty').val();
  	  var price =  $($this).closest('.cart-product').find('.item-total').text().trim();
  	  var priceval = price.replace('$','').trim();
 		digitalData.cart = {
		        item:[{
	                productInfo : {
	                   productID : productid, 
	                   productName : name
	                },
	                category : {
	                  primaryCategory : primaryCat, 
	                  productType:"supplies" 
	                },
	               quantity : qty, 
	               price : priceval 
	            }]
	        };
        digitalData.event = {
	 	       eventInfo:{
	 	          eventName:"product remove" 
	 	          }
	 	      };  
       }
 }

exports.addToCartCertifications = function($this){
	  digitalData.cart = {};
	  digitalData.cart.item = [];
	 $('.eachcert').each(function(i) {
	     if ($(this).is(':checked')) {
		 digitalData.cart.item.push({
	                 productInfo : {
                        productID : $(this).parents('.field-wrapper').find('.certid').val(),
                        productName: $this.find('#pcname').val()
	                 },
	                 category : {
	                     primaryCategory: formatString($this.find('.product-cat').val()), 
	                     productType:"supplies" 
	                   },
	               quantity: $this.find('#Quantity').val(), 
	               price:$this.find('#cprice').val()
		         }) 
	           
	      digitalData.event = {
	           eventInfo:{
	              eventName:"cart add" //static, set to "cart add"
	              }
	          }
	      }
	  });
};

