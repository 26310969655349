'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
   /* $(document).tooltip({
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        }
    });

    $('a.tooltip').tooltip({         
        disabled: true,
        close: function( event, ui ) { $(this).tooltip('disable'); }
       });

     $('a.tooltip').on('click', function () {
        $(this).tooltip('enable').tooltip('open');
      });*/


    /*JIRA PREV-282 : DEV-32: SG issue- 'What is this' link in checkout billing page should not be clickable. Added the folloiwng block.*/
    $('a.tooltip').click(function (e) {
        e.preventDefault();
    });
};
