'use strict';

var compareWidget = require('../compare-widget'),
	dialog = require('../dialog'),
    productTile = require('../product-tile'),
    addToCart = require('./product/addToCart'),
    progress = require('../progress'),
    atmevents = require('../atmevents'),
    gtmevents = require('../gtm'),
    quickview = require('../quickview'),
    util = require('../util');  
    
 

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');
    var isclass = loadingPlaceHolder.attr('data-is-class');
    
    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
    	 if(loadingPlaceHolder.attr('data-loading-state', 'unloaded')) {
    	    	$(".results-hits").closest(".search-result-options").nextAll().addClass("scroll-hidden");
    	    	$(".results-hits").closest(".search-result-options").parent().nextAll().addClass("scroll-hidden");
    	    	$(".footer-item, .validateordercheck").addClass("scroll-hidden");
    	    	$("#BVSpotlightsContainer").addClass("scroll-hidden"); 
    	 }
    	 
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');
        if(loadingPlaceHolder.attr('data-loading-state', 'loading')) {
        	$(".results-hits").closest(".search-result-options").nextAll().addClass("scroll-hidden");
        	$(".results-hits").closest(".search-result-options").parent().nextAll().addClass("scroll-hidden");
        	$(".footer-item, .validateordercheck").addClass("scroll-hidden");
	    	$("#BVSpotlightsContainer").addClass("scroll-hidden"); 
        }
      
        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('div.search-result-content').append(html);
            if(loadingPlaceHolder.attr('data-loading-state', 'loaded')) {
            	$(".results-hits").closest(".search-result-options").nextAll().removeClass("scroll-hidden");
            	$(".results-hits").closest(".search-result-options").parent().nextAll().removeClass("scroll-hidden");
            	$(".footer-item, .validateordercheck").removeClass("scroll-hidden");
    	    	$("#BVSpotlightsContainer").removeClass("scroll-hidden");  
            }
            
        };  

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax

                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();
                const observer = lozad(); // lazy loads elements with default selector as '.lozad'
                observer.observe();
                courseId($('.infinite-scroll-placeholder:last'));
                if (isclass == "class") {
                	//util.searchResultClass(); //sync height of newly appended classes
                    if($(window).width() > 767) {
						 /**TSED-1031 */
						$('.search-result-items > li .class-wrapper').height('auto');
						setTimeout(util.searchResultClass(), 2000);
                        //util.SingleOnlineClass();
                    }      
                    else {
                    	var start = 0;
                    	$('.search-result-items li').each(function(){
                    		var height = $(this).find('.class-month-timings').outerHeight();
                    		if(height > start) {
                    			start = height; 
                    		}
                    		height = 0;
                    	});
                    	$('.search-result-items .class-seats-price,.search-result-items .class-month-timings').outerHeight(start); 
                    }
                }
            },
            error: function () {
                if ($('.infinite-scroll-placeholder[data-loading-state="loading"]').length >= 0) {
                    $('.infinite-scroll-placeholder[data-loading-state="loading"]').attr('data-loading-state','unloaded');
                    infiniteScroll();
                }
            }
        });
    }
    util.tooltipSearchresultpage(); // Added For PHSSECOM-361
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */


function updateProductListing(url,state) {
	if ((!url || url === window.location.href) && state == undefined) {
		$('.refinement.active').removeClass('active');
        return ;
    }
    var datemin = $('input:text[name=datemin]').val(); 
    var datemax = $('input:text[name=datemax]').val();
    
    /*if(url.indexOf('storerefine=facilityid') != -1){
	  $('#main').addClass('selected-refined');		
    }else{
      $('#main').removeClass('selected-refined');	
    }   
    */  
    if($('.radius').length > 0 && $('.radius').val() != '' && $('.searchtype').val() == 'class' && $('.radius').val() != SitePreferences.defaultRadius){
    	url = util.appendParamToURL(url,'radiusval',$('.radius').val());
    }
    progress.show($('.search-result-content'));
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
        compareWidget.init();
        productTile.init();
        progress.hide();
        util.updateselect();
		util.readMoreLink();
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
       	observer.observe();
        //util.searchResultClass();
       	if($(window).width() < 1023){
       		if((($('.sub-category-result .search-result-options.refinement .sort-by').find('li.selected').length > 0) && ($('.plp-refinements .refinement').find('li.selected').length == 0))) {
       		   $('.mobile-filters.iphone-only.refinement-til-tablet').find('.clear-mobile').addClass('disable');
       		   $('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').addClass('disable');
       		} else if( $('.classes-landing-page .search-result-options.refinement .sort-by').find('li.selected').length > 0 && $('.classes-landing-page .plp-refinements .refinement .location-radius-selection').find('li.selected').length > 0  &&  $('.courseformat').find('li.selected').length == 0  &&  datemin === '' && datemax === '' && $('.coursedaysofweek  li.selected ').length == 0 && $('.coursestarttime li.selected').length == 0){
       			$('.mobile-filters.iphone-only.refinement-til-tablet').find('.clear-mobile').addClass('disable');
       		} 
       	}
        /*Added for PHSSECOM-336*/
        if($(window).width() > 767) {
            util.searchResultClass();
        }      
        else { 
        	var start = 0;
        	$('.search-result-items li').each(function(){
        		var height = $(this).find('.class-month-timings').outerHeight();
        		if(height > start) {
        			start = height; 
        		}
        		height = 0;
        	}); 
        	$('.search-result-items .class-seats-price,.search-result-items .class-month-timings').outerHeight(start);
        } 
        /*Added for PHSSECOM-336 Ends*/
        util.SingleOnlineClass();
        if(state == undefined){
        	var cgid = $(this).find('.cgid').val();
        	var earl = !window.history.state ? window.location.href : url;
        	if(cgid){
        		earl = earl.replace('hidden', cgid);
        	}
        	history.pushState(earl, '', earl);
        }
        $('.dot-value').parent('li').addClass('padding-remove');
        $('.mobile-breadcrumbs .breadcrumb').next().remove();
        util.tooltipSearchresultpage();
       //$('.geo-complete-input').val($('.zip').val());
        if($('.zip').length > 0) {
			$('.location-radius-selection .geo-complete-input').val($('.zip').val().replace(/, United States$/, ""));
		}
 
        datepicker();
		 if((datemin != '' && datemax != '') && $('.breadcrumb-refinement-value a').length > 0){
			 $('input:text[name=datemin]').val(datemin);
			 $('input:text[name=datemax]').val(datemax);
		 }
        if($('.pagination-class').find('a').length >0 || $('.sort-by').hasClass('sort-by-class')){
            paginationAndBreadcrumbUrls();
            if (SitePreferences.LISTING_INFINITE_SCROLL) {
            	courseId($('.infinite-scroll-placeholder:last'));
            }
          }
		  if($('ul.price').find('li').hasClass('selected')){
			$('ul.price').find('li.selected input[type="radio"]').attr('checked', true);
		}
		/** Starts: TSED-1068 - Enabling Clear button issue */
		if($(window).width() < 1023){
			if ( $('.forcoursefocus').find('li.selected').length > 0){
				$('.mobile-filters.iphone-only.refinement-til-tablet').find('.clear-mobile').removeClass('disable');
			}
		}
		/** Ends: TSED-1068 - Enabling Clear button issue */
    });
    util.updateselect();
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() { 
	util.lableFocus();
	util.searchSuggestions();
	util.tooltipSearchresultpage();
	util.mobileAppendRefinement();
	$('.mobile-breadcrumbs .breadcrumb').next().remove();
    var $main = $('#main');
    var storeurl = window.location.href;
    
    $('.view-all-classes').on("click",function(e){
    	//adobe analytics for view all button
    	if(SitePreferences.isATMEnabled) { 
	    	atmevents.exploreMoreClasses();    
    	}
    	//GTM events for view all class button
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.viewAllClasses();
    	}
    });
   //adobe analytics for Event - Real Estate tracking - Class Category and SubCategory pages
    $('.learn-more.button a,.subcategory-classes-accordion a,.related-product-container a,.for-instructors a').on("click",function(e){
    	var linkName = $(this).attr("alt");
    	var linkURL = $(this).attr("href");
   	 	if(SitePreferences.isATMEnabled) {
   	 		atmevents.realEstatetrackingSubCategory(linkName,linkURL);    
   	    }
   	 	if(SitePreferences.isGTMEnabled) {
   	 		gtmevents.realEstatetrackingSubCategory($(this));
   	 	}
    });
    
   $main.on("click",'.category-refinements a,.applyfilter .apply-btn', function(e){
	  e.preventDefault();
	 if(SitePreferences.isATMEnabled) {
        var linkname = $(this).parents('.refinement').find('.heading-refinement.toggle').text().trim();
	        if($(this).closest('.product-mobile-view').length > 0){
	        	atmevents.productListRefinement(linkname);
	        }else{
	        	atmevents.courseListRefinement(linkname);	
	         }
   	 }
	 //GTM events for product and course refinements
	 if(SitePreferences.isGTMEnabled) {
		 if($(this).closest('.product-mobile-view').length > 0){
			 gtmevents.refinement(this);
		 } else {
			 gtmevents.courseRefinement(this);
		 }
	 }
    });
   
    //Date picker function
    datepicker();
    
    if($('.pagination-class').find('a').length >0 || $('.sort-by').hasClass('sort-by-class')){
      paginationAndBreadcrumbUrls();
    }
    
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });
    
    //To add product to cart in PLP
    $main.on('click', '.plp-tile .add-to-cart',function (e) {
    	addToCart.addToCart(e);    	
    });
    
    //To open quickview modal in PLP
    $main.on('click', '.plp-tile .open-quickview',function(e){
		e.preventDefault();
		var url = $(this).parents('.product-tile').find('.product-image .thumb-link').attr('href');
		if(url != undefined && url != null){
	        quickview.show({
	            url: url,
	            source: 'quickview'
	        });
		}
	});
    
    
    // category refinebar
    
    $main.on('click', '.dropdown-link-category .dropdown-arrow-refine', function (e) {
    	e.preventDefault();
    	var $this = $(this);
        var $parentLi = $(e.target).closest('.dropdown-link-category');
        $parentLi.next('.sub-cat-refine').find('.dropdown-arrow-refine').toggleClass('active');
        $parentLi.next('.sub-cat-refine').toggleClass('active');
        $parentLi.next('.sub-cat-refine').prev().toggleClass('active');
    });
     // slick slider for cat landing products starts //
    function proslick() {
		if( $(window).width() < 1023 ) { 
			   $(".product-listing-1x4slider .search-result-items").slick({
			        dots: false,
			        infinite: true,
			        slidesToShow: 4,
			        slidesToScroll: 1,
			        responsive: [
			                     {
			                       breakpoint: 1023,
			                       settings: {
			                         slidesToShow: 2,
			                         slidesToScroll: 1
			                       }
			                     },
			                     {
			                       breakpoint: 767,
			                       settings: {
			                         slidesToShow: 1,
			                         slidesToScroll: 1
			                       }
			                     }
			                 ]
		      });
	     } 
    }
    proslick();
	
	// slider for subcategory page starts //
	 function subcatslick() {
	 $('.product-listing-1x4slider .search-result-items-subcategory').slick({
	        dots: false,
	        infinite: true,
	        slidesToShow: 5, 
	        slidesToScroll: 1,
	        responsive: [
	                     {
	                       breakpoint: 1023,
	                       settings: {
	                         slidesToShow: 2,
	                         slidesToScroll: 1
	                       }
	                     },
	                     {
	                       breakpoint: 767,
	                       settings: {
	                         slidesToShow: 1,
	                         slidesToScroll: 1
	                       }
	                     }
	                   ]
	      });
	 }
	 subcatslick();
	//Issue produced in SPrint_38_Release branch fixed
    $main.off('click', '.tab_heading').on('click', '.tab_heading', function (e) { 
        e.preventDefault();
              var $this = $(this);
              var $parentLi = $(e.target).closest('.tab-container');
              $parentLi.siblings('.tab-container').removeClass('active');
			  $parentLi.toggleClass('active');
              if($(window).width() < 1024) { 
              //    $('.tab-container').not($this.closest('.tab-container')).removeClass('active');
              //$this.closest('.tab-container').toggleClass('active');
              }else {
              $('.tab-container').not($this.closest('.tab-container')).removeClass('active');
              
              $this.closest('.tab-container').addClass('active');
              }

                    var $lasttab = $('body').find('.tab_heading.tab-4').closest('.tab-container');
                    if($lasttab.hasClass('active')) {
                          $('.related-product-container').removeClass('hide');
                          //$('body').find('.related-product-container').show();
                    } else {
                          $('.related-product-container').addClass('hide');
                    }
                    if($(".search-result-items-subcategory").hasClass('slick-slider')){$(".search-result-items-subcategory").slick('unslick');}
                    subcatslick(); 
        });
		//Issue produced in SPrint_38_Release branch fixed
    	//$('.tab_heading').eq(0).trigger('click');
		$('.tab_heading').eq(0).closest('.tab-container').addClass('active');

	// slider for subcategory page ends//	
	
	// no search result recommendation goes here
	
	 var sliderLength = $('.slider-recomond .tiles-container');
	 if($(sliderLength).find('.grid-tile').length > 5){
		 //$(sliderLength).find('.product-tile').removeClass('layout-recomond');
		 $(sliderLength).find('button').show();
	 }
	 else{
		 //$(sliderLength).find('.product-tile').addClass('layout-recomond');
		 $(sliderLength).find('button').hide();
	 }
	 
	 $('.slider-recomond .tiles-container').slick({
		  infinite: false,
		  speed: 300,
		  slidesToShow: 5,
		  slidesToScroll: 1,
		  arrows: true,
		  responsive: [
		    {
		      breakpoint: 1023,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 1
		      }
		    },
		    {	
		      breakpoint: 767,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
		});
	 
	 if($(window).width() < 768) {
		 
		 $('.slick-list').slick({
			  infinite: false,
			  speed: 300,
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  arrows: true,
			  responsive: [ 
			    {	
			      breakpoint: 767,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1, 
			        dots:true 
			      }
			    }
			  ]
			});		 
	      }
	 
	 
	 function catheader() {
		 if($(window).width() > 1023) {
			 if($(".product-listing-1x4slider .search-result-items").hasClass('slick-slider')) {
				 $(".product-listing-1x4slider .search-result-items").slick('unslick');
			 }
		 }
	 }
	 catheader(); 
	 	 
	 $(window).resize(function(){
		 if($(".product-listing-1x4slider .search-result-items").hasClass('slick-slider')){$(".product-listing-1x4slider .search-result-items").slick('unslick');}
	     proslick();
	     catheader();
	     $('.mobile-breadcrumbs .breadcrumb').next().remove();
	 });
	 
	 // page load refiement code goes here
	 
	// class refiement page
	 $(window).on('load', function() {
	 if($('.classes-landing-page .plp-refinements .refinement').length > 3 && $('.classes-landing-page .plp-refinements .refinement').length < 4)  {
			$('.classes-landing-page .plp-refinements .refinement.facilityid .heading-refinement').addClass('length3');
			$('.location-class .class-setting-border').addClass('length3-location');
		}
		else {
			$('.classes-landing-page .plp-refinements .refinement.facilityid .heading-refinement').removeClass('length3');
			$('.location-class .class-setting-border').removeClass('length3-location');
		}
	 $('.mobile-breadcrumbs .breadcrumb').next().remove();
	 	util.SingleOnlineClass();
	 });
	 // Read the reviews section
	 $main.on('click', '.inner-content .scroll-bottom', function(e) {
		 e.preventDefault();
		 util.scrollBrowser($main.find('.bv-header .bv-action-bar').offset().top - 80);
	 });
	 
    // handle toggle refinement blocks
    $main.on('click', '.refinement .heading-refinement', function (e) { 
        e.preventDefault();
        var datemin = $('input:text[name=datemin]').val(); 
	    var datemax = $('input:text[name=datemax]').val();
		var $parentLi = $(e.target).closest('.refinement');
		$parentLi.siblings('.refinement').removeClass('active');
		$parentLi.toggleClass('active');
		if($(this).closest('.removecoursefacilityid.active').length > 0){
			var removeattrid = $(this).closest('.refinement.active').find('li.available a,a.clear-btn');
			if($(this).closest('.facilityid.active').length > 0 && $('.mobile-fltr-product').hasClass('active')){
				
			}else{
			  courseId(removeattrid);
			}
			
		}
		
		/*if(($('.list-of-item').is(':visible') && $('.facilityid').hasClass('active') && $('.selected-refined').length == 0 && window.location.href.indexOf('storerefine=facilityid') == -1) && !$('.mobile-fltr-product').hasClass('active')){
    		$('.list-of-item li').removeClass('selected');
    		$('.list-of-item li').removeClass('refined');
    		$('#main').addClass('selected-refined');
    	}*/
		if ($(window).width() < 1024) {
			if($('.filtertype,.coursefocus').is(':visible')) {
				$('.price,.facilityid').parent('.refinement').find('.heading-refinement').addClass('top-border');
			}
			else{
				$('.price,.facilityid').parent('.refinement').find('.heading-refinement').removeClass('top-border');
			}
			if($('.price').is(':visible')) {
				$('.search-result-options').addClass('top-border');
			}
			else{
				$('.search-result-options').removeClass('top-border');
			}
			if($('.courseformat.active').is(':visible')) {
				$('.search-result-options').addClass('top-border');
			}
			else{
				$('.search-result-options').removeClass('top-border');
			}
			if($('.coursecategory.active').is(':visible')) { 
				$('.facilityid').find('.heading-refinement').addClass('top-border');
			}
			else{
				$('.facilityid').find('.heading-refinement').removeClass('top-border');
			}
		}
		
		if(( ($(this).closest('.classes_refinements').hasClass('active') && (datemin != '' && datemax != ''))) && !$('.mobile-fltr-product').hasClass('active')){
               $('.applyfilter').removeClass('disable');
			}else if($('.refinement.facilityid').hasClass('active')){
				 $('.applyfilter').removeClass('disable');
			}else if(!$('.mobile-fltr-product').hasClass('active') && $('.refinement.active').find('li.selected a').length == 0){
				 $('.applyfilter').addClass('disable');
		    } else if(!$('.mobile-fltr-product').hasClass('active') && $('.refinement.active').find('li.selected a').length > 0){
				 $('.applyfilter').removeClass('disable');
		    }
		
		 if($('.classes_refinements').hasClass('active') && $('.refinement.active').find('li.selected a').length == 0 && datemin != '' && datemax != ''){
				$('.classes_refinements.active').find('.clear-refinement').removeClass('disable');
			}

		/*if($(this).closest('.facilityid.active').find('li.refined a').length > 0){
			$('.facilityid.active').find('.clear-refinement').removeClass('disable');
		}else if($(this).closest('.facilityid').hasClass('active') && $(this).closest('.facilityid.active').find('li.refined a').length == 0){
			$('.facilityid.active').find('.clear-refinement').addClass('disable');
		}*/
		
		
		
    });
    $main.on('click','.breadcrumb-category .location-name',function(e) {
    	e.preventDefault();
    	if(!$('.classes-landing-page .facilityid').hasClass('active')){
    		$('.refinement').removeClass('active');
    		$('.classes-landing-page .facilityid').addClass('active');
    		$('.facilityid.active').find('.applyfilter').removeClass('disable');
    		/*if(($('.list-of-item').is(':visible') && $('.facilityid').hasClass('active') && $('.selected-refined').length == 0 && window.location.href.indexOf('storerefine=facilityid') == -1) && !$('.mobile-fltr-product').hasClass('active')){
	    		$('.list-of-item li').removeClass('selected');
	    		$('.list-of-item li').removeClass('refined');
	    		$('#main').addClass('selected-refined');	
    		}*/
    		/*if($(this).closest('.facilityid.active').find('li.refined a').length > 0){
    			$('.facilityid.active').find('.clear-refinement').removeClass('disable');
    		}else if($(this).closest('.facilityid').hasClass('active') && $(this).closest('.facilityid.active').find('li.refined a').length == 0){
    			$('.facilityid.active').find('.clear-refinement').addClass('disable');
    		}*/
    	}
    	else{
    		$('.classes-landing-page .facilityid').removeClass('active');
    	}
    });
    if ($(window).width() < 1024) {
	    $main.on('click','.current-location-name',function(e) {
	    	e.preventDefault();
	    	if(!$('.classes-landing-page .facilityid,.classes-landing-page .main-container,.classes-landing-page .mobile-fltr-product').hasClass('active')){
	    		$('.refinement').removeClass('active');
	    		$('.classes-landing-page .facilityid,.classes-landing-page .main-container,.classes-landing-page .mobile-fltr-product').addClass('active');
	    	}
	    	else{
	    		$('.classes-landing-page .facilityid,.classes-landing-page .main-container,.classes-landing-page .mobile-fltr-product').removeClass('active');
	    	}
	    }); 
    }
    $main.on('click', '.calender-picker .date-pik', function (e) {
    	$(this).closest('.calender-picker').toggleClass('active');
    });
    $main.on('click','.main-container .sort-by .custom-select',function(){ 
        $(this).closest('.main-container').find('.refinement').removeClass('active');
        if($('.sort-by').hasClass('sort-by-class')){
        	courseId($('#grid-sort-header option'));
        }
    });
    
    // mobile filter code js goes here
    if ($(window).width() < 1024) {
        $main.on('click', '.refine-result', function (e) {
    	    $(this).next('.search-result-options.refinement').next('.mobile-fltr-product').toggleClass('active');
    	    $(this).next('.compare-items').next('.search-result-options.refinement').next('.mobile-fltr-product').toggleClass('active');
    	    $(this).closest('.main-container').toggleClass('active');
        });
	 }
     
    $main.on('click','.main-container .sort-by .custom-select',function(){ 
        if($('.main-container, .mobile-fltr-product').hasClass('active')) {
          $('.main-container').removeClass('active');
          $('.mobile-fltr-product').removeClass('active');
        }
    });
    
    $main.on('click', '.category-refinements a,.pagination a,.breadcrumb-refinement-value a,a.clear-btn,a.cat-cross', function (e) {// handle events for updating grid
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.article-pages').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        var $url;
        var datemin = $('input:text[name=datemin]').val(); 
	    var datemax = $('input:text[name=datemax]').val();
        $url = this.href;
        if($(this).closest('.breadcrumb-refinement-value.date-refinement').length > 0  && $url.indexOf('daterange') != -1){
        	 $url = util.removeParamFromURL($url,"daterange");
         }
        if($(this).closest('.classes_refinements').length > 0  && $(this).closest('.clear-refinement').length > 0 ){
        	if($(this).closest('.refinement.active').find('li.selected a').length > 0 || ($url.indexOf('CourseDates') !=-1 || $url.indexOf('daterange') != -1)){
	    		var $obj = util.getQueryStringParams($url.split('?')[1]);
	   	    	 $.each($obj, function(i,v){
	   	            if(v == "CourseStartTime" || v == 'CourseDaysOfWeek'){
	   	            	var $i = 0;
	   	            	$i = parseInt(i.replace('prefn',''));
	   					delete $obj["prefn"+$i];
	   					delete $obj["prefv"+$i];
	   	             }
	   	    	});
	   	    	
	   	    	$url = util.appendParamsToUrl($url.split('?')[0], $obj);
	   	    	$url = util.removeParamFromURL($url,"daterange");
        	}else if(datemin != '' || datemax != ''){
    			 $('input:text[name=datemin]').val('');
    			 $('input:text[name=datemax]').val('');
    			 $url = undefined;
    			 $('.classes_refinements.active').find('.clear-refinement').addClass('disable');
    			 
    			if($('.refinement.active').find('li.selected a').length > 0 || (datemin !='' && datemax !='')){
    		    	 $('.applyfilter').removeClass('disable');
    		 	}else{ 	
    		 		 $('.applyfilter').addClass('disable');
    		 	}
        	}
        }
        var storeurl = window.location.href;
        /*if(storeurl.indexOf('storerefine=facilityid') != -1){
	   	     $url = util.appendParamToURL($url,'storerefine','facilityid');	
   	   	 }*/

    	if ($(this).closest('.coursefocus').length == 0) {
    		var focus = util.getQueryStringParams(storeurl.split('?')[1]);
    		if (focus["classfocus"]) {
    			$url = util.appendParamsToUrl($url,{"classfocus": focus["classfocus"]});
    		}
    	}
    	//TSED-246:Filter Selection - Class Focus is cleared by using Sort Selection - when user clicks clear
    	if ($(this).closest('.coursefocus').length > 0 && $(this).closest('.clear-refinement').length > 0) { 
    		var focus = util.getQueryStringParams($url.split('?')[1]);
    		if (focus["classfocus"]) {
    			$url = util.removeParamFromURL($url,"classfocus");
    		}
    	}
    	//TSED-246 code changes ends
    	
         // back to top scroll when pagination clicked    
    	 if($('.pagination a').length  > 0){
             $("html, body").animate({ scrollTop: 0 }, 600);
          } 
    	 
	    updateProductListing($url);
        
    }).on('click','.refinement li a, .classes_refinements li a', function(e){
    	if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
    	// To restrict the click event of refinements.
    	e.preventDefault();
    	//var $active = $('.mobile-fltr-product .refinement.active').index();
    	var $active = $('#secondary .refinement.active').index();
    	if($(this).attr('href').indexOf($(this).attr('data-id')) != -1){
    		$(this).closest('.multi').find('a').removeClass('current');
    		$(this).addClass('current');
    	}
        if($(this).closest('.multi').length > 0 && !$('.mobile-fltr-product').hasClass('active')){
        	//Toggle class 
        	if($(this).parents('ul.location-class').length > 0 && ($(this).parents('ul.location-class').find('li.selected').length <= SitePreferences.LOCATION_THRESHOLD_COUNT || $(this).closest('li').hasClass('selected'))){
        		$(this).closest('li').toggleClass('selected');
        	 }else if($(this).parents('ul.location-class').length == 0 && !$('.classes_refinements').hasClass('active')){
        		$(this).closest('li').toggleClass('selected');
        	}
        		
        	//$(this).closest('li').toggleClass('selected');
        	if($(this).closest('.refinement.active').find('li.selected a').length > 0 && $(this).closest('.classes_refinements').length == 0){
        		$('.applyfilter').removeClass('disable');
        	}else if($(this).closest('.classes_refinements').length == 0){ 	
        		$('.applyfilter').addClass('disable');
        	}
        	
        	//for date and time refinement
        	if($(this).closest('.classes_refinements').length > 0){
	           	var $url = $(this).attr('href');
	        	var applyurl = $url;
        	    var $this = $(this);
        	    var $active = $('#secondary .refinement.active').index('div.refinement');
        	    var datemin = $('input:text[name=datemin]').val(); 
        	    var datemax = $('input:text[name=datemax]').val();
        	    var urls = $('.coursestarttime,.coursedaysofweek').find('a');
        	    var storeurl = window.location.href;
        	   /* if(storeurl.indexOf('storerefine=facilityid') != -1){
    	   	     $url = util.appendParamToURL($url,'storerefine','facilityid');	
        	   	 }*/
        	    if($('.radius').length > 0 && $('.radius').val() != '' && $('.searchtype').val() == 'class' && $('.radius').val() != SitePreferences.defaultRadius){
        	    	$url = util.appendParamToURL($url,'radiusval',$('.radius').val());
        	    }
        	    
        		$url = util.appendParamsToUrl($url,{'classesRefine':'true','format':'ajax'});
        		$.ajax({
        			url: $url,
        			datatType: 'html',
        			success: function(data){
        				$('#secondary .classes_refinements').html($(data).find('.classes_refinements').html());
        				$('#secondary').find('.classes_refinements .applyfilter a').attr('href', applyurl);
        				$('#secondary .refinement').eq($active).addClass('active');
        				courseId($('ul.coursestarttime,ul.coursedaysofweek').find('li.available a'),$('.classes_refinements').find('.clear-refinement  a'));
        				courseId($('.classes_refinements').find('.clear-refinement a'));
        				datepicker();
        				util.updateselect();
        				if(datemin != '' && datemax != ''){
	    					 $('input:text[name=datemin]').val(datemin);
	    					 $('input:text[name=datemax]').val(datemax);
        				}
        				if($('.refinement.active').find('li.selected a').length > 0 || (datemin != '' && datemax != '')){
         					$('.applyfilter').removeClass('disable'); 
         	    	    }else{
         	    	        $('.applyfilter').addClass('disable');
         	    	    }
        			 }
        		})
        		util.updateselect();
        	 }
        }else if($(this).closest('ul.price').length > 0 && !$('.mobile-fltr-product').hasClass('active')){
			$('.price li').find('a').removeClass('current');
    		$(this).addClass('current');
			$(this).siblings('input[type="radio"].price-radio').attr('checked', true);
        	$('.price li').removeClass("selected");
    		$(this).closest('li').addClass('selected');
    		if($(this).closest('.refinement.active').find('li.selected').length > 0){
        		$('.applyfilter').removeClass('disable');
        	}else{ 	
        		$('.applyfilter').addClass('disable');
        	}
        }else if($('.mobile-fltr-product').hasClass('active')){
        	var $mobilefilter = $('.mobile-fltr-product .mobile-filters').clone();
        	var $url = $(this).attr('href');
        	    var $this = $url;
        		$url = util.appendParamsToUrl($url,{'secondaryRefine':'true','format':'ajax'});
        		var datemin = $('input:text[name=datemin]').val(); 
        	    var datemax = $('input:text[name=datemax]').val();
        	    if($('.radius').length > 0 && $('.radius').val() != '' && $('.searchtype').val() == 'class' && $('.radius').val() != SitePreferences.defaultRadius){
        	    	$url = util.appendParamToURL($url,'radiusval',$('.radius').val());
        	    }
				
        		$.ajax({
        			url: $url,
        			datatType: 'html',
        			success: function(data){
        				$('.mobile-fltr-product').html(data).append($mobilefilter);
        				$('.mobile-fltr-product .refinement').eq($active).addClass('active');
						$('.mobile-fltr-product .refinement.active li.selected').find('input[type="radio"]').attr('checked', true);
						$('.mobile-fltr-product .applyfilter a').attr('href', $this);
						
        				//$('.geo-complete-input').val($('.zip').val());
        				if($('.zip').length > 0) {
        					$('.location-radius-selection .geo-complete-input').val($('.zip').val().replace(/, United States$/, ""));
        				}
         				datepicker();
        				util.updateselect();
        				
        				//remove facility ids and course ids from url
        				if($('.classes_refinements.active').length > 0){
        					courseId($('ul.coursestarttime,ul.coursedaysofweek').find('li.available  a'));
        				}else if($('.removecoursefacilityid.active').find('ul.coursefocus').length >0){
        					courseId($('ul.coursefocus').find('li.available a'));
        				}else if($('.removecoursefacilityid.active').find('ul.coursesetting').length >0){
        					courseId($('ul.coursesetting').find('li.available a'));
        				}
        				
        				 if(datemin != '' && datemax != ''){
        					 $('input:text[name=datemin]').val(datemin);
        					 $('input:text[name=datemax]').val(datemax);
        				 }
        				if($('.refinement').find('li.selected').length > 0 || (datemin !='' && datemax !='')){
         					$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').removeClass('disable'); 
         	    	    }else{
         	    	    	$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').addClass('disable');
         	    	    }
        				//$('.location-radius').find('.selected-option').text("Within " +radius+ " Miles");
        			 }
        		})
         }
        
        if (SitePreferences.isGTMEnabled) {
        	//gtmevents.refinement(this)); 
        }
    }).on('click', '.applyrefinements', function (e) {// To construct a refinement URL and click event on apply for desktop
    	e.preventDefault();   
    	
        var $current = $(this).not('.apply-mobile').closest('ul');
        var $obj,
         	$anchor = $current.find('.current').eq(0),
         	$id = $anchor.data('id'),
         	$ref = "",
         	$classfocuscontainer = $main.find('.coursefocus');
        
        $current.find('a').removeClass('current');
        var  $url = $anchor.attr('href');
        var storeurl = window.location.href;
        if($url == undefined || $url === storeurl){
        	$('.refinement.active').removeClass('active');
        	return;
        	}
        
        if($current.find('li.selected').length == 0){
        	$url = $current.find('.clear-refinement a').attr('href');
        } 
        
        if($current.find('li.selected a').length == 1 && $current.find('li.refined a').length == 1 && $current.find('li.selected').hasClass('refined')){
        	$('.refinement.active').removeClass('active');
        	return;
        }
        
	    var date = $('.daterange').val();
	    if(date != 'null' && $('.daterange').length > 0 && $('.daterange').val() != ""){
	    	 $url = util.appendParamToURL($url,"daterange",date);
	    }
        
        if($current.find('li.selected').length > 0 ){
        	$obj = util.getQueryStringParams($url.split('?')[1]);
        	
        	$.each($obj, function(i,v){
        		if($obj[i] == $id){
        			$ref = i.replace('prefn','prefv');
        		}
        	});
            
        	if ($id == "classfocus"){
        		$ref = "classfocus";
        	}
            $obj[$ref] = "";
            
            $current.find('li.selected a').each(function(){
            	var $val = $(this).data('value');
            	$obj[$ref] = $obj[$ref] == "" ? $val : ($obj[$ref]+'|'+$val); 
            });
            
            //keep classfocus refinement persistent in url
    		var $course = "";
       	    $classfocuscontainer.find('li.selected a').each(function(){
	           	if ($course == "") {
	           		$course = $(this).data('value');
	           	}
	           	else {	
	           		$course += '|' + $(this).data('value');
	           	}
            });
       	    if ($course) {
       	    	$obj['classfocus'] = $course;
       	    }
        	
        	$url = util.appendParamsToUrl($url.split('?')[0], $obj);
        }
        
        //adobe analytics for Course List Refinement class focus, location or class setting
       if (SitePreferences.isATMEnabled) {
        var linkname = $(this).parents('.refinement').find('.heading-refinement.toggle').text().trim();
        if($(this).closest('.product-mobile-view').length > 0){
        	atmevents.productListRefinement(linkname);
        }else{
        	atmevents.courseListRefinement(linkname);	
         }
   	    } 
   	     
        if($(this).closest('.refinement-classes').length > 0){
        	window.location = $url;
        }else{
        	updateProductListing($url);
        }
       
    }).on('click', '.mobile-filters .apply-mobile a, .mobile-filters .clear-btn, .remove-all,.classes_refinements .applyfilter a', function (e) {
    	e.preventDefault();
	   var $url = $(this).attr('href');
	   var storeurl = window.location.href;
	   
       if($(this).closest('.classes_refinements').length > 0 || ($(this).closest('.mobile-filters').length > 0 && !$('.product-mobile-view').hasClass('active') && $(this).closest('.clear-mobile').length == 0)){
        var datemin,datemax;
        if($('input:text[name=datemin]').length>0 && $('input:text[name=datemax]').length>0){
        	datemin =  $('input:text[name=datemin]').val();
        	datemax =  $('input:text[name=datemax]').val();
        }
    	
    	if(datemin != '' && datemax != '' && $url == ''){
    		if(storeurl.indexOf('daterange') != -1){
    			storeurl = util.removeParamFromURL(storeurl,"daterange");
    		}
    		$url = util.appendParamToURL(storeurl,"daterange",datemin+"|"+datemax);
    	}else if(datemin != '' && datemax != '' && $url != ''){
    		$url = util.appendParamToURL($url,"daterange",datemin+"|"+datemax);
    	 }else if($('.daterange').length > 0 && $('.daterange').val() != '' && $url != '' && $('.daterange').val() != 'null'){
    		 $url = util.appendParamToURL($url,"daterange",$('.daterange').val() ); 
    	 }
    	
    	/*if(storeurl.indexOf('storerefine=facilityid') != -1){
   	     $url = util.appendParamToURL($url,'storerefine','facilityid');	
   	    }*/
    	
    	//keep classfocus refinement persistent in url
		var $course = "";
		var $classfocuscontainer = $main.find('.coursefocus');
   	    $classfocuscontainer.find('li.selected a').each(function(){
           	if ($course == "") {
           		$course = $(this).data('value');
           	}
           	else {	
           		$course += '|' + $(this).data('value');
           	}
        });
   	    if ($course) {
   	    	$url = util.appendParamToURL($url,"classfocus",$course);
   	    }
    	
    	 if($(this).closest('.refinement-classes').length > 0){
    		 window.location = $url;
    	 }else{
    		 updateProductListing($url); 
    	 }
    	 
    	 //adobe analytics for Course List Refinement Date and Time
    	if (SitePreferences.isATMEnabled) {
    	 var linkname = "Date & Time";
    	 atmevents.courseListRefinement(linkname);
    	 }
    	}else{
    	if($(this).closest('.refinement-classes').length > 0){
       		 window.location = $url;
       	 }else{	
    	 updateProductListing($url);
       	 }
    	}
    }).on('click', '.plp-refinements input[type="radio"]', function () {
		$(this).siblings('a.refinement-link.price').trigger('click');
	});
     
    //classes category refinements
   
    $main.on('change', '.class-categories select', function (e) { 
	    e.preventDefault();
	    if($(this).find('option:selected').val() == 'Select a Class Type'){
          return;	    	
	    }
	     var $url = $(this).find('option:selected').val();
	     $url = util.removeParamFromURL($url,"cgid");
	     /*if($url.indexOf('storerefine') != -1){
	    	   $url = util.removeParamFromURL($url,"storerefine");
	       }*/
	     
	     /*if(storeurl.indexOf('storerefine=facilityid') != -1){
	   	     $url = util.appendParamToURL($url,'storerefine','facilityid');	
	   	  }*/
	     if($('.zip').length > 0 && $('.zip').val() != "" && $('.latValue').length > 0 && $('.latValue').val() != "" && $('.longitude').length > 0 && $('.longitude').val() != "" && $('.searchtype').length > 0 && $('.searchtype').val() != ""){
	    	 $url = util.appendParamsToUrl($url,{"zip": $('.zip').val(),"latitude":$('.latValue').val(),"longitude": $('.longitude').val(),"searchtype" : $('.searchtype').val()});
	     }
	   
	     updateProductListing($url);
    });
    
    //on chanage of radius 
   /* $main.on('change', '.location-radius select', function (e) { 
	    e.preventDefault();
	       var radius = $(this).find('option:selected').val();
	       var $url = window.location.href;	       
	       var datemin = $('input:text[name=datemin]').val(); 
   	       var datemax = $('input:text[name=datemax]').val();
   	       var urls = $('.location-class .list-of-item').find('a')
   	       if(!$('.mobile-fltr-product').hasClass('active')){
   	    	$url = util.appendParamsToUrl(Urls.subCatRedirect,{"cgid" :$('.cgid').val(),"zip": $('.zip').val(),"latitude":$('.latValue').val(),"longitude": $('.longitude').val(),"searchtype" : $('.searchtype').val()});
   	    	 if($('.list-of-item').is(':visible') && $('.facilityid').hasClass('active')){
 	    		$('.list-of-item li').removeClass('selected');
 	    		$('.list-of-item li').removeClass('refined');
 	    	}
			$url = util.appendParamsToUrl($url,{'radiusval' :radius,'radiusRefine':'true','format':'ajax'});
			$.ajax({
				url: $url,
				datatType: 'html',
				success: function(data){
					$('#secondary .refinement .location-class').html($(data).find('.refinement .location-class').html());
					util.updateselect();
					var $obj = $(data).find('.refinement .location-class .maplist').data('obj');
					if($obj != undefined && !$('.mobile-fltr-product').hasClass('active')){
					   showLocationMap($obj);
					}
					courseId($('.location-class ul.list-of-item').find('a'));
					if($('.list-of-item').is(':visible') && $('.facilityid').hasClass('active')){
			    		$('.list-of-item li').removeClass('selected');
			    		$('.list-of-item li').removeClass('refined');
			    	}
					if(datemin != '' || datemax != ''){
   					 $('input:text[name=datemin]').val(datemin);
   					 $('input:text[name=datemax]').val(datemax);
   				   }
			        //$('.location-radius').find('.selected-option').text("Within " +radius+ " Miles");
			        if($('.facilityid.active').find('li.selected a').length > 0){
						$('.facilityid.active').find('.applyfilter').removeClass('disable');
						$('.facilityid.active').find('.clear-refinement').removeClass('disable');
					}else if($('.facilityid').hasClass('active') && $('.facilityid.active').find('li.selected a').length == 0){
						$('.facilityid.active').find('.clear-refinement').addClass('disable');
						$('.facilityid.active').find('.applyfilter').addClass('disable');
					}
				 }
			})
   	       }else{
			var $active = $('.mobile-fltr-product .refinement.active').index();
	    	var $mobilefilter = $('.mobile-fltr-product .mobile-filters').clone();
	    	
	    	$url = util.removeParamFromURL($url,"radiusval");
	    	if($('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter a').attr("href") != ""){
	    		$url = $('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter a').attr("href");  
	    	}
	        var applyurl = util.appendParamToURL($url,'radiusval',radius);	
	    	
	      	var $this = $(this);
	      		$url = util.appendParamsToUrl(applyurl,{'secondaryRefine':'true','format':'ajax'});
	      		$.ajax({
	      			url: $url,
	      			datatType: 'html',
	      			success: function(data){
	      				if($(data).find('#secondary').length > 0){
	      				$('.mobile-fltr-product').html(data).append($mobilefilter);
	      				}
	      				$('.mobile-fltr-product .refinement').eq($active).addClass('active');
	      				$('.mobile-fltr-product .applyfilter a').attr('href', applyurl);
	      				datepicker();
	      				util.updateselect();
	      				if(datemin != '' || datemax != ''){
	   					 $('input:text[name=datemin]').val(datemin);
	   					 $('input:text[name=datemax]').val(datemax);
	   				    }
	      				if($(this).closest('.refinement.active').find('li.selected a').length > 0 || (datemin != '' && datemax != '') || radius!=''){
	     					$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').removeClass('disable'); 
	     	    	    }else{
	     	    	    	$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').addClass('disable');
	     	    	    }
	      			}
	      		})
	      }
    });
    */
    
 
    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
    	//invoking GTM product click event
        if (SitePreferences.isGTMEnabled) {
        	gtmevents.productClick($(this));
        }

        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        /*Start JIRA PREV-50 : Next and Previous links will not be displayed on PDP if user navigate from Quick View.Added cgid to hash*/
        if (!params.cgid && tile.data('cgid') !== null && tile.data('cgid') !== '') {
            params.cgid = tile.data('cgid');
        }
        /*End JIRA PREV-50*/

        // convert params.start to integer and add index 
        params.start = (+params.start) + (idx + 1);
        if($(this).hasClass('bv-rating-stars-container')){ 
        	params.viewratings = "true"; 
        }
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params); 
    });  

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        if (SitePreferences.isGTMEnabled) {
        	gtmevents.sort($(this).find('option:selected').text());
        }
        if (SitePreferences.isATMEnabled) {
        	atmevents.sortBy($(this).find('option:selected').text());
        } 
        
    var $url = $(this).find('option:selected').val(); 
    var datemin = $('input:text[name=datemin]').val(); 
    var datemax = $('input:text[name=datemax]').val();
    
      
     if(!$('.mobile-fltr-product').hasClass('active')){
    	 if(datemin != '' && datemax != '' && $url != ''){
        	 if($url.indexOf('daterange') != -1){
        		$url = util.removeParamFromURL($url,"daterange");
     		 }
    		$url = util.appendParamToURL($url,"daterange",datemin+"|"+datemax);
    	 }else if($('.daterange').length > 0 && $('.daterange').val() != '' && $url != ''){
    		 $url = util.appendParamToURL($url,"daterange",$('.daterange').val() ); 
    	 }
         updateProductListing($url);
      }else{
    	var $active = $('.mobile-fltr-product .refinement.active').index();
    	var $mobilefilter = $('.mobile-fltr-product .mobile-filters').clone();
      	//append radius value to Url
    	    var $this = $url;
    	    if($('.radius').length > 0 && $('.radius').val() != '' && $('.searchtype').val() == 'class' && $('.radius').val() != SitePreferences.defaultRadius){
    	    	$url = util.appendParamToURL($url,'radiusval',$('.radius').val());
    	    }
    	    
      		$url = util.appendParamsToUrl($url,{'secondaryRefine':'true','format':'ajax'});
      		$.ajax({
      			url: $url,
      			datatType: 'html',
      			success: function(data){
      				$('.mobile-fltr-product').html(data).append($mobilefilter);
      				$('.mobile-fltr-product .refinement').eq($active).addClass('active');
      				$('.mobile-fltr-product .applyfilter a').attr('href', $this );
      				// $('.geo-complete-input').val($('.zip').val());
      				if($('.zip').length > 0) {
    					$('.location-radius-selection .geo-complete-input').val($('.zip').val().replace(/, United States$/, ""));
    				}
      				datepicker();
      				util.updateselect();
      				if(datemin != '' && datemax != ''){
   					 $('input:text[name=datemin]').val(datemin);
   					 $('input:text[name=datemax]').val(datemax);
   				    }
      				if($('.refinement').find('li.selected').length > 0 || (datemin != '' && datemax != '') || $url != ''){
     					$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').removeClass('disable'); 
     	    	    }else{
     	    	    	$('.mobile-filters.iphone-only.refinement-til-tablet').find('.applyfilter').addClass('disable');
     	    	    }
      				//$('.location-radius').find('.selected-option').text("Within " +radius+ " Miles");
      			}
      		})
      }
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
    	    $("html, body").animate({ scrollTop: 0 }, 600); 
        }
    }).on('click', '.sign-me-up', function(e){
    	e.preventDefault();
    	var category;
    	var productid = $(this).closest('.product-tile').attr('data-itemid');
    	var name = $(this).closest('.product-tile').find('.class-name-main').text().trim();
    	var classSetting = $(this).closest('.product-tile').find('.class-format').text();
    	var classTime = $(this).closest('.product-tile').find('.strtime').text()+$(this).closest('.product-tile').find('.str_am_pm').text()  +" - "+$(this).closest('.product-tile').find('.endtime').text()+$(this).closest('.product-tile').find('.end_am_pm').text();
    	var classdayofWeek = $(this).closest('.product-tile').find('.offer-day').text();
    	var classDate =  $(this).closest('.product-tile').find('.offer-month').text()+" "+$(this).closest('.product-tile').find('.offer-date').text();
    	if($(this).closest('.product-tile').attr('data-cgid') != 'null' && $(this).closest('.product-tile').attr('data-cgid') != undefined && $(this).closest('.product-tile').attr('data-cgid') != '') {
		  category = $(this).closest('.product-tile').attr('data-cgid');
		} else {
		   category = '';
		}
    	if(SitePreferences.isATMEnabled) {
        	atmevents.signMeUp(category,productid,name,classSetting,classTime,classdayofWeek,classDate);
        }
    	//GTM analytics for Event - Sign Me Up click - Class search page
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.addClassProductToCart(category,productid,name,classSetting,classTime,classdayofWeek,classDate);
    	}
    	// Virtual Course Enrollment 
    	var $form = $(this).closest('form');
    	var courseval = $form.find('.virtualClass').val();
   		if(courseval == "null" || courseval == "false") {
   			addToCart.addClassToCart(e);
   		} else if(courseval != "null" && courseval) {
   			var $content = $('#main').find('#dialog-virtual-course');
   			var virtualCourseDlg = dialog.create({
   		        options:{ 
   		            width: "755px", 
   		            height: "auto",
   		            dialogClass : 'virtual-class-optin',
	   		        position: { my: 'center', at: 'center', of: window,
	 	            	using: function(pos) {
	 	            	var leftOffset = $(this).css(pos).offset().left;
	 	                        if (leftOffset <= 0) {
	 	                            $(this).css('left', pos.left - leftOffset + 20);
	 	                        }
	 	            		}
	   		         },
   		            open: function() {
   		            	$('body').off('click','#terms-and-conditions').on('click','#terms-and-conditions',function(e) {
   		            		if($(this).is(':checked')){
   		            			$('#terms-and-conditions').prop('checked', true);
   		            			$('.sign-me-up-button').removeAttr('disabled');
   		            		} else {
   		            			$('#terms-and-conditions').prop('checked', false);
   		            			$('.sign-me-up-button').attr('disabled', 'disabled');
   		            		}
   		            	});  
   		            	$('body').off('click','.sign-me-up-button').on('click','.sign-me-up-button',function(event) {
   		            		$form.find('#termsAndConditions').val(true);
   		            		addToCart.addClassToCart(e);
   		            	});
   		            },
   		            close: function() {
   		            	$('.sign-me-up-button').attr('disabled', 'disabled');
   		            }
   		         } 
   		    });
   		    $(virtualCourseDlg).html($content.html());
   		    virtualCourseDlg.dialog("open");
   		}
   		
    }).on('click','.facilityid .heading-refinement,.breadcrumb-category .location-name',function(e) {
    	 e.preventDefault();
		 // map code starts 
    	if(!$('.facilityid').hasClass('active')){ return; }
    	var $obj = $('.maplist').data('obj');
    	if(!$('.mobile-fltr-product').hasClass('active')){
    		showLocationMap($obj);
    	}
     }).on("click",'.cat-product-page button.header-geo-submit',function(e){
    	 var $ele = $('.cat-product-page input[name="q"]');
    	if($ele.val() == $ele.attr('placeholder') || $ele.val() == ""){
    		e.preventDefault();
    	}
    });

    $('.dot-value').parent('li').addClass('padding-remove');
	   	$(document).on('click', function(e) {
	   		if (!$(e.target).closest('.refinement,.refine-result,.top-border,.ui-datepicker,.location-name, .ui-corner-all').length){
	   			   $('.refinement,.mobile-fltr-product').removeClass('active');
	   			   $('.mobile-fltr-product').closest('.main-container').removeClass('active');
	   			   $('.price').parent('.refinement').find('.heading-refinement').removeClass('top-border');
	   			   $('.search-result-options').removeClass('top-border');
	   			   $('.classes-landing-page .facilityid').removeClass('active');
	   		}
	   });
	   	
	// ARC Templete 5 content goes here click event siblings...
	   	$(document).on('click','.content_template .expandable',function(e){
	   		var $parentLi = $(e.target).closest('.expandable');
			$parentLi.siblings('.expandable').removeClass('active');
			$parentLi.toggleClass('active');
	   	});
}

// onclick of read review scroll to spotlight section
$('.scroll-spotlight').on("click",function(e){
    util.scrollBrowser($('.bv-list-wrapper').offset().top - 80); 	 
}); 

//map function
function showLocationMap(maplist){
	  var bounds = new google.maps.LatLngBounds();
      var myLatlng = new google.maps.LatLng($('.latValue').val(), $('.longitude').val());
      var myOptions = {center: myLatlng,zoom:4,mapTypeId: google.maps.MapTypeId.ROADMAP};
      var map = new google.maps.Map(document.getElementById('map'), myOptions);
      
       // custom marker icon
       var markers = [];
       var $obj = maplist;
      $.each($obj, function(i,v){	 
      	 var $array = v; 
      	 var myLatlng = new google.maps.LatLng($array[0], $array[1]);
           var marker = new google.maps.Marker({
               position: myLatlng,
               map: map
           });
           var markerContent = 
               '<div class="store-infowindow">'+
                         '<h5>'+$array[2]+'</h5>'+
                         '<span class="tooltip-map-content">'+$array[3]+'<br />'+$array[4]+','+$array[5]+','+$array[6]+'</span>'+
               '</div>';

           var infoWindow = new google.maps.InfoWindow({
               content: markerContent
           });
           
           google.maps.event.addListener(marker, 'click', function () {
               infoWindow.open(map, marker);
           });
           bounds.extend(myLatlng);
           markers.push(marker);
       });
    }

//removing Course id and Facility id from breadcrumb and pagination urls
function paginationAndBreadcrumbUrls(){
	 if($('.pagination-class').find('a').length >0){
     	if($('.breadcrumbs-desk .breadcrumb-refinement-value a').length>0 && $(window).width() > 1023 ){
     		courseId($('.pagination-class,.breadcrumbs-desk .breadcrumb-refinement-value').find('a'));
     	}else if(('.mobile-breadcrumbs .breadcrumb-refinement-value a').length>0 && $(window).width() < 1024){
     		courseId($('.pagination-class,.mobile-breadcrumbs .breadcrumb-refinement-value').find('a'));
     	}else{
     		courseId($('.pagination-class').find('a'));
     	}
     }else if($('.sort-by').hasClass('sort-by-class')){
     	if($('.breadcrumbs-desk .breadcrumb-refinement-value a').length>0 && $(window).width() > 1023 ){
     		 courseId($('.breadcrumbs-desk .breadcrumb-refinement-value').find('a'));
     	}else if(('.mobile-breadcrumbs .breadcrumb-refinement-value a').length>0 && $(window).width() < 1024){
     		 courseId($('.mobile-breadcrumbs .breadcrumb-refinement-value').find('a'));
     	}
     }
}

function datepicker(){
	 var datemin,datemax;    
	  var enableapplybutton =  function (){
	     	datemin =  $('input:text[name=datemin]').val();
	     	datemax =  $('input:text[name=datemax]').val();
		    if($('.refinement.active').find('li.selected a').length > 0 || (datemin !='' && datemax !='')){
		    	 $('.applyfilter').removeClass('disable');
		 	}else{ 	
		 		 $('.applyfilter').addClass('disable');
		 	}
		    
		    if($('.classes_refinements').hasClass('active')){
				$('.classes_refinements.active').find('.clear-refinement').removeClass('disable');
			}
	    }
	  
	  
	var dateToday = new Date();
	if( $(window).width() > 1023 ) { 
		var dates = $('input.refinement_url,input.destination_url').datepicker({
			dateFormat: 'mm/dd/yy',
			showOn: "button",
			dayNamesMin: [ "sun", "mon", "tues", "wed", "thur", "fri", "sat" ],
			showButtonPanel: true,
			minDate: dateToday,
		    onSelect: function(selectedDate) {
		        var option = this.id == "from" ? "minDate" : "maxDate",
		            instance = $(this).data("datepicker"),
		            date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
		        dates.not(this).datepicker("option", option, date);
		        enableapplybutton();
		    }
		});
	}
	if( $(window).width() < 1024 ) { 
		var dates = $('input.refinement_url,input.destination_url').datepicker({
			dateFormat: 'mm/dd/yy',
			dayNamesMin: [ "sun", "mon", "tues", "wed", "thur", "fri", "sat" ],
			showButtonPanel: true,
			minDate: dateToday,
		    onSelect: function(selectedDate) {
		        var option = this.id == "from" ? "minDate" : "maxDate",
		            instance = $(this).data("datepicker"),
		            date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
		        dates.not(this).datepicker("option", option, date);
		        enableapplybutton();
		    }
		});
	}
	
} 

function courseId(urls){
	 var $this = urls;
	 var $changedurls = {};
	$($this).each(function(i,v){
		var $obj = {},
			$url = $(this).attr('href') || $(this).val(),
			$id = $this[i].getAttribute('data-id'),
			$value = $this[i].getAttribute('data-value'),
		    storeurl = window.location.href,
			$i = 0,
			$q = "",
			$clone = {},
			$infinite = false;
		   
		if($url == undefined || $url == ''){ 
			$url = $(this).attr('data-grid-url');
			if (typeof $url === typeof undefined || $url === false) {
				return;
			}
			$infinite = true;
		}
		
		$obj = util.getQueryStringParams($url.split('?')[1]);
		$clone = $obj;
		
		$.each($clone, function(i,v){
			/*if((v == "CourseID" || v == "FacilityID") && $id == 'FacilityID'){
				if(v == "CourseID"){
					$i = parseInt(i.replace('prefn',''));
					delete $obj["prefn"+$i];
					delete $obj["prefv"+$i];
				}else if(v == "FacilityID"){
					$i = parseInt(i.replace('prefn',''));
					$obj["prefv"+$i] = $value;
				}
			}else if(storeurl.indexOf('storerefine=facilityid') != -1){
				if(v == "CourseID"){
					$i = parseInt(i.replace('prefn',''));
					delete $obj["prefn"+$i];
					delete $obj["prefv"+$i];
				}
			}*/
			if((v == "CourseID" || v == "FacilityID")){
				$i = parseInt(i.replace('prefn',''));
				delete $obj["prefn"+$i];
				delete $obj["prefv"+$i];
			}
		});
		$url = util.appendParamsToUrl($url.split('?')[0], $obj);
		
		if($('.zip').length > 0 && $('.zip').val() != "" && $('.latValue').length > 0 && $('.latValue').val() != "" && $('.longitude').length > 0 && $('.longitude').val() != "" && $('.searchtype').length > 0 && $('.searchtype').val() != ""){
			$url = util.appendParamsToUrl($url,{"zip": $('.zip').val(),"latitude":$('.latValue').val(),"longitude": $('.longitude').val(),"searchtype" : $('.searchtype').val()});
			if($('.daterange').length > 0 && $('.daterange').val() != '' && $('.daterange').val() != 'null'){
	    		 $url = util.appendParamToURL($url,"daterange",$('.daterange').val()); 
	    	 }
		}
		//TSED-246:Filter Selection - Class Focus is cleared by using Sort Selection
		if($('.plp-refinements .refinement').find('li.available.selected').length > 0) {
			var $obj = util.getQueryStringParams($url.split('?')[1]);
			var dataID;
			var dataValue;
			var $focus = "";
			$('.plp-refinements .refinement').find('li.available.selected').each(function() {
				dataID = $(this).find('a').attr('data-id');
				if(dataID == 'classfocus') {
					dataValue = $(this).find('a').attr('data-value');
					if ($focus == "") {
						$focus = dataValue;
		           	} else {
		           		$focus += '|' + dataValue;
		           	}
				}
            });
			if ($focus) {
       	    	$obj['classfocus'] = $focus;
       	    }
			$url = util.appendParamsToUrl($url.split('?')[0], $obj);
		} //TSED-246 code changes ends
		if($(this).val() != '' ){
			$this[i].value = $url; 
		}else if($infinite) {
			$this[i].dataset.gridUrl = $url; 
		}else{
			$this[i].href = $url;
		}
		
		$changedurls = $this;
	});
	return $changedurls;
}

//Added for PHSSECOM-369
$(window).on('load',function(){ 
	if($('.course-finder-class').length > 0) {
		var $seachedFolder = $('#primary').find('input[name="course-finder-class"]').val(),
			$courseFinder = $('select[name="cgid"]'),
			classCategories = $('.classCats').val(),
			classCat = classCategories.split(','),
			classCatUpdated = false;
		if ($seachedFolder != undefined && $seachedFolder != null && $seachedFolder != 'null' && $seachedFolder != '') {
			$.each(classCat,function(i, classCatID){
				var courseCat = classCatID.split('-');
				$.each(courseCat,function(j, classID){
					if ($seachedFolder.indexOf(classID) != -1 || classID.indexOf($seachedFolder) != -1) {
						$courseFinder.val(classCatID);
						util.updateselect();
						classCatUpdated = true;
						return false;
					}
				});
				if(classCatUpdated) {
					return false;
				}
			}); 
		}
	}
});

exports.init = function () {
    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(window).on('scroll', infiniteScroll);
        courseId($('.infinite-scroll-placeholder'));
    }
    productTile.init();
    initializeEvents();
    
    //ON WINDOW BACK PULL THE DATA FROM HISTORY
    window.addEventListener('popstate', function(event) {
	    if (event.state){
		//IF DATA AVAILABLE IN HISTORY UPDATES THE CONTENT WITHOUT PAGE RELOAD
		    updateProductListing(window.location.href,true);
	    }
		/**TSED-484 - Tech Audit: Course Finder Module URL is changing upon different selctions */
		if(event.state === null){
			history.back();
		}
    });
    
};
