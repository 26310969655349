'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util'),
    _ = require('lodash');


var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {
        var $btnNext = $('.quickview-next'),
            $btnPrev = $('.quickview-prev');

        product.initializeEvents();
        util.pdpyoutubeIframe();
        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
            $btnNext.hide();
            $btnPrev.hide();
            return;
        } else {
            /*  Start JIRA PREV-50: Next and Previous links will not be displayed on PDP if user navigate from Quick View.
              Added current URL parameters and index to viewfulldetails link
            */
            var a = $('#view-full-details');
            var wl = window.location;
            var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
            var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};
            var params = $.extend(hashParams, qsParams);
            params.start = parseInt(this.productLinkIndex, 10) + 1;
            var tile = $('#search-result-items .product-tile').first();
            if (!params.cgid && tile.data('cgid') !== null && tile.data('cgid') !== '') {
                params.cgid = tile.data('cgid');
            }
            a.attr('href', a.attr('href') + '#' + $.param(params));
            /*End JIRA PREV-50*/
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.attr('disabled', 'disabled');
        }
        if (this.productLinkIndex === 0) {
            $btnPrev.attr('disabled', 'disabled');
        }

        $btnNext.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        //if (!this.exists()) {  Commenting For PHSSECOM-394
            this.init();
        //}
        url = makeUrl(options.url, options.source, options.productlistid);

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: 920,
                dialogClass: 'quickview-modal',
                //title: Resources.QUICK_VIEW_POPUP,
                open: function () {
                    this.setup(url);
                    if (typeof options.callback === 'function') {
                        options.callback();
                    }
                    if($('.quickview-modal').is(':visible')) {
            			$('body').addClass("overflowfixed");
            			$('.ui-widget-overlay').remove();
            			$( ".quickview-modal" ).wrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
    			    		$('.quickview-modal').css('top','0');
                    }
                    $('.ui-button-icon-only').on('click',function(){
                    	$('body').removeClass("overflowfixed");
                    	$( ".quickview-modal" ).unwrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
                    });
                    util.updateselect();
                    window.addEventListener('scroll', function() {
        				if($('.quickview-modal').is(':visible')) {
        			    	const element = document.querySelector('.quickview-modal');
        			    	const topV = element.style.top;
        			    	if(Math.sign(parseInt(topV)) == -1) {
        			    		$('.quickview-modal').css('top','0');
        			    	}
        			    }
                    });
                    util.scrollMobile();
                    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
                    observer.observe();
                }.bind(this)
            }
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
