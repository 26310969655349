'use strict';

var util = require('./util');

var searchsuggest = (function () {
    return {
        /**
         * @function
         * @description Initializes a new instance of search suggestions for a given container
         */
        init: function (container, defaultValue) {
            var $searchContainer = $(container);
            var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
            var $searchField = $searchForm.find('input[name="q"]');
            var searchType = $searchForm.find('input[name="searchtype"]').val();

            var currentQuery = null,
                lastQuery = null,
                runningQuery = null,
                listTotal = -1,
                listCurrent = -1,
                $resultsContainer = null;

            // Timer object for handling delays
            var $timer = {
                id: null,
                clear: function () {
                    if (this.id) {
                        window.clearTimeout(this.id);
                        this.id = null;
                    }
                },
                start: function (duration, callback) {
                    this.id = setTimeout(callback, duration);
                }
            };

            // Handle arrow keys for navigation
            function handleArrowKeys(keyCode) {
                switch (keyCode) {
                    case 38: // keyUp
                        listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
                        break;
                    case 40: // keyDown
                        listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
                        break;
                    default:
                        listCurrent = -1;
                        return false;
                }

                $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
                $searchField.val($resultsContainer.find('.selected .suggestionterm').first().text());
                return true;
            }

            // Clear results
            function clearResults() {
                if (!$resultsContainer) { return; }
                $resultsContainer.fadeOut(200, function () {
                    $resultsContainer.empty();
                    $('.close-icon-wrapper').hide();
                });
            }

            // Trigger suggestion action
            function suggest() {
                if (runningQuery !== currentQuery) {
                    runningQuery = currentQuery;
                }

                if (!runningQuery.length) {
                    clearResults();
                    runningQuery = null;
                    return;
                }

                if (lastQuery === runningQuery) {
                    runningQuery = null;
                    return;
                }

                var reqUrl = util.appendParamsToUrl(Urls.searchsuggest, { q: runningQuery, searchtype: searchType });
                if (SessionAttributes.DISTRIBUTOR_CATEGORY) {
                    reqUrl = util.appendParamsToUrl(reqUrl, { distributor: SessionAttributes.DISTRIBUTOR_CATEGORY });
                }

                $.get(reqUrl, function (data) {
                    var suggestionHTML = data,
                        ansLength = suggestionHTML.trim().length;

                    if (ansLength === 0) {
                        clearResults();
                    } else {
                        $resultsContainer.empty();

                        if ($searchContainer.parent().hasClass('homepage-search-wrapper')) {
                            $('.close-icon-wrapper').show();
                        }

                        // Update the content only after the data has been fetched
                        setTimeout(function(){
                            $resultsContainer.html(suggestionHTML).fadeIn(200);
                        }, 500);
                    }

                    lastQuery = runningQuery;
                    runningQuery = null;

                    if (currentQuery !== lastQuery) {
                        runningQuery = currentQuery;
                        $timer.clear();
                        $timer.start(300, suggest);
                    }
                    //hide left panel if there is only a matching suggested custom phrase
                    if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
                        $('.search-suggestion-left-panel').css('display', 'none');
                        $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
                        $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
                    }
                });
            }

            // Event listeners
            $searchField
                .attr('autocomplete', 'off')
                .on('focus', function () {
                    if (!$resultsContainer) {
                        $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
                    }
                    if ($searchField.val() === defaultValue) {
                        $searchField.val('');
                    }
                })
                .on('keyup', function (e) {
                    var keyCode = e.keyCode || window.event.keyCode;

                    if (handleArrowKeys(keyCode)) {
                        return;
                    }

                    if (keyCode === 13 || keyCode === 27) {
                        clearResults();
                        return;
                    }

                    currentQuery = $searchField.val().trim();

                    if (!runningQuery) {
                        runningQuery = currentQuery;
                        $timer.clear();
                        $timer.start(300, suggest);
                    }
                });

            $(document).on('click', function (e) {
                if (!$searchContainer.is(e.target)) {
                    setTimeout(clearResults, 200);
                }
            });

            $('.close-icon-wrapper').on('click', function () {
                $('.homepage-search-wrapper input[name="q"]').val('').focus();
            });
        }
    };
})();

module.exports = searchsuggest;
