'use strict';

var ajax = require('../../ajax'),
    util = require('../../util');

var digitalDownload = function() {
	$(document).on('click','#digital-download-link', function(e){
		e.preventDefault();
		
		//prevent false click on disabled button
		if ($(this).parent().prop('disabled')) {
			return false;
		}
		
		//capture the product id
		var pid = $(this).data("pid");
		
		//use redirect to grab attachment from http headers, hiding library url
		window.location = Urls.digitalDownload + "?pid=" + pid;
	});
};

exports.init = function () {
	util.cartclassHeight();
	util.orderDetailsToggle();
	util.addCalenderHidden();
	// Order Conformation page click event goes here
	digitalDownload();
	util.globalSlick();
	util.recentViewClassWidth();
	util.reconmmendtionNotShowing();
}