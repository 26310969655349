'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util');

var isIphone = matchMedia('(max-width: 767px)');
var isIpad = matchMedia('(min-width: 768px) and (max-width: 1023px)');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            $('.product-add-to-cart').find('#Quantity').on('keydown',function(e){
          	  if(($(this).val().length==0 && e.keyCode==48) || (e.keyCode>185 && e.keyCode<192) || (e.keyCode>64 && e.keyCode<91) ||( e.shiftKey && e.keyCode>47 && e.keyCode<58)){
          	        return false; 
          	  }
          });
            tooltip.init();
            $('#product-content').trigger('ready'); // PREVAIL- Added for Gigya integration
            util.updateselect();
            util.pdpyoutubeIframe();
            util.scrollMobile();
            $('.pdpForm input[name="Quantity"]').trigger('blur');
        }
    });
};

module.exports = function () {
    var $pdpMain = $('#pdpMain');
    $(window).load(function(){
		$pdpMain.on('mouseenter', '.productthumbnail', function () {
                var largeImg = $(this).data('lgimg');
				if (!largeImg) { return; }
				// set the main image
                var zoom = false;
				image.setMainImage(largeImg, zoom);
		});

        $pdpMain.on('mouseleave', '.productthumbnail', function () {
				var largeImg;
                if(isIphone.matches) {
                    largeImg = $('li.thumb.selected img.productthumbnail.iphone-only').data('lgimg');
                } else if(isIpad.matches) {
                    largeImg = $('li.thumb.selected img.productthumbnail.ipad-only').data('lgimg');
                } else {
                    largeImg = $('li.thumb.selected img.productthumbnail.desktop-only').data('lgimg');
                }

				if (!largeImg) { return; }

				// set the main image
                var zoom = false;
				image.setMainImage(largeImg, zoom);
			});
	});

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) {
            return;
        }
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '#variations', function () {
        if ($(this).val().length === 0) {
            return;
        }
        updateContent($(this).val());
    });
    
};
