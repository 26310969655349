'use strict';

var imagesLoaded = require('imagesloaded'),
    rating = require('./rating'), //PREVAIL-Added to handle ratings display in-case of ajax calls.
    atmevents = require('./atmevents'),
    gtmevents = require('./gtm'),
    util = require('./util'),
    quickview = require('./quickview');

function initQuickViewButtons() {
    $('.tiles-container .product-image').on('mouseenter', function () {
        var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            quickview.show({
                url: $(this).attr('href').split('#')[0], //PREV JIRA PREV-255 :PLP: On Click Quick view navigating to a wrong page when user first changes the swatches. Taking only href.
                source: 'quickview'
            });

            if (SitePreferences.isGoogleAnalyticsEnabled){
                require('./ga').quickview($(this)[0].pathname);
            }
            
        });
    }).on('mouseleave',function() {
    	$(this).find('.quickview').remove();
    });
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}
//Added For Map functionality in See Details section
function setMapObj(lat, long, zoom, $ele, storeaddress) {
 	if($ele == undefined){return;}
	var $map_id = $ele;
	if (lat != null && long != null && lat != '' && long != '') {
		var lat = parseFloat(lat),
			long = parseFloat(long);
		if(document.getElementById($map_id)){
			var map = new google.maps.Map(document.getElementById($map_id), {
				center: { lat: lat, lng: long },
				zoom: zoom
			});
			
			var marker = new google.maps.Marker({
				position: { lat: lat, lng: long },
				label: { text: storeaddress},
				map: map 
			});	    
			return map;
		}    
	}
}

function classDetailToggle() {
    // for opening the class details
    $('.class-wrapper .class-see-details').off('click').on('click', function(){
    	
    	$(this).closest('.grid-tile').toggleClass('active');
    	
    	if(SitePreferences.isATMEnabled) {
        	atmevents.classSeeDetails(this);
        }
    	if(SitePreferences.isGTMEnabled) {
        	gtmevents.viewClassDetails(this);
        }
    	
        var $classTileMap = $(this).closest('.product-tile').find('.tile-store-map'),
        	storeLat = $(this).find('input[name="latitude"]').val(),
        	storeLang = $(this).find('input[name="longitude"]').val(),
        	storeId = $(this).find('input[name="storeid"]').val(),
        	storeaddress = $(this).find('input[name="storeaddress"]').val();
        if(typeof $classTileMap != "undefined" && typeof $classTileMap.html() != "undefined" && $classTileMap.html().length > 10){
         	return;
        } 
        if ($classTileMap.attr('id')) {
            if (storeLat != null && storeLang != null && storeId != null && storeaddress != null && storeLang != '' && storeLang != '' && storeId != '' && storeaddress != '') {
            	$classTileMap.empty();
            	var $mapElement = $classTileMap.attr('id');
            	setMapObj(storeLat, storeLang, 8, $mapElement, storeaddress);
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    initQuickViewButtons();
    classDetailToggle();
    util.cancelDialog();
    //gridViewToggle();
    rating.init(); //PREVAIL-Added to handle ratings display in-case of ajax calls.
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) {
            return;
        }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);

        /*Start JIRA PREV-466 : Product images are not updating in the compare section when the color swatches are changed in PLP.*/
        var pid = $(this).closest('.product-tile').attr('data-itemid');
        $('.compare-items-panel .compare-item').each(function () {
            var compareid = $(this).attr('data-itemid');
            if (pid === compareid) {
                var $compare = $(this).find('.compare-item-image').eq(0);
                $compare.attr(currentAttrs);
                $compare.data('current', currentAttrs);
            }
        });
        /*End JIRA PREV-466*/
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    
    //adobe analytics for quickview
    var $tiles = $('.tiles-container .product-tile');
    $tiles.on("click", 'a.quickview', function(e) {
    	if(SitePreferences.isATMEnabled) {
        	atmevents.productquickView(this);
        }
    	if(SitePreferences.isGTMEnabled) {
        	gtmevents.quickViewEvent(this);
        }
    });
   
}


exports.init = function () {
    var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) {
        return;
    }
    // Once images/swatches configured then change 'always' to 'done'
    imagesLoaded('.tiles-container').on('always', function() {
        $tiles.each(function(idx) {
            $(this).data('idx', idx);
        });
        if($('.pt_product-compare').length == 0) {
        	$tiles.not('.search-result-content .tiles-container .product-tile').syncHeight();
        }
    });

    initializeEvents();
};
