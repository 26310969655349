'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    atmevents = require('../../atmevents'),
    gtmevents = require('../../gtm'),
    variant = require('./variant'),
    quickview = require('../../quickview');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {	
    productNav();
    recommendations();
    tooltip.init();
    util.pdpyoutubeIframe();
    util.globalSlick();
}

function initQuickViewButtons() {
    $('.tiles-container .product-image').on('mouseenter', function () {
        var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            
            var qucikviewurl=  $(this).attr('href').split('#')[0];
            
            var quickviewnow = require('../../quickview');
            quickviewnow.show({
                url: qucikviewurl,
                source: 'quickview'
            });
        });
    });
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');
    var $main = $('#main');
    initQuickViewButtons();
    addToCart.init();
    availability();
    variant();
    image();
   
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
        if (SitePreferences.isGTMEnabled) {
            require('../../gtm').addToWishlistPDP($(this));
        }
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    //JIRA PREV-386 : On click of size chart link, the page displayed instead of overlay. Replaced $('.size-chart-link a').on('click', with $pdpMain.on('click', '.size-chart-link a',
    // commenting for PHSSDWRE-3766
    /*    $pdpMain.on('click', '.size-chart a', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
            	dialogClass: 'size-chart-class'
            }
        });
        if (SitePreferences.isGTMEnabled) {
            require('../../gtm').sizeChart();
        }
    });*/
    
  //PDP QUANTITY VALIDATION
    $('.pdp-main').off('keypress','.item-quantity input').on('keydown','.quantity input', function(e) {
        var keycode = e.keyCode || e.which;
      /*  if (keycode != 13 && keycode != 8 && keycode != 0 && keycode != 9 && (keycode < 48 || keycode > 57)) {
            return false;
        }*/
    });
    
    $('.product-add-to-cart').find('#Quantity').on('keydown',function(e){
    	 /* if(($(this).val().length==0 && e.keyCode==48) || (e.keyCode>185 && e.keyCode<192) || (e.keyCode>64 && e.keyCode<91) ||( e.shiftKey && e.keyCode>47 && e.keyCode<58)){
    	        return false; 
    	  } */
    });
    
    var hash = window.location.hash;
    if(hash && hash.indexOf('viewratings') != -1 && $pdpMain.find('.tab-reviews').length > 0){
    	 $pdpMain.find('.tab-reviews').trigger('click');
    	 util.scrollBrowser($pdpMain.find('.tabs').offset().top - 80);
    }
    
    $pdpMain.on('click', '.more-details', function() {
    	$pdpMain.find('.product-info .tab-container:nth-child(1)').addClass('active').siblings().removeClass('active');
    	util.scrollBrowser($pdpMain.find('.classes-content-tabs').offset().top - 100);
    }).on('click', '.more-details-link', function(e) {
    	e.preventDefault();
        $pdpMain.find('.tab-1').trigger('click');
        util.scrollBrowser($pdpMain.find('.classes-content-tabs').offset().top - 100);
        if($(window).width() < 767) {
        	$('#pdpMain').find('.tab-container:nth-child(1)').addClass('active').siblings().removeClass('active');
        }
    }).on('click', '.classes-col-1 .bv-stars-container a.bv-rating-stars-container', function(e){
        e.preventDefault();
        util.scrollBrowser($pdpMain.find('.bv-header div.bv-action-bar').offset().top - 80);
    }).on('click', '.product-detail .bv-stars-container a.bv-rating-stars-container', function(e) {
    	e.preventDefault();
    	util.scrollBrowser($pdpMain.find('.bv-header .bv-action-bar').offset().top - 130);
    });
    
    // for scrolling to the size-chart section in PDP 
    $('.pt_product-details .size-chart-link').on('click', function(e) {
    	e.preventDefault();
    	$pdpMain.find('.product-info .tab-container:nth-child(1)').addClass('active').siblings().removeClass('active');
    	util.scrollBrowser($pdpMain.find('.classes-content-tabs').offset().top);
    });
        
    // for classes tooltip
    $pdpMain.on('click', '.class-setting',function(){
    	$(this).closest('.class-setting-language').addClass('active');
    }).on('click','.class-tool-close', function(){
    	$(this).closest('.class-setting-language').removeClass('active'); 
    });
    
    // for the class PDP tabs section
    //Issue produced in SPrint_38_Release branch fixed
    $pdpMain.off('click', '.tab_heading').on('click', '.tab_heading', function(e) {
    	e.preventDefault();
    	var $this = $(this);
    	var parentLi = $(e.target).closest('.tab-container');
    	parentLi.siblings('.tab-container').removeClass('active');
    	//Issue produced in SPrint_38_Release branch is fixed
        parentLi.toggleClass('active');
    	if($(window).width() > 767) {
    		$('.tab-container').not($this.closest('.tab-container')).removeClass('active');
    		$this.closest('.tab-container').addClass('active'); 
    	}
    }).on('click', '.tab_heading', function() {
    	if($(this).is('.tab-4')) {
    		util.globalSlick();
    	}
    });
    //Issue produced in SPrint_38_Release branch fixed
   //$('.tab_heading').eq(0).trigger('click');
   $('.tab_heading').eq(0).closest('.tab-container').addClass('active');

    //for adding the class based on the tabs
    //PHESSCOM-2211
    var tabTitle = $(".classes-content-tabs .tab_heading.tab-2 .tab-title").text();
    if(tabTitle == 'Class Setting') {
    	$('#prerequisite-tab').removeClass('prerequisite');	
    }

    
    function classTabsCount() {
    	var $class_tabs = $('.pdp-main .classes-content-tabs'); 
    	var numTabs = $class_tabs.find('.tab-container').length;
    	if(numTabs == 3) {
    		$class_tabs.addClass('tabs-3');
    	}
    	else if(numTabs == 2) {
    		$class_tabs.addClass('tabs-2');
    	}
    	else if(numTabs == 1) {
    		$class_tabs.addClass('tabs-1');
    	}
    	else {
    		return;
    	}
    }
    classTabsCount(); 
    
    //for activating the first tab
    $('.pdp-main .tabs .tab:first-child').find('.tab-switch').attr('checked', 'checked');
    
    // adding class if related products is present in the pdp
    if(($pdpMain).find('.product-pdp-reconmend')){
    	$('.product-pdp-reconmend').closest('.tab_content').addClass('relate-product');
    }
    
    // for PDP discount price dialog modal
    $pdpMain.on('click','.pdp-qty-discount',function(){
    	var $dialog = $(this).closest('.quantity-message').find('.price-tiered');
    	$dialog.dialog({
    		autoOpen: false,
    		resizable: false,
    		modal: true,
    		width: '600',
    		height: 'auto',
    		dialogClass: 'pdp-price-discount',
    		overlay: {
    			opacity: 0.5,
    			background: 'black'
    		},
    		close: function() {
    			$(this).dialog('destroy').remove;
    		},
    		position: {
    			at: 'center',
    			my: 'center',
    			of: window,
    			collision: 'flipfit'
    		}
    		
    	}).dialog('open');
    });
    
    $('.video-details').on("click",function(e){
    	//adobe analytics for video details 
    	if(SitePreferences.isATMEnabled) {
    		var linkName = $(this).find('img').attr('alt');
      	 atmevents.videodetails(linkName);
      	}
    	//GTM analytics for event - pdp video thumb nail click
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.videoDetailsClickEvent($(this));
    	}
    });
    
    // adding ul for each 4 li's in Detail tab
//    $('.tab_content > li').each(function() {
//        if( $(this).index() < 4){
//    	    $('.tab_content > li').filter(function() {
//    	        return $(this).index() < 4;
//    	    }).wrapAll('<ul class="tab-custom-details"></ul>');
//        } else if ($(this).index() > 3) {
//    	    $('.tab_content > li').filter(function() {
//    	        return $(this).index() > 3;
//    	    }).wrapAll('<ul class="tab-custom-details"></ul>');
//        }
//    });
   
    
    // adding ul for each 4 li's in Detail tab
  $('.tab_content > li').each(function() {
	    $('.tab_content > li:lt(4)').filter(function() {
	        return $(this);
	    }).wrapAll('<ul class="tab-custom-details"></ul>'); 
  });
    
    
    $('.pdp-main .pinterest, .pdp-main .social-pinterest').on('click', function(e) {
    	e.preventDefault();
    	var anchor = $(this).attr("href");
    	window.open(anchor, 'snopzer', 'left = 0, top = 0, width = 600, height = 500');
    });
    
    //To open quickview modal in PLP - TSED-507
    $main.on('click', '.prestan-tile .open-quickview',function(e){
		e.preventDefault();
		var url = $(this).siblings('.thumb-link').attr('href');
		var quickview_prestan = require('../../quickview');
		if(url != undefined && url != null){
			 quickview_prestan.show({
	            url: url,
	            source: 'quickview'
	        });
		}
	});
    
}

$(window).on('load',function(){
	var headerHeight = $('.header-parent').height();
	var urls = window.location.href;
	var newUrl = "" ;
	var $pdpMain = $('#pdpMain');
	if($('.pdp-ratings-section').val()){
		util.scrollBrowser(($('.pdp-main .product-reviews').offset().top) - headerHeight);
		if(urls.indexOf("amp;pdpScrollRating") > - 1 ) {
			newUrl = util.removeParamFromURL(urls, 'amp;pdpScrollRating'); 
		} else {
			newUrl = util.removeParamFromURL(urls, 'pdpScrollRating'); 
		}
		window.history.pushState(null, null, newUrl);
	}
	if($('.pdp-sizeChart-section').val()) {
    	$pdpMain.find('.product-info .tab-container:nth-child(1)').addClass('active').siblings().removeClass('active');
    	util.scrollBrowser($pdpMain.find('.classes-content-tabs').offset().top);
    	newUrl = util.removeParamFromURL(urls, 'sizeChartScrolling');
    	window.history.pushState(null, null, newUrl); 
	}
	//PHSSECOM-2170 - Link directly to tab from anchor link
    if(urls.indexOf('prerequisite-tab') != -1 && $pdpMain.find('.tab-container #prerequisite-tab').length > 0) {
    	$pdpMain.find('.tab-container #prerequisite-tab').closest('.tab-container').siblings().removeClass('active');
    	$pdpMain.find('.tab-container #prerequisite-tab').closest('.tab-container').addClass('active');
    	$('html, body').animate({scrollTop: $('#prerequisite-tab').offset().top -150 }, 100);
    }
	
});

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
