'use strict';

var address = require('./address'),
	onepagecheckout = require('./onepagecheckout'),
	util = require('../../util'),
	orderconfirmation = require('./orderconfirmation');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    // for checkout old design
	if (SitePreferences.OLD_CHECKOUT_ENABLED) {
		onepagecheckout.init();
	}
    //onepagecheckout.init();
    orderconfirmation.init();
/*    if($('.pt_order-confirmation').length == 0){
    	onepagecheckout.init();
    }*/
    util.saveCalendar();
    
    //if on the order review page and there are products that are not available disable the submit order button
    if ($('.order-summary-footer').length > 0) {

        //JIRA PREV-505:Order confirmation page not displayed when user clicks more than once on Place Order button: Added one click function
        $('.submit-order').closest('form').one('submit', function() {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        });

        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }
};
