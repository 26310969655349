'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    atmevents = require('../../atmevents'),
    gtmevents = require('../../gtm'),
    Promise = require('promise'),
    _ = require('lodash');

var addAll = false;

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) { 
	
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1'); 
    }
    
    if(typeof addAll != undefined && addAll && $form.find('.offeringID').val() != undefined) {
    	return Promise.resolve(addClassToCart(form, true)).then(function (response) {
    		if(response){
    			return Promise.resolve($.ajax({
        	        type: 'POST',
        	        async: false, //JIRA PREV-305:SG issue: No of items in cart value not displayed as expected.Added async:false.
        	        url: util.ajaxUrl(Urls.addProduct),
        	        data: $form.serialize()
        	    })).then(function (response) {
        	        // handle error in the response
        	        if (response.error) {
        	            throw new Error(response.error);
        	        } else {
        	        	return "checkout-redirect";
        	        }
        	    });
    		}else{
    			return false;
    		}
        });
	}else{
		return Promise.resolve($.ajax({
	        type: 'POST',
	        async: false, //JIRA PREV-305:SG issue: No of items in cart value not displayed as expected.Added async:false.
	        url: util.ajaxUrl(Urls.addProduct),
	        data: $form.serialize(),
	        timeout: 150000
	    })).then(function (response) {
	        // handle error in the response
	        if (response.error) {
	            throw new Error(response.error);
	        } else {
	            return response;
	        }
	    });
	}
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation(); //TSED-507
    var $form = $(this).closest('form');
    if($form.length == 0){
    	$form = $(e.target).closest('form');
    }
    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
                $('body').removeClass("overflowfixed");
             	$( ".quickview-modal" ).unwrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
            }
            minicart.show(response);
        }
    }.bind(this));
    
    //GTM analytics for event - Add to cart
    if (SitePreferences.isGTMEnabled) {
    	gtmevents.addToCart($form);
    }
    
    if(SitePreferences.isATMEnabled) {
    	atmevents.addToCartbutton($form);
    }
};

/**
 * @description Prestan Cart addToCart - TSED-507
 */
var prestanAddToCart = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    var $form = $(this).closest('form');
    if($form.length == 0){
    	$form = $(e.target).closest('form');
    }
    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
                $('body').removeClass("overflowfixed");
             	$( ".quickview-modal" ).unwrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
            }
            minicart.show(response);
        }
    }.bind(this));
    
    //GTM analytics for event - Add to cart
    if (SitePreferences.isGTMEnabled) {
    	gtmevents.addToCart($form);
    }
    
    if(SitePreferences.isATMEnabled) {
    	atmevents.addToCartbutton($form);
    }
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation(); //TSED-507
    var $productForms = $('#product-set-list').find('form').toArray();
    var $form = $(this).closest('form');
    addAll = true;
    
    Promise.all(_.map($productForms, function(form){
    	return Promise.resolve(addItemToCart(form)).then(function(response){
    		return response;
    	});
    })).then(function (responses) {
    	var $status = false;
		_.map(responses, function(data){
			if(data == "checkout-redirect"){
				window.location.href = Urls.classCheckOut;
				$status = true;
			}else if(data == false){
				dialog.close();
				$('body').removeClass("overflowfixed");
	            $( ".quickview-modal" ).unwrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
				minicart.refresh();
				$status = true;
				setTimeout(function(){
				    $('.offeringNotAdded').removeClass('hide');
				    $('.offeringNotAdded').find('span.error').text("We're unable to add '"+$('.class-name').text()+"' class to your cart");
				},500);
				setTimeout(function(){
					$('.offeringNotAdded').addClass('hide');
				},5000);
			}else if(!$status){
				dialog.close();
				$('body').removeClass("overflowfixed");
	            $( ".quickview-modal" ).unwrap( "<div class='ui-widget-overlay ui-front wrapdialog'></div>" );
	            // show the final response only, which would include all the other items
	            minicart.show(responses[responses.length - 1]);
			}
		})
		addAll = false; 
    });
    gtmevents.addToCart($form);
};

/**
 * @description Handler to handle the add to cart event for virtual classes
 */
var displayVirtualClassPopUp = function (e) {
	e.preventDefault();
	var $form = $(this).closest('form');
	var $content = $('#pdpMain').find('#dialog-virtual-course');
	var virtualCourseDlg =  dialog.create({
	        options:{ 
	            width: "755px", 
	            height: "auto",
	            dialogClass : 'virtual-class-optin',
	            position: { my: 'center', at: 'center', of: window,
	            	using: function(pos) {
	            	var leftOffset = $(this).css(pos).offset().left;
	                        if (leftOffset <= 0) {
	                            $(this).css('left', pos.left - leftOffset + 20);
	                        }
	            		}
	            	},
	            open: function() {
	            	$('body').off('click','#terms-and-conditions').on('click','#terms-and-conditions',function(e) {
	            		if($(this).is(':checked')){
	            			$('#terms-and-conditions').prop('checked', true);
	            			$('.sign-me-up-button').removeAttr('disabled');
	            		} else {
	            			$('#terms-and-conditions').prop('checked', false);
	            			$('.sign-me-up-button').attr('disabled', 'disabled');
	            		}
	            	});  
	            	$('body').off('click','.sign-me-up-button').on('click','.sign-me-up-button',function(event){
	            		$form.find('#termsAndConditions').val(true);
	            		addClassToCart(e);
	            	});
	            },
	            close: function() {
	            	$('.sign-me-up-button').attr('disabled', 'disabled');
	            }
	         } 
	    });
	    $(virtualCourseDlg).html($content.html());
	    virtualCourseDlg.dialog("open");
};

/**
 * @description Handler to handle the add to cart event for classes
 */
var addClassToCart = function (e, status) {
    var $status = (status == undefined) ? false : status;
    if(!$status){
    	 e.preventDefault();
    	 var $this = $(e.target);
    	 var $form = $(e.target).closest('form');
    }else{
    	 var $form = $(e);
    }
   
    var nameOfOrg = $form.find('input[name="orgName"]').val();
    var classRegUrl = $form.find('input[name="clsRegUrl"]');
    var classID = $form.find('input[name="pid"]').val();
    var className =  $form.find('input[name="pname"]').val();
    var classType =  $form.find('input[name="ptype"]').val();
    var ltpName =  $form.find('input[name="ltpname"]').val();
    var phonenumber =  $form.find('input[name="phonenumber"]').val();
    var discountItem = $form.find('input[name="discountitem"]').val();
    
    if (classRegUrl.length > 0 && classRegUrl.val() !== null && classRegUrl.val() !== '') {
        dialog.open({
            url: util.appendParamsToUrl(Urls.classSignUp, {'nameOfOrg': nameOfOrg, 'classRegUrl': classRegUrl.val(), 'classID' : classID}),
            options: {
                autoOpen: false,
                dialogClass: 'sign-up-dialog',
                width: '81.4%',
                height: 'auto',
                modal: true,
                position: {
                    my: 'center',
                    at: 'center',
                    of: window,
                    collision: 'flipfit' 
                },
                open: function() {
                	util.globalSlick();
                }
            }
    	});
    } else {
    	// If standard price is not available, then sales price and listprice same
    	var salespricelength = 0.0;
    	var listpricelength = 0.0;
    	
    	if($('.pt_class-details').length > 0){
    		salespricelength = $('.pt_class-details').find('.lms.price-sales.hide');
        	listpricelength = $('.pt_class-details').find('.lms.price-standard.hide');
    	}else{
    		salespricelength = $this.closest('.product-tile').find('.lms.product-sales-price.hide');
        	listpricelength = $this.closest('.product-tile').find('.lms.product-standard-price.hide');
    	}
    	var discount = 0.0;
    	var listPrice = 0.0;
    	var salesPrice = 0.0;
    	var offeringID = $form.find('.offeringID').val();
    	if(salespricelength.length > 0){
    		salesPrice = parseFloat(salespricelength.val());
    	}
    	if(listpricelength.length > 0){
    		listPrice = parseFloat(listpricelength.val());
    		discount = parseFloat(listPrice - salesPrice);
    	}else{
    		listPrice = parseFloat(salesPrice);
    	} 
    	var orderTotal = parseFloat(salesPrice);
    	
    	var url =  util.appendParamsToUrl(Urls.lmsValidateCreateOrder, {
    		offeringID 	: offeringID,
    		listPrice	: listPrice,
    		discount	: discount,
            orderTotal	: salesPrice,
            format    	:  'ajax'
        });
    	
		//Hitting SABA service before adding this class to cart,
		//check the SABA service to get this class inventory/availability
    	if(!$status){
    		$.ajax({
                url: url,
                dataType: 'html',
                success: function (data) {
                	//alert(data); 
                	if(data == 100){
                    		addItemToCart($form).then(function (response) { //Added For PHSSECOM-456
                    			if(discountItem != undefined && discountItem != null && discountItem != 'null' && discountItem != '') { 
                    				dialog.open({
                    		            url: util.appendParamsToUrl(Urls.displayDiscountItem, {'discountItem': discountItem}),
                    		            options: {
                    		                autoOpen: false,
                    		                dialogClass: 'discount-item-for-course',
                    		                width: '835',
                    		                height: 'auto',
                    		                modal: true,
                    		                position: {
                    		                    my: 'center',
                    		                    at: 'center',
                    		                    of: window,
                    		                    collision: 'flipfit' 
                    		                },
                    		                open: function() {
                    		                	$('body').on('click', '.ui-icon-closethick', function(e){
                    		                		e.preventDefault();
                					      			page.redirect(Urls.classCheckOut); 
                    		                	});
                    		                	$('body').off('click','.gotocheckout').on('click','.gotocheckout',function(e){
                    		                		e.preventDefault();
                					      			dialog.close();
                					      			page.redirect(Urls.classCheckOut); 
                					          	});
                    		                	$('body').off('click','.add-discount-prod').on('click','.add-discount-prod',function(e){
                					      			e.preventDefault();
                					      			dialog.close();
                					      			var $url = util.appendParamsToUrl(Urls.addDiscountItem, {
                					                    pid: discountItem,
                					                    Quantity: 1,
                					                    format: 'ajax'
                					                });
	                				            	$.ajax({
	                				        			url: $url,
	                				        			datatType: 'json',
	                				        			success: function(data){
	                				        				page.redirect(Urls.classCheckOut);
	                				        			}
	                				            	})
                					      		});
                    		                }
                    		            }
                    		    	});
                    			} else {
                    				//Bypassing the cart page once class is added to cart.
                        			window.location = Urls.classCheckOut;
                    			}
                    		}.bind(this));
                	}else{
                		$('.validateordercheck').dialog({
                			autoOpen: false,
                			modal: true,
                			width: '600',
                			height: 'auto',
                			dialogClass: 'addClassError',
                			buttons: {
                				ok: function() {
                					$(this).dialog('close');
                				}
                			}
                		}).dialog('open'); 
                	}
                	if(classType == "LTP"){
                	 $('.class-cart-error').text('');
                	//  $('.class-cart-error').append(" Please contact  <a class='class-cart-customer' href="+Urls.customerService+"> <span> Customer Service</span></a> to register");
                	  $('.class-cart-error').append("At this time the "+ltpName+"  does not offer online registration. Please call "+ phonenumber +" to register for this class.");
                	}
                }
            });
    	}else{
    		return Promise.resolve($.ajax({
    	        url: url,
    	        dataType: 'html',
    	    })).then(function (response) {
    	        // handle error in the response
    	        if (response.error) {
    	            throw new Error(response.error);
    	        } else {
    	        	if(response == 100){
    	        		return true;
    	        	}else{
    	        		if($form.find('.validateordercheck').hasClass('validateordercheck')){
                			$form.find('.validateordercheck').removeClass('hide');
                		}
    	        		return false;
    	        	}	
    	        }
    	    });
    	}
    }
    
    if($(this).closest('.classes-wrapper').length>0) {
    	var category = $('.product-cat').val();
    	var productid = $(this).closest('.classes-wrapper').find('.class-id').attr('data-masterid');
    	var name = $(this).closest('.classes-wrapper').find('.class-name').text().trim();
    	var classSetting = $(this).closest('.classes-wrapper').find('.class-format').text();
    	var classTime = $(this).closest('.classes-wrapper').find('div.sessions:first  .session-strtime').text()+" - "+$(this).closest('.classes-wrapper').find('div.sessions:first .session-endtime').text();
    	var classdayofWeek = $(this).closest('.classes-wrapper').find('div.sessions:first .session-day').text().replace(',','');
    	var classDate =  $(this).closest('.classes-wrapper').find('div.sessions:first .session-month').text()+" "+$(this).closest('.classes-wrapper').find('div.sessions:first .session-date').text();
    	
    	//adobe analytics for Event - Sign Me Up click - Class Product Detail Page
    	if(SitePreferences.isATMEnabled) {
    		atmevents.signMeUp(category,productid,name,classSetting,classTime,classdayofWeek,classDate);
    	}
    	//GTM analytics for Event - Sign Me Up click - Class Product Detail Page
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.addClassProductToCart(category,productid,name,classSetting,classTime,classdayofWeek,classDate);
    	}
    }
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
exports.init = function () {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    //Start JIRA PREV-454, PREV-469 : Application navigation not consistent when click of add to cart button of the Product set page
   // $('#pdpMain, .pt_wish-list').on('click', '.add-to-cart', addToCart);
    $('.product-detail').on('click', '.add-to-cart', addToCart);
    //TSED-507 - Prestan static page add to cart functionality
    $('.plp-tile-preston').on('click', '.prestan-cart', prestanAddToCart);// end TSED-507
    $('#add-all-to-cart').on('click', addAllToCart);
    //adding class set to cart
    $('#add-all-to-cart-class').on('click', addAllToCart);
    //adding class to cart
    if($('#pdpMain').find('.virtualClass').length > 0) {
    	var isVirtualClass = $('#pdpMain').find('.virtualClass').val();
    	if(isVirtualClass == "null" || isVirtualClass == "false") {
    		$('#pdpMain').on('click', '.sign-me-up', addClassToCart);  
    	} else if(isVirtualClass != "null" && isVirtualClass) {
    		$('#pdpMain').on('click', '.sign-me-up', displayVirtualClassPopUp);
    	}
    }
};

exports.addClassToCart = addClassToCart;
exports.addToCart = addToCart;
exports.prestanAddToCart = prestanAddToCart;
exports.displayVirtualClassPopUp = displayVirtualClassPopUp;