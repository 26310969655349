'use strict';

var ajax = require('./ajax'),
    progress = require('./progress'),
    checkout = require('./pages/checkout'),
    util = require('./util'),
    page = require('./page'),
    tooltip = require('./tooltip'),
    validator = require('./validator'),
    login = require('./login'),
    dialog = require('./dialog');

var $cache = {},
    isSPCLogin = false,
    isSPCShipping = false,
    isSPCMultiShipping = false,
    isSPCBilling = false,
	isSPCPayment = false;

function initializeCache() {
    $cache = {
        main: $('#main'),
        primary: $('#primary'),
        secondary: $('#secondary')
    };
}

var autoComplete;

/**
 * @function
 * @description Initialize the address variation modal and display address suggestion returned
 */
function initializeAddressValidationModal() {
    $cache.checkoutForm = $cache.primary.find('.checkout-shipping.address');
    $cache.submitFormButton = $cache.checkoutForm.find('button.spc-shipping-btn');
    $cache.validatoionDialog = $cache.primary.find('#address-validation-dialog');
    $cache.byPassDAV = $cache.checkoutForm.find('.bypassDAV');

    //shipping address fields
    $cache.shippingaddress1 = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]');
    $cache.shippingaddress2 = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]');
    $cache.shippingcity = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]');
    $cache.shippingstateCode = $cache.checkoutForm.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]');
    $cache.shippingcountry = $cache.checkoutForm.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]');
    $cache.shippingpostalCode = $cache.checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]');

    if ($cache.validatoionDialog.length > 0) {
        //$(this).delay(10000);
        var dlg = dialog.create({
            target: $cache.validatoionDialog,
            options: {
                width: 670,
                dialogClass: 'address-validation-dialog',
                open: function() {
                    $cache.validatoionDialog.on('click', '#original-address-edit', function() {
                        dialog.close();
/*                        jQuery('html, body').animate({
                            scrollTop: $('#navigation').position().top
                        }, 500);*/
                    });

                    $cache.validatoionDialog.on('click', '#ship-to-original-address', function() {
                        dialog.close();
                        $cache.byPassDAV.attr('value', 'true');
                        $cache.submitFormButton.click();
                    });

                    $cache.validatoionDialog.on('click', '[id|="suggested-address-edit"]', function() {
                        var selectedAddress = $(this).data('address').split('||');
                        $cache.address1.val(selectedAddress[0]);
                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                            $cache.address2.val(selectedAddress[1]);
                        }
                        $cache.city.val(selectedAddress[2]);
                        $cache.stateCode.val(selectedAddress[3]);
                        $cache.countryCode.val(selectedAddress[4]);
                        $cache.postalCode.val(selectedAddress[5]);
                        dialog.close();
/*                        jQuery('html, body').animate({
                            scrollTop: $('#navigation').position().top
                        }, 500);*/
                    });
                    //select selected adress
                    $cache.validatoionDialog.on('click', '[id|="shipping-ship-to-address-selected"]', function() {
                        var selectedAddress = $(this).data('address').split('||');
                        var enteredZip = $cache.shippingpostalCode.val();
                        $cache.shippingaddress1.val(selectedAddress[0]);
                        if (selectedAddress[1] !== 'undefined' && selectedAddress[1] !== '') {
                            $cache.shippingaddress2.val(selectedAddress[1]);
                        }
                        $cache.shippingcity.val(selectedAddress[2]);
                        $cache.shippingstateCode.val(selectedAddress[3]);
                        $cache.shippingstateCode.trigger('change');
                        var country = selectedAddress[4];
                        $cache.shippingcountry.val(country);
                        $cache.shippingpostalCode.val(selectedAddress[5]);
                        $cache.byPassDAV.attr('value', 'true');
                        dlg.dialog('close');
                        $cache.submitFormButton.click();
                    });
                }
            }
        });
        //close all opened dialogs before opening a new one
        $('.ui-dialog-content').dialog('close');
        $cache.validatoionDialog.show();
        dlg.dialog('open');
    }
}

/**
 * @function
 * @description to expand and collapse the tooltips in checkout
 */
function initTooltip() {
	$(".chkout-toolbox").appendTo(".business-tooltip .field-wrapper");
    $('.tool-question').on('click', function() {
    	$(this).closest('.tooltip-box').addClass('active');
    });
    $('.class-tool-close').on('click', function(e) {
    	e.preventDefault();
    	$(this).closest('.tooltip-box').removeClass('active');
    });
	
}
// for cart items not expanding
function mobileCartItems() {
	 if ($(window).width() < 768) {  
    	 $('.cart-items-accordion').on('click', function() {
    		 $(this).closest('.checkout-mini-cart').toggleClass('active');   
    	 });
	 }	
}

/**
 * @function
 * @description Address Type Radio Button Behavior
 */
function toggleBusinessName() {
    // Hide Company Name Field Residential is chosen
    if ($('input[name$="_singleshipping_shippingAddress_addressType"]:checked').val() === 'true') {
    	$('#residentialbusiness').val('resident');
        $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.address-type').addClass("hide");
        util.lableFocus();
    } else {
    	$('#residentialbusiness').val('business');
        $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.address-type').removeClass("hide");
        $('input[name$="_singleshipping_shippingAddress_companyname"]').addClass("required"); 
        util.lableFocus();
    }
    if (isFormValid($('form.address'), true)) {
        updateStateDropDown();
        updateShippingMethodList();
    }
}


/**
 * @function
 * @description Check basket amount against available PO balance
 * if the payment method is PO, check if the basket contain course
 */
function checkPOAmount() {
	var index;
    if ($('select#POList').length > 0 && $('#is-PO').is(':checked')) {
    	index = $('select#POList')[0].selectedIndex;
    	//check if the basket has course
        if ($('.PO-class-error').hasClass('error')) {
            $(this).parent().addClass("PO-error");
            return false;
        } else {
        	//Check POAmount against Order Total
            if (parseFloat(POData[index].POAmount) < amountTotalPO) {
                $('select#POList').parent().addClass("PO-error");
                $('.PO-amount-error').addClass("error");
                return false;
            } else {
                $('select#POList').parent().removeClass("PO-error");
                $('.PO-amount-error').removeClass("error");
                return true;
            }
        }
    }
    return true;
}


//Profile Phone Type Behavior.
function toggleprofileusinessName() {
    // Hide Business Name Field Individual is chosen
    if ($("input[name$='_login_loginAddress_profileaddressType']:checked").val() != undefined && $('input[name$="_login_loginAddress_profileaddressType"]:checked').val() == 'true') {
        $('.busnissesname-field-update').hide();
    } else {
        $('.busnissesname-field-update').show();
        $('.busnissesname-field-update .input-text').addClass("required"); 
    }
}

function toggledisclaimermessage() {
    // Hide Disclaimer message when Landline is chosen
    if ($("input[name$='_login_loginAddress_profilephoneNo']:checked").val() != undefined && $('input[name$="_login_loginAddress_profilephoneNo"]:checked').val() !== 'true') {
        $('.phone-text-update').hide();
        $('.disclaimer-message').hide();
    } else {
        $('.phone-text-update').show();
        $('.disclaimer-message').show();
    }
    toggletextupdate()
}

//Text Alerts opt-in hidden when country code other then +1
function toggletextupdate() {
	if ($('select[name*="_login_loginAddress_countryCodes_phoneCountryCode"]').val() != undefined && $('select[name*="_login_loginAddress_countryCodes_phoneCountryCode"]').val() !== '+1') {
        $('.phone-text-update').hide();
        $('.disclaimer-message').hide();
    } else if($('input[name$="_login_loginAddress_profilephoneNo"]:checked').val() == 'true' && $('select[name*="_login_loginAddress_countryCodes_phoneCountryCode"]').val() == '+1') {
        $('.phone-text-update').show();
        $('.disclaimer-message').show();
    }
}

//phone number restriction if country code is +1

function phonenumberrestriction() {
	if ($('select[name*="_countryCodes_phoneCountryCode"]').val() != undefined && $('select[name*="_countryCodes_phoneCountryCode"]').val() !== '+1' && $('input[name*="_phone"]').length > 0) {
		$('input[name*="_phone"]').val('');	
		$('input[name*="_phone"]').attr('maxlength','22');
    } else if($('select[name*="_countryCodes_phoneCountryCode"]').val() == '+1' && $('input[name*="_phone"]').length > 0) {
        	$('input[name*="_phone"]').val('');
			$('input[name*="_phone"]').attr('maxlength','12');
    }
}

function phonenumberrestrictiononload() {
	if ($('select[name*="_countryCodes_phoneCountryCode"]').val() != undefined && $('select[name*="_countryCodes_phoneCountryCode"]').val() !== '+1' && $('input[name*="_phone"]').length > 0) {
			$('input[name*="_phone"]').attr('maxlength','22');
	} else if($('select[name*="_countryCodes_phoneCountryCode"]').val() == '+1' && $('input[name*="_phone"]').length > 0) {
			$('input[name*="_phone"]').attr('maxlength','12');
	}
}



/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');

    var country = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
    var state = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
    var stateCode;
    var postalCode;
    //country in undefined
    if (country != 'US') {
        updateStateDropDown();
        $(".shipping-section .state-row").addClass('hide');
        $(".shipping-section .state-other-row").removeClass('hide');
        $(".shipping-section .postal-row").addClass('hide');
        $(".shipping-section .postal-row-other").removeClass('hide');

        if (state != null && state != '' && $("select[id$='_singleshipping_shippingAddress_addressFields_stateOther_state'] option[value=" + state + "]").length > 0) {
            stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val();
            $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val($form.find('select[id$="_singleshipping_shippingAddress_addressFields_stateOther_state"]').val());
            postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
            $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val());
        }
    } else {
        updateStateDropDown();
        $(".shipping-section .state-row").removeClass('hide');
        $(".shipping-section .state-other-row").addClass('hide');
        $(".shipping-section .postal-row").removeClass('hide');
        $(".shipping-section .postal-row-other").addClass('hide');

        $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"] option[value=""]').prop('selected', 'selected').trigger('change');
        $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val($form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val());
        stateCode = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_states_state"]').val();
        postalCode = $form.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();
    }
    var params = {
        address1: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address1"]').val(),
        address2: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_address2"]').val(),
        countryCode: $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val(),
        stateCode: stateCode,
        postalCode: postalCode,
        city: $form.find('input[name$="_singleshipping_shippingAddress_addressFields_city"]').val(),
        addressType: $('body').find('input[id="residentialbusiness"]').val()
    };

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}


/**
 * @function
 * @description Shipping method description Display on change of shipping methods list.
 */
function showShippingMethodDetails($this) {
    var $this = $this;
    if ($this != undefined && $this != null && $this != '') {
        var selected = $this;
        var msgDetails = $('#shipping-method-list').find('select[id$="applicable-shipping-methods"] option[value="' + selected + '"]').data('shippingdetails');
        var $methodDetails = $('.shipping-method-description');
        if (msgDetails != null && msgDetails != '') {
            $methodDetails.removeClass('hide');
            $methodDetails.find('span.bussiness-day').html('');
            $methodDetails.find('span.shipping-details').html('');
            var details = msgDetails.split('|');
            if (details[0] != null && details[0] != '') {
                $methodDetails.find('span.bussiness-day').html(details[0]);
            }
            if (details.length > 1 && details[1] != null && details[1] != '') {
                $methodDetails.find('span.shipping-details').html(details[1]);
            }
        }
    }
}


/**
 * @function
 * @description shows the student login dialog if the user already existed in saba
 * @param
 */
function initialStudenDialog() {
	
	if ($cache.main.find('.error_status').length > 0) {
	    //alert($main.find('.error_status').val());
		//password field, hide show
        $('span.showpwd').click(function() {
            var change = '';
            if ($(this).html() === 'Show') {
                $(this).html('Hide');
                $(this).closest('.field-wrapper').find($('input')).attr('type', 'text');
            } else {
                $(this).html('Show');
                change = 'password';
                $(this).closest('.field-wrapper').find($('input')).attr('type', 'password');
            }
        });
	    if ($cache.main.find('.error_status').val() == '1002') {
	        $(document).on('keypress', function(e) {
	            var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
	            if (key == 13) {
	                if (!$('.learner_id_missed').is(":visible")) {
	                    e.preventDefault();
	                    return false;
	                } else if ($('.learner-pass').hasClass('focus')) {
	                    $('.ui-dialog-buttonset button:first-child').trigger('click');
	                    return false;
	                }
	            }
	        });
	        var counter = 0;
	        $('.learner_id_missed').dialog({
	            autoOpen: false,
	            modal: true,
	            width: '600',
	            height: 'auto',
	            dialogClass: 'learnertrigger',
	            buttons: {
	                Submit: function() {
	                    counter++;
	                    var password = $('.learner_password').val();
	                    var stddata = $('.learner_std_data').val();
	                    var url = util.appendParamsToUrl(Urls.sabaCreateUpdateUser, {
	                        password: password,
	                        stddata: stddata,
	                        format: 'ajax'
	                    });

	                    $.ajax({
	                        url: url,
	                        dataType: 'html',
	                        success: function(data) {
	                            //alert(data);
	                            if (data != 100) {
	                                if (data.trim() == 1001) {
	                                    $('.learner_id_missed .learner_error').addClass('hide');
	                                    $('.learner_id_missed .learner_error.pwd-error').removeClass('hide');
	                                } else {
	                                    $('.learner_id_missed .learner_error').removeClass('hide');
	                                    $('.learner_id_missed .learner_error.pwd-error').addClass('hide');
	                                }
	                            } else if (data == 100) {
	                                $('.learner_id_missed').dialog('close');
	                                $('.cvn .image-cvn').removeClass('learner-visible');
	                                $('.form-row.cvn').removeClass('tooltip-filed');
	                            }
	                        }
	                    });
	                },
	                Cancel: function() {
	                    $(this).dialog('close');
	                    $('.cvn .image-cvn').removeClass('learner-visible');
	                    $('.form-row.cvn').removeClass('tooltip-filed');
	                }
	            },
	            open: function() {
	                setTimeout(function() {
	                    if ($('.learnertrigger').is(':visible')) {
	                        $('.cvn .image-cvn').addClass('learner-visible');
	                    } else {
	                        $('.cvn .image-cvn').removeClass('learner-visible');
	                        $('.form-row.cvn').removeClass('tooltip-filed');
	                    }
	                }, 10);
	            }
	        }).dialog('open');
	    }
	}
	
} 



/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {
        shippingMethodID: shippingMethodID
    });
    ajax.getJson({
        async: false,
        url: url,
        callback: function(data) {
            showShippingMethodDetails(shippingMethodID);
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();
        }
    });
}


/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var shippingMethods;
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        return;
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    /*    $('.address').on('change', '.shipping-section', function() {
            $('.checkout-content').addClass('active');
        });*/

    ajax.getJson({
        async: false,
        url: url,
        callback: function(data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (false && shippingMethods && shippingMethods.toString() === data.toString()) { // PREVAIL-Added for 'false' to handle SPC.
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function() {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('select[id$="applicable-shipping-methods"]').change(function() {
                    selectShippingMethod($(this).val());
                });

                // Commenting the update the summary to avoid altering issue of shipping method.
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.shipping-list-method .custom-select.applicable-methods').length > 0) {
                    var selectedShippingMethodID = $('input[name$="selectedShippingMethod"]').val();
                    if (selectedShippingMethodID != undefined && selectedShippingMethodID != null && selectedShippingMethodID != 'null' && selectedShippingMethodID != '') {
                        $shippingMethodList.find('select[id$="applicable-shipping-methods"] option[value="' + selectedShippingMethodID + '"]').prop('selected', 'selected').trigger('change');
                    } else if ($shippingMethodList.find('select[id$="applicable-shipping-methods"] option:selected').length > 0) {
                        var selected = $shippingMethodList.find('select[id$="applicable-shipping-methods"] option:selected').val();
                        $shippingMethodList.find('select[id$="applicable-shipping-methods"] option[value="' + selected + '"]').prop('selected', 'selected').trigger('change');
                    } else {
                        $shippingMethodList.find('select[id$="applicable-shipping-methods"] option:first').attr('selected', 'selected').trigger('change');
                    }
                    //Enable the checkout button if applicable shipping methods are exist.
                    if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length === 0) {
                    	$('.place-order-div .spc-shipping-btn').closest('form').find('.spc-shipping-btn').removeClass('disable-mode-btn');
                    }
                    util.updateselect();
                    util.lableFocus();
                } else {
                    if ($shippingMethodList.find('.shipping-list-method .no-shipping-methods').length > 0) {
                        //Dissable the checkout button if applicable shipping methods are not exist.
                        $('.place-order-div .spc-shipping-btn').closest('form').find('.spc-shipping-btn').addClass('disable-mode-btn');
                    } else {
                    	$('.place-order-div .spc-shipping-btn').closest('form').find('.spc-shipping-btn').removeClass('disable-mode-btn');
                    }
                }
            });
        }
    });
}

/**
 * @function
 * @description checks if form is valid
 * @params form form element that contain the required fields
 * 		   excludephone boolean, to exclude phone number field from being validated
 */
function isFormValid($form, $excludephone) {
    var firstName = $form.find('input[name$="_addressFields_firstName"]').val();
    var lastName = $form.find('input[name$="_addressFields_lastName"]').val();
    var address1 = $form.find('input[name$="_addressFields_address1"]').val();
    var address2 = $form.find('input[name$="_addressFields_address2"]').val();
    var countryCode = $form.find('select[id$="_addressFields_country"]').val();
    var stateCode = $form.find('select[id$="_addressFields_states_state"]').val();
    var postalCode = $form.find('input[name$="_addressFields_postal"]').val();
    var city = $form.find('input[name$="_addressFields_city"]').val();
    var phone = $form.find('input[name$="_addressFields_phone"]').val();
    var addressType = $form.find('input[name$="_addressType"]:checked').val();
    var companyname = $form.find('input[name$="_companyname"]').val();
    if (firstName != '' && firstName != undefined && lastName != '' && lastName != undefined && address1 != '' && address1 != undefined && countryCode != '' && countryCode != undefined && stateCode != '' && stateCode != undefined && postalCode != '' && postalCode != undefined && city != '' && city != undefined && ($excludephone == true ? true : phone != '' && phone != undefined)) {
        if (addressType != undefined && addressType != '' && (addressType == 'true' ? companyname != undefined && companyname == '' : true)) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}



/**
 * @function
 * @description updates the state dropdown value based on the country selected
 */
function updateStateDropDown() {
    var $shippingMethodList = $('#shipping-method-list');
    if ($shippingMethodList && $shippingMethodList.length > 0) {
        var $form = $('.address');
        if ($('#dwfrm_singleshipping_shippingAddress_addressFields_address1').is(':Visible')) {
            country = $form.find('select[id$="_singleshipping_shippingAddress_addressFields_country"]').val();
            if (country != 'US') {
                $(".shipping-section .state-row").addClass('hide');
                $(".shipping-section .state-other-row").removeClass('hide');
                $(".shipping-section .postal-row").addClass('hide');
                $(".shipping-section .postal-row-other").removeClass('hide');
            } else {
                $(".shipping-section .state-row").removeClass('hide');
                $(".shipping-section .state-other-row").addClass('hide');
                $(".shipping-section .postal-row").removeClass('hide');
                $(".shipping-section .postal-row-other").addClass('hide');
            }
        } else {
            var country = $form.find('select[id$="_billing_billingAddress_addressFields_country"]').val();
            if (country != 'US') {
                $(".billing-section .state-row").addClass('hide');
                $(".billing-section .state-other-row").removeClass('hide');
                $(".billing-section .postal-row").addClass('hide');
                $(".billing-section .postal-row-other").removeClass('hide');
            } else {
                $(".billing-section .state-row").removeClass('hide');
                $(".billing-section .state-other-row").addClass('hide');
                $(".billing-section .postal-row").removeClass('hide');
                $(".billing-section .postal-row-other").addClass('hide');
            }
        }
    }
}


function updateStateDropDownBilling() {
    var $shippingMethodList = $('#shipping-method-list');
    var $form = $('.address');
    var country = $form.find('select[id$="_billing_billingAddress_addressFields_country"]').val();
    if (country != 'US') {
        $(".checkout-billing .state-row").addClass('hide');
        $(".checkout-billing .state-other-row").removeClass('hide');
        $(".checkout-billing .postal-row").addClass('hide');
        $(".checkout-billing .postal-row-other").removeClass('hide');
      } else {
        $(".checkout-billing .state-row").removeClass('hide');
        $(".checkout-billing .state-other-row").addClass('hide');
        $(".checkout-billing .postal-row").removeClass('hide');
        $(".checkout-billing .postal-row-other").addClass('hide');
      }    
}

/**
 * @function
 * @description displays applicable shipping methods
 */
function showShippingMethods($checkoutForm) {

    $checkoutForm.on('focusout change', 'input[name$="_shippingAddress_addressFields_firstName"], input[name$="_shippingAddress_addressFields_lastName"], input[name$="_shippingAddress_addressFields_address1"], input[name$="_shippingAddress_addressFields_address2"], select[name$="_shippingAddress_addressFields_states_state"], select[name$="_shippingAddress_addressFields_stateOther_state"], input[name$="_shippingAddress_addressFields_city"], input[name$="_shippingAddress_addressFields_postal"], select[name$="_shippingAddress_addressFields_country"], input[name$="_shippingAddress_addressFields_otherPostal"], input[name$="_singleshipping_addressList"], input[name$="_singleshipping_shippingAddress_companyname"]', function() {

        var postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_postal"]').val();

        if (postal == '' || postal == null || postal == undefined) {
            postal = $checkoutForm.find('input[name$="_singleshipping_shippingAddress_addressFields_otherPostal"]').val();
        }
        if (isFormValid($checkoutForm, true)) {
            updateStateDropDown();
            updateShippingMethodList();
        }
    });
}



/**
 * @function
 * @description Fills Address fields using data from Google Places Auto LookUp
 */
function fillAddress() {
    var place = autoComplete.getPlace();
    var componentForm = {
        street_number: 'short_name',
        route: 'short_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'short_name',
        country: 'short_name'
    };

    var addressFields = {
        route: 'input[name$="_addressFields_address1"]',
        locality: 'input[name$="_addressFields_city"]',
        administrative_area_level_1: 'select[name$="_addressFields_states_state"]',
        postal_code: 'input[name$="_addressFields_postal"]'
    }
    var streetNo = '';
    var stateCode = '';
    var countryCode = '';
    $('input[name$="_addressFields_postal"]').val('');
    $('select[name$="_addressFields_states_state"]').val('');
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            if (addressType === 'street_number') {
                streetNo = place.address_components[i][componentForm[addressType]];
            } else {
                var val = (addressType === 'route') ? streetNo + ' ' + place.address_components[i][componentForm[addressType]] : place.address_components[i][componentForm[addressType]];
                if (addressType === 'country') {
                    var territoryAddress = SitePreferences.TERRITORY_ADDRESS;
                    if (territoryAddress != undefined && territoryAddress != null && territoryAddress.indexOf(val) > 0) {
                        countryCode = 'US';
                        stateCode = val;
                    } else {
                        countryCode = val;
                    }
                    $('select[name$="_addressFields_country"]').val(countryCode);
                    $('select[name$="_addressFields_country"]').trigger('change');
                    updateStateDropDown();
                    if ($('select[name$="_addressFields_states_state"]').is(':visible')) {
                        $('select[name$="_addressFields_states_state"]').val(stateCode);
                        $('select[name$="_addressFields_states_state"]').trigger('change');
                        var suggestedState = $('select[name$="_addressFields_states_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="_addressFields_states_state"]').val('');
                            $('select[name$="_addressFields_states_state"]').trigger('change');
                        }
                    }
                    if ($('select[name$="_addressFields_stateOther_state"]').is(':visible')) {
                        $('select[name$="_addressFields_stateOther_state"]').val(stateCode);
                        $('select[name$="_addressFields_stateOther_state"]').trigger('change');
                        var suggestedState = $('select[name$="_addressFields_stateOther_state"]').val();
                        if (suggestedState == null || suggestedState == '' || suggestedState == undefined) {
                            $('select[name$="_addressFields_stateOther_state"]').val('OT');
                            $('select[name$="_addressFields_stateOther_state"]').trigger('change');
                        }
                    }
                }
                if (addressType === 'administrative_area_level_1') {
                    stateCode = val;
                } else {
                    $(addressFields[addressType]).val(val);
                    $('.address1-row').find('.input-text').removeClass('error');
                    $('.address1-row').find('.input-text').next('span.error').hide();
                    $('.postal-row,.city-row').find('.input-text').removeClass('error');
                    $('.postal-row,.city-row').find('.input-text').next('span.error').hide();
                }
            }
        }
    }
    util.lableFocus();
    util.updateselect();
    validator.init();
    $('select[name$="_addressFields_country"]').focusout();
}

function initializeDom() {
    isSPCLogin = $('.spc-login').length > 0;
    isSPCShipping = $('.spc-shipping').length > 0;
    isSPCMultiShipping = $('.spc-multi-shipping').length > 0;
    isSPCBilling = $('.spc-billing').length > 0;
    isSPCPayment = $('.spc-payment').length > 0;
}

function initializeEvents() {

    //address form
    var $checkoutForm = $('form.address');
    var google_api_inactive = SitePreferences.GOOGLE_API_DISABLED;
    //init tool tips
    initTooltip();
    util.checkoutScrollbar();
    if (isSPCLogin) {
        loadSPCLogin();
        login.init();
    } else if (isSPCShipping) {
        initializeAddressValidationModal();
        loadSPCShipping();

    } else if (isSPCMultiShipping) {
        loadSPCMultiShipping();

    } else if (isSPCBilling) {
    	initialStudenDialog();
        loadSPCBilling();
    } else if (isSPCPayment) {
        loadSPCPayment();
    }
    
    // updating value of addToEmailList in payment form
    $('.checkout-summary input[name$="_billing_billingAddress_addToEmailList"]').on('change', function(e) {
    	progress.show($cache.primary);
	    if ($('.checkout-summary').find('input[name$="billing_billingAddress_addToEmailList"]').is(':checked')) {
	    	$('.add-to-email-list').find('input[name$="_billing_billingAddress_addToEmailList"]').attr('value', true);
	    } else {
	    	$('.add-to-email-list').find('input[name$="_billing_billingAddress_addToEmailList"]').attr('value', false);
	    }
        $.ajax({
        	url: util.appendParamToURL(Urls.setMailSubscribeValue, 'subscribe', $('.checkout-summary').find('input[name$="billing_billingAddress_addToEmailList"]').is(':checked'))
        }).done(function() {
        	 progress.hide();
        });
    });
    
    if($('input[name$="billing_billingAddress_addToEmailList"]').is(':checked').length > 0){
 	   $('.add-to-email-list').find('input[name$="billing_billingAddress_addToEmailList"]').attr('value', true);
    } else {
 	   $('.add-to-email-list').find('input[name$="billing_billingAddress_addToEmailList"]').attr('value', false);
    }

// updating billing page state drop down
    $('.address').on('focusout change', 'input[name$="_billingAddress_addressFields_firstName"], input[name$="_billingAddress_addressFields_lastName"], input[name$="_billingAddress_addressFields_address1"], input[name$="_billingAddress_addressFields_address2"], select[name$="_billingAddress_addressFields_states_state"], select[name$="_billingAddress_addressFields_stateOther_state"], input[name$="_billingAddress_addressFields_city"], input[name$="_billingAddress_addressFields_postal"], select[name$="_billingAddress_addressFields_country"], input[name$="_billingAddress_addressFields_otherPostal"], input[name$="_billing_addressList"]', function() {
       var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
         if (country != '' && country != null) {
                updateStateDropDownBilling();
            }        
    });
    
    var country = $checkoutForm.find('select[name$="_billing_billingAddress_addressFields_country"]').val();
    if (country != '' && country != null) {
           updateStateDropDownBilling();
       }
    
    // disabled placeorder button on page load
    if($('.spc-placeorder-disabled').length > 0){
   	 $('.spc-placeorder-btn').attr('disabled', false);
   	}
   	else {
   		$('.spc-placeorder-btn').attr('disabled', true);
   	}
    
    function showCheckoutCouponForm() {
        // Hide coupon form when no selected
        if ($("input[name$='_billing_haveacoupon']:checked").val() != undefined && $('input[name$="_billing_haveacoupon"]:checked').val() !== 'false' && $('.billing-coupon-code').find('.valid-coupon-exists').length == 0) {       
            $('.checkout-havaacoupon').show();
            $('.coupon-form-apply').show();
            $('.billing-coupon-code').show();
            $('.coupon-tax-wrap').find('.have-another-promo').hide();
        } else {
            $('.checkout-havaacoupon').hide();
            $('.coupon-form-apply').hide();
            //$('.coupon-tax-wrap').find('.have-another-promo').hide();
            $('.billing-coupon-code').find('.error-messages').hide();
            $('.billing-coupon-code').find('.coupon-error').hide();
        }
    }
    if($('.billing-coupon-code').find('.valid-coupon-exists').length > 0){ 
    	$('.have-promo-havaacoupon').hide();
    	$('.coupon-tax-wrap').find('.have-another-promo').show();
    }
   
   var $main = $('#main');
   var $couponForm = $('.checkout-payment');
   if ($main.find('.coupon-state-checkout').length > 0) {
       $main.find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
   }

   $main.on('click', '.have-another-promo', function (e) {
	    e.preventDefault();
	    $('.checkout-havaacoupon').show();
	    $main.find('.coupon-form-apply').show();
	    //$main.find('.have-another-promo').hide();
	    $main.find('.have-promo').hide();	    
	});
   
   $couponForm.on('click', '#add-coupon', function(e) {
       e.preventDefault();
       var $error = $couponForm.find('.coupon-error'),
           code = $('input[name$="_couponCode"]').val();
       if (code.trim().length === 0) {
           //$('.redemption.coupon').html('');
           $main.find('.coupon-error.error').show();
           $error.html(Resources.COUPON_CODE_MISSING);
           $('.billing-coupon-code').find('.error-messages').hide();
           //$main.find('.error-messages').addClass('hide'); 
           return;
       } else {
           $main.find('.coupon-error.error').hide();
       }

       var url = util.appendParamsToUrl(Urls.addCoupon, {
           couponCode: code,
           format: 'ajax'
       });
       ajax.load({
           url: url,
           callback: function(data) {
               $('.redemption.coupon').html(data);
               $('input[name$="_billing_couponCode"]').val('');
               $('input[name$="_billing_couponCode"]').focusout();
               //$main.find('.coupon-form-checkout').addClass('hide');
               if ($main.find('.cartcoupon').length > 0) {
                   $main.find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
                   $main.find('.coupon-form-apply').hide();
                   $main.find('.have-another-promo').show();
               } else {
                   $main.find('.have-another-promo').text(Resources.CART_COUPON_CODE);
                   //$main.find('.have-another-promo').show();
               }
               if($('.billing-coupon-code').find('.valid-coupon-exists').length == 0){ 
                 	$('.have-promo-havaacoupon').show();
                 } else if($('.billing-coupon-code').find('.valid-coupon-exists').length > 0){ 
                  	$('.have-promo-havaacoupon').hide();
                  }
               if ($('.billing-coupon-code').find('.error-messages .haveerror').length > 0) {
            	   $main.find('.coupon-form-apply').show();
            	   $main.find('.have-another-promo').hide();
               }
               if ($main.find('.cartcoupon').length >= 5) {
                   $main.find('.coupon-form-apply').hide();
                   $main.find('.have-another-promo').hide();
               }
               updateSummary();
           }

       });
       updateSummary();

   }).on('keydown', 'input[name$="_couponCode"]', function(e) { // trigger events on enter
       if (e.which === 13) {
           e.preventDefault();
           $('#add-coupon').click();
       }
   }).on('click', '.removeButtonCoupon', function(e) { //remove coupon on billing page
       e.preventDefault();
       var url = Urls.removeCouponCheckout;
       var uuid = $(this).data('uuid');
       url = util.appendParamsToUrl(url, {
           uuid: uuid,
           format: 'ajax'
       });
       ajax.load({
           url: url,
           method: 'POST',
           callback: function(data) {
               $('.redemption.rowcoupons.coupon').html(data);
               updateSummary();
               if($('.billing-coupon-code').find('.valid-coupon-exists').length == 0){ 
                	$('.have-promo-havaacoupon').show();
                } else if($('.billing-coupon-code').find('.valid-coupon-exists').length > 0){ 
                 	$('.have-promo-havaacoupon').hide();
                 }
               if ($main.find('.cartcoupon').length == 0) {
            	   $('.checkout-havaacoupon').show();
            	   $main.find('.coupon-form-apply').show();
            	   $('.coupon-tax-wrap').find('.have-another-promo').hide();
                   //$main.find('.have-another-promo').text(Resources.CART_COUPON_CODE);
               } else {
                   $main.find('.have-another-promo').show();
                   $main.find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
               }
           }
       });
   });
   
   if ($main.find('.cartcoupon').length > 0) {
       $main.find('.coupon-form-apply').hide();
       $('.coupon-tax-wrap').find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
       $('.coupon-tax-wrap').find('.have-another-promo').show();
   }
   if ($main.find('.cartcoupon').length == 0) {
	   $main.find('.checkout-havaacoupon').removeClass('hide');
       $main.find('.coupon-form-apply').show();
       //$('.coupon-tax-wrap').find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
       $('.coupon-tax-wrap').find('.have-another-promo').hide();
   }
   if ($main.find('.cartcoupon').length >= 5) {
       $main.find('.coupon-form-apply').hide();
       $('.coupon-tax-wrap').find('.have-another-promo').hide();
   }
   
   
   $main.on('click', '.removeCouponCheckout', function(e) {
       e.preventDefault();
       var $current = $(this);
       var $content = $('#dialog-confirm');
       var couponRemoveDlg = dialog.create({
           options: {
               width: "755px",
               height: "auto",
               dialogClass: 'remove-coupon-cart',
               open: function() {
                   $('body').find('span.couponcode').html($current.data('couponcode'));
                   $('body').find('.couponuuid').val($current.data('uuid'));
                   $('body').off('click', '.keep-coupon').on('click', '.keep-coupon', function(e) {
                       dialog.close();
                   });
                   $('body').off('click', '.coupon-remove-checkout').on('click', '.coupon-remove-checkout', function(e) {
                       e.preventDefault();
                       $('body').find('#remove-coupon-' + $('.couponuuid').val()).trigger('click');
                       dialog.close();
                   });
               }
           }
       });
       $(couponRemoveDlg).html($content.html());
       couponRemoveDlg.dialog("open");
   });
    $cache.primary.on('click', '.checkout-tab-head[data-href]', function(e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).data('href'),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    checkout.init();
                }
                util.updateselect();
                progress.hide();
                updateSummary();
                //updateCCSection();
            }
        });
    });

    $cache.primary.on('click', '.refresh-content[data-refreshurl]', function(e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).data('refreshurl'),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                //handleBasketError();
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    checkout.init();
                }
                util.updateselect();
                progress.hide();
                if ($cache.primary.find('.spc-summary').length === 0) {
                    updateSummary();
                } else {
                    updateReviewSummary();
                }
                updateCCSection();
            }
        });
    });

    $cache.secondary.on('click', '.order-component-block a, .order-totals-table .order-shipping a', function(e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).attr('href'),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
            }
        });
    });

    $cache.primary.on('submit', 'form.address', function(e) {
        if ($(this).find('button[class$=-btn]').length > 0) {
            e.preventDefault();
            $(this).find('button[class$=-btn]').click();
        }
    });

   // Adding sequence for the phone number (3-3-4 digits).
    $('input[name*="_phone"]').on('keypress keyup change', function (e) {
        var number = $(this).val().replace(/[^\d]/g, '');
            number = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        	$(this).val(number)
    });
    
    // Adding sequence for the phone number (3-3-4 digits) on load
    if($('input[name*="_phone"]').length > 0) {
        var number = $('input[name*="_phone"]').val().replace(/[^\d]/g, '');
        number = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        $('input[name*="_phone"]').val(number);
    }
    
    //on page load, toggle company field
    toggleBusinessName();
    toggleprofileusinessName();
    toggledisclaimermessage();
    toggletextupdate();
    phonenumberrestrictiononload();
    showCheckoutCouponForm();
    // toggle business field
    $checkoutForm.on('click', 'input[name$="_singleshipping_shippingAddress_addressType"]', toggleBusinessName);
    $checkoutForm.on('click', 'input[name$="_login_loginAddress_profileaddressType"]', toggleprofileusinessName);
    $checkoutForm.on('click', 'input[name$="_login_loginAddress_profileaddressType"]', toggleprofileusinessName);
    $checkoutForm.on('click', 'input[name$="_login_loginAddress_profilephoneNo"]', toggledisclaimermessage);
    $checkoutForm.on('change', 'select[name*="_login_loginAddress_countryCodes_phoneCountryCode"]', toggletextupdate);
    $couponForm.on('click', 'input[name$="_billing_haveacoupon"]', showCheckoutCouponForm);
    $checkoutForm.on('keypress keyup change', 'select[name*="_countryCodes_phoneCountryCode"]',phonenumberrestriction);


    //block of shipping methods
    showShippingMethods($checkoutForm);


    //auto fill address form
    if (!google_api_inactive) {
        var address1Field = $checkoutForm.find("input[id$='_addressFields_address1']")[0];
        autoComplete = new google.maps.places.Autocomplete(address1Field);
        if (!$('#shipping-method-list') || $('#shipping-method-list').length === 0) {
            autoComplete.setComponentRestrictions({
                'country': 'us'
            });
        }
        $checkoutForm.find('input[name$="_addressFields_address1"]').attr("placeholder", "");
        autoComplete.addListener('place_changed', fillAddress);
    }

}

/* on keydown point to states dropdown */

function stateDropDown() {
    $('body').off('keydown').on('keydown', function (e) {
		var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0,
				 $active_select = $('.custom-select.current_item');

			if($active_select.length > 0 && !util.isMobile()) {
				e.preventDefault();
				var $list = $active_select.find('li'),
						$active;

				if(!$active_select.find('li.selected').length) {
					$active_select
						.find('li')
						.eq($active_select.find('option:selected').index())
						.addClass('selected');
				}

				if(key === 8) { // Delete key
					window.$currentkeycode = window.$currentkeycode.slice(0, -1);
				} else {
					window.$currentkeycode += $.trim(String.fromCharCode(key).toLowerCase());
				}
				$active = $active_select.find("li[label^='" + window.$currentkeycode + "']");

				if($active.length == 0){
					window.$currentkeycode = window.$currentkeycode.substr(window.$currentkeycode.length - 1, 1);
					$active = $active_select.find("li[label^='" + window.$currentkeycode + "']");
				}
				if($active.length == 0){
					$active = $active_select.find("li[label^='" + window.$currentkeycode.toUpperCase() + "']");
				}
				if(key == 40){
					$active = $active_select.find('li.selected');
					if($active.next().length > 0){
						$active_select.find('li').removeClass('selected');
						$active.next().addClass('selected');
					}
				}else if(key == 38){
					$active = $active_select.find('li.selected');
					if($active.prev().length > 0){
						$active_select.find('li').removeClass('selected');
						$active.prev().addClass('selected');
					}
				}
				else if(key === 13){
					if($active.length == 0 && $active_select.find('li.selected').length > 0){
						$active_select.find('li.selected').trigger('click');
					}else{
						$active.eq(0).trigger('click');
					}
				}else if(key === 9 || key === 27){
					util.closeselectbox();
				}else if($active.length != 0){
					$list.removeClass('selected');
					$active.eq(0).addClass('selected');
				}
				if(key === 8) { // Delete key
					return false;
				}

				var h = 0;
				if($active_select.find('.selection-list li.selected').length > 0){
					$.each($active_select.find('.selection-list li').splice(0, $active_select.find('.selection-list li.selected').index() - 1),function(){ h+=$(this).outerHeight();})
				}

				$active_select.find('.selection-list').scrollTop(h);
			}
	}).off('click').on('click', function (e) {
			$('select').removeClass('select-focus');
			$('.ui-dialog:visible .dialogInput').remove();
	}); 
	
}

/**
 * @function
 * @description populate shipping and billing address 
 * on change of address selection from dropdown
 */
function populateAddressOnSelect() {
    var $form = $('.address');
    // select address from list
    $('select[name$="_billing_addressList"]', $form).on('change', function() {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        //$('#commonaddressid').hide();

        //Added For PHSSECOM-408
        if ($('#addShipValue').length > 0 && $('#addShipValue').val() != undefined) {
            if ($('#addShipValue').val() == 'true') {
                $('.shipping-section').find('.add-new-shipping').trigger('click');
            }
        }

        if (!selectedAddress) {
            if ($(selected).val() != null && $(selected).val().trim() === 'addanew') {
                //$('#commonaddressid').show();
                clearAddressForm($form);
                util.updateselect();
                util.lableFocus();
            } else {
                return;
            }
        }
        util.fillBillingAddressFields(selectedAddress, $form);
        util.lableFocus();
    });
    $('select[name$="_singleshipping_addressList"]', $form).on('change', function() {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        //$('#commonaddressid').hide();
        if (!selectedAddress) {
            if ($(selected).val() != null && $(selected).val().trim() === 'addanew') {
                clearAddressForm($form);
                util.updateselect();
                util.lableFocus();
                return;
            } else {
                return;
            }
        }
        util.fillShippingAddressFields(selectedAddress, $form);
        $('select[name$="_singleshipping_addressList"]').val(selectedAddress.ID);
        util.updateselect();

        if (selectedAddress.companyName != null) {
            var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
            if ($radios.is(':checked') === true) {
                $radios.filter('[value=false]').prop('checked', true);
            }
            $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val(selectedAddress.companyName);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", false);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').removeClass('row-disabled');
            toggleBusinessName();
        } else {
            var $radios = $('input:radio[name$=_singleshipping_shippingAddress_addressType]');
            if ($radios.is(':checked') === true) {
                $radios.filter('[value=true]').prop('checked', true);
            }
            $('.shipping-section').find('input[name$="_singleshipping_shippingAddress_companyname"]').val('');
            $('input[name$="_singleshipping_shippingAddress_companyname"]').prop("disabled", true);
            $('input[name$="_singleshipping_shippingAddress_companyname"]').closest('.form-row').addClass('row-disabled');
            toggleBusinessName();
        }

        updateShippingMethodList();

        util.lableFocus();
    });


}


/**
 * @function
 * @description empty the address fields
 * shipping and billing address form
 */
function clearAddressForm(addressform) {
    var $form = addressform;
    if ($form != undefined) {
        $form.find('input[name$="_addressFields_address1"]').val('');
        $form.find('input[name$="_addressFields_address2"]').val('');
        $form.find('input[name$="_addressFields_city"]').val('');
        $form.find('input[name$="_addressFields_postal"]').val('');
        $form.find('input[name$="_addressFields_country"]').val('');
        $form.find('select[name$="_addressFields_states_state"]').val('');
        $form.find('input[name$="_phone"]').val('');
        $form.find('select[name$="_addressList"]').val('');
        $form.find('input[name$="_addressFields_firstName"]').val('');
        $form.find('input[name$="_addressFields_lastName"]').val('');
        $form.find('input[name$="_addressFields_Email"]').val('');
    }
}

function loadSPCLogin() {
    updateSummary();
    $cache.primary.find('.spc-login').trigger('ready');
    //phone number restriction if country code is +1
    $('select[name*="_countryCodes_phoneCountryCode"]').on('keypress keyup change ', function (e) {
    	phonenumberrestriction();
    });
    phonenumberrestrictiononload();
    $cache.primary.on('click', '.spc-login-btn:not(:disabled)', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');
        var url = util.appendParamToURL(form.attr('action'), 'format', 'ajax');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);        
        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize()
        }).done(function(data) {       	  
        	if(data.error && data.error.indexOf('CSRF') > -1) {
        		page.redirect(Urls.cartShow);
        		return;
        	}
            $cache.primary.html(data);
            updateSummary();
            initSPC();
            validator.init();
            util.updateselect();
            stateDropDown(); 
            util.scrollBrowser($('.checkout-tab-head.open').offset().top - 100); 
            progress.hide();
        });


    });


    $cache.primary.on('click', '.spc-guest-btn:not(:disabled)', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize()
        }).done(function(data) {
            $cache.primary.html(data);
            updateSummary();
            initSPC();
            validator.init();
            util.updateselect();
            progress.hide();
        });
    });
}

function loadSPCShipping() {
    //initiate address population
    populateAddressOnSelect();
    stateDropDown();
    $cache.primary.on('click', '.spc-shipping-btn:not(:disabled)', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');
        var url = util.appendParamToURL(form.attr('action'), 'format', 'ajax');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        //copy postal codes
        form = fillPostal(form);
        //check companyname
        if (form.find('input[name$="_companyname"]') != undefined && form.find('input[name$="_companyname"]').val() == '') {
            form.find('input[name$="_companyname"]').val('');
        }
        form.validate();
        if (!form.valid()) {
            /*            $('html, body').animate({
                            scrollTop: $('.checkout-shipping .shipping-address-fields .error:visible').first().offset().top - 30
                        }, 500);*/
            return false;
        }
        progress.show($cache.primary); 

        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize()
        }).done(function(data) {
        	if(data.error && data.error.indexOf('CSRF') > -1) {
        		page.redirect(Urls.cartShow);
        		return;
        	}
            $cache.primary.html(data);
            stateDropDown(); 
            updateSummary();
            initSPC();
            //tooltip.init();
            util.limitCharacters();
            validator.init();
            checkout.init();
            util.updateselect();            
            updateCCSection();
            progress.hide();
        });  
    });
}

function loadSPCMultiShipping() {
    $cache.primary.on('click', '.spc-multi-shipping-btn:not(:disabled)', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
                updateCCSection();
            }
        });
    });

    $cache.primary.on('click', '.multi-shiptosinglebutton:not(:disabled)', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');

        var url = util.appendParamToURL(form.attr('action'), $(this).attr('name'), true);
        url = util.appendParamToURL(url, form.find('input[name="csrf_token"]').attr('name'), form.find('input[name="csrf_token"]').attr('value'));
        progress.show($cache.primary);
        ajax.load({
            url: url,
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                updateSummary();
                initSPC();
                if (SitePreferences.QASc_ENABLED) {
                    qas.init();
                }
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
                updateCCSection();
                util.scrollBrowser($('.checkout-tab-head.open').offset().top - 100); 
            }
        });
    });

    //ship to store radio button. Ajax to get store details
    $cache.primary.on('click', '.js-multi-shiptostorebutton', function(e) {
        e.preventDefault();
        var form = $(this).closest('form');

        var url = util.appendParamToURL(form.attr('action'), $(this).attr('name'), true);
        url = util.appendParamToURL(url, form.find('input[name="csrf_token"]').attr('name'), form.find('input[name="csrf_token"]').attr('value'));

        progress.show($cache.primary);
        ajax.load({
            url: url,
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
                updateCCSection();
            }
        });
    });

    $cache.primary.on('click', '.item-shipping-address a', function(e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).attr('href'),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
                updateCCSection();
            }
        });
    });

}

function loadSPCBilling() {
    //initiate address population
	util.scrollBrowser($('.checkout-tab-head.open').offset().top - 100);  
    populateAddressOnSelect();
    stateDropDown(); 
    $cache.primary.on('click', '.spc-billing-btn:not(:disabled)', function(e) {

        e.preventDefault();
        /*        if (!($('input[name$="_billing_giftCertPin"]').parents('div.js-giftCertPin').hasClass('hide'))) {
                    $('input[name$="_billing_giftCertPin"]').removeClass('required');
                }*/
        var form = $(this).closest('form');
        var url = util.appendParamToURL(form.attr('action'), 'format', 'ajax');
        //copy postal codes
        form = fillPostal(form);
        //check CC expiry
        /*        var cardExpMonth = $('input.expiration-status-month').val();
                var cardExpYear = $('input.expiration-status-year').val();
                var curDate = new Date();
                if ((cardExpYear != '' && curDate.getFullYear() > cardExpYear) || (cardExpYear != '' && cardExpMonth != '' && curDate.getFullYear() == cardExpYear && curDate.getMonth() > cardExpMonth - 1)) {
                    $(document).find('div.expiry-error').removeClass('hide');
                    $(document).find('div.expiry-error').text(Resources.EXPIRY_ERROR);
                    $(window).scrollTop($('div.checkout-tab-head.open').offset().top);
                    return false;
                } else {
                    if (!($(document).find('div.expiry-error').hasClass('hide'))) {
                        $(document).find('div.expiry-error').addClass('hide');
                    }
                }*/
        //client side check for CC number length
        /*        var $ccField = $(document).find('input[name*="_creditCard_number"]');
                if ($ccField.length > 0) {
                    if ($(document).find('select[name$="_type"]').val() == 'AX' && $ccField.val().length < 18) {
                        $ccField.parents('div.form-row').addClass('error');
                        if ($ccField.parents('div.form-row').find('div.form-caption').length > 0) {
                            $ccField.parents('div.form-row').find('div.form-caption').replaceWith('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        } else {
                            $ccField.parents('div.form-row').append('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        }
                        return false;
                    } else if ($(document).find('select[name$="_type"]').val() != 'AX' && $ccField.val().length < 19) {
                        $ccField.parents('div.form-row').addClass('error');
                        if ($ccField.parents('div.form-row').find('div.form-caption').length > 0) {
                            $ccField.parents('div.form-row').find('div.form-caption').replaceWith('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        } else {
                            $ccField.parents('div.form-row').append('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        }
                        return false;
                    }
                }*/

        form.validate();
        if (!form.valid()) {
            $('html, body').animate({
                scrollTop: $('.checkout-billing .address-fields .error:visible').first().offset().top - 30
            }, 500);
            return false;
        }
        progress.show($cache.primary);


        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize()
        }).done(function(data) {
        	if(data.error && data.error.indexOf('CSRF') > -1) {
        		page.redirect(Urls.cartShow);
        		return;
        	}
            $cache.primary.html(data);
            updateReviewSummary();
            initSPC();
            if ($cache.primary.find('.spc-summary').length === 0) {
                tooltip.init();
                util.limitCharacters();
                validator.init();
                /*                    var $ccField = $(document).find('input[name*="_creditCard_number"]');
                                if ($ccField.length > 0) {
                                    if ($ccField.val().indexOf('*') != -1) {
                                        $ccField.val('');
                                        var $cvcNumber = $(document).find('input[name*="paymentMethods_creditCard_cvn"]');
                                        $cvcNumber.val('');
                                    }
                                }*/
            }
            
            
            updateSummary();
            initSPC();
            tooltip.init();
            util.limitCharacters();
            validator.init();
            checkout.init();
            util.updateselect();
            progress.hide();
            updateCCSection();
            util.scrollBrowser($('.checkout-tab-head.open').offset().top - 100); 
        });
    });

    //billing address same as shipping address
    //clear form if check box is unchecked, checked, fill up with shipping address
    $cache.primary.on('click', 'input[name$="_shippingAddress_useAsBillingAddress"]', function(e) {
        var $clearform = 'true';
        var form = $(this).closest('form');
        if ($('input[name$="_shippingAddress_useAsBillingAddress"]:checked').val() === 'true') {
            $clearform = 'false';
        }
        //billing url
        var url = util.appendParamsToUrl(Urls.billing, {
            'format': 'ajax',
            'clearform': $clearform
        });
        //e.preventDefault();
        progress.show($cache.primary);

        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize()
        }).done(function(data) {
            $cache.primary.html(data);
            updateReviewSummary();
            initSPC();
            validator.init();
            checkout.init();
            util.updateselect();
            progress.hide();
        });
    });


    $cache.primary.on('click', '.redemption.giftcert a.remove', function(e) {
        e.preventDefault();
        progress.show($cache.primary);
        var url = $(this).attr('href');
        ajax.load({
            url: url,
            data: {},
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                //handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                checkout.init();
                util.updateselect();
                progress.hide();
            }
        });
    });
    /*    if ($(document).find('.checkout-tab-content.tab-content-show').find('.error-checkout').length > 0) {
            var $ccField = $(document).find('input[name*="_creditCard_number"]');
            var $PaymentMethod = $('#PaymentMethod_CREDIT_CARD');
            if ($ccField.length > 0) {
                if ($ccField.val().indexOf('*') != -1) {
                    $PaymentMethod.find('.input-text').val('');
                    var $cardtype = $PaymentMethod.find('.card-type');
                    $cardtype.find('.input-select option:selected').removeAttr('selected');
                    $cardtype.find('.selected-option').empty().text('Visa');
                    var $month = $PaymentMethod.find('.month');
                    $month.find('.input-select option:selected').removeAttr('selected');
                    $month.find('.selected-option').empty().text('MM');
                    var $year = $PaymentMethod.find('.year');
                    $year.find('.input-select option:selected').removeAttr('selected');
                    $year.find('.selected-option').empty().text('YYYY');
                }
            }
        }*/
}

/**
 * @function
 * @description loads payment page related events and initialize them
 */
function loadSPCPayment() {
    //payment form
    var $paymentForm = $('form.checkout-payment');
    //append cc images next to cvn field
    $('.visa-card-image').appendTo('.spc-cvn .field-wrapper'); 
    
    util.checkoutScrollbar();
    //hide CC if PayPal or PO is selected on load
    if ($paymentForm.find('input[name$="_billing_paymentMethods_selectedPaymentMethodID"]:checked').val() == "PayPal" || $paymentForm.find('input[name$="_billing_paymentMethods_selectedPaymentMethodID"]:checked').val() == "PO") {
        $('.payment-method.expend-details').removeClass('payment-method-expanded');
        //expand PO content section
        if($paymentForm.find('input[name$="_billing_paymentMethods_selectedPaymentMethodID"]:checked').val() == "PO") {
        	$('.payment-information').find('.PO-content').addClass('payment-method-expanded');
        	$('select#POList').trigger('change'); //simulate change to activate initial amountTotal check
            //Check if Course in Cart
        	checkPOAmount();
        }
    }
    var $cvcNumber = $('.cvn').find('input[name*="paymentMethods_creditCard_cvn"]');
    if ($cvcNumber.length > 0) {
    	$cvcNumber.val('');
    }
    
    if($('.billing-coupon-code').find('.valid-coupon-exists').length > 0){ 
		$("input[name$='_billing_haveacoupon']").prop("checked", true); 
	}
    //expand and collapse credit card fields on select of payment method
    $paymentForm.on('click', '#is-PayPal, #is-PO', function() { //checking paypal checked or not
        $('.payment-method-expanded').removeClass('payment-method-expanded');
        //$(this).closest('.checkout-content').find('.credit-number .input-text').val('');
        //$('select[name$="_billing_paymentMethods_creditCard_expiration_month"]').val('');
        //$('select[name$="_billing_paymentMethods_creditCard_expiration_year"]').val('');
        //$(this).closest('.checkout-content').find('.cvn .input-text').val('');
        util.updateselect();
        util.lableFocus();
    }).on('click', '#is-CREDIT_CARD', function() {
        $('.payment-information').find('.expend-details').addClass('payment-method-expanded');
        $(this).closest('.payment-information').find('.PO-content').removeClass('payment-method-expanded');
    }).on('click', '#is-PO', function(){
    	$(this).closest('.payment-information').find('.PO-content').addClass('payment-method-expanded');
    	$('select#POList').trigger('change'); //simulate change to activate initial amountTotal check
    }).on('change', 'select#POList', function() {
        //Check if Course in Cart
    	checkPOAmount();
    });


    //update cc type
    updateCCType();
    //append spaces to cc number field
    util.cardnumberSpace();
    $cache.primary.on('click', '.spc-payment-btn:not(:disabled)', function(e) {

        e.preventDefault();
        var form = $(this).closest('form');
        var url = util.appendParamToURL(form.attr('action'), 'format', 'ajax');
        var applyName = $(this).attr('name');
        //check CC expiry
        /*        var cardExpMonth = $('input.expiration-status-month').val();
                var cardExpYear = $('input.expiration-status-year').val();
                var curDate = new Date();
                if ((cardExpYear != '' && curDate.getFullYear() > cardExpYear) || (cardExpYear != '' && cardExpMonth != '' && curDate.getFullYear() == cardExpYear && curDate.getMonth() > cardExpMonth - 1)) {
                    $(document).find('div.expiry-error').removeClass('hide');
                    $(document).find('div.expiry-error').text(Resources.EXPIRY_ERROR);
                    $(window).scrollTop($('div.checkout-tab-head.open').offset().top);
                    return false;
                } else {
                    if (!($(document).find('div.expiry-error').hasClass('hide'))) {
                        $(document).find('div.expiry-error').addClass('hide');
                    }
                }*/
        //client side check for CC number length
        /*        var $ccField = $(document).find('input[name*="_creditCard_number"]');
                if ($ccField.length > 0) {
                    if ($(document).find('select[name$="_type"]').val() == 'AX' && $ccField.val().length < 18) {
                        $ccField.parents('div.form-row').addClass('error');
                        if ($ccField.parents('div.form-row').find('div.form-caption').length > 0) {
                            $ccField.parents('div.form-row').find('div.form-caption').replaceWith('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        } else {
                            $ccField.parents('div.form-row').append('<div class="form-caption error-message">Invalid Credit Card Number</div>');
            }
                        return false;
                    } else if ($(document).find('select[name$="_type"]').val() != 'AX' && $ccField.val().length < 19) {
                        $ccField.parents('div.form-row').addClass('error');
                        if ($ccField.parents('div.form-row').find('div.form-caption').length > 0) {
                            $ccField.parents('div.form-row').find('div.form-caption').replaceWith('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        } else {
                            $ccField.parents('div.form-row').append('<div class="form-caption error-message">Invalid Credit Card Number</div>');
                        }
                        return false;
                    }
                }*/

        //Check if Course in Cart for the PO payment
        if(!checkPOAmount()) {
        	return false;
        }
        
        form.validate();
        if (!form.valid()) {
            $('html, body').animate({
                scrollTop: $('.checkout-billing .address-fields .error:visible').first().offset().top - 30
            }, 500);
            return false;
        }
        progress.show($cache.primary);


        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize() + '&' + applyName + '=makepayment'
        }).done(function(data) {
        	if(data.error && data.error.indexOf('CSRF') > -1) {
        		page.redirect(Urls.cartShow);
        		return;
        	}
            $cache.primary.html(data);
            updateReviewSummary();
            initSPC();
            if ($cache.primary.find('.spc-summary').length === 0) {
                tooltip.init();
                util.limitCharacters();
                validator.init();
                /*                    var $ccField = $(document).find('input[name*="_creditCard_number"]');
                                if ($ccField.length > 0) {
                                    if ($ccField.val().indexOf('*') != -1) {
                                        $ccField.val('');
                                        var $cvcNumber = $(document).find('input[name*="paymentMethods_creditCard_cvn"]');
                                        $cvcNumber.val('');
                                    }
                                }*/
            }
            
            updateSummary(true);
            initSPC();
            tooltip.init();
            util.limitCharacters();
            validator.init();
            checkout.init();
            util.updateselect();
            util.scrollBrowser($('.checkout-tab-head.open').offset().top - 200); 
            progress.hide();
            //updateCCSection();
            $('.spc-placeorder-disabled').closest('form').on('submit', function() {
                $('.checkout-summary .spc-placeorder-disabled').attr('disabled', 'disabled');
            });          
            
        });
    });

    
/*    $cache.primary.on('click', '.spc-placeorder-disabled', function() {
    	$(this).attr('disabled', true);  
        progress.hide(); 
    
    });*/
    
/*    $('.spc-placeorder-disabled').closest('form').on('submit', function() {
        $('.checkout-summary .spc-placeorder-disabled').attr('disabled', 'disabled');
    })*/

    
}


/**
 * @function
 * @description finds and returns the Credit Card type 
 * @param number : credit card number
 */
function detectCardType(number) {
    var re = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        Master: /^((5[1-5]|2[2-7])[0-9]{14})$/,
        Amex: /^3[47][0-9]{13}$/,
        Discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/
    }

    for (var key in re) {
        if (re[key].test(number)) {
            return key
        }
    }
}


/**
 * @function
 * @description updates credit card type based on user entered number
 * this updates cc type hidden select  field 
 */
function updateCCType() {
    $('input[name*="_creditCard_number"]').focusout(function() {
        var ccnum = $(this).val();
        var checknum = ccnum.replace(/\s/g, "");
        var cctype = detectCardType(checknum);
        if (cctype != '' && cctype != 'undefined' && cctype != null) {
            $('select[name$="_type"]').val(cctype);
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        } else if (ccnum.length > 15) {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
            $('.card-number').find('.field-wrapper').append('<span class="invalidcard error">' + Resources.VALIDATE_CARDTYPE + '</span>');
        } else {
            $('select[name$="_type"]').val('Visa');
            util.updateselect();
            $('.card-number').find('.field-wrapper').find('.invalidcard.error').remove();
        }
    });
}



function updateSummary(summary) {
    var $summary = $('#secondary.summary');
    var isSummaryPage = false;
    // indicate progress
    // for checkout page tooltip
    initTooltip();
    util.checkoutScrollbar();
    progress.show($summary);
    if(summary != undefined && summary != null && summary != 'null' && summary == true) {
    	isSummaryPage = true;
    }
    var url = util.appendParamToURL(Urls.summaryRefreshURL, 'summarypage', isSummaryPage);
    $.ajax({
        url: url,
        dataType: 'HTML'
    }).done(function(res) {
            var $ordercont = $(res).find('.checkout-ordersummary').length == 0 ? $(res).filter('.checkout-ordersummary') : $(res).find('.checkout-ordersummary');
            $summary.find('.checkout-ordersummary').html($ordercont.html());
            $summary.fadeIn('fast');
            $summary.find('.checkout-mini-cart .minishipment .header a').hide();
            $summary.find('.order-totals-table .order-shipping .label a').hide();
            progress.hide();
            util.toolgeSummary();
            util.checkoutScrollbar(); 
            initTooltip();
         // updating value of addToEmailList updateSummary
            $('.checkout-summary input[name$="_billing_billingAddress_addToEmailList"]').on('change', function(e) {
            	progress.show($cache.primary);
        	    if ($('.checkout-summary').find('input[name$="billing_billingAddress_addToEmailList"]').is(':checked')) {
        	    	$('.add-to-email-list').find('input[name$="_billing_billingAddress_addToEmailList"]').attr('value', true);
        	    } else {
        	    	$('.add-to-email-list').find('input[name$="_billing_billingAddress_addToEmailList"]').attr('value', false);
        	    }
                $.ajax({
                	url: util.appendParamToURL(Urls.setMailSubscribeValue, 'subscribe', $('.checkout-summary').find('input[name$="billing_billingAddress_addToEmailList"]').is(':checked'))
                }).done(function() {
                	 progress.hide();
                });
            });
             
            
            // disabled placeorder button on updateSummary
           if($('.spc-placeorder-disabled').length > 0){
            	$('.spc-placeorder-btn').attr('disabled', false);
            }
            else {
            	$('.spc-placeorder-btn').attr('disabled', true);
            }
            
           $('.sum-btn').closest('form').on('submit', function() {
               $('.checkout-summary .sum-btn').attr('disabled', 'disabled');
           });
           mobileCartItems();
    });
    
}

/**
 * @function
 * @description checks if the postal is empty fills with otherpostal
 * 				if otherpostal is empty, fills with postal
 * @params form form element that contain the postal code
 */
function fillPostal(form) {
    var postal = form.find('input[name$="_addressFields_postal"]');
    var otherPostal = form.find('input[name$="_addressFields_otherPostal"]');
    if (postal != undefined && otherPostal != undefined) {
        if (postal.val() == '' && otherPostal.val() != '') {
            postal.val(otherPostal.val());
        }
        if (postal.val() != '' && otherPostal.val() == '') {
            otherPostal.val(postal.val());
        }
    }
    return form;
}

function updateReviewSummary() {
    var url = Urls.summaryReviewRefreshURL;
    var summary = $('#secondary.summary');
    summary.load(url, function() {
        summary.find('.checkout-mini-cart .minishipment .header a').hide();
        summary.find('.order-totals-table .order-shipping .label a').hide();

        //loading order summary section for review page
        if ($('.order-summary-footer').length > 0) {
            $('.submit-order').closest('form').one('submit', function() {
                $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
            });

            if ($('.notavailable').length > 0) {
                $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
            }
        }
    });
}

function updateCCSection() {
    if ($('#creditCardList').length > 0 && ($('#creditCardList').val() != '')) {
        var selectedCards = $('#creditCardList option');
        var cardSelected = false;
        //remove all the selected option from dropdown
        //check for existing card selected 
        selectedCards.each(function() {
            $(this).removeAttr('selected');
            if ($(this).attr('form-selected')) {
                $(this).attr('selected', 'selected');
                cardSelected = true;
                $('#creditCardList').val($(this).val()).trigger('change');
                return;
            }
        });

        //if no selected card found, select the default card stored in the account
        if (!cardSelected) {
            selectedCards.each(function() {
                if ($(this).attr('default-selected')) {
                    $(this).attr('selected', 'selected');
                    cardSelected = true;
                    $('#creditCardList').val($(this).val()).trigger('change');
                    return;
                }
            });
        }

        if (!cardSelected) {
            $('#creditCardList').trigger('change');
        }
    } else {
        var $ccField = $(document).find('input[name*="_creditCard_number"]');
        var $PaymentMethod = $('#PaymentMethod_CREDIT_CARD');
        if ($ccField.length > 0) {
            if ($ccField.val().indexOf('*') != -1) {
                $PaymentMethod.find('.input-text').val('');
                var $cardtype = $PaymentMethod.find('.card-type');
                $cardtype.find('.input-select option:selected').removeAttr('selected');
                $cardtype.find('.selected-option').empty().text('Visa');
                var $month = $PaymentMethod.find('.month');
                $month.find('.input-select option:selected').removeAttr('selected');
                $month.find('.selected-option').empty().text('MM');
                var $year = $PaymentMethod.find('.year');
                $year.find('.input-select option:selected').removeAttr('selected');
                $year.find('.selected-option').empty().text('YYYY');
            }
        }
    }
}

 
mobileCartItems();
 
function initSPC() {
    initializeCache();
    initializeDom();
    initializeEvents();
}

exports.init = function() {
    initializeCache();
    initializeDom();
    initializeEvents();
};