'use strict';

var formatString = function(str){
	if(str != undefined && str != ''){
		str =  str.replace(/&/g, 'and').replace(/;/g, ' ').replace(/,/g, ' ').replace(/\+/g, 'and').replace(/%/g, ' ').replace(/@/g, ' ').replace(/#/g, ' ')
			   .replace(/!/g, ' ').replace(/\(/g, ' ').replace(/\)/g, ' ').replace(/\[/g, ' ').replace(/]/g, ' ').replace(/{/g, ' ').replace(/}/g, ' ').replace(/=/g, ' ')
			   .replace(/\*/g, ' ').replace(/\^/g, ' ').replace(/-/g, ' ').replace(/\$/g, ' ').replace(/\*/g, ' ').replace(/\|/g,'/').replace(/"/g, '').replace(/'/g, ' ');
	   }
	return str; 
};
var index = 1;

/**
 * My Account Forgot Password - Fire when user initiates forgot password procedure
 */
exports.forgotPassword = function() {
    dataLayer.push({
        'event': 'myAccountForgot', // case sensitive - use exact value
        'eventCategory': pageContext.type,
        'eventAction': 'Retrieve Password',
        'eventLabel': 'Forgot Password'
    });
};

/**
 * Email-Signup - Fire when user initiates email subscription
 */
exports.emailSubmitFooter = function() {
	dataLayer.push({
		'eventName': 'email signup footer'+String(index),
		'primaryCategory':formatString(document.title),
		'linkName': "Email news"
	});
};
/**
 * Customer-Service - Fire when user clicks footer customer service link 
 */
exports.footerCustomerServiceLink = function($this) {
	dataLayer.push({
        'event': 'customer service phone click',
        'footerLinkText': $($this).attr('alt') // populate with anchor alt text
    });
};
/**
 * Social-Media - Fire when user clicks footer social media links 
 */
exports.footerSocialIconClick = function($this) {
	dataLayer.push({
        'event': 'social click',
        'socialNetwork': $($this).attr('alt') // populate with anchor alt text
    });
};
exports.headerMenuNavigationlinks = function($this) {
	var level1 = $($this).parents('ul.menu-category').find('li.active a.has-sub-menu span' ).text(),
		level2 = $($this).closest('div').hasClass('level-2') ? $($this).text().trim() : '',
		level3 = $($this).closest('ul').hasClass('level-3') ? $($this).text().trim() : '',
		level4 = $($this).closest('ul').hasClass('level-4') ? $($this).text().trim() : '';
		if(level4 !='' && $($this).closest('ul').parent().parent().hasClass('level-3')) {
			level3 = $($this).parents('ul.level-3.menu-category').find('li.anchoring-class.submenu.active span a').first().text().trim();
		}
	dataLayer.push({
        'event': 'header nav click',
        'L1categoryName': level1 != '' ? level1 : '',
        'L2categoryName': level2 != '' && level3 == '' ? level2 : level2 == '' && level3 == '' ? '' : $($this).data('parentcatname'),
        'L3categoryName': level3 != '' && level4 == '' ? level3 : level3 == '' && level4 == '' ? '' : level3,
        'L4categoryName': level4 != '' ? level4 : '',
        'pageName': formatString(document.title)
    });
};

/**
 * Product Click Event - Fire when a user clicks on a visible product in the category grid page
 */
exports.productClick = function($this) {

    var dataContainer = $this.closest('.product-tile'), 
    	productName = '', 
    	masterID = '',
    	catName = '',
    	catPath = '',
    	currencyCode = '', 
    	brand = '', 
    	price = '',
    	tile = $this.closest('.grid-tile'),
    	tileIndex = '';
    /*var impression = pageData.ecommerce.impressions.filter(function(obj) {
        return obj.position == (tile.data('idx') + 1);
    })[0];*/
    if (dataContainer.length == 1) {
    	productName = dataContainer.attr('data-productname');
    	masterID = dataContainer.attr('data-masterid');
    	catName = dataContainer.attr('data-category-name');
    	catPath = dataContainer.attr('data-category-path');
    	currencyCode = dataContainer.attr('data-currency');
    	brand = dataContainer.attr('data-brand');
    	price = dataContainer.find('.product-sales-price').length > 0 ? (tile.find('.product-sales-price').length > 1 ? tile.find('.product-sales-price')[0].text : tile.find('.product-sales-price').text()) : '';
    	
    	tileIndex = $('li.grid-tile').length > 0 ? $('li.grid-tile').index(tile) + 1 : '';
    }

    dataLayer.push({
        'event': 'productClick',
        'eventCategory': catName,
        'eventAction': 'product click',
        'eventLabel': catPath, //Product List Value ie. category name / search result
        'ecommerce': {
            'currencyCode': currencyCode,
            'click': {
                'actionField': {
                    'list': catPath
                },
                'products': [{
                    'name': productName,
                    'id': masterID,
                    'price': price,
                    'brand': brand,
                    'category': catName,
                    'list': catPath, // value will need to persist to the purchase event
                    'position': tileIndex
                }]
            }
        }
    });
};


/**
 * Product Click from Recommendation Zone - Fire when a user clicks a recommended item in the PDP Recommendation Zone
 */
exports.productClickRecPDP = function($this) {

    var sku = $this.find('.capture-product-id').text();
    var impression = pageData.ecommerce.detail.impressions.filter(function(obj) {
        return obj.id == sku;
    })[0];

    dataLayer.push({
        'event': 'productClick',
        'productClicked': sku,
        'eventAction': 'product click',
        'eventLabel': '', //Product List Value ie. category name / search result
        'ecommerce': {
            'currencyCode': pageData.ecommerce.currencyCode,
            'click': {
                'actionField': {
                    'list': 'PDP you might also like'
                },
                'products': [{
                    'name': impression.name,
                    'id': impression.id,
                    'price': impression.price,
                    'category': impression.category,
                    'list': impression.list, // value will need to persist to the purchase event
                    'position': impression.position
                }]
            }
        }
    });
};


/**
 *  Category Page Sort - Fire when a user changes the sort by value on the category grid page
 */
exports.sort = function(sortID) {
    dataLayer.push({
        'event': 'pageSort',
        'eventCategory': pageContext.type,
        'eventAction': 'page sort',
        'eventLabel': 'category page sort: ' + sortID //Note naming convention
    });
};

/**
 *  Category Page Refinement - Fire when a user makes a category refinement selection
 */
exports.refinement = function($this) {

    if ($($this).parents('.refinement').length === 0) {
        return;
    }

    var refinementType = $($this).parents('.refinement').find('.heading-refinement').text();
    var refinementValue = $($this).closest('ul').find('li.selected a.current').text() ? $($this).closest('ul').find('li.selected a.current').text() : $($this).text();
    dataLayer.push({
        'event': 'pageRefinement',
        'eventCategory': pageContext.type,
        'eventAction': 'category refinement',
        'eventLabel': 'category page refinement: ' + (refinementType ? refinementType.replace(/(?:\r\n|\r|\n)/g, '') : '') + ': ' + (refinementValue ? refinementValue.replace(/(?:\r\n|\r|\n)/g, '') : ''),
        'refinementType': refinementType ? refinementType.replace(/(?:\r\n|\r|\n)/g, '') : '',
        'refinementValue': refinementValue ? refinementValue.replace(/(?:\r\n|\r|\n)/g, '') : ''
    });
};

/**
 *  TSED-1104: Course Refinement Tracking
 *  Course Refinement - Fire when a user applies a filter on refinement selection
 */
exports.courseRefinement = function($this) {

    if ($($this).parents('.refinement').length === 0) {
        return;
    }

    var refinementType = $($this).parents('.refinement').find('.heading-refinement').text();
    //TSED-1104: Course Refinement Tracking
    var courseFormat = [];
    var selectedTimeOfDay = [];
    var dateRange = "";
    var courseFocusSelected = false;
    if(refinementType){
    	var refinementTypeText = refinementType.replace(/(?:\r\n|\r|\n|\t)/g, '').toLocaleUpperCase();
    	//class_focus
    	var classFocusValue = [];
		if($('.forcoursefocus').find('.available.selected').length > 1) {
			var arr = [];
			classFocusValue = $('.forcoursefocus').find('.available.selected').text().replace(/(?:\r\n|\r|\n|\t)/g, '');
			$.each($('.forcoursefocus').find('.available.selected'), function(){
				arr.push($(this).text().trim());
				classFocusValue = arr;
    		});
		} else if($('.forcoursefocus').find('.available.selected').length > 0) {
			classFocusValue = $('.forcoursefocus').find('.available.selected').text().replace(/(?:\r\n|\r|\n|\t)/g, '');
		}
    	
    	//class_format
    	courseFormat = 'All Formats';
		if($('.plp-refinements').find('.refinement').find('.coursesetting li.available.selected').length > 1) {
			var arr = [];
			var formatValue = "";
			$.each($('.plp-refinements').find('.refinement').find('.coursesetting li.available.selected'), function(){
				formatValue = $(this).find('a').attr('data-value');
				if(formatValue == 'classroom+online') {
					formatValue = "Online + Classroom";
				}
    			arr.push(formatValue);
    			courseFormat = arr;
    		});
		} else if($('.plp-refinements').find('.refinement').find('.coursesetting li.available.selected').length > 0) {
			var formatValue = $('.plp-refinements').find('.refinement').find('.coursesetting li.available.selected').find('a').attr('data-value');
			if(formatValue == 'classroom+online') {
				formatValue = "Online + Classroom";
			}
			courseFormat = formatValue;
		}
		//date_range
		var minDate = "", maxDate = "";
		minDate = $('.plp-refinements').find('.refinement').find('.preferreddaterange').find($('input:text[name=datemin]')).val();
		maxDate = $('.plp-refinements').find('.refinement').find('.preferreddaterange').find($('input:text[name=datemax]')).val();
		if(minDate != '' && minDate != undefined && maxDate !='' && maxDate != undefined) {
			dateRange = minDate + ' - ' + maxDate;
		} else {
			dateRange = '';
		}
		//day_of_week
		var arr = [], selectedDay = [];
	    if($('.plp-refinements').find('.refinement').find('.coursedaysofweek li.selected.available').length > 1) {
	    	$.each($('.plp-refinements').find('.refinement').find('.coursedaysofweek li.selected.available'), function(){
	    		var day = $(this).find('a').attr('data-value');
	    		if(day == 'WED') {
	    			arr.push('wednesday');
	    		} else if(day == 'THUR') {
	    			arr.push('thursday');
	    		} else if(day == 'SAT') {
	    			arr.push('saturday');
	    		} else {
	    			arr.push(($(this).find('a').attr('data-value') + 'day').toLowerCase());
	    		}
	    		selectedDay = arr;
	    	});
	    } else if($('.plp-refinements').find('.refinement').find('.coursedaysofweek li.selected.available').length == 1) {
	    	var courseDay = $('.plp-refinements').find('.refinement').find('.coursedaysofweek li.selected.available').find('a').attr('data-value');
	    	if(courseDay == 'WED') {
    			arr.push('wednesday');
    		} else if(courseDay == 'THUR') {
    			arr.push('thursday');
    		} else if(courseDay == 'SAT') {
    			arr.push('saturday');
    		} else {
    			selectedDay = (courseDay + 'day').toLowerCase();
    		}
	    }
	    //time_of_day
	    var arr = [];
	    if($('.plp-refinements').find('.refinement').find('.coursestarttime li.selected.available').length > 1) {
	    	$.each($('.plp-refinements').find('.refinement').find('.coursestarttime li.selected.available'), function(){
    			arr.push($(this).find('a').attr('data-value'));
    			selectedTimeOfDay = arr;
    		});
	    } else if($('.plp-refinements').find('.refinement').find('.coursestarttime li.selected.available').length == 1) {
	    	selectedTimeOfDay = $('.plp-refinements').find('.refinement').find('.coursestarttime li.selected.available').find('a').attr('data-value');
	    }
    }
    
    /*dataLayer.push({
        'event': 'pageRefinement',
        'eventCategory': pageContext.type,
        'eventAction': 'category refinement',
        'eventLabel': 'category page refinement: ' + (refinementType ? refinementType.replace(/(?:\r\n|\r|\n)/g, '') : ''),
        'refinementType': refinementType ? refinementType.replace(/(?:\r\n|\r|\n|\t)/g, '') : '',
        'classCity': $('.geo-location').val(),
        'classZip': $('.geo-zipcode').val(), 
        'classsearchType': formatString($('.product-cat').val())
    });*/
    
    //TSED-1104: Course Refinement Tracking
    dataLayer.push({
    	'event': 'course_refinement',
        'class': formatString($('.product-cat').val()),
        'location': $('.geo-location').val(),
        'class_format': courseFormat,
        'day_of_week': selectedDay ? selectedDay : '',
        'time_of_day': selectedTimeOfDay ? selectedTimeOfDay : '',
        'date_range': dateRange, 
        'class_focus': classFocusValue
    });
};
/**
 *  View class details - Fire when a user clicks see details on class search page.
 */
exports.viewClassDetails = function(classObj) {
	var category;
	var productid = $(classObj).closest('.product-tile').attr('data-itemid');
	var name = formatString($(classObj).closest('.product-tile').find('.class-name-main').text().trim());
	var classSetting = formatString($(classObj).closest('.product-tile').find('.class-format').text());
	var classTime = $(classObj).closest('.product-tile').find('.strtime').text()+$(classObj).closest('.product-tile').find('.str_am_pm').text()  +" - "+$(classObj).closest('.product-tile').find('.endtime').text()+$(classObj).closest('.product-tile').find('.end_am_pm').text();
	var classdayofWeek = $(classObj).closest('.product-tile').find('.offer-day').text();
	var classDate =  $(classObj).closest('.product-tile').find('.offer-month').text()+" "+$(classObj).closest('.product-tile').find('.offer-date').text();
	category = formatString($('.product-cat').val());
	
	dataLayer.push({
        'event': 'productClick',
        'eventCategory': category,
        'eventAction': 'product click',
        'eventLabel': 'product quick view',
        'productType': 'course',
        'ecommerce': {
            'currencyCode': $('.currencyCode').val(),
            'click': {
                'actionField': {
                    'list': 'See class details'
                },
                'products': [{
                    'name': name,
                    'id': productid,
                    'category': category,
                    'classSetting' : classSetting,
                    'classzipCode': $('.geo-zipcode').val(), 
                    'classTime' : classTime,
                    'classdayofWeek' : classdayofWeek,
                    'classDate': classDate
                }]
            }
        }
    });
};

/**
 *  Add to Cart - Fires when a user adds an item to their shopping cart
 */
exports.addToCart = function($form) {
	var category = formatString($('.product-cat').val());
	if($($form).closest('.product-col-2.product-detail.product-set').length > 0){
		var name = formatString($($form).closest('.product-col-2.product-detail.product-set').find('.product-set-name').text()),
	      	pid = $form.find("input[name='pid']").val(),
	      	variantID = '',
	      	currencyCode = '',
	      	quantity = "1";
		var price = $('.standardprice').text(),
		  	price = $('.salesprice').text();
		var priceval = price.replace('$','').trim();
	} else if($($form).closest('.plp-tile').length > 0) {
		var name = $form.find("input[name='product-name']").val(),
			pid = $form.find("input[name='pid']").val(),
			quantity = "1",
			variantID = $($form).closest('.product-tile').attr('data-itemid'),
			currencyCode = $($form).closest('.product-tile').attr('data-currency'),
			price = $form.find('input[name="product-price"]').val();
		var priceval = price.replace('USD','').trim();
	} else {
		var name = formatString($($form).closest('.product-col').find('.product-detail .product-name').text()),
			pid = $form.find("input[name='mpid']").val(),
			quantity = $form.find('input[name="Quantity"]').val(),
			variantID = $form.find('input[name="pid"]').val(),
			currencyCode = $form.find('input[name="currencyCode"]').val(),
			price = $($form).closest('.product-col-2.product-detail').find('.price-sales').text();
		  	var priceval = price.replace('$','').trim();
	}
    var productVariations = $form.find('.product-variations').length > 0 ? $form.find('.product-variations') : $('.product-variations');
    var color = '';
    var size = '';
    if (productVariations) {
        var variationAttributes = productVariations.data('attributes');
        if (variationAttributes && variationAttributes.color) {
            color = variationAttributes.color.displayValue;
        }
        if (variationAttributes && variationAttributes.size) {
            size = variationAttributes.size.displayValue;
        }
    }

    dataLayer.push({
        'event': 'addToCart',
        'productSKUAdded': $form.find('input[name="pid"]').val(), // product SKU added
        'productType': 'supplies',
        'ecommerce': {
            'currencyCode': currencyCode,
            'add': {
            	'actionField': {
                    'list': 'Shopping cart'
                },
            	'products': [{
                    'id': pid,
                    'name': name,
                    'price': priceval,
                    'category': category,
                    'color': color, //product color
                    'size': size, //product size
                    'variant': variantID, //variation product id
                    'quantity': quantity
                }]
            }
        }
    });
};
/**
 *  Add to Cart - Fires when a user clicks sign me up button
 */
exports.addClassProductToCart = function(category,productid,name,classSetting,classTime,classdayofWeek,classDate) {
	dataLayer.push({
        'event': 'addToCart',
        'productSKUAdded': productid, // product SKU added
        'ecommerce': {
            'currencyCode': $('.currencyCode').val(),
            'add': {
            	'actionField': {
                    'list': 'Shopping cart'
                },
            	'products': [{
                    'id': productid,
                    'name': name,
                    'price': $('#classes-wrapper').find('.price-sales').text().trim(),
                    'category': category,
                    'classSetting' : formatString(classSetting),
                    'classzipCode': $('.geo-zipcode').val(),
                    'classTime' : classTime,
                    'classdayofWeek' : classdayofWeek,
                    'classDate': classDate
                }]
            }
        }
    });
};

/**
 *  Video Details - When a user clicks video details on PDP
 */
exports.videoDetailsClickEvent = function($this) {
	dataLayer.push({
        'event': 'videoPlay',
        'eventCategory': pageContext.type,
        'eventAction': 'product video play',
        'eventLabel': $this.find('img').attr('alt')
    });
};
/**
 *  Cancellation Policy - When a user clicks cancellation policy link
 */
exports.cancelationPolicyEvent = function() {
	dataLayer.push({
        'event': 'cancellation policy click',
        'eventCategory': pageContext.type,
        'eventLabel': 'cancellation policy link'
    });
};
/**
 *  View All Classes - When a user clicks view all button
 */
exports.viewAllClasses  = function(){
	dataLayer.push({
        'event': 'explore more classes click',
        'eventCategory': pageContext.type,
        'eventLabel': 'explore more classes'
    });
};

/**
 *  Size Chart - When a user views the sizing chart information page on PDP
 */
exports.sizeChart = function() {
    dataLayer.push({
        'event': 'sizeChart',
        'eventCategory': pageContext.type,
        'eventAction': 'product size chart',
        'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
    });
};

/**
 * Find in Store - Fire when a user clicks "Find in Store" on PDP
 */
exports.findInStorePDP = function() {
    dataLayer.push({
        'event': 'findInStore',
        'eventCategory': pageContext.type,
        'eventAction': 'find in store - initiate',
        'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
    });
};

/**
 * Find in Store � Zip Code Search - Fire when a user searches their zip code for product availability
 */
exports.findInStoreSearchPDP = function(label) {
    dataLayer.push({
        'event': 'searchZipCode',
        'eventCategory': pageContext.type,
        'eventAction': 'find in store - search',
        'eventLabel': label
    });
};

/**
 * Find in Store - Zip Code Search - Fire when a user searches their zip code for product availability
 */
exports.bisnPDP = function() {
    dataLayer.push({
        'event': 'backInStock',
        'eventCategory': pageContext.type,
        'eventAction': 'back in stock click',
        'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
    });
};

/**
 * Newsletter subscription event - Fire event when user moves to next step of checkout process
 */
exports.newsLetterSignupCheckout = function() {
    dataLayer.push({
        'event': 'newsletterSubscriptionCheckout',
        'eventCategory': 'checkout',
        'eventAction': 'subscribe to newsletter',
        'eventLabel': $('input[name$="billing_billingAddress_addToEmailList"]').is(':checked') ? 'checked' : 'unchecked'
    });
};

/**
 * Add to Wishlist / Save for Later - Fires when a user adds an item to their wishlist
 */
exports.addToWishlistPDP = function($this) {
    if ($this.data('action') !== 'wishlist') {
        return;
    }
    dataLayer.push({
        'event': 'saveProduct',
        'eventCategory': pageContext.type,
        'eventAction': 'save for later',
        'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
    });
};

/**
 * Promotion code applied - TIMING: Fires when a user adds an item to their shopping cart
 */
exports.applyCoupon = function(code) {
    dataLayer.push({
        'event': 'promoApplied',
        'eventCategory': pageContext.type,
        'eventAction': 'promotion applied',
        'eventLabel': code
    });
};

/**
 * Remove coupon : Fires when a user clicks remove coupon link
 */
exports.removeCoupon = function(code) {
    dataLayer.push({
        'event': 'removeCoupon',
        'eventCategory': pageContext.type,
        'eventAction': 'coupon removed',
        'eventLabel': code
    });
};
/**
 * Another coupon : Fires when a user clicks Have a coupon link
 */
exports.anotherCouponEvent = function() {
    dataLayer.push({
        'event': 'have a coupon code click',
        'eventCategory': pageContext.type,
        'eventLabel': 'have a coupon code'
    });
};

exports.formErrorMyAccounts = function(formattrs){
	dataLayer.push({
        'event': 'form error',
        'eventCategory': formatString(document.title),
        'formattributes': formattrs
    });
};

exports.realEstateTrackingHomePage = function(linkName,linkURL) {
	dataLayer.push({
        'event': 'content click',
        'eventCategory': 'home page',
        'eventLabel': formatString($this.attr('alt'))
    });
};

exports.realEstateTrackingCartPage = function($this){
	dataLayer.push({
        'event': 'content click',
        'eventCategory': 'shopping cart page',
        'eventLabel': $this.attr('alt')
    });
};

exports.realEstatetrackingSubCategory = function($this){
	dataLayer.push({
        'event': 'content click',
        'eventCategory': 'home page',
        'eventLabel': $this.attr('alt')
    });
};

exports.addToCalendar = function(){
	dataLayer.push({
        'event': 'add to calendar',
        'eventCategory': formatString(document.title),
        'eventLabel': 'add to calendar'
    });
};

exports.launchMyCourse = function(orderobj){
	var category;
	var productid = $(orderobj).closest('.cart-full-product').find('.product-id').text();
	var name = formatString($(orderobj).closest('.cart-full-product').find('.product-details-col .name a').text());
	var classSetting = formatString($('.online-launch').closest('.cart-full-product').find('.class-col-3.desktop-device .class-format').text());
	var classTime = $(orderobj).closest('.cart-full-product').find('.strtime').text()+$(orderobj).closest('.cart-full-product').find('.str_am_pm').text()  +" - "+$(orderobj).closest('.cart-full-product').find('.endtime').text()+$(orderobj).closest('.cart-full-product').find('.end_am_pm').text();
	var classdayofWeek = $(orderobj).closest('.cart-full-product').find('.offer-day').text();
	var classDate =  $(orderobj).closest('.cart-full-product').find('.offer-month').text()+" "+$(orderobj).closest('.cart-full-product').find('.offer-date').text();
	category = formatString($('.product-cat').val());
	
	dataLayer.push({
        'event': 'launch my course',
        'eventCategory': 'order confirm',
        'eventLabel': 'launch my course', // product SKU added
        'ecommerce': {
            'click': {
                'actionField': {
                    'list': 'launch my course'
                },
                'products': [{
                    'name': name,
                    'id': productid,
                    'category': category,
                    'classSetting' : classSetting,
                    'classzipCode': $('.geo-zipcode').val(), 
                    'classTime' : classTime,
                    'classdayofWeek' : classdayofWeek,
                    'classDate': classDate
                }]
            }
        }
    });
};
/**
 * Remove from Cart - Fire when a user removes an item from their shopping cart
 */
exports.removeProductItemFromCart = function($this) {
	if($($this).closest('.cartpage-product-section').length > 0){
		var name = formatString($($this).parents('.cart-product').find('.name').text().trim());
	  	var productid  = $($this).closest('.cart-product').find('.sku .value').text();
	  	var primaryCat = formatString($($this).closest('.cart-product').find('.item-cat').val());
	  	var quantity = $($this).closest('.cart-product').find('#updateqty').val();
	  	var price =  $($this).closest('.cart-product').find('.item-total').text().trim();
	  	var priceval = price.replace('$','').trim();
	  	
	  	dataLayer.push({
            'event': 'productRemove',
            'productSKURemoved': productid, // SKU of product removed
            'ecommerce': {
                'currencyCode': 'USD',
                'remove': {
                    'products': [{
                        'id': productid,
                        'name': name,
                        'price': priceval,
                        'category': primaryCat,
                        'quantity': quantity
                    }]
                }
            }
        });
	}
	if($($this).closest('.cartpage-class-section').length > 0){
    	var name = formatString($($this).closest('.cartpage-class-section').find('.name').text().trim());
    	var productid  = $($this).closest('.cartpage-class-section').find('.item-id').val();
    	var classSetting = formatString($($this).closest('.cartpage-class-section ').find('.class-col-3.desktop-device .class-format').text());
    	var classTime = $($this).closest('.cartpage-class-section').find('.strtime').text()+$($this).closest('.cartpage-class-section').find('.str_am_pm').text()  +" - "+$($this).closest('.cartpage-class-section').find('.endtime').text()+$($this).closest('.cartpage-class-section').find('.end_am_pm').text();
    	var classdayofWeek = $($this).closest('.cartpage-class-section').find('.offer-day').text();
    	var classDate =  $($this).closest('.cartpage-class-section').find('.offer-month').text()+" "+$($this).closest('.cartpage-class-section').find('.offer-date').text();
    	var primaryCat = formatString($($this).closest('.cartpage-class-section').find('.item-cat').val());
    	var price =  $($this).closest('.cartpage-class-section').find('.item-price span').text().trim();
    	var priceval = price.replace('$','').trim();
    	
    	dataLayer.push({
            'event': 'productRemove',
            'productSKURemoved': productid, // SKU of product removed
            'ecommerce': {
                'currencyCode': 'USD',
                'remove': {
                    'products': [{
                        'id': productid,
                        'name': name,
                        'price': priceval,
                        'category': primaryCat,
                        'quantity': quantity,
                        'classSetting': classSetting, 
 	                    'classzipCode': $('.geo-zipcode').val(),
 	                    'classTime' : classTime, 
 	                    'classdayofWeek' : classdayofWeek, 
 	                    'classDate' : classDate
                    }]
                }
            }
        });
	}
};

exports.findAClass = function($this) {
	var classSearchtype,classModule,classCity;
	if($($this).closest('.header-class-search').length > 0) {
		classSearchtype = $('.header-class-search .selection-list').find('li.selected').text();
		classCity = $('.header-class-search').find('.geo-location').val();
		classModule = "search box";
	} else if($($this).closest('.classes-search-action').length > 0) {
		classSearchtype = $('.classes-search-action .selection-list').find('li.selected').text();
		classCity = $('.classes-search-action').find('.geo-location').val();
		classModule = "utility search box";
	} else if($($this).closest('.home-class').length > 0) {
		classSearchtype =  $('.home-find-classes-form .selection-list').find('li.selected').text().trim();
		classModule = "content box search";
		classCity = $('.home-class').find('.geo-location').val();
	}
	//TSED-987:
	var classSearch, classFormat, location;
	if($($this).closest('.find-a-class-newdesign').length > 0) {
		classSearch = $('.custom-select.class-select').find('.selection-list li.selected').text().trim();
		classFormat = $('.custom-select.course-format').find('.selection-list li.selected').text().trim();
		location = $($this).closest('.cat-search').find('input.geo-location').val();
		
		dataLayer.push({
			'event' : "find_a_class",
			'location': location,
			'class' : classSearch,
			'class_format' : classFormat
		});
	}
	
	dataLayer.push({
        'event': 'find a class',
        'eventCategory': pageContext.type,
        'eventAction': classModule,
        'eventLabel': classSearchtype,
        'classlocation': classCity
    });
};

/**
 * Add certificate : Fires when a user clicks add to cart button
 */
exports.addToCartCertifications = function($this){
	var products = [];
	$('.eachcert').each(function(i) {
		if ($(this).is(':checked')) {
			var product = {
				productID:$(this).parents('.field-wrapper').find('.certid').val(),
                productName: $this.find('#pcname').val(),
                quantity: $this.find('#Quantity').val(), 
	            price:$this.find('#cprice').val(),
	            productType:'supplies'
			};
			products.push(product);
		}
	});
	dataLayer.push({
        'event': 'cart add',
        'eventCategory': formatString(document.title),
        'ecommerce': {
            'add': {
                'products':products
            }
        }
    });
};

exports.quickViewEvent = function(productObj) {
	/*if (tile.find('.quickview-nav').length == 0) {
        return;
    }*/
	var dataContainer = $(productObj).closest('.product-tile'),
	    tileIndex = '',
	    masterID = '',
	    productName = '',
	    catPath = '',
	    catName = '',
	    brand = '',
	    price = '',
	    tile = productObj.closest('.grid-tile'),
	    currencyCode = '';
	if (dataContainer.length == 1) {
		productName = dataContainer.attr('data-productname');
        masterID = dataContainer.attr('data-masterid');
        catPath = dataContainer.attr('data-category-path');
        catName = dataContainer.attr('data-category-name');
        currencyCode = dataContainer.attr('data-currency');
        brand = dataContainer.attr('data-brand');
        price = dataContainer.find('.product-sales-price').length > 0 ? (dataContainer.find('.product-sales-price').length > 1 ? dataContainer.find('.product-sales-price')[0].text : dataContainer.find('.product-sales-price').text()) : '';
        var priceval = price.replace('$','').trim();
        
        tileIndex = $('li.grid-tile').length > 0 ? $('li.grid-tile').index(tile) + 1 : '';
	}
	dataLayer.push({
        'event': 'productQuickview', // Fires on PDP Pages
        'ecommerce': {
            'currencyCode': currencyCode,
            'detail': {
                'actionField': {
                    'list': 'Search Results'
                },
                'products': [{
                    'name': productName, //product name
                    'id': masterID, // Parent ID
                    'price': priceval, // round to two decimals, if range use lowest price shown on site
                    'brand': brand, // product brand
                    'category': catName, // from hybris - product category
                    'list': catPath, // Breadcrumb (up to 5 Categories) (Search, Home other options)
                    'position': tileIndex // product location in PLP
                }]
            }
        }
    });
};

exports.init = function() {

    if (pageContext.type === 'MyAccount') {
        /**
         * My Account Check Order Status - Fire when a user checks the status of their order
         */
        $('button[name$="_ordertrack_findorder"]').click(function() {

            if (!$(this).closest('form').valid()) {
                return;
            }

            dataLayer.push({
                'event': 'myAccountCheckOrder', // case sensitive - use exact value
                'eventCategory': pageContext.type,
                'eventAction': 'Check Order Status',
                'eventLabel': 'Check Order Status'
            });
        });
    } else if (pageContext.type === 'storefront') {

        /**
         * Below is for homepage carousel & banners
         * Note - Carosuel structure may change from merchant to merchant. Below function assumed Prevail structure.
         */
        var ecommerceObj = {};
        var ecommerce = {};
        ecommerce.promoView = {};
        ecommerce.promoView.promotions = [];

        //Header banner
        $('.slotJSON').each(function() {
            $.merge(ecommerce.promoView.promotions, $(this).data('slotjson'));
        });

        //Home page slider
        $('.pt_storefront #homepage-slider #homepage-slides li img').click(function() {
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [{
                            'name': $(this).attr('alt'),
                            'creative': $(this).attr('src') ? $(this).attr('src').substr($(this).attr('src').lastIndexOf('/') + 1) : '',
                            'position': 'Homepage Carousel ' + ($(this).index() + 1)
                        }]
                    }
                }
            });
        });

        ecommerceObj.event = 'homePagePromoView';
        ecommerceObj.ecommerce = ecommerce;
        dataLayer.push(ecommerceObj);

    } else if (pageContext.type === 'product') {

        var $pdpMain = $('#pdpMain');
        /**
         * Product Click from Recommendation Zone - Fire when a user clicks a recommended item in the PDP Recommendation Zone
         */
        $('.recommendation-item').click(function() {
            //exports.productClickRecPDP($(this));
        });

        /**
         * Facebook Share - Fire when a user clicks to share an item on Facebook
         */
        $pdpMain.on('click', '.socialsharing a[data-share="facebook"]', function() {

            dataLayer.push({
                'event': 'facebookShare',
                'eventCategory': pageContext.type,
                'eventAction': 'facebook social share',
                'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
            });
        });

        /**
         * Twitter Share - Fire when a user clicks to share an item on Twitter
         */
        $pdpMain.on('click', '.socialsharing a[data-share="twitter"]', function() {

            dataLayer.push({
                'event': 'twitterShare',
                'eventCategory': pageContext.type,
                'eventAction': 'twitter social share',
                'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
            });
        });

        /**
         * Google+ Share - Fire when a user clicks to share an item on Google+
         */
        $pdpMain.on('click', '.socialsharing a[data-share="googleplus"]', function() {

            dataLayer.push({
                'event': 'googleShare',
                'eventCategory': pageContext.type,
                'eventAction': 'google+ social share',
                'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
            });
        });

        /**
         * Pinterest Share - Fire when a user clicks to share an item on Pinterest
         */
        $pdpMain.on('click', '.socialsharing a[data-share="pinterest"]', function() {

            dataLayer.push({
                'event': 'pinterestShare',
                'eventCategory': pageContext.type,
                'eventAction': 'pinterest social share',
                'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
            });
        });

        /**
         * Size Chart - When a user views the sizing chart information page on PDP
         */
        $pdpMain.on('click', '.socialsharing a[data-share="pinterest"]', function() {

            dataLayer.push({
                'event': 'pinterestShare',
                'eventCategory': pageContext.type,
                'eventAction': 'pinterest social share',
                'eventLabel': $('.product-col-2').find('input[name="pid"]').val() + ':' + $('.product-col-2 .product-name').text()
            });
        });

    } else if (pageContext.type === 'Cart') {

        /**
         * Add to Wishlist / Save for Later - Fires when a user adds an item to their wishlist from the shopping cart page
         */
        $('.add-to-wishlist').click(function() {
            dataLayer.push({
                'event': 'saveProduct',
                'eventCategory': pageContext.type,
                'eventAction': 'save for later',
                'eventLabel': $(this).parents('.cart-row:first').find('.item-details .sku .value').text()
            });
        });

        /**
         * Proceed with PayPal - Fire when a user clicks to proceed with PayPal on the shopping cart page
         */
        $('.checkout-paypal').click(function() {
            dataLayer.push({
                'event': 'checkoutPaypal',
                'eventCategory': pageContext.type,
                'eventAction': 'Checkout with Paypal'
            });
        });

        /**
         * Proceed to Checkout - Fire when a user click s "Proceed to Checkout" from shopping cart page
         */
        $('button[name$="_cart_checkoutCart"]').click(function() {
            dataLayer.push({
                'event': 'checkoutProceed',
                'eventCategory': 'Cart',
                'eventAction': 'Proceed to Checkout'
            });
        });

    } else if (pageContext.type === 'checkout') {
        /**
         * Newsletter subscription event - Fire event when user moves to next step of checkout process
         */
        $('button[name$="_billing_save"]').click(function() {
            exports.newsLetterSignupCheckout();
        });
    }
};
