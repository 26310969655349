'use strict';
var dialog = require('../../dialog');
var util = require('../../util');
var qs = require('qs');
var url = require('url');
var _ = require('lodash');

var zoomMediaQuery = matchMedia('(min-width: 960px)');

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
function loadZoom (zmq) {
    var $imgZoom = $('#pdpMain .main-image'),
        $imgZoomSrc = $('#pdpMain .main-image img.zoomImg'),
        $imgUrls = $('li.thumb.selected img.productthumbnail.desktop-only').data('lgimg'),
        hiresUrl,
        hiresAlt;

    if ($('#pdpMain .main-image').length > 0) {
        hiresUrl = $imgUrls.hires;
        hiresAlt = $imgUrls.alt;
    }

    if (!zmq) {
        zmq = zoomMediaQuery;
    }

    $('.img-loader').show();

    if (!zoomMediaQuery.matches) {
        $('.img-loader').hide();
    }


    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zoomMediaQuery.matches) {
        var isZoomInitialized = $('img.zoomImg').length;

        if (!isZoomInitialized) {
            $imgZoom.zoom({
                url: hiresUrl,
                callback: function() {
                    $(this).attr('alt', hiresAlt);
                }
            });
        } else {
            $imgZoomSrc.attr('src', hiresUrl);
        }

        setTimeout(function () {
            $('.img-loader').hide();
        }, 1000);

    }
}


zoomMediaQuery.addListener(loadZoom);

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage (atts, zoom) {
    $('#pdpMain .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    $('#pdpMain .primary-image').attr('data-src', atts.url);

    //updatePinButton(atts.url);
    if (!dialog.isActive() && !util.isMobile()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }

    var dontZoom = $('.product-image-container').parent().hasClass('quickviewmobile');
    if (!dontZoom && zoom) {
        setTimeout(function () {
            loadZoom();
        }, 25);
    }
}

function updatePinButton (imageUrl) {
    var pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    var newUrl = imageUrl;
    if (!imageUrl) {
        newUrl = document.querySelector('#pdpMain .primary-image').getAttribute('src');
    }
    var href = url.parse(pinButton.href);
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    var newHref = url.format(_.extend({}, href, {
        query: query, // query is only used if search is absent
        search: qs.stringify(query)
    }));
    pinButton.href = newHref;
}

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
}

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    //  updatePinButton();

    var dontZoom = $('.product-image-container').parent().hasClass('quickviewmobile');
    if (!dontZoom) {
        loadZoom();
    }

    // handle product thumbnail click event  
    var $pdpMain = $('#pdpMain');
    $('#pdpMain').on('click', '.productthumbnail', function () {
		// switch indicator
		$(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
		$(this).closest('.thumb').addClass('selected');
		var largeImg = $(this).data('lgimg');

        var zoom = true;
		setMainImage(largeImg, zoom);
	});
};
module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
