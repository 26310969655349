'use strict';

var account = require('./account'),
	dialog = require('../dialog'),
	util = require('../util'),
	page = require('../page'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    atmevents = require('../atmevents'),
    gtmevents = require('../gtm'),
    progress = require('../progress'),
    cartStoreInventory = require('../storeinventory/cart');


var $main = $('#main');

$main.find('.removeCoupon').on('click', function (e) {
    e.preventDefault();
    var $current = $(this);
    var $content = $('#dialog-confirm');
    
    //adobe analytics for remove coupon
    if(SitePreferences.isATMEnabled) {
    	var code = $(this).attr("data-couponcode");
        atmevents.removeCoupon(code);
    }
    //GTM events for remove coupon
    if(SitePreferences.isGTMEnabled) {
    	var code = $(this).attr("data-couponcode");
    	gtmevents.removeCoupon(code);
    }
    var couponRemoveDlg =  dialog.create({
        options:{ 
            width: "755px", 
            height: "auto",
            dialogClass : 'remove-coupon-cart',
            open: function() {
            	$('body').find('span.couponcode').html($current.data('couponcode'));
      		  	$('body').find('.couponuuid').val($current.data('uuid'));      		    
	      		$('body').off('click','.keep-coupon').on('click','.keep-coupon',function(e){
	      			dialog.close();
	          	});
	      		$('body').off('click','.coupon-remove').on('click','.coupon-remove',function(e){
	      			e.preventDefault();
	      		  //adobe analytics for remove coupon
	      			if(SitePreferences.isATMEnabled) {
	      		    	var code = $('.removeCoupon').attr("data-couponcode");
	      		    	atmevents.removeCoupon(code);
	      		    }
	      			//GTM events for remove coupon
	      		    if(SitePreferences.isGTMEnabled) {
	      		    	var code = $('.removeCoupon').attr("data-couponcode");
	      		    	gtmevents.removeCoupon(code);
	      		    }
	      			
	      			$('body').find('#remove-'+$('.couponuuid').val()).trigger('click');
	      		});	      		
            }
         } 
    });
    $(couponRemoveDlg).html($content.html());
    couponRemoveDlg.dialog("open");
});

$main.on('click', '.have-another-promo', function (e) {
    e.preventDefault();
    $main.find('.cart-coupon-code').show();
    $main.find('.cart-footer').addClass('promo-click');
    $main.find('.cart-shop-bottom').addClass('coupon-expand');
    //$main.find('.have-another-promo').hide();
    $main.find('.have-promo').hide();
    
    //adobe analytics for Have a Coupon code link on shopping cart
	if(SitePreferences.isATMEnabled) {
		atmevents.anotherCoupon();
    }
	//GTM events for Have a Coupon code link on shopping cart
	if(SitePreferences.isGTMEnabled) {
		gtmevents.anotherCouponEvent();
	}
    if($('.cart-coupon-code').is(':visible')){
    	//$('.cart-coupon-code').hide();
    }
    else{
    	//$('.cart-coupon-code').show(); 
    }
});

/*$main.on('click', '.button-text.cartpagecontinue', function (e) {
    e.preventDefault();
    page.redirect(Urls.continueHomePage);
});*/

//PHSSECOM-1360
$main.on('click', '.bonus-item-actions a', function (e) {
    e.preventDefault();
    bonusProductsView.show(this.href);
});
// ended-1360

function initQuickViewButtons() {
    $('.tiles-container .product-image').on('mouseenter', function () {
		var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            var qucikviewurl=  $(this).attr('href').split('#')[0];
            var quickviewnow = require('../quickview'); 
            quickviewnow.show({
                url: qucikviewurl,
                source: 'quickview'
            });
        });
    });
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	initQuickViewButtons();
	util.cartOuterheight();
	util.cartclassHeight();
	util.globalSlick();
	util.recentViewClassWidth();
	util.reconmmendtionNotShowing();
	
	//qty input box key code on press only number value.
	$('.cart-row').off('keypress','.item-quantity input').on('keypress','.item-quantity input', function(e) { 
    	var keycode = e.keyCode || e.which;
        if (keycode != 13 && keycode != 8 && keycode != 0 && keycode != 9 && keycode !=37 && keycode != 39 && keycode !=46 && (keycode < 48 || keycode > 57)) {
            return false;
        }
    });
	 
    //For showing Update Link 
    $('.item-quantity .qty-field').on('change, keyup', function(e) {
        e.preventDefault();
        var amount = $(this).val();
        //$(this).parents('.cart-row').find('.removecart').trigger('click');
        $(this).closest('.item-quantity').find('.update-individual-pli').removeClass('hide');
        $(this).closest('.item-quantity').find('.btn-remove-update').removeClass('hide');
        // cart product update button is visiable of not
        if ($('.update-cart-btn').is(':visible')) {
            $(this).closest('.item-quantity').addClass('update-visiable');
        } else {
            $(this).closest('.item-quantity').removeClass('update-visiable');
        }
        if ($(this).closest('.item-quantity').find('#updateqty').val() > 1000) {
            $(this).closest('.cart-row.cart-product').find('.qty-lineitm-msg').show();
        } else if ($(this).closest('.item-quantity').find('#updateqty').val() < 1001) {
            $(this).closest('.cart-row.cart-product').find('.qty-lineitm-msg').hide();
        }
        
        if($(this).hasClass('desktop-qty')) {
            $(this).parent().prev().find('#updateqty').val($(this).val());
        }
    });

/*    $('.item-quantity .qty-field').on('focusout', function(e) {
        var qty = $(this).closest('.item-quantity').find('input[name="qtyval"]').val();
        var newQtyVal = $(this).val();
        if (qty != newQtyVal) {
            $(this).closest('.item-quantity').find('.update-individual-pli').trigger("click");
        }    	
    });

    $('.update-individual-pli').on('click', function(e) {
        e.preventDefault();
        var $form = $(this).closest('#cart-items-form');
        var applyName = $(this).attr('name');
        progress.show('#main');
        $.ajax({
            url: $form.attr('action'),
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST',
            success: function(response) {
                if ($(response).length > 0 && $(response).find('.plp-content').length > 0) {
                    $main.find('.plp-content').html($(response).find('.plp-content').html());
                }
                initializeEvents();
                progress.hide();
            }
        });
    }); */

    //DiscountItem
/* Removing Cross Sell Product link from Cart Page
    $('.discounted-item').on('click', function(e) {
        e.preventDefault();
        var discountItem = $('input[name="discountitem"]').val();
        if (discountItem != undefined && discountItem != null && discountItem != 'null' && discountItem != '') {
            dialog.open({
                url: util.appendParamsToUrl(Urls.displayDiscountItem, {
                    'discountItem': discountItem
                }),
                options: {
                    autoOpen: false,
                    dialogClass: 'discount-item-for-course',
                    width: '835',
                    height: 'auto',
                    modal: true,
                    position: {
                        my: 'center',
                        at: 'center',
                        of: window,
                        collision: 'flipfit'
                    },
                    open: function() {
                        $('body').on('click', '.ui-icon-closethick', function(e) {
                            e.preventDefault();
                            dialog.close();
                        });
                        $('body').on('click', '.gotocheckout', function(e) {
                            e.preventDefault();
                            dialog.close();
                        });
                        $('body').on('click', '.add-discount-prod', function(e) {
                            e.preventDefault();
                            dialog.close();
                            var $url = util.appendParamsToUrl(Urls.addDiscountItem, {
                                pid: discountItem,
                                Quantity: 1,
                                format: 'ajax'
                            });
                            $.ajax({
                                url: $url,
                                datatType: 'json',
                                success: function(data) {
                                    page.redirect(Urls.cartShow);
                                }
                            })
                        });
                    }
                }
            });
        }
    }); */

	if($('.order-lever-promotion').is(':visible')){
		$('.discount-promo').addClass('visible-promo');
	}
	else{
		$('.discount-promo').removeClass('visible-promo');
	}
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
        util.updateselect();
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });
    
    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        } else if (e.which === 13) {
            e.preventDefault();
            $('#add-coupon').click();
        } // JIRA PREV-30 : Cart page:  Coupon Code is not applying, when the user hit enter key.
    });
    
  //adobe analytics and Google tag manager for apply coupon
    $("#add-coupon").on("click",function(e){
    	if(SitePreferences.isATMEnabled) {
        	var code = $('input[name$="_couponCode"]').val();
            atmevents.applyCoupon(code);
           }
    	//GTM for apply coupon event
    	if (SitePreferences.isGTMEnabled) {
        	gtmevents.applyCoupon(code);
        }
    });
    
  // adobe analytics for Event - Real Estate Tracking - Cart Page
    $('.cart-recommend-section a,.cart-recommendations .last-visited a').on("click",function(e){
    	if(SitePreferences.isATMEnabled){
    		var linkName = $(this).attr('alt');
    		var linkURL = $(this).attr('href');
    		atmevents.realEstateTrackingCartPage(linkName,linkURL);
    	}
    	if(SitePreferences.isGTMEnabled) {
    		gtmevents.realEstateTrackingCartPage($(this));
    	}
    	
    });
    
    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        } 
    });
    
	// Added For ARC Coupon Form Display Purpose
	if($main.find('.cartcoupon').length>0){
		$main.find('.cart-coupon-code').hide();
		if($main.find('.check-promo-coupon').val() == 'true'){
			$main.find('.discount-promo-stion').hide(); 
		}
		$main.find('.have-another-promo').text(Resources.CART_ANOTHER_COUPON_CODE);
		//$main.find('.have-another-promo').show();
		$main.find('.have-promo').show();
	}
	if($main.find('.cartcoupon').length>=5){
		$main.find('.cart-coupon-code').hide();
		//$main.find('.have-another-promo').hide();
		$main.find('.have-promo').hide();
	}
	$(window).resize(function(){
		if($(window).width() > 767) {
			util.cartOuterheight();
		} 
	});
	
	//adobe analytics and GTM event for remove item from cart 
	$('.item-user-actions').on("click",function(e){
		atmevents.removeItemFromCartpage(this);
		gtmevents.removeProductItemFromCart(this);
	});
	
   var $tiles = $('.tiles-container .product-tile');
    $tiles.on("click", 'a.quickview', function(e) {
    	if(SitePreferences.isATMEnabled) {
        	atmevents.productquickView(this);
        }
    });
}

//PREVAIL - Added for PayPal Integration.
/**
 * @private
 * @function
 * @description Initialize additional functionality related with PayPal integration
 */
function initPaypalFunctionality() {

	var dialog = require('../dialog');
	var util = require('../util');

    var $expressButton = $('.js_paypal_start_ba_checkout');

    $expressButton.on('click', function() {
        if ($expressButton.data('is-address-exist') === true) {
            return true;
        }
        dialog.open({
            url: $expressButton.data('edit-address-url'),
            options: {
                title: $expressButton.data('edit-address-title'),
                open: initEditDefaultShippingAddressForm
            }
        });
        return false;
    });

    function initEditDefaultShippingAddressForm() {
        var $form = $('#paypalEditDefaultShippingAddress');
        $form.on('click', '.apply-button', function() {
            if (!$form.valid()) {
                return false;
            }
            var applyName = $form.find('.apply-button').attr('name');
            var options = {
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
            $.ajax(options).done(function(data) {
                if (typeof(data) !== 'string') {
                    if (data.success) {
                        dialog.close();
                        window.location = $expressButton.attr('href');
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    $('#dialog-container').html(data);
                    initEditDefaultShippingAddressForm();
                }
            });
            return false;
        });
        $form.on('click', '.cancel-button, .close-button', function() {
            dialog.close();
            return false;
        });
        $('#paypalSelectSavedAddress').change(function() {
            var data = $(this).val();
            try {
                data = JSON.parse(data);
                for (name in data) {
                    var val = data[name];
                    if (typeof val === 'string') {
                        val = val.replace(/\^/g, "'");
                    }
                    $('#dwfrm_profile_address_' + name).val(val);
                }
            } catch (e) {
                $form.find('input:text').val('');
                $form.find('select').val('');
            }
        });
    }
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    //PREVAIL - Added for PayPal Integration
    if (SitePreferences.isPayPalEnabled) {
        initPaypalFunctionality();
    }
};
