'use strict';


var ajax = require('./ajax'),
    util = require('./util'),
    page = require('./page'),
    atmevents = require('./atmevents'),
    gtmevents = require('./gtm'),
    service = new google.maps.places.AutocompleteService(),
    sessionToken = new google.maps.places.AutocompleteSessionToken();
window.geolocation = {};
window.currentaddress = window.currentaddress || "";
window.apiFailure = false;

var api_inactive = SitePreferences.GOOGLE_API_DISABLED;
var prediction_click = false;
var prediction_error = false;
//new geocoder api object call
//https://developers.google.com/maps/documentation/javascript/geocoding
var geocoder;

function displaySuggestions(predictions, status, ele) {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
    }

    $(ele).closest('.geo-complete-results').find('.autocomplete-results').empty();
    predictions.forEach(function(prediction) {
        $(ele).closest('.geo-complete-results').find('.autocomplete-results').append('<li data-lat>' + prediction.description.replace(/, United States$/, "") + '</li>');
    });
    if($('.geo-complete-results').find('.autocomplete-results li:first').text().length > 0) {
        $('.autocomplete-results').append('<li class="poweredby"><span class="powered_by_google"></span></li>')
    }
    if (predictions != null && predictions.length == 0) {
        $(ele).closest('.geo-complete-results').find('.autocomplete-results').append('<div class="use-current-location"><span class="geo-arrow"></span><span class="use-location">Use my location</span></div>');
    } else {
        $(ele).closest('.geo-complete-results').find('.autocomplete-results li:last-child').after('<div class="use-current-location"><span class="geo-arrow"></span><span class="use-location">Use my location</span></div>');
    }

    $('.autocomplete-results').hide();
    $(ele).closest('.geo-complete-results').find('.autocomplete-results').show();
    $('.autocomplete-results li').closest('.geo-complete-results').find('.use-current-location').css('border-top', 'solid 1px #d6d6d6');
};

function currentLocation() {
    window.geolocation = {
        lat: $('.geo-latitude').val(),
        lng: $('.geo-longitude').val()
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            window.geolocation = {
                lat: (typeof position.coords == "object") ? position.coords.latitude : $('.geo-latitude').val(),
                lng: (typeof position.coords == "object") ? position.coords.longitude : $('.geo-longitude').val()
            };
        });
    }
}

function setGeoValues(lat, long) {
    if (typeof(Storage) !== "undefined" && lat != null && long != null && sessionStorage.getItem(lat + "||" + long)) {
        var $latLong = JSON.parse(sessionStorage.getItem(lat + "||" + long));
        var $geo_cont = $('.geo-complete-results');
        $geo_cont.find('.geo-complete-lat').val($latLong.latitude);
        $geo_cont.find('.geo-complete-lng').val($latLong.longitude);
        window.currentaddress = $latLong.address;
        return false;
    }
    var latlong = {lat: parseFloat(lat), lng: parseFloat(long)};
    geocoder.geocode({'location': latlong}, function(results, status) {
    	if (status === 'OK') {
    		if (Array.isArray(results) && typeof results[0] != "undefined") {
    			 window.currentaddress = "";
                 $.each(results[0].address_components, function(i, v) {
                     if (v.types[0] == "country" || v.types[0] == "administrative_area_level_1" || v.types[0] == "locality" || v.types[0] == "political") {
                         if (window.currentaddress.length == 0) {
                             window.currentaddress = v.types[0] == "administrative_area_level_1" ? (v.short_name != null ? v.short_name : '') : v.long_name.replace(/, United States$/, "");
                         } else if (v.types[0] != "country") {
                             window.currentaddress += ', ' + (v.types[0] == "administrative_area_level_1" ? v.short_name : v.long_name);
                         }
                     }
                 });
	        }
    		if (window.currentaddress != '' && !($('.global-header-main').find('input[name="seslatlong"]').val())) {
                $('.geo-complete-input').val(window.currentaddress);
                var $geo_cont = $('.geo-complete-results');
                $geo_cont.find('.geo-complete-lat').val(window.geolocation.lat);
                $geo_cont.find('.geo-complete-lng').val(window.geolocation.lng);
            }
            if (typeof(Storage) !== "undefined") {
                var $geo_cont = $('.geo-complete-results');

                $geo_cont.find('.geo-complete-lng').val();
                // Store in the browser
                sessionStorage[lat + "||" + long] = JSON.stringify({
                    "latitude": lat,
                    "longitude": long,
                    "address": window.currentaddress
                });
            }
        }
    });
}

//@FIX - PHSSECOM-354
//function to set the first prediction returned by the API in to the geo input field
//params - prediction text, element and event
function setFirstPrediction($predictiontext, $element, $event) {
    if (!api_inactive && !window.apiFailure) {
    	$element.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
    }
    var $geo_cont = $element.closest('form');
    
    var address = $predictiontext;
    geocoder.geocode({'address': address}, function(results, status) {
    	if (Array.isArray(results) && typeof results[0] != "undefined") {
            if (status == "OK") {
                $geo_cont.find('.geo-complete-lat').val(results[0].geometry.location.lat());
                $geo_cont.find('.geo-complete-lng').val(results[0].geometry.location.lng());
                $geo_cont.find('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
            } else {
                if (geolocation == undefined) {
                    return;
                }
                $geo_cont.find('.geo-complete-lat').val(geolocation.lat);
                $geo_cont.find('.geo-complete-lng').val(geolocation.lng);
                $geo_cont.find('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
            }

            $.each(results[0].address_components, function(i, v) {
                if (v.types[0] == "postal_code") {
                    $('.geo-complete-zip').val(v.types[0] == "postal_code" ? v.short_name : v.long_name)
                }
            });
        }
    });

    $element.closest('.geo-complete-results').find('.geo-complete-input').val($predictiontext);
    if (($element.closest('.cat-search').find('.select-class').val() != "Select a Class Type" || $element.closest('.cat-search').find('.select-class').val() != "Choose a Class") && $element.closest('.cat-search').find('.geo-location').val() != "") {
        $element.removeAttr('disabled');
        $element.siblings('.autocomplete-results').hide();
    }
}

function currentAddress() {
    navigator.geolocation.getCurrentPosition(function(position) {
        setGeoValues(position.coords.latitude, position.coords.longitude);
    });
    if (window.currentaddress != '' && !($('.global-header-main').find('input[name="seslatlong"]').val())) {
        $('.geo-complete-input').val(window.currentaddress);
        var $geo_cont = $('.geo-complete-results');
        $geo_cont.find('.geo-complete-lat').val(window.geolocation.lat);
        $geo_cont.find('.geo-complete-lng').val(window.geolocation.lng);
    } else {
        if ($('.geo-akmai').val() != undefined) {
            $('.geo-complete-input').val($('.geo-akmai').val().toLowerCase().replace(/, united states$/, ""));
            //TSED-987: Find A Class Tool Redesign
            if($('.geo-complete-new').find('span.geo-complete-value').length > 0 && $('.geo-akmai').val() != '') {
            	var locationValue = $('.geo-akmai').val().replace(/, United States$/, "").replace(/\d+/, '').replace(/, USA$/, "").replace(/, usa$/, "");
            	var arr = locationValue.split(',');
            	arr[0] = arr[0].toString().substr(0,1).toUpperCase() + arr[0].toString().substr(1);
            	if(arr[1] != undefined && arr[1] != '') {
            		arr[1] = arr[1].toUpperCase();
            	}
            	$('.geo-complete-new span.geo-complete-value').text(arr.toString());
            	$('.geo-location.geo-complete-new').prop('title', arr.toString());
            }
        }

        var $geo_cont = $('.geo-complete-results');
        $geo_cont.find('.geo-complete-lat').val($('.geo-latitude').val());
        $geo_cont.find('.geo-complete-lng').val($('.geo-longitude').val());
        setGeoValues($('.geo-latitude').val(), $('.geo-longitude').val());
    }
}


exports.init = function() {
	geocoder = new google.maps.Geocoder();

    //on load set current state information
    currentLocation();
    currentAddress();
    var $timer = 0;
    //click events on autocomplete text and populates lat,long values.
    $(document).on('keyup', '.geo-complete-results .geo-complete-input', function(e) {
        var $this = $(this);
        if ($(this).val() == "" || $(this).val().length < 3) {
            return false;
        }
        var inputVal = $(this).val();
        prediction_click = false;
        prediction_error = false;
        window.clearTimeout($timer);
        $timer = setTimeout(function() {
            if (typeof(Storage) !== "undefined" && sessionStorage.getItem(inputVal) != null) {
                var $session = JSON.parse(sessionStorage.getItem(inputVal));
                displaySuggestions($session.predictions, $session.status, $this);
                //@FIX : PHSSECOM-354, on keyup, the first pridiction is stored at browser storage
                if ($this.closest('.geo-complete-results').find('.autocomplete-results li').length > 0) {
                    var $firstPrediction = $this.closest('.geo-complete-results').find('.autocomplete-results li').first().text();
                    sessionStorage['firstPredication_' + inputVal.trim()] = JSON.stringify({
                        "firstprediction": $firstPrediction,
                        "isonpredicationclick": false
                    });
                    $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled'); //search is enabled when the predictions are found
                } else {
                	if (!api_inactive && !window.apiFailure) {
                		$this.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
                	}
                }
            } else {
                service.getPlacePredictions({
                    input: $this.val(),
                    types: ['(regions)'],
                    componentRestrictions: {
                        //TSED-535: Allow CPS Courses to be Searched in Puerto Rico on RCO
                    	country: ["us", "pr", "vi", "gu", "mp"]
                        //country: 'US'
                    },
                    sessionToken: sessionToken
                }, function(predictions, status) {
                    if (predictions == null && status == "OVER_QUERY_LIMIT") {
                        prediction_error = true;
                        $('.geo-complete-results').find('.autocomplete-results').empty();
                        if (sessionStorage.getItem(inputVal) != null) {
                            var $session = JSON.parse(sessionStorage.getItem(inputVal));
                            displaySuggestions($session.predictions, $session.status, $this);
                            //@FIX : PHSSECOM-354, on keyup, the first pridiction is stored at browser storage
                            if ($this.closest('.geo-complete-results').find('.autocomplete-results li').length > 0) {
                                var $firstPrediction = $this.closest('.geo-complete-results').find('.autocomplete-results li').first().text();
                                sessionStorage['firstPredication_' + inputVal] = JSON.stringify({
                                    "firstprediction": $firstPrediction,
                                    "isonpredicationclick": false
                                });
                                $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
                            } else {
                            	if (!api_inactive && !window.apiFailure) {
                            		$this.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
                            	}
                            }
                        }
                    } else if (predictions == null) {
                        /*if (typeof(Storage) !== "undefined") {
                   	 		  // Store in the browser
                   	 		 sessionStorage[inputVal] = JSON.stringify({
  	                 	 		  "predictions" : [],
  	                 	 		  "status" : "OK"
                   	 		 });
                   	 	   }*/
              	 	    //@FIX : PHSSECOM-354, on keyup, the first pridiction is stored at browser storage
              	 	    //this is to make sure the search is trigerred all the time suggestion are visible on store front
                        if ($this.closest('.geo-complete-results').find('.autocomplete-results li').length > 0) {
                            var $firstPrediction = $this.closest('.geo-complete-results').find('.autocomplete-results li').first().text();
                            sessionStorage['firstPredication_' + inputVal] = JSON.stringify({
                                "firstprediction": $firstPrediction,
                                "isonpredicationclick": false
                            });
                            $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
                        } else {
                        	if (!api_inactive && !window.apiFailure) {
                        		$this.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
                        	}
                        }
                    } else {
                        if (typeof(Storage) !== "undefined") {
                            // Store in the browser
                            sessionStorage[inputVal] = JSON.stringify({
                                "predictions": predictions,
                                "status": status
                            });
                        }
                        displaySuggestions(predictions, status, $this);
                        //@FIX : PHSSECOM-354, on keyup, the first pridiction is stored at browser storage
                        if ($this.closest('.geo-complete-results').find('.autocomplete-results li').length > 0) {
                            var $firstPrediction = $this.closest('.geo-complete-results').find('.autocomplete-results li').first().text();
                            sessionStorage['firstPredication_' + inputVal] = JSON.stringify({
                                "firstprediction": $firstPrediction,
                                "isonpredicationclick": false
                            });
                            $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
                        } else {
                        	if (!api_inactive && !window.apiFailure) {
                        		$this.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
                        	}
                        }
                    }
                });
            }
        }, 400);


    }).on('change', '.class-hero .class-search.cat-search,.header-class-search.cat-search,.search-tabs .class-search.cat-search', function() {
        if ($('.custom-select .validate-message').length > 0) {
            $('.custom-select .validate-message').remove();
        }
        $('div.cat-search').find('.custom-select .selected-option').removeClass('error');
        prediction_click = false;
    }).on('click', '.header-class-search .header-geo-submit,.find-a-class-submit,.home-class .submitbutton,.global-geo .header-geo-submit, .location-radius-selection .update-filters .apply-btn, .mobile-filters .update-filters .apply-btn', function(e) {
        //analytics Find A Class
    	if ($(this).closest('.header-class-search').length > 0 || $(this).closest('.classes-search-action').length > 0 || $(this).closest('.home-class').length > 0) {
    		//adobe analytics
    		if (SitePreferences.isATMEnabled) {
    			atmevents.findAClass(this);
    		}
    		//GTM analytics
    		if(SitePreferences.isGTMEnabled) {
    			gtmevents.findAClass(this);
    		}
    	}
    	//TSED-987: Location pop up is clicked and user not provided city/zip and closes the pop up. 
    	if($('.find-a-class-newdesign').find('.geo-complete-new span.geo-complete-value').length > 0) {
    		if($('.geo-complete-input').val() == '' && $('.find-a-class-newdesign').find('.geo-complete-new span.geo-complete-value').text() != '') {
    			$('.find-a-class-newdesign').find('.geo-complete-input').val($('.geo-complete-new span.geo-complete-value').text());
        	}
    	}
    	
        
        //@FIX : PHSSECOM-354, set first prediction in to text field
        if (typeof(Storage) !== "undefined" && $('.geo-complete-input').val().length > 0 && sessionStorage.getItem('firstPredication_' + $('.geo-complete-input').val()) != null) {
            var $sessionPrediction = JSON.parse(sessionStorage.getItem('firstPredication_' + $('.geo-complete-input').val()));
            if (!$sessionPrediction.isonpredicationclick) {
                setFirstPrediction($sessionPrediction.firstprediction, $(this), e);
            }
        }

        var radius = $('.location-radius select').find('option:selected').val();
        if ($(this).closest('.mobile-filters').length > 0 && $('.stores-value .geo-complete-input').val() != '' && (($('.zip').val() != '' && $('.zip').val() == $('.stores-value .geo-complete-input').val()) && $('.radius').val() == radius)) {
            return false;
        }
        if (prediction_error && ($(this).closest('.cat-search').find('.select-class').val() != "Select a Class Type" || $(this).closest('.cat-search').find('.select-class').val() != "Choose a Class") && $(this).closest('.cat-search').find('input.geo-location').val() != "") {
            e.preventDefault();
            var $this = $(this);

            $geo_cont = $this.closest('form');

            if ($(this).closest('.update-filters').length > 0) {
                $geo_cont = $('.location-radius-selection').find('.geo-complete-results');
            }

            // $geo_cont.find('.header-geo-submit').attr('disabled','disabled'); // Added For PHSSDWRE-3827
            var address = $this.closest('.header-class-search').find('.geo-complete-input').val();
            geocoder.geocode({'address': address, 'componentRestrictions': {country: 'US'}}, function(results, status) {
                if (results != null && status == 'OK') {
                	$this.closest('form').find('.geo-complete-input').val(results[0].formatted_address);
                    $geo_cont.find('.geo-complete-lat').val(results[0].geometry.location.lat());
                    $geo_cont.find('.geo-complete-lng').val(results[0].geometry.location.lng());
                    // $geo_cont.find('.header-geo-submit').removeAttr('disabled'); // Added For PHSSDWRE-3827
                } else {
                	if (geolocation == undefined) {
                        return;
                    }
                    $geo_cont.find('.geo-complete-lat').val(geolocation.lat);
                    $geo_cont.find('.geo-complete-lng').val(geolocation.lng);
                    //$geo_cont.find('.header-geo-submit').removeAttr('disabled'); // Added For PHSSDWRE-3827
                    
                	console.log('Geocode was not successful for the following reason: ' + status);
                }
                
                if (typeof results == "object" && typeof results[0] != "undefined") {
                    $.each(results[0].address_components, function(i, v) {
                        if (v.types[0] == "postal_code") {
                            $('.geo-complete-zip').val(v.types[0] == "postal_code" ? v.short_name : v.long_name)
                        }
                    });
                }
                prediction_error = false;
                prediction_click = true;
                $this.trigger('click');
                //@FIX : PHSSECOM-354, set first prediction in to text field
                $('body').find('ul.autocomplete-results').hide();
                if (!api_inactive && !window.apiFailure) {
                	$geo_cont.find('.header-geo-submit').attr('disabled', 'disabled');
                }
              });
        } else if (!prediction_click) {
            e.preventDefault();
            var $this = $(this);
            //@FIX : PHSSECOM-354, show error message when search is clicked without category 
            if ($(this).closest('.cat-search').find('.select-class').val() == "Select a Class Type") {
                $('<span/>').attr('class', 'validate-message').appendTo($(this).closest('div.cat-search').find('.custom-select'));
                $(this).closest('div.cat-search').find('.custom-select .selected-option').addClass('error');
                $(this).closest('.cat-search').find('span.validate-message').text('Please select a Class Type');
                $('body').find('ul.autocomplete-results').hide();
                return false;
            }
            
            //TSED-987: Find A Class Tool Redesign - If any of the three fields are not completed, then display the error message above the course finder.
            /*if ($('.find-a-class.new-design').find('.findaclass-error-meesage').text().length > 0) {
            	$('.find-a-class.new-design').find('.findaclass-error-meesage').addClass('hide');
            }*/
            
            if ($(this).closest('.cat-search').find('.select-class').val() == "Choose a Class" || ($(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').length > 0 && $(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').text() == "")) {
            	if($('.find-a-class.new-design').find('.findaclass-error-meesage').length > 0) {
                    $('.find-a-class.new-design').find('.findaclass-error-meesage').show();
                    $('.find-a-class.new-design').find('.find-a-class-submit').prop("disabled", true);
            	}
            	return false;
            }
            
            //TSED-987: Find A Class Tool Redesign
            var $val = '', $geo_cont = $this.closest('form');
            if($(this).closest('.find-a-class-newdesign').length > 0) {
            	$val = $this.closest('form').find('.geo-complete-input').val() != '' ? $this.closest('form').find('.geo-complete-input').val() : '';
            } else {
            	$val = $this.closest('form').find('.autocomplete-results li').length == 0 ? $this.closest('form').find('.geo-complete-input').val() : $this.closest('form').find('.autocomplete-results li').eq(0).text();
            }
            
            $this.closest('form').find('.geo-complete-input').val($val);

            if ($this.closest('form').find('.autocomplete-results li').length == 0 && typeof(Storage) !== "undefined" && sessionStorage.getItem($val) != null) {
                var $session = JSON.parse(sessionStorage.getItem($val));
                $val = $session.predictions[0].description.replace(/, United States$/, "");
            }

            if ($(this).closest('.update-filters').length > 0) {
                $geo_cont = $('.location-radius-selection').find('.geo-complete-results');
                $val = $('.stores-value .autocomplete-results li').length == 0 ? $('.stores-value .geo-complete-input').val() : $('.stores-value .autocomplete-results li').eq(0).text();
                $('.stores-value .geo-complete-input').val($val);
            }
            var browserType = false;
            if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                browserType = true;
            }
            //$geo_cont.find('.header-geo-submit').attr('disabled','disabled'); // Added For PHSSDWRE-3827
            //and here
            var address = $val;
            geocoder.geocode({'address': address, 'componentRestrictions': {country: 'US'}}, function(results, status) {
            	if (results != null && status == 'OK') {
            		$geo_cont.find('.geo-complete-lat').val(results[0].geometry.location.lat());
                    $geo_cont.find('.geo-complete-lng').val(results[0].geometry.location.lng());
                    //$geo_cont.find('.header-geo-submit').removeAttr('disabled'); // Added For PHSSDWRE-3827
                } else {
                	if (geolocation == undefined) {
                		return;
                    }
                    $geo_cont.find('.geo-complete-lat').val(geolocation.lat);
                    $geo_cont.find('.geo-complete-lng').val(geolocation.lng);
                    //$geo_cont.find('.header-geo-submit').removeAttr('disabled'); // Added For PHSSDWRE-3827
                }
                prediction_click = true;
                prediction_error = false;
                if (Array.isArray(results) && typeof results[0] != "undefined") {
                	$.each(results[0].address_components, function(i, v) {
                		if (v.types[0] == "postal_code") {
                              $('.geo-complete-zip').val(v.types[0] == "postal_code" ? v.short_name : v.long_name)
                        }
                    });
                }
                $this.trigger('click');
                $('body').find('ul.autocomplete-results').hide();
                if (!api_inactive && !window.apiFailure){
                	$geo_cont.find('.header-geo-submit').attr('disabled', 'disabled');
                }
            });
        } else if (($(this).closest('.cat-search').find('.select-class').val() != "Select a Class Type" || ($(this).closest('.cat-search.find-a-class-newdesign').find('.select-class').length > 0 && $(this).closest('.cat-search.find-a-class-newdesign').find('.select-class').val() != "Choose a Class")) && $(this).closest('.cat-search').find('input.geo-location').val() != "" && $(this).closest('.update-filters').length == 0) {
            var $geo_cont = $(this).closest('form');
            //TSED-987: Find A Class Tool Redesign - If Choose a class or location fields are not filled, then display the error message above the course finder.
            if ($(this).closest('.cat-search').find('.select-class').val() == "Choose a Class" || ($(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').length > 0 && $(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').text() == "")) {
            	if($('.find-a-class.new-design').find('.findaclass-error-meesage').length > 0) {
                    $('.find-a-class.new-design').find('.findaclass-error-meesage').show();
                    $('.find-a-class.new-design').find('.find-a-class-submit').prop("disabled", true);
            	}
            	return false;
            }
            //TSED-987: The only required fields the customer needs to search on will be location and course category. Course format will be optional to enter.
            var courseFormatValue = $(this).closest('.cat-search').find('.course-formats-list').val();
            if(courseFormatValue == "All Formats") {
            	$(this).closest('.cat-search').find('.course-formats-list').val('');
            }
            //TSED-987: Fix for filtering the Online course format when user selects Online only category on course finder tool.
            if($('.select-class.cat-search').length && $('.course-formats-list').length) {
            	var course_selected = $('.select-class.cat-search').find('option:selected').val();
        		var class_selected = $('.course-formats-list').find('option:selected').val();
        		if(course_selected === 'online-safety-classes' && class_selected === 'Online'){
        			$geo_cont.find('input[name="courseformat"]').val('Online');
        		} else {
        			$geo_cont.find('input[name="courseformat"]').val(class_selected);
        		}
            }
            
            //TSED-987: Find a class dataLayer snippet
        	if($(this).closest('.find-a-class-newdesign').length > 0) {
        		//GTM analytics
        		if(SitePreferences.isGTMEnabled) {
        			gtmevents.findAClass(this);
        		}
        	}
            $geo_cont.submit();
            if (!api_inactive && !window.apiFailure) {
            	$geo_cont.find('.header-geo-submit').attr('disabled', 'disabled');
            }
            return true;
        } else if ($(this).closest('.update-filters').length > 0 && $('.stores-value').find('.geo-location').val() != '') {
            var radius = $('.location-radius select').find('option:selected').val();
            if($(window).width() < 1024){
            	if($('.location-radius .select-class').hasClass('changed')) {
    	            page.redirect(util.appendParamsToUrl(Urls.subCatRedirect, {
    	                "cgid": $('.cgid').val(),
    	                "zip": $('.stores-value .geo-complete-input').val(),
    	                "latitude": $('.stores-value .geo-complete-lat').val(),
    	                "longitude": $('.stores-value .geo-complete-lng').val(),
    	                "searchtype": $('.searchtype').val(),
    	                "radiusval": radius,
    	                'radiusRefine': 'true'
    	            }));
    	            return true;
                }else {
                	return;
                }
            } else {
            	page.redirect(util.appendParamsToUrl(Urls.subCatRedirect, {
	                "cgid": $('.cgid').val(),
	                "zip": $('.stores-value .geo-complete-input').val(),
	                "latitude": $('.stores-value .geo-complete-lat').val(),
	                "longitude": $('.stores-value .geo-complete-lng').val(),
	                "searchtype": $('.searchtype').val(),
	                "radiusval": radius,
	                'radiusRefine': 'true'
	            }));
	            return true;
            }
        } else {
            e.preventDefault();
            prediction_click = false;
            if ($('.autocomplete-results').is(':visible')) {
                $('body').find('ul.autocomplete-results').hide();
            }
            if ($(this).closest('.cat-search').find('span.validate-message').length > 0) {
                $(this).closest('.cat-search').find('span.validate-message').remove();
            }
            
            //TSED-987: Find A Class Tool Redesign - If any of the three fields are not completed, then display the error message above the course finder.
            if ($(this).closest('.cat-search').find('.select-class').val() == "Choose a Class" || ($(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').length > 0 && $(this).closest('.cat-search').find('.geo-complete-new span.geo-complete-value').text() == "")) {
            	if($('.find-a-class.new-design').find('.findaclass-error-meesage').length > 0) {
                    $('.find-a-class.new-design').find('.findaclass-error-meesage').show();
            	}
            	return false;
            } else if($(this).closest('.cat-search').find('.select-class').val() == "Select a Class Type") {
                $('<span/>').attr('class', 'validate-message').appendTo($(this).closest('div.cat-search').find('.custom-select'));
                $(this).closest('div.cat-search').find('.custom-select .selected-option').addClass('error');
                $(this).closest('.cat-search').find('span.validate-message').text('Please select a Class Type');
            } else {
                if ($(this).closest('.update-filters').length > 0) {
                    if ($(window).width() < 1024) {
                        $('.mobile-fltr-product').addClass('active')
                        $('.refinement.facilityid').addClass('active');
                    }
                    $('<span/>').attr('class', 'validate-message').appendTo($('div.stores-value').find('.header-geo-field'));
                    $('div.stores-value').find('.header-geo-field').addClass('error');
                    $('.stores-value').find('span.validate-message').text('Please select Location');
                } else {
                    $('<span/>').attr('class', 'validate-message').appendTo($(this).closest('div.cat-search').find('.header-geo-field'));
                    $(this).closest('div.cat-search').find('.header-geo-field').addClass('error');
                    $(this).closest('.cat-search').find('span.validate-message').text('Please select Location');
                }
            }
            return false;
        }

    }).on('blur', '.geo-complete-results .geo-complete-input', function(e) {
        $(this).closest('.autocomplete-results').hide();
        $(this).closest('.header-geo-field').removeClass('no-border');
        prediction_click = false;
        var inputValue = $('.geo-complete-input').val().trim();

        if ($(this).parents().hasClass('location-popup')) {
            inputValue = $('.location-popup .geo-complete-input').val().trim();
        }

        //@FIX : PHSSECOM-354, set first prediction in to text field
        if (typeof(Storage) !== "undefined" && sessionStorage.getItem('firstPredication_' + inputValue) != null && inputValue.length > 0) {
            var $sessionPrediction = JSON.parse(sessionStorage.getItem('firstPredication_' + inputValue));
            if (!$sessionPrediction.isonpredicationclick) {
                setFirstPrediction($sessionPrediction.firstprediction, $(this), e);
            }
        }
    }).on('focus', '.geo-complete-results .geo-complete-input', function(e) {
        $(this).closest('.header-geo-field').addClass('no-border');
        prediction_click = false;
        if ($('.header-geo-field .validate-message').length > 0) {
            $('.header-geo-field .validate-message').remove();
        }
		//@FIX : PHSSECOM-354, set first prediction in to text field
        if (typeof(Storage) !== "undefined" && sessionStorage.getItem('firstPredication_' + $('.geo-complete-input').val().trim()) != null && $('.geo-complete-input').val().trim().length > 0) {
            var $sessionPrediction = JSON.parse(sessionStorage.getItem('firstPredication_' + $('.geo-complete-input').val().trim()));
            if (!$sessionPrediction.isonpredicationclick) {
                setFirstPrediction($sessionPrediction.firstprediction, $(this), e);
            }
        }
        $('.geo-complete-input').val('');
        if (!api_inactive && !window.apiFailure){
        	$(this).closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
        }
        $('body').find('ul.autocomplete-results li').empty();
        $(this).closest('.geo-complete-results').find('.autocomplete-results').show();
        $('body').find('ul.autocomplete-results .use-current-location').css('border-top', 'none').show();
        $('.header-geo-field').removeClass('error');
    }).on('click', '.geo-complete-results .autocomplete-results li', function(e) {
        var $val = $(this).text(),
            $geo_cont = $(this).closest('.geo-complete-results'),
            $this = $(this);
        prediction_click = true;
		//@FIX : PHSSECOM-354, set first prediction in to text field
        if (typeof(Storage) !== "undefined" && sessionStorage.getItem('firstPredication_' + $val) != null) {
            var $sessionPrediction = JSON.parse(sessionStorage.getItem('firstPredication_' + $val));
            sessionStorage['firstPredication_' + $val] = JSON.stringify({
                "firstprediction": $sessionPrediction,
                "isonpredicationclick": true
            });
        }
        $(this).closest('.geo-complete-results').find('.geo-complete-input').val($val);
        if (!api_inactive && !window.apiFailure){
        	$this.closest('.geo-complete-results').find('.header-geo-submit').attr('disabled', 'disabled');
        }

        var address = $val;
        geocoder.geocode({'address': address}, function(results, status) {
            if (Array.isArray(results) && typeof results[0] != "undefined") {
            	if (status == "OK") {
                    $geo_cont.find('.geo-complete-lat').val(results[0].geometry.location.lat());
                    $geo_cont.find('.geo-complete-lng').val(results[0].geometry.location.lng());
                    $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
                } else {
                    if (geolocation == undefined) {
                        return;
                    }
                    $geo_cont.find('.geo-complete-lat').val(geolocation.lat);
                    $geo_cont.find('.geo-complete-lng').val(geolocation.lng);
                    $this.closest('.geo-complete-results').find('.header-geo-submit').removeAttr('disabled');
                }

                $.each(results[0].address_components, function(i, v) {
                    if (v.types[0] == "postal_code") {
                        $('.geo-complete-zip').val(v.types[0] == "postal_code" ? v.short_name : v.long_name)
                    }
                });
            }
        });

        //$this.parent().siblings('.header-geo-submit').removeAttr('disabled');
        $(this).closest('.autocomplete-results').hide();
    }).on('click', '.use-current-location .use-location,.geo-arrow, .header-geo-icon,.result-classes', function() {
        $(this).closest('.autocomplete-results').hide();
        $(this).closest('.header-geo-location').find('.header-geo-submit').removeAttr('disabled');
        if ($('span.validate-message').length > 0) {
            $('.header-geo-field').removeClass('error');
            $('.header-geo-field .validate-message').remove();
        }
        if($('.location-popup div.label-container .location-error').length > 0){
            $('div.label-container .location-error').hide();
        }
        if (window.currentaddress != "" && $(this).closest('.result-classes').length > 0) {
            page.redirect(util.appendParamsToUrl(Urls.subCatRedirect, {
                "cgid": 'first-aid',
                "zip": window.currentaddress,
                "latitude": window.geolocation.lat,
                "longitude": window.geolocation.lng,
                "searchtype": 'class'
            }));
        } else if ($(this).closest('.result-classes').length > 0) {
            page.redirect(util.appendParamsToUrl(Urls.subCatRedirect, {
                "cgid": 'first-aid',
                "zip": $('.geo-akmai').val().toLowerCase().replace(/, united states$/, ""),
                "latitude": $('.geo-latitude').val(),
                "longitude": $('.geo-longitude').val(),
                "searchtype": 'class'
            }));
        }

        if (window.currentaddress != "") {
            $(this).closest('.geo-complete-results').find('.geo-complete-input').val(window.currentaddress);
            $(this).closest('.geo-complete-results').find('.geo-complete-lat').val(window.geolocation.lat);
            $(this).closest('.geo-complete-results').find('.geo-complete-lng').val(window.geolocation.lng);
        } else {
            $(this).closest('.geo-complete-results').find('.geo-complete-input').val($('.geo-akmai').val().toLowerCase().replace(/, united states$/, ""));
            $(this).closest('.geo-complete-results').find('.geo-complete-lat').val($('.geo-latitude').val());
            $(this).closest('.geo-complete-results').find('.geo-complete-lng').val($('.geo-longitude').val());
        }
        $('body').find('ul.autocomplete-results li').empty();
        $('body').find('ul.autocomplete-results .use-current-location').css('border-top', 'none').hide();
    });
};

//the global callback function for api failure (i.e. bad api key)
//see - https://developers.google.com/maps/documentation/javascript/events#auth-errors
window.gm_authFailure = function() {
	jQuery("input[id$='billingAddress_addressFields_address1']").attr("placeholder", "").attr("style", "").prop("disabled", false).removeClass("gm-err-autocomplete");
	jQuery("input[id$='shippingAddress_addressFields_address1']").attr("placeholder", "").attr("style", "").prop("disabled", false).removeClass("gm-err-autocomplete");
	window.apiFailure = true;
};
